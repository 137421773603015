import * as upcService from "../api/services/upcServices";
import {axiosError} from "../api/services/errorHandler";

export const dbCheckCommitmentLetterValidity = (callback) => {
    return (dispatch, getState) => {
        return upcService.dbCheckCommitmentLetterValidity().then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data.data
                })
            },
            (error) =>{
            });
    }
};

export const dbCreateUpcApplication = (data, callback) => {
    return (dispatch, getState) => {
        return upcService.dbCreateUpcApplication(data, callback).then((result) => {
                callback(result.request);
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbRequestDataForUpcApplications = (pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return upcService.dbRequestDataForUpcApplications(pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                axiosError(error, callback);
            });
    }
};

export const dbRequestDataForUpcList = (pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return upcService.dbRequestDataForUpcList(pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                axiosError(error, callback);
            });
    }
};

export const dbGetUpcApplication = (applicationId, callback) => {
    return (dispatch, getState) => {
        return upcService.dbGetUpcApplication(applicationId).then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbGetUpcDetail = (applicationId, callback) => {
    return (dispatch, getState) => {
        return upcService.dbGetUpcDetail(applicationId).then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

