import React, {Component} from 'react'
import {Route} from "react-router-dom";
import GuestWrapper from "../components/GuestWrapper";

class PublicRoute extends Component{

    render () {
        const {path, component, fullwidth} = this.props;
        return (
            <Route path={path} render={() => {
                return (
                    <GuestWrapper component={component} fullwidth={fullwidth}/>
                )
            }} />
        )
    }
}

export default PublicRoute;
