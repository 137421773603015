import axios from 'axios';
import {axiosError} from "./errorHandler";
import {getHeaderWithToken} from "./authToken";
import {
    gtin13ApplicationDetail,
    gtin13ApplicationEdit,
    gtin13NewApplicationUrl,
    requestDataForGtin13List
} from "../../constants/GcpUrlConstants";


export const dbCreateGtin13Application = (data, callback) => {
    return new Promise(((resolve, reject) => {
        axios.post(gtin13NewApplicationUrl, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbRequestDataForGtin13List = (applicationId, pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForGtin13List + "?application_id=" + applicationId +
            "&pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbLoadGtin13ApplicationDetail = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(gtin13ApplicationDetail + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbLoadGtin13Application = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(gtin13ApplicationEdit + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbEditGtin13Application = (data, applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.post(gtin13ApplicationEdit + "?application_id=" + applicationId, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

