const getAuthToken = () => {
    let token = localStorage.getItem("authToken");
    if(token && token.trim())
        return token;
    return "";
};

export const getLocale = () => {
    let language = localStorage.getItem("locale");

    if (language && language.trim())
        return language;
    return "tr"
};

export const getHeaderWithToken = () => {
    return {
        headers: {
            'Authorization': 'Token ' + getAuthToken(),
            'Accept-Language': getLocale()
        }
    };
};

export const getHeaderTokenWithMultipart = () => {
    return {
        headers: {
            'Authorization': 'Token ' + getAuthToken(),
            'content-type': 'multipart/form-data',
            'Accept-Language': getLocale()
        }
    };
};

export const getDefaultHeader = () => {
    return {
        headers: {
            'Accept-Language': getLocale()
        }
    };
};