import React, {Component} from 'react'
import * as upcActions from "../../actions/upcActions";
import {T, withTranslators} from "lioness";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TextInput from "../input_types/TextInput";
import ContentModule from "../ContentModule";
import Gtin12List from "../gtin12/Gtin12List";

class UpcDetail extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            application: {
                upc: "",
                status: "",
                registryDate: "",
            }
        };
    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }

        this.props.loadApplicationDetail(applicationId, this.applicationItemCallback);
    }

    applicationItemCallback = (result) => {
        if (result.status === 200){
            this.setState({
                application: {
                    upc: result.data.upc,
                    status: result.data.status_name,
                    registryDate: result.data.registry_dt,
                },
                currentStep: 2
            })
        }
    };

    render(){
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>UPC Number View</T></h2>

                <ContentModule>
                    <T>The datails of your UPC number can be seen below. If you have created GTIN-12 using your UPC the products will appear in the list below.</T>
                </ContentModule>

                {
                    this.state.currentStep === 1 ?
                        <div><T>Loading...</T></div> :

                        <React.Fragment>
                            <ContentModule>
                                <form>
                                    <div className="editor small signin">
                                        <div>
                                            <TextInput key={"upc"} inputId={"upc"} inputTitle={t("UPC")} inputValue={this.state.application["upc"]} disabled required={false}/>
                                            <TextInput key={"status"} inputId={"status"} inputTitle={t("Status")} inputValue={t(this.state.application["status"])} disabled required={false}/>
                                            <TextInput key={"registryDate"} inputId={"registryDate"} inputTitle={t("Registry Date")} inputValue={this.state.application["registryDate"]} disabled required={false}/>
                                        </div>
                                    </div>
                                </form>
                            </ContentModule>
                            <div className={"mt-20"}>
                                <Gtin12List
                                    hideHeader
                                    defaultPageSize={5}
                                />
                            </div>
                        </React.Fragment>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationDetail: (applicationId, callback) => {
            dispatch(upcActions.dbGetUpcDetail(applicationId, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(UpcDetail)));