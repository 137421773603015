import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {T, withTranslators} from "lioness";
import BarcodeInput from "../input_types/BarcodeInput";
import BarcodeInputJpeg from "../input_types/BarcodeInputJpeg";

import ContentModule from "../ContentModule";
import Iframe from 'react-iframe'
import * as gtin12Actions from "../../actions/gtin12Actions";
import SmartSearchInput from "../input_types/SmartSearchInput";

class ApplicationDetail extends Component{
    constructor(props){
        super(props);

        this.state = {
            gtin: "",
            applicationItem: {},
        };
    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
            this.setState({"gtin": applicationId})
        }

        this.props.loadApplicationDetail(applicationId, this.applicationItemCallback);
    }

    applicationItemCallback = (result) => {
        if (result.status === 200){
            this.setState({
                applicationItem: result.data.application,
                currentStep: 2
            })
        }
    };

    render(){
        const {memberId, udyBaseUrl, udyToken, locale} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>View GTIN-12</T></h2>

                <ContentModule>
                    <T>You can see product information identified by GTIN-12</T>
                </ContentModule>

                {
                    this.state.currentStep === 1 ?
                        <div><T>Loading...</T></div> :

                        <React.Fragment>
                            <ContentModule padless={true}>
                                <Iframe
                                    url={udyBaseUrl + "/#/login?token=" + udyToken + "&member_id=" + memberId + "&module=catalogue_view&gtin=" + this.state.gtin + "&lang=" + locale}
                                    width="100%"
                                    height="600px"
                                    id="view-gtin12-iframe"
                                    frameBorder={0}
                                />
                            </ContentModule>


                            <ContentModule>
                                <h3 className={"t-center"}><T>Barcode</T></h3>
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                    <BarcodeInput id={'ean13'} type={'upca'} number={this.state.gtin}/>
                                </div>
                                <p className={"t-center col-md-6"}><T>The quality of Barcode and Data matrix images may vary depending on the device you will print.</T></p>
                            </ContentModule>

                            

                            <ContentModule style={{textAlign: 'center'}}>
                                <h3 className={"t-center"}><T>GS1 SmartSearch</T></h3>
                                <SmartSearchInput number={this.state.applicationItem["gtin12"]} type={'GTIN-12'}
                                                  description={this.state.applicationItem['labelDescription']}
                                                  brand={this.state.applicationItem['brand']}
                                                  target={this.state.applicationItem['targetMarket']}
                                                  imageUrl={this.state.applicationItem['imageUrl']}  />
                            </ContentModule>
                        </React.Fragment>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationDetail: (applicationId, callback) => {
            dispatch(gtin12Actions.dbLoadGtin12ApplicationDetail(applicationId, callback))
        }
    }
};


const mapStateToProps = (state, ownProps) => {
    const { data, authorize } = state;
    return {
        memberId: authorize.memberId,
        udyBaseUrl: authorize.udyBaseUrl,
        udyToken: authorize.udyToken,
        locale: data.locale,
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ApplicationDetail)));
