import axios from 'axios';
import {getHeaderTokenWithMultipart, getHeaderWithToken} from "./authToken";
import {axiosError} from "./errorHandler";
import {
    applicationDetailUrl,
    applicationStatus,
    gcpDetailUrl,
    mergeApplicationDetailUrl,
    newApplicationUrl,
    newMergeApplicationUrl,
    newNumberApplicationUrl,
    newTransferApplicationDocLogUrl,
    newTransferApplicationUrl,
    transferApplicationDetailUrl,
    updateApplicationUrl,
    updateTransferApplicationUrl
} from "../../constants/GcpUrlConstants";

export const dbSubmitApplication = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(newApplicationUrl, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbSubmitMergeMembershipApplication = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(newMergeApplicationUrl , data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbUpdateApplication = (data, application_id) => {
    return new Promise(((resolve, reject) => {
        axios.post(updateApplicationUrl+"?application_id="+application_id, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};


export const dbGetApplicationStatus = (application_id) => {
    return new Promise(((resolve, reject) => {
        axios.get(applicationStatus+"?application_id="+application_id, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbCheckCommitmentLetterValidity = () => {
    return new Promise(((resolve, reject) => {
        axios.get(newNumberApplicationUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbSubmitNewNumberApplication = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(newNumberApplicationUrl, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbSubmitTransferApplicationDocLog = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(newTransferApplicationDocLogUrl, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbSubmitTransferApplication = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(newTransferApplicationUrl, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbSubmitMergeApplication = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(newMergeApplicationUrl, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbGetApplicationDetail = (application_id) => {
    return new Promise(((resolve, reject) => {
        axios.get(applicationDetailUrl+"?application_id="+application_id, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};


export const dbGetGcpDetail = (application_id) => {
    return new Promise(((resolve, reject) => {
        axios.get(gcpDetailUrl+"?application_id="+application_id, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

/*---------------------------------------------------Transfer---------------------------------------------------------*/

export const dbGetTransferApplication = () => {
    return new Promise(((resolve, reject) => {
        axios.get(newTransferApplicationUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetTransferApplicationDetail = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(transferApplicationDetailUrl+"?application_id="+applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbUpdateTransferApplication = (data, application_id) => {
    return new Promise(((resolve, reject) => {
        axios.post(updateTransferApplicationUrl+"?application_id="+application_id, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

/*---------------------------------------------------Merge------------------------------------------------------------*/

export const dbGetMergeApplication = () => {
    return new Promise(((resolve, reject) => {
        axios.get(newMergeApplicationUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetMergeApplicationDetail = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(mergeApplicationDetailUrl+"?application_id="+applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};
