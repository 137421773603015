import React, {Component, Fragment} from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";
import {connect} from "react-redux";
import {makeGtin12ListTableData} from "../TableUtils";
import * as gtin12Actions from "../../actions/gtin12Actions";
import {withRouter} from "react-router-dom";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";

const DEFAULT_PAGE_SIZE = 10;

class Gtin12List extends Component{
    constructor(props){
        super(props);

        this.state = {
            applicationId: "",
            defaultPageSize: this.props.defaultPageSize ? this.props.defaultPageSize : DEFAULT_PAGE_SIZE,
            table: {
                data: [],
                pages: null,
                loading: true,
            },
        };

        this.fetchData = this.fetchData.bind(this);
    }

    componentWillMount(){
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            const applicationId = this.props.match.params["applicationId"];
            if (applicationId !== undefined && applicationId !== null)
                this.setState({
                    applicationId: this.props.match.params["applicationId"]
                })
        }
    }

    loadDataCallback = (state, result) =>{
        const tableData = makeGtin12ListTableData(result.data, {
            memberId: this.props.memberId,
            udyBaseUrl: this.props.udyBaseUrl,
            udyToken: this.props.udyToken,
            locale: this.props.locale,
        });
        const totalTableSize = result.total_size;

        if(result.status === "ok"){
            this.setState({
                table:{
                    ...this.state.table,
                    data: tableData,
                    pages: Math.ceil(totalTableSize / state.pageSize),
                    loading: false
                },
            })
        }
    };

    fetchData(state, instance) {
        this.setState({
            table:{
                ...state,
                loading: true
            }
        });

        this.props.requestDataForGtin12List(this.state.applicationId, state.pageSize, state.page, state.sorted, this.loadDataCallback, state);
    }

    render(){
        const table = this.state.table;
        const {t} = this.props;

        return(
            <div>
                {
                    !this.props.hideHeader ?
                        <Fragment>
                            <h2 className={"t-center"}><T>GTIN-12 List</T></h2>
                            <ContentModule>
                                <T>All GTIN-12's generated from allocated UPCs are listed below.</T><br/><br/>
                                <T>The product appears as “Registered” within the first 3 hours after the product information is entered and “Active” after the product information is shared with GS1 Head Office and “Delist” if the product is withdrawn from the market.</T>
                            </ContentModule>
                        </Fragment> : null
                }

                <div className={"table-content "}>
                    <ReactTable
                        columns={[
                            {
                                Header: <T>GTIN-12</T>,
                                accessor: "gtin12"
                            },
                            {
                                Header: <T>Status</T>,
                                accessor: "status",
                            },
                            {
                                Header: <T>Date</T>,
                                accessor: "registry_dt"
                            },
                            {
                                Header: <T>Actions</T>,
                                accessor: "actions",
                                sortable: false
                            },
                        ]}
                        manual
                        data={table.data}
                        pages={table.pages}
                        loading={table.loading}
                        onFetchData={this.fetchData}
                        defaultPageSize={this.state.defaultPageSize}
                        className="-striped -highlight"
                        multiSort={false}

                        previousText={<T>Previous</T>}
                        nextText={<T>Next</T>}
                        loadingText={<T>Loading...</T>}
                        noDataText={<T>No rows found</T>}
                        pageText={<T>Page</T>}
                        ofText={<T>of</T>}
                        rowsText={t('rows')}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        requestDataForGtin12List: (applicationId, pagesize, page, sorted, callback, state) => {
            let sortedFieldName="", sortedIsDesc="";
            if (sorted.length !== 0){
                sortedFieldName = sorted[0].id;
                sortedIsDesc = sorted[0].desc ? "desc": "asc";
            }

            dispatch(gtin12Actions.dbRequestDataForGtin12List(applicationId, pagesize, page, sortedFieldName, sortedIsDesc, callback, state));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    const { data, authorize } = state;
    return {
        memberId: authorize.memberId,
        udyBaseUrl: authorize.udyBaseUrl,
        udyToken: authorize.udyToken,
        locale: data.locale,
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(Gtin12List)));