import React, {Component} from 'react'
import {connect} from "react-redux";
import * as dataActions from "../../actions/dataActions";
import TextInput from "../input_types/TextInput";
import CityInput from "../input_types/CityInput";
import CountyInput from "../input_types/CountyInput";
import SSInput from "../input_types/SSInput";
import CheckBoxInput from "../input_types/CheckBoxInput";
import ReactTooltip from 'react-tooltip';
import {withRouter} from "react-router-dom";
import ReactModal from "react-modal";

import {T, withTranslators} from 'lioness';

import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'

import '../../styles/Login.css'
import '../../styles/SignUp.css'

import {
    BILLING_ADDRESS_HELP,
    BILLING_EMAIL_HELP,
    BILLING_PHONE_HELP,
    BILLING_POSTCODE_HELP,
    COMPANY_EMAIL_HELP,
    EMAIL_HELP,
    FAX_NO_HELP,
    FIRST_NAME_HELP,
    LAST_NAME_HELP, MERSIS_NO_HELP,
    NACE_CODE_HELP,
    PHONE_HELP,
    PO_NO_HELP,
    POST_ADDRESS_HELP,
    POST_PHONE_HELP,
    POSTCODE_HELP,
    SAME_POST_ADDRESS_HELP,
    SELECTED_PO_HELP,
    TAX_REGISTER_NO_HELP,
    TCKN_HELP,
    TITLE_HELP,
    WEBSITE_HELP
} from "../../constants/HelpMessages";
import ContentModule from "../ContentModule";
import NaceCodeSelectInput from "../input_types/NaceCodeSelectInput";
import {push} from "react-router-redux";
import Iframe from "../Iframe";
import * as authorizeActions from "../../actions/authorizeActions";
import LeftCheckBoxInput from "../input_types/LeftCheckBoxInput";


class SignUp extends Component{

    constructor(props){
        super(props);
        const {t} = this.props;

        this.state = {

            // first screen
            titleInput: '',
            selectedPO: null,
            parentOrganizationNo: '',
            parentOrganizationNoError: '',
            selectedTO: null,

            selectedCompanyType: null, //1 for corporate, 2 for individual, 3 for artisan

            selectedNaceCode: null,
            naceCodeError: '',
            companyEmailInput: '',

            taxRegisterNumberInput: '',
            taxRegisterNumberInputError: '',
            tcknInput: '',
            tcknInputError: '',
            mersisInput: '',
            mersisInputError: '',

            postPhoneInput: '',
            postPhoneInputError: '',
            websiteInput: '',
            websiteInputError: '',
            faxNoInput: '',
            faxNoInputError: '',

            // second screen
            firstNameInput: '',
            lastNameInput: '',
            emailInput: '',
            emailCheck: '',
            phoneInput: '',
            phoneInputError: '',

            // third screen
            billingAddressInput: '',
            billingPostCodeInput: '',
            billingPhoneInput: '',
            billingPhoneInputError: '',
            postAddressInput: '',
            postPostCodeInput: '',

            billingEmailInput: '',

            selectedBillingCity: null,
            selectedBillingCounty: null,

            selectedPostCity: null,
            selectedPostCounty: null,

            postCodeIsVerifying: false,
            billingPostCodeIsValid: true,
            postcodeIsValid: true,

            checkPostFields: true,
            acceptConditionsInput: false,
            // other
            currentStep: 1,
            nextButton: t('Next'),
            submitting: false,
            showModal: false,
            modalMessage: "",

            // Membership usage
            usageType1: false,
            usageType2: false,
            usageType3: false,
            usageType4: false,
            usageType5: false,
            usageType6: false,
            usageType7: false,
            usageType8: false,
            usageTypeOther: false,
            usageTypeOtherText: '',
        };

        this.handleCityOnChange = this.handleCityOnChange.bind(this);
        this.handleStateNameChange = this.handleStateNameChange.bind(this);

        this.handleNoModal = this.handleNoModal.bind(this);
        this.handleYesModal = this.handleYesModal.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleAcceptConditionsOnChange = this.handleAcceptConditionsOnChange.bind(this);
        this.handleUsageTypeChange = this.handleUsageTypeChange.bind(this);
        this.handleNaceCodeInputChange= this.handleNaceCodeInputChange.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        let firstScreen = localStorage.getItem("firstScreen");
        let secondScreen = localStorage.getItem("secondScreen");
        let thirdScreen = localStorage.getItem("thirdScreen");

        let firstScreenValues, secondScreenValues, thirdScreenValues;
        let allValues = {};

        if (firstScreen){
            firstScreen = JSON.parse(firstScreen);

            firstScreenValues = {
                titleInput: firstScreen.titleInput,
                selectedPO: firstScreen.selectedPO,
                parentOrganizationNo: firstScreen.parentOrganizationNo,
                selectedCompanyType: firstScreen.selectedCompanyType,
                selectedTO: firstScreen.selectedTO,
                companyEmailInput: firstScreen.companyEmailInput,
                taxRegisterNumberInput: firstScreen.taxRegisterNumberInput,
                tcknInput: firstScreen.tcknInput,
                mersisInput: firstScreen.mersisInput,
                selectedNaceCode: firstScreen.selectedNaceCode,
                postPhoneInput: firstScreen.postPhoneInput,
                websiteInput: firstScreen.websiteInput,
                faxNoInput: firstScreen.faxNoInput,
                usageType1: firstScreen.usageType1,
                usageType2: firstScreen.usageType2,
                usageType3: firstScreen.usageType3,
                usageType4: firstScreen.usageType4,
                usageType5: firstScreen.usageType5,
                usageType6: firstScreen.usageType6,
                usageType7: firstScreen.usageType7,
                usageType8: firstScreen.usageType8,
                usageTypeOther: firstScreen.usageTypeOther,
                usageTypeOtherText: firstScreen.usageTypeOtherText,
            };
        }

        if (secondScreen){
            secondScreen = JSON.parse(secondScreen);

            secondScreenValues = {
                firstNameInput: secondScreen.firstNameInput,
                lastNameInput: secondScreen.lastNameInput,
                emailInput: secondScreen.emailInput,
                emailCheck: secondScreen.emailCheck,
                phoneInput: secondScreen.phoneInput,
            };
        }

        if (thirdScreen){
            thirdScreen = JSON.parse(thirdScreen);

            thirdScreenValues = {
                billingAddressInput: thirdScreen.billingAddressInput,
                billingPostCodeInput: thirdScreen.billingPostCodeInput,
                billingPhoneInput: thirdScreen.billingPhoneInput,
                postAddressInput: thirdScreen.postAddressInput,
                postPostCodeInput: thirdScreen.postPostCodeInput,
                billingEmailInput: thirdScreen.billingEmailInput,
                selectedBillingCity: thirdScreen.selectedBillingCity,
                selectedBillingCounty: thirdScreen.selectedBillingCounty,
                selectedPostCity: thirdScreen.selectedPostCity,
                selectedPostCounty: thirdScreen.selectedPostCounty,
                postCodeIsVerifying: thirdScreen.postCodeIsVerifying,
                billingPostCodeIsValid: thirdScreen.billingPostCodeIsValid,
                postcodeIsValid: thirdScreen.postcodeIsValid,
                checkPostFields: thirdScreen.checkPostFields,
            };
        }

        allValues = {...firstScreenValues, ...secondScreenValues, ...thirdScreenValues};

        this.setState({
            ...this.state,
            ...allValues
        });

        if (!this.props.isPOloaded)
            this.props.getPOdata();
        if (!this.props.isTOloaded)
            this.props.getTOdata();
        if (!this.props.citiesLoaded)
            this.props.getCityData();
    }

    handlePostFields(postCheck, reset) {
        if(postCheck) {
            this.setState({
                'postAddressInput': this.state.billingAddressInput,
                'postPostCodeInput': this.state.billingPostCodeInput,
                'selectedPostCity': this.state.selectedBillingCity,
                'selectedPostCounty': this.state.selectedBillingCounty
            });
        } else if(reset) {
            this.setState({
                'postAddressInput': '',
                'postPostCodeInput': '',
                'selectedPostCity': null,
                'selectedPostCounty': null,
            });
        }
    }

    /*
    handleAcceptConditionsOnChange(event){
        this.setState({
            acceptConditionsInput: !this.state.acceptConditionsInput
        })
    }
    */

    handleUsageTypeChange(event) {
        const target = event.target;
        const value = target.checked;

        this.setState({
            [target.id]: value
        });
    }

    handleInputChange(event) {
        const target = event.target;

        if(target.type === 'checkbox') {
            this.handlePostFields(target.checked, true);
        } else {
            this.handlePostFields(this.state.checkPostFields, false);
        }

        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [target.id]: value
        });
    }

    handleNaceCodeInputChange(key, selectedNace){
        this.setState({
            selectedNaceCode: selectedNace
        });
    }

    handleCityOnChange(value, selectedCity, selectedCounty){
        if (value == null){
            this.setState({ [selectedCity]: null, [selectedCounty]: null });
        }
        else {
            let cityName = value.value;
            const loadedCountiesName = this.props.loadedCountiesName;
            this.setState({ [selectedCity]: value, [selectedCounty]: null });

            if(!(cityName in loadedCountiesName))
                this.props.dbGetCountiesData(value.pk, cityName);
        }
    };

    handleStateNameChange(value, stateName){
        this.setState({ [stateName]: value });
    };

    signUpCallBack = (status) => {
        if (status === 200){
            // remove saved screens
            localStorage.removeItem("firstScreen");
            localStorage.removeItem("secondScreen");
            localStorage.removeItem("thirdScreen");

            this.props.goTo("/to/complete/" + this.state.companyEmailInput);
        }
        else
            this.setState({
                submitting: false
            })
    };

    emailCheckCallBack = (result) => {
        if(result.status === 200){
            if (result.data.message){
                this.setState({
                    showModal: true,
                    modalMessage: result.data.message,
                    'emailCheck': '',
                })
            }
            else
                this.setState({
                    'currentStep': 2,
                    'emailCheck': '',
                });
        }
        else
            this.setState({
                'emailCheck': '',
            })
    };

    handleNoModal () {
        this.setState({ showModal: false});
    }

    handleYesModal () {
        this.setState({
            showModal: false,
            currentStep: 2
        });
    }

    // divide states
    checkAnyEmptyInput(){
        let errorStates = {};
        let phoneError = false, tcknError = false, naceCodeError = false, taxError = false, mersisError = false, usageTypeError = false;
        let parentOrganizationNoError = false;
        const {t} = this.props;

        if(this.state.currentStep === 1) {
            // tckn validation check
            errorStates.tcknInputError = "";
            errorStates.taxRegisterNumberInputError = "";
            errorStates.mersisInputError = "";

            if (this.state.selectedCompanyType.pk === 2 || this.state.selectedCompanyType.pk === 3){
                const tckn = this.state.tcknInput;
                if (tckn.length === 11 && tckn[0] !== 0){
                    let digitSum = 0;
                    for (let i=0; i<tckn.length - 1; i++)
                        digitSum += parseInt(tckn[i], 10);
                    if (digitSum % 10 !== parseInt(tckn[10], 10)){
                        errorStates.tcknInputError = t("Enter a valid TCKN");
                        tcknError = true;
                    }
                }
                else{
                    errorStates.tcknInputError = t("Enter a valid TCKN");
                    tcknError = true;
                }
            }
            else {
                // tax validation check
                // if parent organization is from Kibris, pk will be 366/367

                if (this.state.selectedPO.pk === 366 || this.state.selectedPO.pk === 367){
                    if (this.state.taxRegisterNumberInput.length !== 5){
                        errorStates.taxRegisterNumberInputError = t("Enter a valid tax number (5 digits)");
                        taxError = true;
                    }
                }
                else if (this.state.taxRegisterNumberInput.length !== 10) {
                    errorStates.taxRegisterNumberInputError = t("Enter a valid tax number");
                    taxError = true;
                }
                else if (this.state.mersisInput.length !== 16) {
                    errorStates.mersisInputError = t("Enter a valid MERSİS no");
                    mersisError = true;
                }
            }

            if (!/\d/.test(this.state.parentOrganizationNo)) {
                errorStates.parentOrganizationNoError = t("Enter a valid parent organization no.");
                parentOrganizationNoError = true;
            }
            else {
                errorStates.parentOrganizationNoError = "";
            }

            if (!this.state.postPhoneInput || !this.state.postPhoneInput.length || this.state.postPhoneInput.includes("_")) {
                errorStates.postPhoneInputError = t('Enter a valid phone number');
                phoneError = true;
            }
            else {
                errorStates.postPhoneInputError = '';
            }

            // fax empty validation check
            if (this.state.faxNoInput.includes("_") && this.state.faxNoInput.split("_").length === 11){
                this.setState({
                    faxNoInput: ""
                })
            }

            // product nace code check
            errorStates.naceCodeError = '';
            if(this.state.selectedNaceCode === null){
                errorStates.naceCodeError = t('Please either select a nace code!');
                naceCodeError = true;
            }

            errorStates.usageTypeError = '';
            if(!(this.state.usageType1 || this.state.usageType2 || this.state.usageType3 || this.state.usageType4 || this.state.usageType5 || this.state.usageType6 || this.state.usageType7 || this.state.usageType8 || this.state.usageTypeOther)){
                errorStates.usageTypeError = t('Please at least select one of the usage purposes.');
                usageTypeError = true;
            }

            if(this.state.usageTypeOther && (this.state.usageTypeOtherText === null || this.state.usageTypeOtherText.trim() === "" )){
                errorStates.usageTypeError = t('When Other is selected please provide a text.');
                this.setState({'usageTypeOtherText': ''});
                usageTypeError = true;
            }else if (this.state.usageTypeOther && (this.state.usageTypeOtherText.trim().length < 5 || this.state.usageTypeOtherText.trim().length > 100)){
                errorStates.usageTypeError = t('The Other purpose character length should be between 5 and 100.');
                usageTypeError = true;
            }

        }
        if(this.state.currentStep === 2) {
            if (!this.state.phoneInput.length || this.state.phoneInput.includes("_")) {
                errorStates.phoneInputError = t('Enter a valid phone number');
                phoneError = true;
            }
            else {
                errorStates.phoneInputError = '';
            }
        }
        if(this.state.currentStep === 3) {
            if (!this.state.billingPhoneInput.length || this.state.billingPhoneInput.includes("_")) {
                errorStates.billingPhoneInputError = t('Enter a valid phone number');
                phoneError = true;
            }
            else {
                errorStates.billingPhoneInputError = '';
            }
        }
        this.setState(errorStates);
        return phoneError || tcknError || naceCodeError || taxError || mersisError || parentOrganizationNoError || usageTypeError;
    }

    handlePrev(event) {
        event.preventDefault();

        if(this.state.currentStep>1) {
            this.setState({
                'currentStep': this.state.currentStep - 1,
            });
        }
    }

    verifyPostcodeCallback = (result) =>{
        if (result.status === 200){
            this.setState({
                billingPostCodeIsValid: result.data.is_billing_post_code_ok,
                postcodeIsValid: result.data.is_post_code_ok,
            });

            if (result.data.is_valid_post_code)
                this.performSubmit();
        }

        this.setState({
            postCodeIsVerifying: false
        });

    };

    performSubmit(){
        this.setState({
            submitting: true
        });

        // if billing and post address same
        let postAddress, postPostCode, selectedPostCity,selectedPostCounty;
        if (this.state.checkPostFields){
            postAddress = this.state.billingAddressInput;
            postPostCode = this.state.billingPostCodeInput;
            selectedPostCity = this.state.selectedBillingCity;
            selectedPostCounty = this.state.selectedBillingCounty;
        } else {
            postAddress = this.state.postAddressInput;
            postPostCode = this.state.postPostCodeInput;
            selectedPostCity = this.state.selectedPostCity;
            selectedPostCounty = this.state.selectedPostCounty;
        }

        const formData = new FormData();
        formData.append('company_type', this.state.selectedCompanyType.pk);

        if (this.state.selectedCompanyType.pk === 1){
            formData.append('tax_register_no', this.state.taxRegisterNumberInput);
            formData.append('mersis_no', this.state.mersisInput);
            formData.append('tckn', "");
        }
        else {
            formData.append('tckn', this.state.tcknInput);
            formData.append('tax_register_no', "");
        }

        formData.append('title', this.state.titleInput);
        formData.append('auth_email', this.state.emailInput);
        formData.append('website', this.state.websiteInput);
        formData.append('fax_no', this.state.faxNoInput);
        formData.append('auth_first_name', this.state.firstNameInput);
        formData.append('auth_last_name', this.state.lastNameInput);
        formData.append('auth_phone', this.state.phoneInput);
        formData.append('billing_address', this.state.billingAddressInput);
        formData.append('billing_city', this.state.selectedBillingCity.pk);
        formData.append('billing_county', this.state.selectedBillingCounty.pk);
        formData.append('billing_phone', this.state.billingPhoneInput);
        formData.append('billing_postcode', this.state.billingPostCodeInput);
        formData.append('parent_organization', this.state.selectedPO.pk);
        formData.append('parent_organization_no', this.state.parentOrganizationNo);
        formData.append('post_address', postAddress);
        formData.append('post_city', selectedPostCity.pk);
        formData.append('post_county', selectedPostCounty.pk);
        formData.append('post_postcode', postPostCode);
        formData.append('post_phone', this.state.postPhoneInput);
        formData.append('tax_office', this.state.selectedTO.pk);
        formData.append('nace_code', this.state.selectedNaceCode.pk);
        formData.append('email', this.state.companyEmailInput);
        formData.append('billing_email', this.state.billingEmailInput);

        formData.append('usage_type_1', this.state.usageType1);
        formData.append('usage_type_2', this.state.usageType2);
        formData.append('usage_type_3', this.state.usageType3);
        formData.append('usage_type_4', this.state.usageType4);
        formData.append('usage_type_5', this.state.usageType5);
        formData.append('usage_type_6', this.state.usageType6);
        formData.append('usage_type_7', this.state.usageType7);
        formData.append('usage_type_8', this.state.usageType8);
        formData.append('usage_type_other', this.state.usageTypeOther);

        if (this.state.usageTypeOther){
            formData.append('usage_type_other_text', this.state.usageTypeOtherText.trim());
        } else {
            formData.append('usage_type_other_text', "");
        }

        this.props.signUp(formData, this.signUpCallBack);
    }

    saveFirstScreen(){
        const firstScreen = {
            titleInput: this.state.titleInput,
            selectedPO: this.state.selectedPO,
            parentOrganizationNo: this.state.parentOrganizationNo,
            selectedCompanyType: this.state.selectedCompanyType,
            selectedTO: this.state.selectedTO,
            companyEmailInput: this.state.companyEmailInput,
            taxRegisterNumberInput: this.state.taxRegisterNumberInput,
            mersisInput: this.state.mersisInput,
            tcknInput: this.state.tcknInput,
            selectedNaceCode: this.state.selectedNaceCode,
            postPhoneInput: this.state.postPhoneInput,
            websiteInput: this.state.websiteInput,
            faxNoInput: this.state.faxNoInput,
            usageType1: this.state.usageType1 ? this.state.usageType1 : false,
            usageType2: this.state.usageType2 ? this.state.usageType2 : false,
            usageType3: this.state.usageType3 ? this.state.usageType3 : false,
            usageType4: this.state.usageType4 ? this.state.usageType4 : false,
            usageType5: this.state.usageType5 ? this.state.usageType5 : false,
            usageType6: this.state.usageType6 ? this.state.usageType6 : false,
            usageType7: this.state.usageType7 ? this.state.usageType7 : false,
            usageType8: this.state.usageType8 ? this.state.usageType8 : false,
            usageTypeOther: this.state.usageTypeOther ? this.state.usageTypeOther : false,
            usageTypeOtherText: this.state.usageTypeOtherText,
        };

        localStorage.setItem("firstScreen", JSON.stringify(firstScreen));
    }

    saveSecondScreen(){
        const secondScreen = {
            firstNameInput: this.state.firstNameInput,
            lastNameInput: this.state.lastNameInput,
            emailInput: this.state.emailInput,
            emailCheck: this.state.emailCheck,
            phoneInput: this.state.phoneInput,
        };

        localStorage.setItem("secondScreen", JSON.stringify(secondScreen));
    }

    saveThirdScreen(){
        const thirdScreen = {
            billingAddressInput: this.state.billingAddressInput,
            billingPostCodeInput: this.state.billingPostCodeInput,
            billingPhoneInput: this.state.billingPhoneInput,
            postAddressInput: this.state.postAddressInput,
            postPostCodeInput: this.state.postPostCodeInput,
            billingEmailInput: this.state.billingEmailInput,
            selectedBillingCity: this.state.selectedBillingCity,
            selectedBillingCounty: this.state.selectedBillingCounty,
            selectedPostCity: this.state.selectedPostCity,
            selectedPostCounty: this.state.selectedPostCounty,
            postCodeIsVerifying: this.state.postCodeIsVerifying,
            billingPostCodeIsValid: this.state.billingPostCodeIsValid,
            postcodeIsValid: this.state.postcodeIsValid,
            checkPostFields: this.state.checkPostFields,
        };

        localStorage.setItem("thirdScreen", JSON.stringify(thirdScreen));
    }

    handleSubmit(event) {
        event.preventDefault();

        let currStep = this.state.currentStep;

        if (!this.checkAnyEmptyInput()){
            // divide states
            if(currStep === 1) {
                this.saveFirstScreen();

                this.setState({
                    'emailCheck': 'btn-disabled'
                });

                const formData = new FormData();
                const companyType = this.state.selectedCompanyType.pk;
                formData.append('company_type', companyType);
                formData.append('email', this.state.companyEmailInput);

                if (companyType === 1)
                    formData.append('tax_number', this.state.taxRegisterNumberInput);
                else
                    formData.append('tckn', this.state.tcknInput);

                this.props.checkEmailTcknTaxNo(formData, this.emailCheckCallBack);
            }
            else if (currStep === 2){
                this.saveSecondScreen();

                this.setState({
                    'currentStep': 3,
                });
            }
            else if(currStep === 3) {
                this.saveThirdScreen();

                let postPostCode = this.state.checkPostFields ? this.state.billingPostCodeInput: this.state.postPostCodeInput;
                let postCityId = this.state.checkPostFields ? this.state.selectedBillingCity.pk: this.state.selectedPostCity.pk;

                if (this.state.billingPostCodeInput || postPostCode){
                    if (!this.state.postCodeIsVerifying){
                        this.setState({
                            postCodeIsVerifying: true
                        });

                        let billingCityId = this.state.selectedBillingCity.pk;
                        let billingPostCode = this.state.billingPostCodeInput;

                        this.props.dbVerifyPostCode(billingCityId, billingPostCode, postCityId, postPostCode, this.verifyPostcodeCallback)
                    }
                }
                else{
                    this.performSubmit();
                }
            }
        }
    }

    render(){
        const {t} = this.props;

        let prevClass = 'btn';
        let currStep = this.state.currentStep;
        let checkClass = this.state.emailCheck;
        if(currStep === 1)
            prevClass = 'btn prev-btn btn-disabled';
        else
            prevClass = 'btn prev-btn';

        let billingPostCodeError = "";
        if (this.state.billingPostCodeInput && !this.state.billingPostCodeIsValid)
            billingPostCodeError = t("Please enter a valid postcode");

        let postPostCode = this.state.checkPostFields? this.state.billingPostCodeInput: this.state.postPostCodeInput;

        let postCodeError = "";
        if (postPostCode && !this.state.postcodeIsValid)
            postCodeError = t("Please enter a valid postcode");

        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const nextButtonValue = this.state.currentStep === 3 ? t("Submit"): t("Next");

        const companyTypesOptions = [
            {
                pk: 1,
                label: t("Legal Entity Company"),
                value: t("Legal Entity Company"),
            },
            {
                pk: 2,
                label: t("Real Person Company"),
                value: t("Real Person Company"),
            },
            {
                pk: 3,
                label: t("Person/Artisan"),
                value: t("Person/Artisan"),
            },
        ];

        return(
            <div className={"pos-abs"}>

                <Iframe
                    source={"GCP_new_application/h1.html"}
                />

                <h1 className={"t-center"}><T>Signup</T></h1>

                <ReactModal
                    isOpen={this.state.showModal}
                    ariaHideApp={false}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <ContentModule moduleClass={"no-padding"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title">
                                    <T>Existing company found!</T>
                                </h2>
                            </div>
                            <div className="modal-body">
                                {t(this.state.modalMessage)}

                                <div className="modal-footer">
                                    <button type="button" className={"btn-modal btn-modal-close mr-10 " } onClick={this.handleNoModal}>
                                        <T>No</T>
                                    </button>
                                    <button type="button" className={"btn-modal btn-modal-submit" } onClick={this.handleYesModal}>
                                        <T>Yes</T>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ContentModule>
                </ReactModal>

                <ul id="progressbar">
                    <li className='active'/>
                    <li className={currStep > 1 ? 'active' : undefined}/>
                    <li className={currStep > 2 ? 'active' : undefined}/>
                </ul>
                {
                    currStep === 1 ?
                    <ContentModule>
                        <form onSubmit={this.handleSubmit} id={'signUpForm'} className="editor small signin">
                            <h2 className={"t-center"}><T>Company Information</T></h2>
                            <div>
                                <TextInput key={"titleInput"} inputId={"titleInput"} inputTitle={t("Company Name")} inputValue={this.state.titleInput} handleInputChange={this.handleInputChange} maxLength={128} dataToolTip={t(TITLE_HELP)}/>
                                <SSInput select={"selectedPO"} inputId={"poInput"} inputTitle={t("Parent Organization")} value={this.state.selectedPO} handleInputChange={this.handleStateNameChange} isSSloaded={this.props.isPOloaded} ssData={this.props.poData} dataToolTip={t(SELECTED_PO_HELP)} />
                                <TextInput key={"parentOrganizationNo"} inputId={"parentOrganizationNo"} inputTitle={t("Parent Organization No")} inputValue={this.state.parentOrganizationNo} handleInputChange={this.handleInputChange} dataToolTip={t(PO_NO_HELP)} maxLength={50} errorText={this.state.parentOrganizationNoError}/>
                                <SSInput select={"selectedTO"} inputId={"TOInput"} inputTitle={t("Tax Office")} value={this.state.selectedTO} handleInputChange={this.handleStateNameChange} isSSloaded={this.props.isTOloaded} ssData={this.props.toData}/>
                                <SSInput select={"selectedCompanyType"} inputId={"selectedCompanyType"} inputTitle={t("Company Type")} value={this.state.selectedCompanyType} handleInputChange={this.handleStateNameChange} isSSloaded ssData={companyTypesOptions}/>
                                {
                                    this.state.selectedCompanyType ?
                                        (this.state.selectedCompanyType.pk === 2 || this.state.selectedCompanyType.pk === 3) ?
                                            <TextInput key={"tcknInput"} inputId={"tcknInput"} inputTitle={t("TCKN")} inputValue={this.state.tcknInput} handleInputChange={this.handleInputChange} isTckn dataToolTip={t(TCKN_HELP)} errorText={this.state.tcknInputError}/>
                                            :
                                            <div>
                                                <TextInput key={"taxRegisterNumberInput"} inputId={"taxRegisterNumberInput"} isTaxNo inputTitle={t("Tax Register Number")} inputValue={this.state.taxRegisterNumberInput} handleInputChange={this.handleInputChange} dataToolTip={t(TAX_REGISTER_NO_HELP)} errorText={this.state.taxRegisterNumberInputError} maxLength={10}/>
                                                <TextInput key={"mersisInput"} inputId={"mersisInput"} isMersis inputTitle={t("MERSİS No")} inputValue={this.state.mersisInput} handleInputChange={this.handleInputChange} dataToolTip={t(MERSIS_NO_HELP)} errorText={this.state.mersisInputError} maxLength={16}/>
                                            </div>
                                        : null
                                }
                                <NaceCodeSelectInput selectedNace={this.state.selectedNaceCode} handleInputChange={this.handleNaceCodeInputChange} dataToolTip={t(NACE_CODE_HELP)}/>
                                <TextInput key={"companyEmailInput"} inputType={"email"} inputId={"companyEmailInput"} inputTitle={t("Company Email")} inputValue={this.state.companyEmailInput} handleInputChange={this.handleInputChange} dataToolTip={t(COMPANY_EMAIL_HELP)} maxLength={64}/>
                                <TextInput key={"postPhoneInput"} inputId={"postPhoneInput"} inputTitle={t("Phone Number")} inputValue={this.state.postPhoneInput} handleInputChange={this.handleInputChange} isPhone dataToolTip={t(POST_PHONE_HELP)} errorText={this.state.postPhoneInputError}/>
                                <TextInput key={"faxNoInput"} inputId={"faxNoInput"} inputTitle={t("Fax Number")} inputValue={this.state.faxNoInput} handleInputChange={this.handleInputChange} isPhone dataToolTip={t(FAX_NO_HELP)} errorText={this.state.faxNoInputError} required={false} />
                                <TextInput key={"websiteInput"} inputId={"websiteInput"} inputType="url" inputTitle={t("Website")} placeholder="https://example.com"  inputValue={this.state.websiteInput} handleInputChange={this.handleInputChange} dataToolTip={t(WEBSITE_HELP)} errorText={this.state.websiteInputError} required={false} maxLength={64}/>
                                <fieldset className={"checkbox-list-container"}>
                                    <legend>{t("Please Check the Reason(s) of Your Membership Application")} <span className={"required-field"}>*</span></legend>
                                    { this.state.usageTypeError ?
                                        <div className={"t-center"}><span className={"input-error"}>{this.state.usageTypeError}</span></div>
                                        :
                                        ''
                                    }
                                    <div className={"checkbox-list"}>
                                        <LeftCheckBoxInput key={"usageType1"} inputId={"usageType1"} inputValue={this.state.usageType1} handleInputChange={this.handleUsageTypeChange} inputTitle={t("Barcoding Imported Products")}/>
                                        <LeftCheckBoxInput key={"usageType2"} inputId={"usageType2"} inputValue={this.state.usageType2} handleInputChange={this.handleUsageTypeChange} inputTitle={t("Barcoding Manufactured Products")}/>
                                        <LeftCheckBoxInput key={"usageType3"} inputId={"usageType3"} inputValue={this.state.usageType3} handleInputChange={this.handleUsageTypeChange} inputTitle={t("Product Tracking and Traceability")}/>
                                        <LeftCheckBoxInput key={"usageType4"} inputId={"usageType4"} inputValue={this.state.usageType4} handleInputChange={this.handleUsageTypeChange} inputTitle={t("Obtain GLN Number")}/>
                                        <fieldset style={{marginBottom: '10px', }} className={"checkbox-list-container"}>
                                            <legend>{t("Medical Device Sales")}</legend>
                                            <div className={"checkbox-list"}>
                                                    <LeftCheckBoxInput key={"usageType6"} inputId={"usageType6"} inputValue={this.state.usageType6} handleInputChange={this.handleUsageTypeChange} inputTitle={t("EUDAMED Sales")}/>
                                                    <LeftCheckBoxInput key={"usageType7"} inputId={"usageType7"} inputValue={this.state.usageType7} handleInputChange={this.handleUsageTypeChange} inputTitle={t("US FDA UDI Sales")}/>
                                                    <LeftCheckBoxInput key={"usageType8"} inputId={"usageType8"} inputValue={this.state.usageType8} handleInputChange={this.handleUsageTypeChange} inputTitle={t("Turkey Inside Sales")}/>
                                                </div>
                                        </fieldset>
                                        <div className={"checkbox-list"}>
                                        {this.state.usageType5 ? (<LeftCheckBoxInput key={"usageType6"} inputId={"usageType6"} inputValue={this.state.usageType6} handleInputChange={this.handleUsageTypeChange} inputTitle={t("EUDAMED Sales")}/>) : ('')}
                                        {this.state.usageType5 ? (<LeftCheckBoxInput key={"usageType7"} inputId={"usageType7"} inputValue={this.state.usageType7} handleInputChange={this.handleUsageTypeChange} inputTitle={t("FDA UDI Sales")}/>) : ('')}
                                            {this.state.usageType5 ? (<LeftCheckBoxInput key={"usageType8"} inputId={"usageType8"} inputValue={this.state.usageType8} handleInputChange={this.handleUsageTypeChange} inputTitle={t("Turkey Inside Sales")}/>) : ('')}
                                        </div>
                                        <div style={{display: "contents"}}> &nbsp;</div>
                                            <LeftCheckBoxInput key={"usageTypeOther"} inputId={"usageTypeOther"} inputValue={this.state.usageTypeOther} handleInputChange={this.handleUsageTypeChange} inputTitle={t("Other")}/>
                                        {
                                            this.state.usageTypeOther ?
                                                <input type={"text"} id={"usageTypeOtherText"} min={5} placeholder={t("Please provide your purpose (5-100 chars)")} value={this.state.usageTypeOtherText} required={true}  onChange={this.handleInputChange} maxLength={100} className={"essi"}/>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>
                            </div>
                        </form>
                    </ContentModule>: ''
                } {
                    currStep === 2 ?
                     <ContentModule>
                        <form onSubmit={this.handleSubmit} id={'signUpForm'} className="editor small signin">
                            <h2 className={"t-center"}><T>Company Authorized Person Information</T></h2>
                            <div>
                                <TextInput key={"firstNameInput"} inputId={"firstNameInput"} inputTitle={t("First Name")} inputValue={this.state.firstNameInput} handleInputChange={this.handleInputChange} dataToolTip={t(FIRST_NAME_HELP)} maxLength={64}/>
                                <TextInput key={"lastNameInput"} inputId={"lastNameInput"} inputTitle={t("Last Name")} inputValue={this.state.lastNameInput} handleInputChange={this.handleInputChange} dataToolTip={t(LAST_NAME_HELP)} maxLength={64}/>
                                <TextInput key={"emailInput"} inputType={"email"} inputId={"emailInput"} inputTitle={t("Email")} inputValue={this.state.emailInput} handleInputChange={this.handleInputChange} dataToolTip={t(EMAIL_HELP)} maxLength={64}/>
                                <TextInput key={"phoneInput"} inputId={"phoneInput"} inputTitle={t("Mobile Phone")} inputValue={this.state.phoneInput} handleInputChange={this.handleInputChange} isPhone dataToolTip={t(PHONE_HELP)} errorText={this.state.phoneInputError}/>
                            </div>
                        </form>
                    </ContentModule>   : ''
            } {
                    currStep === 3 ?
                    <ContentModule>
                        <form onSubmit={this.handleSubmit} id={'signUpForm'} className="editor small signin">
                            <h2 className={"t-center"}><T>Billing and Post Address Information</T></h2>
                            <div>
                                <h4 className={"address_title"}><T>Billing Address</T></h4>
                                <TextInput key={"billingEmailInput"} inputType={"email"} inputId={"billingEmailInput"} inputTitle={t("Billing Email")} inputValue={this.state.billingEmailInput} handleInputChange={this.handleInputChange} dataToolTip={t(BILLING_EMAIL_HELP)} maxLength={64}/>
                                <TextInput key={"billingAddressInput"} inputId={"billingAddressInput"} inputTitle={t("Billing Address")} inputValue={this.state.billingAddressInput} handleInputChange={this.handleInputChange} dataToolTip={t(BILLING_ADDRESS_HELP)} maxLength={128}/>
                                <TextInput key={"billingPostCodeInput"} inputId={"billingPostCodeInput"} inputTitle={t("Billing Postcode")} inputValue={this.state.billingPostCodeInput} handleInputChange={this.handleInputChange} dataToolTip={t(BILLING_POSTCODE_HELP)} errorText={billingPostCodeError} required={false} maxLength={5}/>
                                <CityInput key={"billingCity"} inputId={"selectedBillingCity"} inputTitle={t("Billing City")} selectedCity={'selectedBillingCity'} selectedCounty={'selectedBillingCounty'} value={this.state.selectedBillingCity} handleCityChange={this.handleCityOnChange} citiesData={this.props.citiesData} citiesLoaded={this.props.citiesLoaded} />
                                <CountyInput key={"billingCounty"} inputId={"billingCounty"} inputTitle={t("Billing County")} selectedCity={this.state.selectedBillingCity} selectedCounty={'selectedBillingCounty'} value={this.state.selectedBillingCounty} handleCountyChange={this.handleStateNameChange} loadedCountiesData={this.props.loadedCountiesData}/>
                                <TextInput key={"billingPhoneInput"} inputId={"billingPhoneInput"} inputTitle={t("Billing Phone")} inputValue={this.state.billingPhoneInput} handleInputChange={this.handleInputChange} isPhone dataToolTip={t(BILLING_PHONE_HELP)} errorText={this.state.billingPhoneInputError}/>
                                <CheckBoxInput key={"checkPostFields"} inputId={"checkPostFields"} inputTitle={t("If Post Address Is Not Same As Billing Address Please Uncheck")} inputValue={this.state.checkPostFields} handleInputChange={this.handleInputChange} dataToolTip={t(SAME_POST_ADDRESS_HELP)} />
                                    {
                                        !this.state.checkPostFields
                                        ?
                                            <div className={"mt-20"}>
                                                <h4 className={"address_title"}><T>Post Address</T></h4>
                                                <TextInput key={"postAddressInput"} inputId={"postAddressInput"} inputTitle={t("Post Address")} inputValue={this.state.postAddressInput} handleInputChange={this.handleInputChange} dataToolTip={t(POST_ADDRESS_HELP)} maxLength={128}/>
                                                <TextInput key={"postPostCodeInput"} inputId={"postPostCodeInput"} inputTitle={t("Post Code")} inputValue={this.state.postPostCodeInput} handleInputChange={this.handleInputChange} dataToolTip={t(POSTCODE_HELP)} errorText={postCodeError} required={false} maxLength={5}/>
                                                <CityInput key={"postCity"} inputId={"postCityInput"} inputTitle={t("Post City")} selectedCity={'selectedPostCity'} selectedCounty={'selectedPostCounty'} value={this.state.selectedPostCity} handleCityChange={this.handleCityOnChange} citiesData={this.props.citiesData} citiesLoaded={this.props.citiesLoaded} />
                                                <CountyInput inputId={"postCity"} inputTitle={t("Post County")} selectedCity={this.state.selectedPostCity} selectedCounty={'selectedPostCounty'} value={this.state.selectedPostCounty} handleCountyChange={this.handleStateNameChange} loadedCountiesData={this.props.loadedCountiesData}/>
                                            </div>
                                        : ''
                                    }
                                {/*
                                <div className={"mt-20"}>
                                    <input type="checkbox" id={"acceptConditionsInput"} className={"mr-10"} checked={this.state.acceptConditionsInput} onChange={this.handleAcceptConditionsOnChange} required={true}/>
                                    <T
                                        message="I have read {{ link }}. I Accept that the personal data supplied during the signup process can be stored and processed by GS1 Turkey according to Turkish GDPR."
                                        link={<a href={"login-help"} target="_blank"><T>Privacy Policy</T></a>}
                                    />
                                </div>
                                */}
                            </div>
                        </form>
                    </ContentModule>   : ''
            }
                <div className="form-buttons">
                        <button className={[prevClass, checkClass, isSubmitting].join(' ')} onClick={this.handlePrev}><T>Previous</T></button>
                        <input form='signUpForm' className={[checkClass,'btn next-btn', isSubmitting].join(' ')} type="submit" value={nextButtonValue}/>
                </div>
                <ReactTooltip eventOff={"click"} scrollHide={false} resizeHide={false} effect={"solid"} place={"right"}/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        checkEmailTcknTaxNo: (data, callBack) => {
            dispatch(authorizeActions.dbCheckEmailTcknTaxNo(data, callBack));
        },
        signUp: (data, callBack) => {
            dispatch(authorizeActions.dbSignUp(data, callBack));
        },
        getPOdata: () => {
            dispatch(dataActions.dbGetPOdata());
        },
        getTOdata: () => {
            dispatch(dataActions.dbGetTOdata());
        },
        getCityData: () => {
            dispatch(dataActions.dbGetCityData());
        },
        dbGetCountiesData: (cityId, cityName) => {
            dispatch(dataActions.dbGetCountiesForCity(cityId, cityName))
        },
        dbVerifyPostCode: (billingCityId, billingPostCode, cityId, cityPostCode, callback) => {
            dispatch(authorizeActions.dbVerifyPostCode(billingCityId, billingPostCode, cityId, cityPostCode, callback));
        },
        goTo: (url) => {
            dispatch(push(url))
        }
    }
};

const mapStateToProps = (state) => {
    const { data } = state;

    return {
        isPOloaded: data.isPOloaded,
        poData: data.poData,

        isTOloaded: data.isTOloaded,
        toData: data.toData,

        citiesLoaded: data.citiesLoaded,
        citiesData: data.citiesData,

        loadedCountiesName: data.loadedCountiesName,
        loadedCountiesData: data.loadedCountiesData,
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(SignUp)));
