import React, {Component, Fragment} from 'react'
import * as applicationActions from "../../../actions/applicationActions";
import {connect} from "react-redux";
import FileInput from "../../input_types/FileInput";
import {T, withTranslators} from "lioness";
import ContentModule from "../../ContentModule";
import ReactModal from "react-modal";
import TextInput from "../../input_types/TextInput";
import {commitmentLetterDownloadUrl, mergeLetterDownloadUrl} from "../../../constants/GcpUrlConstants";
import * as upcActions from "../../../actions/upcActions";
import {isFileSizeExceeded, showFileError} from "../../FileUtils";

class MergeNew extends Component{
    constructor(props){
        super(props);
        const {t} = this.props;

        this.state = {
            currentStep: 0,
            isCommitmentLetterValid: null,
            applicationId: 0,
            showModal: false,

            submitting: false,
            applicationMessage: t('Loading...'),
            mergeFile: null,
            commitmentLetterFile: null,
            extraFiles: null,

            // merge file states
            mergeFileCurrentStep: 1,
            registerDate: "",
            registerNumber: "",
            registerPage: "",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleMergeFileSubmit = this.handleMergeFileSubmit.bind(this);
    }

    componentWillMount(){
        this.props.checkCommitmentLetterValidity(this.commitmentLetterValidCallback)
    };

    commitmentLetterValidCallback = (result) => {
        if (result.status === 200){
            this.setState({
                currentStep: 1,
                isCommitmentLetterValid: result.data.commitment_letter_valid
            });
        }
    };

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            [event.target.id]: submitFile,
        });

    };

    handleInputChange(event) {
        if (event.target.id === "registerPage"){
            if (parseInt(event.target.value, 10) <= 999999999 || event.target.value === "")
                this.setState({
                    [event.target.id]: event.target.value
                });
        }
        else
            this.setState({
                [event.target.id]: event.target.value
            });
    }

    newApplicationCallBack = (result) => {
        if (result.status === 200)
            this.setState({
                currentStep: 2,
                applicationId: result.data.id,
                submitting: false
            });
        else
            this.setState({
                submitting: false
            })
    };

    handleSubmit(event) {
        event.preventDefault();

        const formData = new FormData();
        formData.append('merge_file', this.state.mergeFile);
        if (!this.state.isCommitmentLetterValid)
            formData.append('commitment_letter', this.state.commitmentLetterFile);

        let extraFiles= this.state.extraFiles;
        if(extraFiles) {
            for (let i = 0; i < extraFiles.length; i++) {
                formData.append('extra_files', extraFiles[i]);
            }
        }

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        this.props.submitApplication(
            formData,
            this.newApplicationCallBack
        );
    }

    showModal(){
        this.setState({
            showModal: true
        })
    }

    hideModal(){
        //reset filled form values
        this.setState({
            showModal: false,
            mergeFileCurrentStep: 1,
            registerDate: "",
            registerNumber: "",
            registerPage: ""
        })
    }

    handleMergeFileSubmit(event){
        event.preventDefault();
        const {t} = this.props;

        let isRegisterDateValid = true;
        const registerDate = this.state.registerDate;

        if (registerDate.includes("_")){
            isRegisterDateValid = false;
        }
        else if (registerDate.length === 10){
            const parts = registerDate.split("/");
            const day = parts[0];
            const month = parts[1];
            const year = parts[2];

            if (parseInt(day, 10) === 0 || parseInt(day, 10) > 31 || parseInt(month, 10) === 0 || parseInt(month, 10) > 12 ||
                parseInt(year, 10) < 1900 || parseInt(year, 10) > 2099){
                isRegisterDateValid = false;
            }
        }
        else
            isRegisterDateValid = false;

        if (!isRegisterDateValid){
            this.setState({
                registerDateError: t("Enter a valid date in dd/mm/yyyy format"),
            });

            return;
        }

        if (this.state.registerDateError)
            this.setState({
                registerDateError: ""
            });

        this.setState({
            mergeFileCurrentStep: 2
        })
    }

    render(){
        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>Merge With An Existing Member</T></h2>

                <ContentModule>
                    <T>You can merge with an existing company. You need to supply related trade registry gazzette.</T><br/>
                    <T>You can use "Generate Merge File" button to generate merge file required in your application.</T>
                </ContentModule>

                {
                    this.state.currentStep === 0 ?
                        <div>
                            <T>Loading...</T>
                        </div>:

                    this.state.currentStep === 1 ?
                        <Fragment>
                            <ContentModule>
                                <form onSubmit={this.handleSubmit} className={"mt-10 editor small signin"}>
                                    <div>
                                        <div className={"mt-10"}>
                                            <span className={"signup-button"} onClick={this.showModal}> <T>Generate Merge File</T></span>
                                        </div>
                                        <FileInput inputId={"mergeFile"} inputTitle={t("Merge File")} handleUploadFile={this.handleUploadFile}/>
                                        {
                                            !this.state.isCommitmentLetterValid &&
                                            <div>
                                                <div className={"mt-10"}>
                                                    <T
                                                        message={"Please first download the {{ link }}"}
                                                        link={<a href={commitmentLetterDownloadUrl} target={"_blank"}><T>Commitment Letter</T></a>}
                                                    />
                                                </div>
                                                <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile}/>
                                            </div>
                                        }
                                        <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false} />
                                    </div>
                                    <div className="btnInline t-center mt-10">
                                        <input className={"signup-button " + isSubmitting} type="submit" value={t("Submit")}/>
                                    </div>
                                </form>
                            </ContentModule>
                            <ReactModal
                                isOpen={this.state.showModal}
                                ariaHideApp={false}
                                className="Modal"
                                overlayClassName="Overlay"
                            >
                                <ContentModule moduleClass={"no-padding"}>
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="modal-title"><T>Generate Merge File</T></h2>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={this.handleMergeFileSubmit} className={"mt-10"}>
                                                {
                                                    this.state.mergeFileCurrentStep === 1 ?
                                                        <div>
                                                            <fieldset className="editor small signin no-border">
                                                                <div>
                                                                    <TextInput key={"registerDate"} inputId={"registerDate"} inputTitle={t("Register date (dd/mm/yyyy)")} isDate inputValue={this.state.registerDate} handleInputChange={this.handleInputChange} required errorText={this.state.registerDateError}/>
                                                                    <TextInput key={"registerNumber"} inputId={"registerNumber"} inputTitle={t("Register number")} maxLength={10} inputValue={this.state.registerNumber} handleInputChange={this.handleInputChange}/>
                                                                    <TextInput key={"registerPage"} inputId={"registerPage"} inputTitle={t("Register page")} inputType={"number"} inputValue={this.state.registerPage} handleInputChange={this.handleInputChange}/>
                                                                </div>
                                                            </fieldset>
                                                            <div className="modal-footer t-center">
                                                                <button type="button" className={"btn-modal btn-modal-close mr-10 " } onClick={this.hideModal}><T>Close</T></button>
                                                                <input className={"btn-modal btn-modal-submit "} type="submit" value={t("Generate")}/>
                                                            </div>
                                                        </div>: null
                                                }
                                                {
                                                    this.state.mergeFileCurrentStep === 2 ?
                                                        <div>
                                                            <T>Merge file is successfully generated. Click in the below link to download:</T>
                                                            <div className="modal-footer t-center">
                                                                <button type="button" className={"btn-modal btn-modal-close mr-10 " } onClick={this.hideModal}><T>Close</T></button>
                                                                <a
                                                                    className={"btn-modal btn-modal-submit "}
                                                                    href={mergeLetterDownloadUrl + "?registry_gazette_date=" + this.state.registerDate + "&registry_gazette_number=" + this.state.registerNumber + "&registry_gazette_page=" + this.state.registerPage}
                                                                    target={"_blank"}
                                                                >
                                                                    <T>Merge File</T>
                                                                </a>
                                                            </div>
                                                        </div>: null
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </ContentModule>
                            </ReactModal>
                        </Fragment>:

                        this.state.currentStep === 2 ?
                            <div>
                                <T>Application is submitted successfully!</T>
                            </div>: null
                }
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        checkCommitmentLetterValidity: (callback) => {
            dispatch(upcActions.dbCheckCommitmentLetterValidity(callback));
        },
        submitApplication: (data, callback) => {
            dispatch(applicationActions.dbSubmitMergeApplication(data, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(MergeNew));
