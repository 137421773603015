import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';
import InputMask from 'react-input-mask';
import {findDOMNode} from 'react-dom'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

class TextInput extends Component{
    componentWillMount(){
        this.handleFocus = this.handleFocus.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleFocus(event){
        event.preventDefault();
        ReactTooltip.show(findDOMNode(this.refs.tooltip));
    }

    handleInputChange(event){
        const {parentKey} = this.props;
        ReactTooltip.show(findDOMNode(this.refs.tooltip));

        if (parentKey)
            this.props.handleInputChange(event, parentKey);
        else
            this.props.handleInputChange(event);
    }

    getComponent(){
        const {inputId, inputId2, inputTitle, inputValue, inputValue2, isPhone, isCreditCard, isExpiryDate, CVC,
            isTckn, isTaxNo, isATS, isGtin13, isGtin12, isDate, isTextArea, isMersis, inputType, disabled, maxLength,
            placeholder, required} = this.props;
        const _inputType = inputType ? inputType : 'text';
        const _required = required !== false;
        const _maxLength = maxLength ? maxLength : '';
        const _placeholder = placeholder ? placeholder : inputTitle;

        if (isPhone)
            return <InputMask mask="+\9\0 999 999 99 99" type={"text"} maskChar={'_'} id={inputId} alwaysShowMask required value={inputValue} onFocus={this.handleFocus} onChange={this.handleInputChange} />;
        else if (isCreditCard)
            return <InputMask mask="9999 9999 9999 9999" type={"text"} maskChar={'_'} id={inputId} alwaysShowMask required value={inputValue} onFocus={this.handleFocus} onChange={this.handleInputChange} />;
        else if (CVC){
            return <InputMask mask="999" className={"cvc-format"} type={"text"} maskChar={''} id={inputId2} placeholder={"CVC"} alwaysShowMask value={inputValue2} onFocus={this.handleFocus} onChange={this.handleInputChange} />
        }
        else if (isExpiryDate){
            return <div className={"d-flex"}>
                <InputMask mask="99" className={"ats-small"} type={"text"} maskChar={''} id={inputId2} placeholder={"MM"} alwaysShowMask value={inputValue2} onFocus={this.handleFocus} onChange={this.handleInputChange} />
                <InputMask mask="9999" className={"yr-format"} type={"text"} maskChar={''} id={inputId2} placeholder={"YYYY"} alwaysShowMask value={inputValue2} onFocus={this.handleFocus} onChange={this.handleInputChange} />
            </div>;
        }
        else if (isTckn)
            return <InputMask mask="99999999999" type={"text"} maskChar={''} id={inputId} placeholder={_placeholder} alwaysShowMask value={inputValue} onFocus={this.handleFocus} onChange={this.handleInputChange} required={_required} />;
        else if (isMersis)
            return <InputMask mask="9999999999999999" type={"text"} maskChar={''} id={inputId} placeholder={_placeholder} alwaysShowMask value={inputValue} onFocus={this.handleFocus} onChange={this.handleInputChange} required={_required} />;
        else if (isTaxNo)
            return <InputMask mask="9999999999" type={"text"} maskChar={''} id={inputId} placeholder={_placeholder} alwaysShowMask value={inputValue} onFocus={this.handleFocus} onChange={this.handleInputChange} required={_required} />;
        else if (isGtin13)
            return <InputMask mask="9999999999999" type={"text"} maskChar={''} id={inputId} placeholder={_placeholder} required={_required} alwaysShowMask value={inputValue} onFocus={this.handleFocus} onChange={this.handleInputChange} />;
        else if (isGtin12)
            return <InputMask mask="999999999999" type={"text"} maskChar={''} id={inputId} placeholder={_placeholder} required={_required} alwaysShowMask value={inputValue} onFocus={this.handleFocus} onChange={this.handleInputChange} />;
        else if (isDate)
            return <InputMask mask="99/99/9999" type={"text"} maskChar={'_'} id={inputId} placeholder={_placeholder} required={_required} alwaysShowMask value={inputValue} onFocus={this.handleFocus} onChange={this.handleInputChange} />;
        else if (isATS){
            let customMask = createNumberMask({
                prefix: '',
                decimalSymbol: ',',
                thousandsSeparatorSymbol: '.',
                integerLimit: 20
            });

            return <div className={"d-flex"}>
                 <MaskedInput
                    className={"essi"}
                    mask={customMask}
                    onChange={this.handleInputChange}
                    value={inputValue}

                    render={(ref, props) => (
                        <input
                            id={inputId}
                            placeholder={inputTitle}
                            required
                            ref={ref}
                            {...props}
                        />
                    )}
                 />
                <span className={"ats-separator"}>,</span>
                <InputMask mask="99" className={"ats-small"} type={"text"} maskChar={''} id={inputId2} placeholder={"00"} alwaysShowMask value={inputValue2} onFocus={this.handleFocus} onChange={this.handleInputChange} />
            </div>;
        }
        else if(isTextArea)
            return <textarea id={inputId} disabled={disabled} placeholder={_placeholder} value={inputValue} required={_required} rows={4} cols={50} onFocus={this.handleFocus} onChange={this.handleInputChange} maxLength={_maxLength}/>;
        else
            return <input type={_inputType} id={inputId} min={1} disabled={disabled} placeholder={_placeholder} value={inputValue} required={_required} onFocus={this.handleFocus} onChange={this.handleInputChange} maxLength={_maxLength} className={"essi"}/>;
    }

    render(){
        const {inputId, inputTitle, dataToolTip, errorText, required, noFlex} = this.props;
        const component = this.getComponent();
        const _required = required !== false;
        const _flexClass = noFlex ? '' : 'child d-flex';

        return(
            <div className="child-wrapper">
                <div className={_flexClass}>
                    <label htmlFor={inputId}>
                        {inputTitle}
                        {
                            _required ? <span className={"required-field"}>*</span> :''
                        }
                    </label>
                    <div>
                        {
                            component
                        }
                        {
                            dataToolTip
                                ?
                                <span>
                                    <i className={"tooltip-icon"} ref="tooltip" data-tip={dataToolTip} data-delay-show='500'>?</i>
                                </span>
                                : ''
                        }
                    </div>
                </div>
                <div className={"t-center"}>
                    {
                        errorText && errorText.length ?
                            (
                                <span className={"input-error"}>{errorText}</span>
                            )
                            :
                            ''
                    }
                </div>
            </div>
        )
    }
}

export default TextInput;