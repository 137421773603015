import React, {Component} from 'react'
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import TextInput from "../input_types/TextInput";
import FileLink from "../input_types/FileLink";
import * as vwActions from "../../actions/vwActions";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import PayInfo from "../payment/PayInfo";

class ApplicationDetail extends Component{
    constructor(props){
        super(props);

        this.state = {
            overall_application: {
                application_date: '',
                status_name: '',
                update_at: '',
            },
            application: {},
            applicationStatus: null,
            extraFiles: []
        }
    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }

        this.props.loadApplication(applicationId, this.applicationDetailCallback)
    }

    applicationDetailCallback = (result) => {
        if (result.status === 200){
            this.setState({
                overall_application: result.data.overall_application,
                application: result.data.application,
                applicationStatus: result.data.application_status,
                extraFiles: result.data.extra_files,
                service_fee: result.data.service_fee,
                service_fee_cancelled: result.data.service_fee_cancelled,
                invoice_number: result.data.invoice_number,
                paid_amount: result.data.paid_amount,
                remaining_amount: result.data.remaining_amount,
                service_id: result.data.service_id,
                payment_status: result.data.payment_status,
            })
        }
    };

    constructNumbers(){
        const {t} = this.props;

        return Object.keys(this.state.application).sort().map((key) =>{
            if (this.state.application[key] !== null && this.state.application[key] !== undefined)
                return <div key={key}>
                            <TextInput key={"createdAt"} inputId={"createdAt"} inputTitle={t("Application Date")} inputValue={this.state.application[key]["created_at"]} disabled required={false}/>
                            <TextInput key={"brand"} inputId={"brand"} inputTitle={t("Brand")} inputValue={this.state.application[key]["brand"]} disabled required={false}/>
                            <TextInput key={"labelDescription"} inputId={"labelDescription"} inputTitle={t("Label Description")} inputValue={this.state.application[key]["labelDescription"]} disabled required={false}/>
                            <div className={"sep"}/>
                        </div>;
            return null;
        })
    }

    constructMeta(){
        const {t} = this.props;

        return (
            <div>
                <TextInput key={"status"} inputId={"status"} inputTitle={t("Application Status")} inputValue={t(this.state.overall_application.status_name)} disabled required={false}/>
                <TextInput key={"createdAt"} inputId={"createdAt"} inputTitle={t("Application Date")} inputValue={this.state.overall_application.application_date} disabled required={false}/>
                <TextInput key={"updateAt"} inputId={"updateAt"} inputTitle={t("Last Update Date")} inputValue={this.state.overall_application.update_at} disabled required={false}/>
                <TextInput key={"latestApplicationNotes"} isTextArea inputId={"latestApplicationNotes"} inputTitle={t("Latest Application Notes")} inputValue={this.state.overall_application.latest_application_note} disabled required={false}/>
            </div>
        );
    }

    render(){
        const meta = this.constructMeta();
        const allNumbers = this.constructNumbers();
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>View Varying Weight Number Application</T></h2>

                <ContentModule>
                    <T>You can follow the status of your application from this page.</T>
                </ContentModule>

                <PayInfo service_fee={this.state["service_fee"]}
                         is_cancelled={this.state["service_fee_cancelled"]}
                         remaining_amount={this.state["remaining_amount"]}
                         payment_status={this.state["payment_status"]}
                         pre_sales_agreement={this.state["pre_sales_agreement"]}
                         distant_sales_agreement={this.state["distant_sales_agreement"]}
                         path={"/member/pay/servicefee/" + this.state["service_id"]}/>

                <ContentModule>
                    <form className="editor small signin">
                        {
                            meta
                        }

                        <h3 className={"mt-20"}><T>Product Information Related to the Application</T></h3>
                        {
                            allNumbers
                        }
                        {
                            this.state.extraFiles.length ?
                                <FileLink inputId={"extraFiles"} inputTitle={t("Extra Files")}  multiple={true} inputValue={this.state.extraFiles} required={false}/> :
                                null
                        }
                        {
                            this.state.overall_application.status === 2 &&
                            <div className={"btnInline mt-10 t-center mt-20 nohover"}>
                                <Link to={"/member/vw/application/edit/" + this.props.match.params.applicationId} className={"signup-button small"}>
                                    <T>Edit</T>
                                </Link>
                            </div>
                        }
                    </form>
                </ContentModule>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplication: (applicationId, callback) => {
            dispatch(vwActions.dbLoadVwApplication(applicationId, callback))
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ApplicationDetail)));