import React, {Component} from 'react'
import TextInput from "./input_types/TextInput";
import {connect} from "react-redux";
import * as authorizeActions from "../actions/authorizeActions";
import '../styles/ViewProfile.css'
import SSInput from "./input_types/SSInput";
import * as dataActions from "../actions/dataActions";
import CityInput from "./input_types/CityInput";
import CountyInput from "./input_types/CountyInput";
import FileInput from "./input_types/FileInput";
import ContentModule from "./ContentModule";
import {T, withTranslators} from 'lioness';
import {PERSONAL_PO_TYPES} from "../constants/ParentOrganizationType"
import {updateCommitmentLetterDownloadUrl} from "../constants/GcpUrlConstants";
import {REQUEST_STATUS_COMMITMENT_LETTER, REQUEST_STATUS_NEW, REQUEST_STATUS_NOT_CREATED} from "../constants/Statuses";
import NaceCodeSelectInput from "./input_types/NaceCodeSelectInput";
import MessageBox from "./MessageBox";

class ViewProfile extends Component {

    constructor(props) {
        super(props);
        const {t} = this.props;

        this.state = {
            //general states
            isEditProfileEnabled: false,
            isEditDetailsEnabled: false,

            isProfileEditSubmitting: false,
            isEditDetailsSubmitting: false,
            isCommitmentFileSubmitting: false,

            updateRequestStatus: -1,
            selectedCompanyType: null, //1 for corporate, 2 for individual, 3 for artisan

            //alert states
            profileTitleAlertEnabled: false,
            profileDetailsAlertEnabled: false,
            titleInputHasChanged: true,

            //member title fields
            originalTitle: '',
            titleInput: '',

            originalPO: null,
            selectedPO: null,
            selectedTO: null,
            parentOrganizationNo: '',
            parentOrganizationNoError: '',

            registerDate: "",
            registerDateError: "",
            registerNumber: "",
            registerPage: "",

            updateFile: null,

            //member title commitment file
            commitmentFile: null,

            //member detail fields
            selectedBillingCity: null,
            selectedBillingCounty: null,

            selectedPostCity: null,
            selectedPostCounty: null,

            companyType: 0,
            taxRegisterNumberInput: '',
            tcknInput: '',
            tcknInputError: '',
            mersisInput: '',
            mersisInputError: '',
            websiteInput: '',
            websiteInputError: '',
            faxNoInput: '',
            faxNoInputError: '',
            firstNameInput: '',
            lastNameInput: '',
            companyEmailInput: '',
            emailInput: '',
            phoneInput: '',
            phoneInputError: '',
            billingAddressInput: '',
            billingPostCodeInput: '',
            billingEmailInput: '',
            billingPhoneInput: '',
            billingPhoneInputError: '',
            postAddressInput: '',
            postPostCodeInput: '',
            postPhoneInput: '',
            postPhoneInputError: '',

            selectedNaceCode: null,
            naceCodeError: '',

            companyTypesOptions: [
                {
                    pk: 1,
                    label: t("Legal Entity Company"),
                    value: t("Legal Entity Company"),
                },
                {
                    pk: 2,
                    label: t("Real Person Company"),
                    value: t("Real Person Company"),
                },
                {
                    pk: 3,
                    label: t("Person/Artisan"),
                    value: t("Person/Artisan"),
                },
            ]
        };

        this.enableProfileEdit = this.enableProfileEdit.bind(this);
        this.disableProfileEdit = this.disableProfileEdit.bind(this);

        this.enableDetailsEdit = this.enableDetailsEdit.bind(this);
        this.disableDetailsEdit = this.disableDetailsEdit.bind(this);

        this.handleCityOnChange = this.handleCityOnChange.bind(this);
        this.handleStateNameChange = this.handleStateNameChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleNaceCodeInputChange = this.handleNaceCodeInputChange.bind(this);

        this.handleEditProfileSubmit = this.handleEditProfileSubmit.bind(this);
        this.handleEditCommitmentFileSubmit = this.handleEditCommitmentFileSubmit.bind(this);
        this.handleEditDetailsSubmit = this.handleEditDetailsSubmit.bind(this);

        this.hideProfileTitleAlert = this.hideProfileTitleAlert.bind(this);
        this.hideProfileDetailsAlert = this.hideProfileDetailsAlert.bind(this);
    };

    memberStatesCallback = (data, group) => {
        const {t} = this.props;

        if(this.props.memberDataLoaded) {
            let member = this.props.member;

            this.props.dbGetCountiesData(member.billing_city_pk, member.billing_city);
            this.props.dbGetCountiesData(member.post_city_pk, member.post_city);

            let companyType = null;
            if (member.company_type === 1)
                companyType = {
                    pk: 1,
                    label: t("Legal Entity Company"),
                    value: t("Legal Entity Company"),
                };
            else if (member.company_type === 2)
                companyType = {
                    pk: 2,
                    label: t("Real Person Company"),
                    value: t("Real Person Company"),
                };
            else if (member.company_type === 3)
                companyType = {
                    pk: 3,
                    label: t("Person/Artisan"),
                    value: t("Person/Artisan"),
                };

            this.setState({
                selectedPO: {label: member.parent_organization, pk: member.parent_organization_pk, value: member.parent_organization, type: member.parent_organization_type},
                originalPO: {label: member.parent_organization, pk: member.parent_organization_pk, value: member.parent_organization, type: member.parent_organization_type},
                selectedTO: {label: member.tax_office, pk: member.tax_office_pk, value: member.tax_office},
                selectedNaceCode: {label: member.nace_code, pk: member.nace_code_pk, value: member.nace_code},
                titleInput: member.title,
                originalTitle: member.title,
                parentOrganizationNo: member.parent_organization_no,
                taxRegisterNumberInput: member.tax_register_no,
                tcknInput: member.tckn ? member.tckn : "",
                mersisInput: member.mersis_no ? member.mersis_no: "",
                companyType: member.company_type,

                selectedCompanyType: companyType,

                websiteInput: member.website,
                faxNoInput: member.fax_no ? member.fax_no : "",
            });

            this.setState({
                firstNameInput: member.auth_first_name,
                lastNameInput: member.auth_last_name,
                companyEmailInput: member.email,
                emailInput: member.auth_email,
                phoneInput: member.auth_phone,
            });

            this.setState({
                selectedBillingCity: {label: member.billing_city, pk: member.billing_city_pk, value: member.billing_city},
                selectedBillingCounty: {label: member.billing_county, pk:member.billing_county_pk, value: member.billing_county},
                billingAddressInput: member.billing_address,
                billingPostCodeInput: member.billing_postcode,
                billingEmailInput: member.billing_email,
                billingPhoneInput: member.billing_phone,
            });

            this.setState({
                selectedPostCity: {label: member.post_city, pk: member.post_city_pk, value: member.post_city},
                selectedPostCounty: {label: member.post_county, pk: member.post_county_pk, value: member.post_county},
                postAddressInput: member.post_address,
                postPostCodeInput: member.post_postcode,
                postPhoneInput: member.post_phone,

            });
        }
    };

    componentWillMount(){
        this.props.checkRequestStatus(this.checkProfileEditCallback);
        this.props.getMemberData(this.memberStatesCallback);

        if (!this.props.isPOloaded)
            this.props.getPOdata();
        if (!this.props.isTOloaded)
            this.props.getTOdata();
        if (!this.props.citiesLoaded)
            this.props.getCityData();

        const {fromProfileRouter} = this.props;
        if (fromProfileRouter){
            this.setState({
                isEditDetailsEnabled: true
            });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.id]: value
        });
    }

    handleNaceCodeInputChange(key, selectedNace){
        this.setState({
            selectedNaceCode: selectedNace
        });
    }

    handleCityOnChange(value, selectedCity, selectedCounty){
        if (value == null){
            this.setState({ [selectedCity]: null, [selectedCounty]: null });
        }
        else {
            let cityName = value.value;
            const loadedCountiesName = this.props.loadedCountiesName;
            this.setState({ [selectedCity]: value, [selectedCounty]: null });

            if(!(cityName in loadedCountiesName))
                this.props.dbGetCountiesData(value.pk, cityName);
        }
    };

    handleStateNameChange(value, stateName){
        this.setState({ [stateName]: value });
    };

    checkProfileEditCallback = (result) => {
        if(result.status === 200) {
            let updateRequestStatus = result.data.request_status;
            let profileTitleAlertEnabled = false;

            if (updateRequestStatus === REQUEST_STATUS_COMMITMENT_LETTER || updateRequestStatus === REQUEST_STATUS_NEW)
                profileTitleAlertEnabled = true;

            this.setState({
                updateRequestStatus: updateRequestStatus,
                profileTitleAlertEnabled: profileTitleAlertEnabled
            });
        }

        this.props.getMemberStatus();
    };

    editTitleCallback = (result) => {
        if (result.status === 200){
            this.setState({
                updateRequestStatus: 1,
                profileTitleAlertEnabled: true,
            });

            this.props.getMemberData(this.memberStatesCallback);
            this.disableProfileEdit();
        }

        this.props.getMemberStatus();

        this.setState({
            isProfileEditSubmitting: false
        });
    };

    editCommitmentFileCallback = (result) => {
        if (result.status === 200){
            this.setState({
                updateRequestStatus: 2
            })
        }

        this.setState({
            isCommitmentFileSubmitting: false
        });
    };

    editDetailsCallback = (result) => {
        if (result.status === 200){
            this.setState({
                profileDetailsAlertEnabled: true
            });

            this.props.getMemberData(this.memberStatesCallback);
            this.disableDetailsEdit();
        }

        this.setState({
            isEditDetailsSubmitting: false
        });

        this.props.getMemberStatus();
    };

    handleUploadFile = (event) => {
        this.setState({
            [event.target.id]: event.target.files[0]
        });
    };

    checkAnyEmptyInput() {
        const {t} = this.props;

        let errorStates = {};
        let phoneError = false, tcknError = false, emailCheckError = false, mersisError = false;

        // tckn validation check
        errorStates.tcknInputError = '';
        if (this.state.selectedCompanyType.pk !== 1 && this.state.tcknInput){
            const tckn = this.state.tcknInput;
            if (tckn.length === 11 && tckn[0] !== 0){
                let digitSum = 0;
                for (let i=0; i<tckn.length - 1; i++)
                    digitSum += parseInt(tckn[i], 10);
                if (digitSum % 10 !== parseInt(tckn[10], 10))
                    tcknError = true;
            } else
                tcknError = true;
            if (tcknError)
                errorStates.tcknInputError = t('Enter a valid TCKN');
        }

        // mersis validation check
        errorStates.mersisInputError = "";
        if (this.state.companyType === 1 && this.state.mersisInput.length !== 16) {
            errorStates.mersisInputError = t("Enter a valid MERSİS no");
            mersisError = true;
        }

        if (!this.state.phoneInput.length || this.state.phoneInput.includes("_")) {
            errorStates.phoneInputError = t('Enter a valid phone number');
            phoneError = true;
        } else {
            errorStates.phoneInputError = '';
        }

        // fax no check
        errorStates.faxNoInputError = '';
        if (this.state.faxNoInput){
            let clearFax = this.state.faxNoInput.replace(/ /g,'').replace(/_/g, '');
            if(clearFax.length < 13 && clearFax.length !==0 && clearFax !== '+90') {
                errorStates.faxNoInputError = t('Enter a valid fax number');
                phoneError = true;
            }
        }

        if (!this.state.postPhoneInput.length || this.state.postPhoneInput.includes("_")) {
            errorStates.postPhoneInputError = t('Enter a valid phone number');
            phoneError = true;
        }
        else {
            errorStates.postPhoneInputError = '';
        }

        if (!this.state.billingPhoneInput.length || this.state.billingPhoneInput.includes("_")) {
            errorStates.billingPhoneInputError = t('Enter a valid phone number');
            phoneError = true;
        }
        else {
            errorStates.billingPhoneInputError = '';
        }


        this.setState(errorStates);
        return phoneError || tcknError || emailCheckError || mersisError;
    }

    isRegistryDateValid(){
        const {t} = this.props;

        let isRegisterDateValid = true;
        const registerDate = this.state.registerDate;

        if (registerDate.includes("_")){
            isRegisterDateValid = false;
        }
        else if (registerDate.length === 10){
            const parts = registerDate.split("/");
            const day = parts[0];
            const month = parts[1];
            const year = parts[2];

            if (parseInt(day, 10) === 0 || parseInt(day, 10) > 31 || parseInt(month, 10) === 0 || parseInt(month, 10) > 12 ||
                parseInt(year, 10) < 1900 || parseInt(year, 10) > 2099){
                isRegisterDateValid = false;
            }
        }
        else
            isRegisterDateValid = false;

        if (!isRegisterDateValid){
            this.setState({
                registerDateError: t("Enter a valid date in dd/mm/yyyy format"),
            });
            return false;
        }

        if (this.state.registerDateError)
            this.setState({
                registerDateError: ""
            });

        return true;
    };

    enableProfileEdit(){
        this.setState({
            isEditProfileEnabled: true
        });

        this.props.checkRequestStatus(this.checkProfileEditCallback);
    }

    disableProfileEdit(){
        this.setState({
            isEditProfileEnabled: false
        });
    }

    enableDetailsEdit(){
        this.setState({
            isEditDetailsEnabled: true
        });
    }

    disableDetailsEdit(){
        this.setState({
            isEditDetailsEnabled: false
        });
    }

    handleEditProfileSubmit(event){
        const {t} = this.props;
        event.preventDefault();
        //first check if the title has changed
        if (this.state.originalTitle === this.state.titleInput &&
            this.state.originalPO.pk === this.state.selectedPO.pk){
            //check if the message box is present, if not show it
            if (this.state.titleInputHasChanged)
                this.setState({
                    titleInputHasChanged: false,
                    profileTitleAlertEnabled: true
                });

            return;
        }
        else
            this.setState({
                titleInputHasChanged: true,
                profileTitleAlertEnabled: false
            });

        if (!/\d/.test(this.state.parentOrganizationNo)) {
            this.setState({
                parentOrganizationNoError: t("Enter a valid parent organization no.")
            });
            return;
        }
        let member = this.props.member;
        let isTeskOrganization = 0;
        if(member && this.state.selectedPO)
            isTeskOrganization = PERSONAL_PO_TYPES.indexOf(this.state.selectedPO.type);

        const formData = new FormData();
        formData.append('title', this.state.titleInput);
        formData.append('parent_organization', this.state.selectedPO.pk);
        formData.append('parent_organization_no', this.state.parentOrganizationNo);

        // if the new parent organization is TESK type, just send the uploaded file
        if (isTeskOrganization === 0){
            this.setState({
                isProfileEditSubmitting: true
            });

            formData.append('update_file', this.state.updateFile ? this.state.updateFile : '');
            this.props.updateTitle(formData, this.editTitleCallback);
        }
        else if (this.isRegistryDateValid()){
            this.setState({
                isProfileEditSubmitting: true
            });

            const registerDate = this.state.registerDate;

            // convert date to yyyy-mm-dd format
            let parseRegisterDate = registerDate.substring(6, 10) + "-" + registerDate.substring(3, 5) + "-" + registerDate.substring(0, 2);

            formData.append('registry_gazette_date', this.state.registerDate ? parseRegisterDate : '');
            formData.append('registry_gazette_number', this.state.registerNumber ? this.state.registerNumber :'');
            formData.append('registry_gazette_page', this.state.registerPage ? this.state.registerPage : '');

            this.props.updateTitle(formData, this.editTitleCallback);
        }
    }

    handleEditCommitmentFileSubmit(event){
        event.preventDefault();

        this.setState({
            isCommitmentFileSubmitting: true
        });

        const formData = new FormData();
        formData.append('commitment_file', this.state.commitmentFile);
        this.props.updateCommitmentFile(formData, this.editCommitmentFileCallback);
    };

    handleEditDetailsSubmit(event){
        event.preventDefault();

        if(!this.checkAnyEmptyInput()) {

            this.setState({
                isEditDetailsSubmitting: true
            });

            const formData = new FormData();

            formData.append('tax_office', this.state.selectedTO.pk);
            formData.append('nace_code', this.state.selectedNaceCode.pk);
            formData.append('website', this.state.websiteInput ? this.state.websiteInput : '');
            formData.append('fax_no', this.state.faxNoInput);
            formData.append('auth_first_name', this.state.firstNameInput);
            formData.append('auth_last_name', this.state.lastNameInput);
            formData.append('auth_email', this.state.emailInput);
            formData.append('auth_phone', this.state.phoneInput);
            formData.append('billing_address', this.state.billingAddressInput);
            formData.append('billing_postcode', this.state.billingPostCodeInput ? this.state.billingPostCodeInput: "" );
            formData.append('billing_city', this.state.selectedBillingCity.pk);
            formData.append('billing_county', this.state.selectedBillingCounty.pk);
            formData.append('billing_phone', this.state.billingPhoneInput);
            formData.append('billing_email', this.state.billingEmailInput);
            formData.append('post_address', this.state.postAddressInput);
            formData.append('post_postcode', this.state.postPostCodeInput ? this.state.postPostCodeInput : "");
            formData.append('post_city', this.state.selectedPostCity.pk);
            formData.append('post_county', this.state.selectedPostCounty.pk);
            formData.append('post_phone', this.state.postPhoneInput);
            formData.append('company_type', this.state.selectedCompanyType.pk);

            if (this.state.selectedCompanyType.pk === 1){
                formData.append('tax_register_no', this.state.taxRegisterNumberInput);
                formData.append('mersis_no', this.state.mersisInput);
                formData.append('tckn', "");
            }
            else {
                formData.append('tckn', this.state.tcknInput);
                formData.append('tax_register_no', "");
                formData.append('mersis_no', "");
            }

            this.props.updateDetails(formData, this.editDetailsCallback);
        }
    }

    hideProfileTitleAlert(){
        this.setState({
            profileTitleAlertEnabled: false
        })
    }

    hideProfileDetailsAlert(){
        this.setState({
            profileDetailsAlertEnabled: false
        })
    }

    getProfileTitleMessage(){
        const {t} = this.props;

        let showMessage = false;
        let message = "";
        let messageBoxType = "";

        const updateRequestStatus = this.state.updateRequestStatus;

        if (updateRequestStatus === REQUEST_STATUS_COMMITMENT_LETTER){
            showMessage = true;
            message = t("Please upload commitment letter to finish updating title!");
            messageBoxType = "alert-warning"
        }
        else if (updateRequestStatus === REQUEST_STATUS_NEW){
            showMessage = true;
            message = t("You need to send original documents that you have uploaded during your application to GS1");
            messageBoxType = "alert-info"
        }
        else if (!this.state.titleInputHasChanged){
            showMessage = true;
            message = t("There is no change.");
            messageBoxType = "alert-danger"
        }

        if (showMessage)
            return <MessageBox
                messageBoxType={messageBoxType}
                message={message}
                show={this.state.profileTitleAlertEnabled}
                handleOnChange={this.hideProfileTitleAlert}
            />;
        else
            return null;
    }

    getProfileDetailsMessage(){
        const {t} = this.props;

        if (this.state.profileDetailsAlertEnabled)
            return <MessageBox
                messageBoxType={"alert-success"}
                message={t("Profile is updated successfully")}
                isDismissible
                show={this.state.profileDetailsAlertEnabled}
                handleOnChange={this.hideProfileDetailsAlert}
            />;
        else
            return null;
    }

    render(){
        const {t, isCandidateView, fromProfileRouter} = this.props;
        const updateRequestStatus = this.state.updateRequestStatus;

        let member = this.props.member;
        let isTeskOrganization = 0;
        if(member && this.state.selectedPO)
            isTeskOrganization = PERSONAL_PO_TYPES.indexOf(this.state.selectedPO.type);

        const isProfileEditSubmitting = this.state.isProfileEditSubmitting ? "btn-disabled": "";
        const isEditDetailsSubmitting = this.state.isEditDetailsSubmitting ? "btn-disabled": "";
        const isCommitmentFileSubmitting = this.state.isCommitmentFileSubmitting ? "btn-disabled": "";

        const {isEditProfileEnabled, isEditDetailsEnabled} = this.state;

        const profileTitleMessage = this.getProfileTitleMessage();
        const profileDetailsMessage = this.getProfileDetailsMessage();

        const cmsClass = isCandidateView || fromProfileRouter ? "content-module-sized":"";

        return(
            <div className={cmsClass}>
                <h2 className={"t-center"}><T>Profile</T></h2>

                <ContentModule>
                    <T>You can change your information from here. Some changes may require revision and addtional documents.</T>
                </ContentModule>

                {
                    this.props.memberDataLoaded ?
                        <React.Fragment>
                            {
                                profileTitleMessage
                            }
                            <ContentModule>
                                {
                                    isEditProfileEnabled ?
                                        <div className="editor small sigin">
                                            {
                                                updateRequestStatus === -1 ?
                                                    <T>Loading...</T>
                                                    :
                                                    updateRequestStatus === REQUEST_STATUS_NOT_CREATED ?
                                                        <form onSubmit={this.handleEditProfileSubmit}>
                                                            <div className="editor small signin">
                                                                <TextInput key={"titleInput"} inputId={"titleInput"} inputTitle={t("Company Name")} inputValue={this.state.titleInput} handleInputChange={this.handleInputChange}   />
                                                                <SSInput select={"selectedPO"} inputId={"poInput"} inputTitle={t("Parent Organization")} value={this.state.selectedPO} handleInputChange={this.handleStateNameChange} isSSloaded={this.props.isPOloaded} ssData={this.props.poData}  />
                                                                <TextInput key={"parentOrganizationNo"} inputId={"parentOrganizationNo"} inputTitle={t("Parent Organization No")} inputValue={this.state.parentOrganizationNo} handleInputChange={this.handleInputChange}  maxLength={50} errorText={this.state.parentOrganizationNoError} />
                                                                <h3 className={"t-left"}>
                                                                    <T>Required Information</T>
                                                                </h3>
                                                                {
                                                                    isTeskOrganization !== -1 ?
                                                                        <FileInput inputId={"updateFile"} inputTitle={t("Registry Gazette")} handleUploadFile={this.handleUploadFile}/>
                                                                        :
                                                                        <div>
                                                                            <TextInput key={"registerDate"} inputId={"registerDate"}
                                                                                       inputTitle={t("Registry Gazette Date (dd/mm/yyyy)")} isDate
                                                                                       inputValue={this.state.registerDate}
                                                                                       handleInputChange={this.handleInputChange} required
                                                                                       errorText={this.state.registerDateError}/>
                                                                            <TextInput key={"registerNumber"} inputId={"registerNumber"} inputTitle={t("Registry Gazette Number")} maxLength={20} inputValue={this.state.registerNumber} handleInputChange={this.handleInputChange}/>
                                                                            <TextInput key={"registerPage"} inputId={"registerPage"} inputTitle={t("Registry Gazette Page")} inputType={"number"} maxLength={20} inputValue={this.state.registerPage} handleInputChange={this.handleInputChange}/>
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div className="btnInline mt-10 t-center">
                                                                <span className={"signup-button small mr-5 add-number"} onClick={this.disableProfileEdit}>
                                                                    <T>Cancel</T>
                                                                </span>
                                                                <input className={"signup-button small " + isProfileEditSubmitting} type="submit" value={t("Submit")}/>
                                                            </div>
                                                        </form>
                                                        :
                                                        updateRequestStatus === REQUEST_STATUS_COMMITMENT_LETTER &&
                                                        <form onSubmit={this.handleEditCommitmentFileSubmit}>
                                                            <div className={"mt-10"}>
                                                                <T
                                                                    message={"Please first download the {{ link }}"}
                                                                    link={<a href={updateCommitmentLetterDownloadUrl} target={"_blank"}><T>Commitment Letter</T></a>}
                                                                />
                                                            </div>
                                                            <FileInput inputId={"commitmentFile"} inputTitle={t("Please upload signed commitment letter.")} handleUploadFile={this.handleUploadFile}/>

                                                            <div className="btnInline mt-10 t-center">
                                                                    <span className={"signup-button small mr-5 add-number"} onClick={this.disableProfileEdit}>
                                                                        <T>Cancel</T>
                                                                    </span>
                                                                <input className={"signup-button small "} type="submit" value={t("Submit")}/>
                                                            </div>
                                                        </form>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <h3 className={"t-left"}>
                                                <T>Member</T>
                                            </h3>
                                            <div>
                                                <b><T>Company Name</T>:</b>
                                                <span className={"ml-10"}>{member.title}</span>
                                            </div>
                                            <div>
                                                <b><T>Parent Organization</T>:</b>
                                                <span className={"ml-10"}>{member.parent_organization}</span>
                                            </div>
                                            <div>
                                                <b><T>Parent Organization No</T>:</b>
                                                <span className={"ml-10"}>{member.parent_organization_no}</span>
                                            </div>
                                            {
                                                updateRequestStatus === REQUEST_STATUS_NOT_CREATED && !fromProfileRouter && member.master_gln!='' && 
                                                <button className="signup-button mt-10" onClick={this.enableProfileEdit}>
                                                    <T>Edit Title</T>
                                                </button>
                                            }
                                            {
                                                updateRequestStatus === REQUEST_STATUS_COMMITMENT_LETTER &&
                                                <ContentModule containerClass={"mt-20"}>
                                                    <form onSubmit={this.handleEditCommitmentFileSubmit}>
                                                        <div>
                                                            <T>Last step</T>:
                                                        </div>
                                                        <div className={"mt-10"}>
                                                            <T
                                                                message={"Please first download the {{ link }}"}
                                                                link={<a href={updateCommitmentLetterDownloadUrl} target={"_blank"}><T>Commitment Letter</T></a>}
                                                            />
                                                        </div>
                                                        <FileInput inputId={"commitmentFile"} inputTitle={t("Please upload signed commitment letter.")} handleUploadFile={this.handleUploadFile}/>

                                                        <div className="btnInline mt-10 t-center">
                                                            <input className={"signup-button small " + isCommitmentFileSubmitting} type="submit" value={t("Submit")}/>
                                                        </div>
                                                    </form>
                                                </ContentModule>
                                            }
                                        </div>
                                }
                            </ContentModule>

                            {
                                profileDetailsMessage
                            }
                            <ContentModule>
                                {
                                    !isEditDetailsEnabled ?
                                        <div>
                                            <h3 className={"t-left"}><T>Member Details</T></h3>
                                            <b><T>Email</T>:</b> {member.email}<br/>
                                            <b><T>Tax Office</T>:</b> {member.tax_office}<br/>
                                            {
                                                this.state.companyType === 2 ?
                                                    <div>
                                                        <b><T>TCKN</T>:</b> {member.tckn}<br/>
                                                    </div>:
                                                    <div>
                                                        <b><T>Tax Register No</T>:</b> {member.tax_register_no}<br/>
                                                        <b><T>MERSİS No</T>:</b> {member.mersis_no}<br/>
                                                    </div>
                                            }
                                            <b><T>Nace Code</T>:</b> {member.nace_code}<br/>
                                            <b><T>Website</T>:</b> <a href={member.website} target='_blank'>{member.website}</a><br/>
                                            <b><T>Fax Number</T>:</b> {member.fax_no}<br/>

                                            <h3 className={"mt-20 t-left"}><T>Authorized Person</T></h3>
                                            <b><T>First Name</T>:</b> {member.auth_first_name}<br/>
                                            <b><T>Last Name</T>:</b> {member.auth_last_name}<br/>
                                            <b><T>Email</T>:</b> {member.auth_email}<br/>
                                            <b><T>Mobile Phone</T>:</b> {member.auth_phone}<br/>

                                            <h3 className={"mt-20 t-left"}><T>Post Address</T></h3>
                                            <b><T>Post Address</T>:</b> {member.post_address}<br/>
                                            <b><T>Post Postcode</T>:</b> {member.post_postcode}<br/>
                                            <b><T>Post City</T>:</b> {member.post_city}<br/>
                                            <b><T>Post County</T>:</b> {member.post_county}<br/>
                                            <b><T>Phone Number</T>:</b> {member.post_phone}<br/>

                                            <h3 className={"mt-20 t-left"}><T>Billing Address</T></h3>
                                            <b><T>Billing Address</T>:</b> {member.billing_address}<br/>
                                            <b><T>Billing Postcode</T>:</b> {member.billing_postcode}<br/>
                                            <b><T>Billing City</T>:</b> {member.billing_city}<br/>
                                            <b><T>Billing County</T>:</b> {member.billing_county}<br/>
                                            <b><T>Billing Email</T>:</b> {member.billing_email}<br/>
                                            <b><T>Billing Phone</T>:</b> {member.billing_phone}<br/>

                                            <button className="mt-20 signup-button small" onClick={this.enableDetailsEdit}>
                                                <T>Edit</T>
                                            </button>
                                        </div> :
                                        <form onSubmit={this.handleEditDetailsSubmit}>
                                            <div className="editor small signin">
                                                <h3 className={"t-left"}><T>Member</T></h3>
                                                <TextInput key={"titleInput"} inputId={"titleInput"} inputTitle={t("Title")} inputValue={this.state.titleInput} disabled={true} />
                                                <TextInput key={"companyEmailInput"} inputType={"email"} inputId={"companyEmailInput"} inputTitle={t("Company Email")} inputValue={this.state.companyEmailInput} disabled={true}/>
                                                <SSInput select={"selectedTO"} inputId={"TOInput"} inputTitle={t("Tax Office")} value={this.state.selectedTO} handleInputChange={this.handleStateNameChange} isSSloaded={this.props.isTOloaded} ssData={this.props.toData}  />
                                                <SSInput select={"selectedCompanyType"} inputId={"selectedCompanyType"} inputTitle={t("Company Type")} value={this.state.selectedCompanyType} handleInputChange={this.handleStateNameChange} isSSloaded ssData={this.state.companyTypesOptions}/>
                                                {
                                                    this.state.selectedCompanyType ?
                                                        this.state.selectedCompanyType.pk === 1 ?
                                                            <div>
                                                                <TextInput key={"taxRegisterNumberInput"} inputId={"taxRegisterNumberInput"} isTaxNo inputTitle={t("Tax Register Number")} inputValue={this.state.taxRegisterNumberInput} handleInputChange={this.handleInputChange} errorText={this.state.taxRegisterNumberInputError} maxLength={10} required={true}/>
                                                                <TextInput key={"mersisInput"} inputId={"mersisInput"} isMersis inputTitle={t("MERSİS No")} inputValue={this.state.mersisInput} handleInputChange={this.handleInputChange} errorText={this.state.mersisInputError} maxLength={16} required={true}/>
                                                            </div>:
                                                            <TextInput key={"tcknInput"} inputId={"tcknInput"} inputTitle={t("TCKN")} inputValue={this.state.tcknInput} handleInputChange={this.handleInputChange} isTckn  errorText={this.state.tcknInputError} required={true}/>
                                                        : null
                                                }
                                                <NaceCodeSelectInput selectedNace={this.state.selectedNaceCode} handleInputChange={this.handleNaceCodeInputChange}/>
                                                <TextInput key={"websiteInput"} inputId={"websiteInput"} inputType="url" inputTitle={t("Website")} placeholder="https://example.com"  inputValue={this.state.websiteInput} handleInputChange={this.handleInputChange}  errorText={this.state.websiteInputError} required={false}   />
                                                <TextInput key={"faxNoInput"} inputId={"faxNoInput"} inputTitle={t("Fax Number")} inputValue={this.state.faxNoInput} handleInputChange={this.handleInputChange} isPhone  errorText={this.state.faxNoInputError} required={false}   />

                                                <h3 className={"t-left"}><T>Authorized Person</T></h3>
                                                <TextInput key={"firstNameInput"} inputId={"firstNameInput"} inputTitle={t("First Name")} inputValue={this.state.firstNameInput} handleInputChange={this.handleInputChange}   />
                                                <TextInput key={"lastNameInput"} inputId={"lastNameInput"} inputTitle={t("Last Name")} inputValue={this.state.lastNameInput} handleInputChange={this.handleInputChange}   />
                                                <TextInput key={"emailInput"} inputType={"email"} inputId={"emailInput"} inputTitle={t("Email")} inputValue={this.state.emailInput} handleInputChange={this.handleInputChange}   />
                                                <TextInput key={"phoneInput"} inputId={"phoneInput"} inputTitle={t("Mobile Phone")} inputValue={this.state.phoneInput} handleInputChange={this.handleInputChange} isPhone  errorText={this.state.phoneInputError}  />

                                                <h3 className={"t-left"}><T>Post Address</T></h3>
                                                <TextInput key={"postAddressInput"} inputId={"postAddressInput"} inputTitle={t("Post Address")} inputValue={this.state.postAddressInput} handleInputChange={this.handleInputChange}   />
                                                <TextInput key={"postPostCodeInput"} inputId={"postPostCodeInput"} inputTitle={t("Post Postcode")} inputValue={this.state.postPostCodeInput} handleInputChange={this.handleInputChange}  required={false} />
                                                <CityInput key={"postCity"} inputId={"selectedPostCity"} inputTitle={t("Post City")} selectedCity={'selectedPostCity'} selectedCounty={'selectedPostCounty'} value={this.state.selectedPostCity} handleCityChange={this.handleCityOnChange} citiesData={this.props.citiesData} citiesLoaded={this.props.citiesLoaded}   />
                                                <CountyInput key={"postCounty"} inputId={"postCounty"} inputTitle={t("Post County")} selectedCity={this.state.selectedPostCity} selectedCounty={'selectedPostCounty'} value={this.state.selectedPostCounty} handleCountyChange={this.handleStateNameChange} loadedCountiesData={this.props.loadedCountiesData}  />
                                                <TextInput key={"postPhoneInput"} inputId={"postPhoneInput"} inputTitle={t("Post Phone")} inputValue={this.state.postPhoneInput} handleInputChange={this.handleInputChange} isPhone  errorText={this.state.postPhoneInputError}  />

                                                <h3 className={"t-left"}><T>Billing Address</T></h3>
                                                <TextInput key={"billingAddressInput"} inputId={"billingAddressInput"} inputTitle={t("Billing Address")} inputValue={this.state.billingAddressInput} handleInputChange={this.handleInputChange}   />
                                                <TextInput key={"billingPostCodeInput"} inputId={"billingPostCodeInput"} inputTitle={t("Billing Postcode")} inputValue={this.state.billingPostCodeInput} handleInputChange={this.handleInputChange}  required={false} />
                                                <CityInput key={"billingCity"} inputId={"selectedBillingCity"} inputTitle={t("Billing City")} selectedCity={'selectedBillingCity'} selectedCounty={'selectedBillingCounty'} value={this.state.selectedBillingCity} handleCityChange={this.handleCityOnChange} citiesData={this.props.citiesData} citiesLoaded={this.props.citiesLoaded}   />
                                                <CountyInput key={"billingCounty"} inputId={"billingCounty"} inputTitle={t("Billing County")} selectedCity={this.state.selectedBillingCity} selectedCounty={'selectedBillingCounty'} value={this.state.selectedBillingCounty} handleCountyChange={this.handleStateNameChange} loadedCountiesData={this.props.loadedCountiesData}  />
                                                <TextInput key={"billingEmailInput"} inputType={"email"} inputId={"billingEmailInput"} inputTitle={t("Billing Email")} inputValue={this.state.billingEmailInput} handleInputChange={this.handleInputChange}   />
                                                <TextInput key={"billingPhoneInput"} inputId={"billingPhoneInput"} inputTitle={t("Billing Phone")} inputValue={this.state.billingPhoneInput} handleInputChange={this.handleInputChange} isPhone  errorText={this.state.billingPhoneInputError}  />
                                            </div>
                                            <div className="btnInline mt-10 t-center">
                                            <span className={"signup-button small mr-5 add-number"} onClick={this.disableDetailsEdit}>
                                                <T>Cancel</T>
                                            </span>
                                                <input className={"signup-button small " + isEditDetailsSubmitting} type="submit" value={t("Submit")}/>
                                            </div>
                                        </form>
                                }
                            </ContentModule>
                        </React.Fragment>
                        : ''
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkRequestStatus: (callback) => {
            dispatch(authorizeActions.dbCheckRequestStatus(callback));
        },
        getMemberData:(callback) => {
            dispatch(authorizeActions.dbGetMemberData(callback));
        },
        checkEmail: (data, callBack) => {
            dispatch(authorizeActions.dbCheckEmail(data, callBack));
        },
        signUp: (data, callBack) => {
            dispatch(authorizeActions.dbSignUp(data, callBack));
        },
        update: (data, callBack, group) => {
            dispatch(authorizeActions.dbUpdate(data, callBack, group));
        },
        updateTitle: (data, callback) => {
            dispatch(authorizeActions.dbUpdateProfileTitle(data, callback));
        },
        updateCommitmentFile: (data, callback) => {
            dispatch(authorizeActions.dbUpdateProfileCommitmentFile(data, callback));
        },
        updateDetails: (data, callback) => {
            dispatch(authorizeActions.dbUpdateProfileDetails(data, callback));
        },
        getPOdata: () => {
            dispatch(dataActions.dbGetPOdata());
        },
        getTOdata: () => {
            dispatch(dataActions.dbGetTOdata());
        },
        getCityData: () => {
            dispatch(dataActions.dbGetCityData());
        },
        dbGetCountiesData: (cityId, cityName) => {
            dispatch(dataActions.dbGetCountiesForCity(cityId, cityName))
        },
        getMemberStatus: () => {
            dispatch(authorizeActions.dbGetMemberStatus());
        },
    }
};

const mapStateToProps = (state, ownProps) => {
    const { authorize } = state;
    const { data } = state;
    return {
        authed: authorize.authed,
        member: authorize.member,
        memberDataLoaded: authorize.memberDataLoaded,

        isPOloaded: data.isPOloaded,
        poData: data.poData,
        isTOloaded: data.isTOloaded,
        toData: data.toData,
        citiesLoaded: data.citiesLoaded,
        citiesData: data.citiesData,
        loadedCountiesName: data.loadedCountiesName,
        loadedCountiesData: data.loadedCountiesData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(ViewProfile))
