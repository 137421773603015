import React, {Component} from 'react'
import {connect} from "react-redux";
import * as gtin8Actions from "../../actions/gtin8Actions";
import {T, withTranslators} from "lioness";
import NewApplication from "./NewApplicationv2";
import {withRouter} from "react-router-dom";
import ContentModule from "../ContentModule";

class NewApplicationWrapper extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 0,
            waitingApplicationId: null,
            hasWaitingApplication: false,
            isCommitmentLetterValid: null,
            itemPrice: 0
        };
    }

    componentWillMount(){
        let {applicationId} = this.props.match.params;
        if (!applicationId)
            applicationId = "";

        this.props.checkNewApplicationValidity(applicationId, this.handleNewApplicationChecker);
    }

    handleNewApplicationChecker = (result) =>{

        if (result.status === 200){
            if (!result.data.application_exists){
                this.setState({
                    currentStep: 1,
                })
            }
            else if (result.data.has_info_nok){
                this.setState({
                    currentStep: 2,
                })
            }
            else if (result.data.has_waiting_application){
                this.setState({
                    currentStep: 3,
                    hasWaitingApplication: true,
                    waitingApplicationId: result.data.waiting_application_id,
                    isCommitmentLetterValid: result.data.commitment_letter_valid,
                    itemPrice: result.data.item_price
                })
            }
            else if (result.data.has_active_applications){
                this.setState({
                    currentStep: 6,
                })
            }
            else {
                this.setState({
                    currentStep: 4,
                    isCommitmentLetterValid: result.data.commitment_letter_valid,
                    itemPrice: result.data.item_price
                });
            }
        }
    };

    getComponent(){
        const {currentStep} = this.state;

        if (currentStep === 0)
            return <div className={"t-center"}><T>Loading...</T></div>;
        else if (currentStep === 1)
            return <ContentModule>
                <T>This application does not exist.</T>
            </ContentModule>;
        else if (currentStep === 2)
            return <ContentModule colorClass={"bg-danger"}><T>No new GTIN-8 application is allowed. Please update the numbers which need editions.</T></ContentModule>;
        else if (currentStep === 3)
            return <ContentModule>
                <T>You have unfinished application.</T>
                <div className={"mt-20 t-center"}>
                    <span className={"signup-button small mr-5"} onClick={()=>{this.setState({currentStep: 5})}}> <T>Continue Application</T> </span>
                </div>
            </ContentModule>;
        else if (currentStep === 4)
            return <NewApplication isCommitmentLetterValid={this.state.isCommitmentLetterValid} itemPrice={this.state.itemPrice}/>;
        else if (currentStep === 5)
            return <NewApplication applicationId={this.state.waitingApplicationId} isCommitmentLetterValid={this.state.isCommitmentLetterValid} itemPrice={this.state.itemPrice}/>;
        else if (currentStep === 6)
            return <ContentModule>
                <T>You have active application. Please wait to finish active application(s) before creating a new application.</T>
            </ContentModule>;
        else return <div/>
    }

    render(){
        const component = this.getComponent();

        return(
            <div className={"new-application"}>
                <h2 className={"t-center"}><T>New GTIN-8 Application</T></h2>

                <ContentModule>
                    <T>Ürün bilgilerinizin başvuru aşamasında doğru olarak girilmesi önem arz etmektedir. Eskik olan bilgiler sonradan girilebilmektedir. Ancak eksik bilgili ürünlerin olması durumunda yeni başvuru yapılamamaktadır.</T>
                    <ul>
                        <li><strong><T>GTIN-8</T></strong>: <T>GTIN-8 başvurunuz tamamlandığında sistem tarafından otomatik olarak atanmaktadır</T></li>
                        <li><strong><T>Firma Adı</T></strong>: <T>Sisteme kayıtlı olan firma unvanı otomatik olarak atanmaktadır</T></li>
                        <li><strong><T>Hedef Pazar</T></strong>: <T>Ürünün satışının yapıldığı ülke (Tüm 7 nitelik bilgisi bu ülke diline uygun olarak girilmelidir)</T></li>
                        <li><strong><T>Marka</T></strong>: <T>Ürün üzerindeki marka adı (Firma adı değildir)</T></li>
                        <li><strong><T>Etiket Açıklaması</T></strong>: <T>Ürün etiketi üzerindeki açıklama (Detaylı bilgi yazılacaktır)</T></li>
                        <li><strong><T>Ürün Görseli</T></strong>: <T>Ürün fotografının yayınlandığı URL</T></li>
                        <li><strong><T>Kategori</T></strong>: <T>GS1 Küresel Ürün Kategorisine göre ürünün kategorisi</T></li>
                    </ul>
                </ContentModule>

                {
                    component
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        checkNewApplicationValidity: (applicationId, callback) =>{
            dispatch(gtin8Actions.dbValidateGtin8Application(applicationId, callback))
        }
    }
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(NewApplicationWrapper)));