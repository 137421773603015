import React, {Component} from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";
import {connect} from "react-redux";
import {makeGtin8ListTableData} from "../TableUtils";
import * as gtin8Actions from "../../actions/gtin8Actions";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";

const DEFAULT_PAGE_SIZE = 5;

class Gtin8List extends Component{
    constructor(props){
        super(props);

        this.state = {
            allNumbersTable: {
                data: [],
                pages: null,
                loading: true,
            }
        };

        this.fetchData = this.fetchData.bind(this);
    }

    loadDataCallback = (state, result) =>{
        const tableData = makeGtin8ListTableData(result.data);
        const totalTableSize = result.total_size;

        if(result.status === "ok"){
            this.setState({
                allNumbersTable:{
                    ...this.state.allNumbersTable,
                    data: tableData,
                    pages: Math.ceil(totalTableSize / state.pageSize),
                    loading: false
                },
            });
        }
    };

    fetchData(state, instance) {
        this.setState({
            allNumbersTable:{
                ...state,
                loading: true
            }
        });

        let tableType = 0;

        this.props.requestDataForGtin8List(tableType, state.pageSize, state.page, state.sorted, this.loadDataCallback, state);
    }

    render(){
        const {allNumbersTable,} = this.state;
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}> <T>GTIN-8 List</T></h2>
                <ContentModule>
                    <T>All GTIN-8's allocated to your name are listed below.</T>
                </ContentModule>

                <div className={"table-content "}>
                    <ReactTable
                        columns={[
                            {
                                Header: <T>GTIN-8</T>,
                                accessor: "gtin8"
                            },
                            {
                                Header: <T>Info Ok</T>,
                                accessor: "app_item_info_ok",
                                sortable: false
                            },
                            {
                                Header: <T>Registry Date</T>,
                                accessor: "registry_dt"
                            },
                            {
                                Header: <T>Actions</T>,
                                accessor: "actions",
                                sortable: false
                            },
                        ]}
                        manual
                        data={allNumbersTable.data}
                        pages={allNumbersTable.pages}
                        loading={allNumbersTable.loading}
                        onFetchData={this.fetchData}
                        defaultPageSize={DEFAULT_PAGE_SIZE}
                        className="-striped -highlight"
                        multiSort={false}

                        previousText={<T>Previous</T>}
                        nextText={<T>Next</T>}
                        loadingText={<T>Loading...</T>}
                        noDataText={<T>No rows found</T>}
                        pageText={<T>Page</T>}
                        ofText={<T>of</T>}
                        rowsText={t('rows')}
                        id={"allNumbersTable"}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        requestDataForGtin8List: (tableType, pagesize, page, sorted, callback, state) => {
            let sortedFieldName="", sortedIsDesc="";
            if (sorted.length !== 0){
                sortedFieldName = sorted[0].id;
                sortedIsDesc = sorted[0].desc ? "desc": "asc";
            }

            dispatch(gtin8Actions.dbRequestDataForGtin8List(tableType, pagesize, page, sortedFieldName, sortedIsDesc, callback, state));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(Gtin8List));