import * as gtin13Service from "../api/services/gtin13Service";

export const dbCreateGtin13Application = (data, callback) => {
    return (dispatch, getState) => {
        return gtin13Service.dbCreateGtin13Application(data, callback).then((result) => {
                callback(result.request);
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbRequestDataForGtin13List = (applicationId, pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return gtin13Service.dbRequestDataForGtin13List(applicationId, pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbLoadGtin13ApplicationDetail = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin13Service.dbLoadGtin13ApplicationDetail(applicationId, callback).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbLoadGtin13Application = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin13Service.dbLoadGtin13Application(applicationId, callback).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbEditGtin13Application = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin13Service.dbEditGtin13Application(data, applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};

