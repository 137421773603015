import * as dataService from "../api/services/dataService";
import * as DataActionType from "../constants/DataActionType";
import {axiosError} from "../api/services/errorHandler";

export const setLocale = (locale) => {
    if(locale !== "tr" && locale !== "en") {
        locale = "tr";
    }
    return (dispatch, getState) => {
        return dispatch(setLocaleAction(locale));
    }
};

export const dbGetPOdata = () => {
    return (dispatch, getState) => {
        return dataService.dbGetPOdata().then((result) => {
                dispatch(initPOdata(result.data));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetTOdata = () => {
    return (dispatch, getState) => {
        return dataService.dbGetTOdata().then((result) => {
                dispatch(initTOdata(result.data));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetCityData = () => {
    return (dispatch, getState) => {
        return dataService.dbGetCityData().then((result) => {
                dispatch(initCityData(result.data));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetCountryData = () => {
    return (dispatch, getState) => {
        return dataService.dbGetCountryData().then((result) => {
                dispatch(initCountryData(result.data));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetSegmentsData = () => {
    return (dispatch, getState) => {
        return dataService.dbGetSegmentsData().then((result) => {
                dispatch(initSegmentsData(result.data));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetFamiliesForSegment = (segmentId) => {
    return (dispatch, getState) => {
        return dataService.dbGetFamiliesForSegment(segmentId).then((result) => {
                dispatch(initFamilyForSegment(result.data, segmentId));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetClassForFamily = (familyId) => {
    return (dispatch, getState) => {
        return dataService.dbGetClassForFamily(familyId).then((result) => {
                dispatch(initClassForFamily(result.data, familyId));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetBrickForClass = (classId) => {
    return (dispatch, getState) => {
        return dataService.dbGetBrickForClass(classId).then((result) => {
                dispatch(initBrickForClass(result.data, classId));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetCountiesForCity = (cityId, cityName) => {
    return (dispatch, getState) => {
        return dataService.dbGetCountyData(cityId).then((result) => {
                dispatch(initCountForCity(result.data, cityName));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};


export const dbRequestDataForGcpTable = (applicationId, pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return dataService.dbRequestDataForGcpTable(applicationId, pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                // callback(messageError);
                axiosError(error, callback);
            });
    }
};

export const dbRequestApplicationsDataForGcpTable = (pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return dataService.dbRequestApplicationsDataForGcpTable(pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                // callback(messageError);
                axiosError(error, callback);
            });
    }
};

export const dbRequestDataForTransferTable = (pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return dataService.dbRequestDataForTransferTable (pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                // callback(messageError);
                axiosError(error, callback);
            });
    }
};


export const dbLoadMemberStatsData = (callback) => {
    return (dispatch, getState) => {
        return dataService.dbLoadMemberStatsData().then((result) => {
                callback({
                    "data": result.data.data,
                    "status": result.request.status,
                })
            },
            (error) =>{
                // callback(messageError);
                axiosError(error, callback);
            });
    }
};

export const dbGetMeasurementsData = () => {
    return (dispatch, getState) => {
        return dataService.dbGetMeasurementsData().then((result) => {
                dispatch(initMeasurementsData(result.data));
            },
            (error) =>{
                // callback(messageError);
            });
    }
};


/*==================================================== ACTION CONSTRUCTIONS ==========================================*/

export const setLocaleAction = ( locale ) => {
    localStorage.setItem("locale", locale);
    return {
        type: DataActionType.SET_LOCALE,
        payload: {
            locale: locale
        }
    }
};

export const initPOdata = (data) => {
    return {
        type: DataActionType.GET_PO,
        payload: {
            isPOloaded: true,
            poData: data
        }
    }
};

export const initTOdata = (data) => {
    return {
        type: DataActionType.GET_TO,
        payload: {
            isTOloaded: true,
            toData: data
        }
    }
};

export const initCityData = (data) => {
    return {
        type: DataActionType.GET_CITIES,
        payload: {
            citiesLoaded: true,
            citiesData: data
        }
    }
};

export const initCountryData = (data) => {
    return {
        type: DataActionType.GET_COUNTRIES,
        payload: {
            countriesLoaded: true,
            countriesData: data
        }
    }
};

export const initNaceCodesData = (data) => {
    return {
        type: DataActionType.GET_NACECODES,
        payload: {
            naceCodesLoaded: true,
            naceCodesData: data
        }
    }
};

export const initCountForCity = (data, city) => {
    return {
        type: DataActionType.LOAD_COUNTY,
        payload: {
            countyName: city,
            countyData: data
        }
    }
};

export const initSegmentsData = (data) => {
    return {
        type: DataActionType.LOAD_SEGMENTS,
        payload: {
            isSegmentsloaded: true,
            segmentsData: data
        }
    }
};

export const initFamilyForSegment = (data, segment) => {
    return {
        type: DataActionType.LOAD_FAMILY,
        payload: {
            segmentId: segment,
            familyData: data
        }
    }
};

export const initClassForFamily = (data, family) => {
    return {
        type: DataActionType.LOAD_CLASS,
        payload: {
            familyId: family,
            classData: data
        }
    }
};

export const initBrickForClass = (data, _class) => {
    return {
        type: DataActionType.LOAD_BRICK,
        payload: {
            classId: _class,
            brickData: data
        }
    }
};

export const initMeasurementsData = (data) => {
    return {
        type: DataActionType.LOAD_MEASUREMENTS,
        payload: {
            measurementsLoaded: true,
            measurementsData: data
        }
    }
};
