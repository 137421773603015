import React, {Component} from 'react'
import {connect} from "react-redux";
import * as gcpActions from "../../../actions/gcpActions";
import {RadioButton, RadioGroup} from "react-radio-buttons";
import TextInput from "../../input_types/TextInput";
import MultiSelectInput from "../../input_types/MultiSelectInput";
import * as applicationActions from "../../../actions/applicationActions";
import {transferLetterDownloadUrl, transferPetitionLetterDownloadUrl,transferofInheritnceLetterDownloadUrl} from "../../../constants/GcpUrlConstants";
import {T, withTranslators} from "lioness";
import ContentModule from "../../ContentModule";
import Iframe from "../../Iframe";

//TODO add check for allowing transfer?
class TransferToMember extends Component{
    constructor(props){
        super(props);
        const {t} = this.props;

        this.state = {
            currentStep: 0,
            applicationType: "gcp",
            transferType: "normal",
            gcpList: null,
            selectedGcps: "",
            isDataLoaded: false,

            toMemberTitle: '',

            hasError: false,
            errorMessage: "",

            applicationId: 0,
            submitting: false,
            applicationMessage: t('Loading...'),
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMultiSelectInputChange = this.handleMultiSelectInputChange.bind(this);
        this.onChangeApplicationType = this.onChangeApplicationType.bind(this);
        this.onChangeTransferType = this.onChangeTransferType.bind(this);
    }

    componentWillMount(){
        //load active gcps
        this.props.loadActiveGcps(this.activeGcpsCallback);
        // this.props.checkNewApplicationValidity(this.handleNewApplicationChecker);
    }

    handleNewApplicationChecker = (result) =>{
        // not used for now.
        if (result.status === 200){
            if (result.data.has_info_nok === true){
                const {t} = this.props;
                this.setState({
                    applicationMessage: t("No new application is allowed. You have waiting transfer request.")
                })
            }
            else {
                this.setState({
                    currentStep: 1,
                    applicationMessage: "",
                });
            }
        }
    };

    activeGcpsCallback = (result) => {
        let applicationType = "gcp";
        if (result.data.length === 1)
            applicationType = "membership";
        this.setState({
            currentStep: 2,
            gcpList: result.data,
            applicationType: applicationType,
            isDataLoaded: true,
        })
    };

    newApplicationCallBack = (result) => {
        if (result.status === 200){
            this.setState({
                currentStep: 3,
                applicationId: result.data.id,
                submitting: false
            })
        }
        else
            this.setState({
                submitting: false
            })
    };

    handleMultiSelectInputChange(value) {
        this.setState({
            selectedGcps: value
        });
    }

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    onChangeApplicationType(value) {
        this.setState({
            applicationType: value
        })
    }

    onChangeTransferType(value) {
        this.setState({
            transferType: value
        })
    }

    handleSubmit(event){
        event.preventDefault();
        const {t} = this.props;

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        // check if user has selected all gcps in gcp applicationType
        let allActiveGcpsSelected = true;
        for(let i=0; i<this.state.gcpList.length; i++){
            let currentDbGcp = this.state.gcpList[i];
            if(currentDbGcp.is_active === 1 && this.state.selectedGcps.indexOf(currentDbGcp) === -1)
                allActiveGcpsSelected = false;
        }
        if (this.state.applicationType === "gcp" && allActiveGcpsSelected){
            this.setState({
                hasError: true,
                submitting: false,
                errorMessage: t("Selecting all active gcps transfers all membership. Select membership application type above")
            });
            return;
        }
        if (this.state.hasError){
            this.setState({
                hasError: false,
                errorMessage: ""
            });
        }

        // submit application
        const formData = new FormData();
        formData.append('application_type', this.state.applicationType);
        formData.append('transfer_type', this.state.transferType);
        
        formData.append('to_member_title', this.state.toMemberTitle);

        if (this.state.applicationType === "gcp"){
            let parsedSelectedGcps = [];

            this.state.selectedGcps.map((item) =>
                parsedSelectedGcps.push(item.value)
            );

            formData.append('gcps', JSON.stringify(parsedSelectedGcps));
        }

        this.props.submitApplication(
            formData,
            this.newApplicationCallBack
        );
    }

    render(){
        const currentStep = this.state.currentStep;
        const isGcpTransferDisabled = this.state.gcpList !== null && this.state.gcpList.length === 1;
        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}> <T>Transfer of GCP or Membership</T></h2>

                <ContentModule>
                    <T>Sistem üyeleri üyeliklerini ve/veya kendilerine tahsis edilen Firma Önek Numalarını (GCP) GS1 Türkiye'nin onayıyla devredebilirler.</T>
                    <T>Bu sayfadan üretilecek devir belgesinin her iki tarafca noter onayıyla imzalanması gerekmektedir.</T>
                    <T>Devir alacak firma bu belge ile devir başvurusunu yapacak ve başvuru takibi devir alacak firma tarafından yapılacaktır.</T>
                </ContentModule>

                <Iframe
                    source={"GCP_Devri/h8.html"}
                />

                <div className={"mt-10"}>
                {
                    currentStep === 0 ?
                        <div className={"t-center"}>
                            <h3>{this.state.applicationMessage}</h3>
                        </div>:
                    currentStep === 1 ?
                        <div><T>Loading...</T></div>:

                        currentStep === 2 ?
                        <div className="mt-10">
                            <div>
                                <h4><T>Whould you like to make GCP or Membership transfer?</T></h4>
                                <RadioGroup onChange={ this.onChangeApplicationType } value={""} horizontal>
                                    <RadioButton value="gcp" disabled={isGcpTransferDisabled}>
                                        <T>GCP Transfer</T>
                                    </RadioButton>
                                    <RadioButton value="membership">
                                        <T>Membership Transfer</T>
                                    </RadioButton>
                                </RadioGroup>

                                <h4><T>Is the trasfer due to death of the current member?</T></h4>
                                <RadioGroup onChange={ this.onChangeTransferType } value={""} horizontal>
                                    <RadioButton value="normal">
                                        <T>No</T>
                                    </RadioButton>
                                    <RadioButton value="death">
                                        <T>Yes</T>
                                    </RadioButton>
                                </RadioGroup>
                            </div>
                            <ContentModule containerClass={"mt-20"}>
                                <form onSubmit={this.handleSubmit} className={"mt-10 editor small signin"}>
                                    <div>
                                        <TextInput key={"toMemberTitle"} inputId={"toMemberTitle"} inputTitle={t("To member title")} maxLength={100} inputValue={this.state.toMemberTitle} handleInputChange={this.handleInputChange}/>
                                        {
                                            this.state.applicationType === "gcp" ?
                                                <MultiSelectInput inputId={"selectedGcps"} inputTitle={t("Select Gcp")} value={this.state.selectedGcps} isDataLoaded={this.state.isDataLoaded} optionsData={this.state.gcpList} handleInputChange={this.handleMultiSelectInputChange}/>
                                                : null
                                        }
                                    </div>
                                    <div className={"after-submit mt-10"}>
                                        {
                                            this.state.hasError &&
                                            <div className="validation-summary-errors">
                                                <span>{this.state.errorMessage}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className="btnInline mt-10 t-center">
                                        <input className={"signup-button " + isSubmitting} type="submit" value={t("Submit")}/>
                                    </div>
                                </form>
                            </ContentModule>
                        </div>:

                            currentStep === 3 ?
                                <ContentModule containerClass={"mt-20"}>
                                    <T>Başvurunuzun devam edebilmesi için aşağıdaki evrakları indirip gerekli imza ve noter onayları sonrasında devir alacak firmaya teslim ediniz.</T>
                                    <T>Devir alacak firma bu belgeler ile devir başvurusunu yapacak ve başvuru takibi devir alacak firma tarafından yapılacaktır.</T>
                                    
                                    {
                                        this.state.transferType != "death" ?
                                            <div>
                                        <a href={transferLetterDownloadUrl + "?id=" + this.state.applicationId} className={"ml-5"} target={"_blank"}><T>Transfer file</T></a>
                                            </div>: null
                                    }

                                    
                                    <div>
                                        <a href={transferPetitionLetterDownloadUrl + "?id=" + this.state.applicationId} className={"ml-5"} target={"_blank"}><T>Petition file</T></a>
                                    </div>
                                    {
                                        this.state.transferType === "death" ?
                                            <div>
                                               <div>
                                        <a href={transferofInheritnceLetterDownloadUrl + "?id=" + this.state.applicationId} className={"ml-5"} target={"_blank"}>Mirascilar Arası Devir Belgesi</a>
                                    </div>
                                            </div>: null
                                    }
                                    
                                    {
                                        this.state.transferType === "death" ?
                                            <div className={"mt-20"}>
                                                <T>Some other info about death case goes here...</T>
                                            </div>: null
                                    }
                                </ContentModule> : null
                }
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // checkNewApplicationValidity: (callback) =>{
        //     dispatch(gcpActions.dbValidateTransferApplication(callback))
        // },
        loadActiveGcps: (callback) => {
            dispatch(gcpActions.dbLoadActiveGcps(callback));
        },
        submitApplication: (data, callback) => {
            dispatch(applicationActions.dbSubmitTransferApplicationDocLog(data, callback));
        }
    }
};


const mapStateToProps = (state, ownProps) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(TransferToMember));
