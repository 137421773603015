import React, {Component} from 'react'
import {T, withTranslators} from "lioness";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TextInput from "../../input_types/TextInput";
import ContentModule from "../../ContentModule";
import * as applicationActions from "../../../actions/applicationActions";
import FileLink from "../../input_types/FileLink";

class MergeView extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            application: {
                status: "",
                createdAt: "",
            }
        };
    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }
        else if (this.props && this.props.applicationId)
            applicationId = this.props.applicationId;

        this.props.loadApplicationDetail(applicationId, this.applicationItemCallback);
    }

    applicationItemCallback = (result) => {

        if (result.status === 200){
            this.setState({
                application: {
                    status: result.data.application_status_name,
                    mergeFile: result.data.merge_file,
                    extraFiles: result.data.extra_files,
                    createdAt: result.data.created_at,
                },
                currentStep: 2
            })
        }
    };

    render(){
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>View Merge Application</T></h2>

                <ContentModule>
                    <T>You can follow the status of your application from this page.</T>
                </ContentModule>

                {
                    this.state.currentStep === 1 ?
                        <div><T>Loading...</T></div> :

                        <React.Fragment>
                            <ContentModule>
                                <form>
                                    <div className="editor small signin">
                                        <div>
                                            <TextInput key={"status"} inputId={"status"} inputTitle={t("Status")} inputValue={t(this.state.application["status"])} disabled required={false}/>
                                            <FileLink inputId={"mergeFile"} inputTitle={t("Merge File")} required={false} inputValue={this.state.application["mergeFile"]}/>
                                            {
                                                this.state.application["extraFiles"] ?
                                                    <FileLink inputId={"extraFiles"} inputTitle={t("Extra Files")}  multiple={true} inputValue={this.state.application["extraFiles"]} required={false}/> :null
                                            }
                                            <TextInput key={"createdAt"} inputId={"createdAt"} inputTitle={t("Date")} inputValue={this.state.application["createdAt"]} disabled required={false}/>
                                        </div>
                                    </div>
                                </form>
                            </ContentModule>
                        </React.Fragment>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationDetail: (applicationId, callback) => {
            dispatch(applicationActions.dbGetMergeApplicationDetail(applicationId, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(MergeView)));