import * as auxService from "../api/services/auxService";

export const dbGetTermsOfUse = (callback) => {
    return (dispatch, getState) => {
        return auxService.dbGetTermsOfUse().then((result) => {
                callback(result.data.text)
            },
            (error) =>{
            });
    }
};


export const dbGetMemberServiceFeeList = (pagesize, page, sortedFieldName, sortedIsDesc, filterOption, callback) => {
    return (dispatch, getState) => {
        return auxService.dbGetServiceFeeList(pagesize, page, sortedFieldName, sortedIsDesc, filterOption).then((result) => {
                callback({
                    "status": result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};


export const dbServiceFeeDetail = (id, callback) => {
    return (dispatch, getState) => {
        return auxService.dbGetServiceFeeDetail(id).then((result) => {
                callback({
                    "status": result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};


export const dbGetMemberSubscriptionFeeList = (pagesize, page, sortedFieldName, sortedIsDesc, filterOption, callback) => {
    return (dispatch, getState) => {
        return auxService.dbGetSubscriptionFeeList(pagesize, page, sortedFieldName, sortedIsDesc, filterOption).then((result) => {
                callback({
                    "status": result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};


export const dbSubscriptionDetail = (id, callback) => {
    return (dispatch, getState) => {
        return auxService.dbGetSubscriptionDetail(id).then((result) => {
                callback({
                    "status": result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetFeeDetail = (data, callback) => {
    return (dispatch, getState) => {
        return auxService.dbGetFeeDetail(data).then((result) => {
                callback({
                    "status": result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};