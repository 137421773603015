import React, {Component} from 'react'

class HomeVideo extends Component{

    render(){
        return(
            <div className={"t-center"}>
                <video width="90%" controls>
                    <source src={"https://sistem.gs1tr.org/media/multimedia/kodi_intro.mp4"}/>
                </video>
            </div>
        )
    }
}

export default HomeVideo;