import React, {Component} from 'react'

class MyServices extends Component{

    render(){
        return(
            <div>
                <h2 className={"t-center mt-20"}>
                    There are no current services for now.
                </h2>
            </div>
        )
    }
}

export default MyServices;