import React, {Component} from 'react'
import MemberRouter from "../routes/MemberRouter";
import MemberHeader from "./MemberHeader";
import {Link, withRouter} from "react-router-dom";
import {T} from "lioness";
import ContentModule from "./ContentModule";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import "../styles/Drawer.css"
import WithDebtRouter from "../routes/WithDebtRouter";
import WithEditProfileRouter from "../routes/WithEditProfileRouter";

class MemberHome extends Component{
    constructor(props){
        super(props);

        this.state = {
            toggle13: false,
            toggle8: false,
            toggleGcp: false,
            toggleVw: false,
            toggleUpc: false,
            toggle12: false,
            toggleTransfer: false,
            togglePayment: false,
            toggleOtherServices: false
        };

        this.handleToggle13 = this.handleToggle13.bind(this);
        this.handleToggle8 = this.handleToggle8.bind(this);
        this.handleToggleGcp = this.handleToggleGcp.bind(this);
        this.handleToggleVw = this.handleToggleVw.bind(this);
        this.handleToggleUpc = this.handleToggleUpc.bind(this);
        this.handleToggleGtin12 = this.handleToggleGtin12.bind(this);
        this.handleToggleTransfer = this.handleToggleTransfer.bind(this);
        this.handleTogglePayment = this.handleTogglePayment.bind(this);
        this.handleOtherServices = this.handleOtherServices.bind(this);
    }

    componentWillMount(){
        if (this.props && this.props.location && this.props.location.pathname){
            if(this.props.location.pathname.includes("/member/gtin8/")){
                this.setState({
                    toggle8: true
                })
            }
            else if(this.props.location.pathname.includes("/member/gtin13/")){
                this.setState({
                    toggle13: true
                })
            }
            else if(this.props.location.pathname.includes("/member/gtin12/")){
                this.setState({
                    toggle12: true
                })
            }
            else if(this.props.location.pathname.includes("/member/gcp/")){
                this.setState({
                    toggleGcp: true
                })
            }
            else if(this.props.location.pathname.includes("/member/vw/")){
                this.setState({
                    toggleVw: true
                })
            }
            else if(this.props.location.pathname.includes("/member/upc/")){
                this.setState({
                    toggleUpc: true
                })
            }
            else if(this.props.location.pathname.includes("/member/transfer/")){
                this.setState({
                    toggleTransfer: true
                })
            }
            else if(this.props.location.pathname.includes("/member/payment/")){
                this.setState({
                    togglePayment: true
                })
            }
            else if(this.props.location.pathname.includes("/member/other-services/")){
                this.setState({
                    toggleOtherServices: true
                })
            }
        }
    }

    handleToggle8(){
        this.setState({
            toggle8: !this.state.toggle8
        })
    }

    handleToggle13(){
        this.setState({
            toggle13: !this.state.toggle13
        })
    }

    handleToggleGcp(){
        this.setState({
            toggleGcp: !this.state.toggleGcp
        })
    }

    handleToggleVw(){
        this.setState({
            toggleVw: !this.state.toggleVw
        })
    }

    handleToggleUpc(){
        this.setState({
            toggleUpc: !this.state.toggleUpc
        })
    }

    handleToggleGtin12(){
        this.setState({
            toggle12: !this.state.toggle12
        })
    }

    handleToggleTransfer(){
        this.setState({
            toggleTransfer: !this.state.toggleTransfer
        })
    }

    handleTogglePayment(){
        this.setState({
            togglePayment: !this.state.togglePayment
        })
    }

    handleOtherServices(){
        this.setState({
            toggleOtherServices: !this.state.toggleOtherServices
        })
    }


    render(){
        const classGcp = !this.state.toggleGcp ? "d-hide" : "";
        const class13 = !this.state.toggle13 ? "d-hide" : "";
        const class8 = !this.state.toggle8 ? "d-hide" : "";
        const classVw = !this.state.toggleVw ? "d-hide" : "";
        const classUpc = !this.state.toggleUpc ? "d-hide" : "";
        const class12 = !this.state.toggle12 ? "d-hide" : "";
        const classTransfer = !this.state.toggleTransfer ? "d-hide" : "";
        const classPayment = !this.state.togglePayment ? "d-hide" : "";
        const classOtherServices = !this.state.toggleOtherServices ? "d-hide" : "";

        return(
            <div>
                <MemberHeader/>
                {
                    this.props.debt?
                        <WithDebtRouter/>:

                        !this.props.isProfileComplete ?
                            <WithEditProfileRouter/>:

                            <div className={"content-wrapper"}>
                                <div className={"drawer-main"}>
                                    <ContentModule colorClass={"bg-sky-active"} containerClass={"drawer-wrapper"}>
                                        <ul>
                                            <li><Link to={"/member"}> <T>Home</T> </Link></li>

                                            <li onClick={this.handleToggleGcp}>
                                                <div className={"draw-li-item-a"}><FontAwesomeIcon icon={this.state.toggleGcp? faCaretUp : faCaretDown} /> <T>GCP</T></div>
                                            </li>
                                            <li className={"group " + classGcp}>
                                                <Link to={"/member/gcp/list"} className={"draw-li-item"}> <T>GCP List</T> </Link>
                                                <Link to={"/member/gcp/myapplications"} className={"draw-li-item"}> <T>My applications</T> </Link>
                                                <Link to={"/member/gcp/newapplication"} className={"draw-li-item"}> <T>New application</T> </Link>
                                            </li>

                                            <li onClick={this.handleToggle13}>
                                                <div className={"draw-li-item-a"}><FontAwesomeIcon icon={this.state.toggle13? faCaretUp : faCaretDown} /> <T>GTIN-13</T></div>
                                            </li>
                                            <li className={"group " + class13}>
                                                <Link to={"/member/gtin13/newapplication"} className={"draw-li-item"}> <T>GTIN-13 Data Entry</T> </Link>
                                                <Link to={"/member/gtin13/list"} className={"draw-li-item"}> <T>GTIN-13 List</T> </Link>
                                            </li>

                                            <li onClick={this.handleToggle8}>
                                                <div className={"draw-li-item-a"}><FontAwesomeIcon icon={this.state.toggle8? faCaretUp : faCaretDown} /> <T>GTIN-8</T></div>
                                            </li>
                                            <li className={"group " + class8}>
                                                <Link to={"/member/gtin8/list"} className={"draw-li-item"}> <T>GTIN-8 List</T> </Link>
                                                <Link to={"/member/gtin8/myapplications"} className={"draw-li-item"}> <T>Uncompleted Applications</T> </Link>
                                                <Link to={"/member/gtin8/newapplication"} className={"draw-li-item"}> <T>New application</T> </Link>
                                            </li>

                                            <li onClick={this.handleToggleVw}>
                                                <div className={"draw-li-item-a"}><FontAwesomeIcon icon={this.state.toggleVw? faCaretUp : faCaretDown} /> <T>Varying Weight Numbers</T></div>
                                            </li>
                                            <li className={"group " + classVw}>
                                                <Link to={"/member/vw/list"} className={"draw-li-item"}> <T>Varying Weight Number List</T> </Link>
                                                <Link to={"/member/vw/myapplications"} className={"draw-li-item"}> <T>Uncompleted Applications</T> </Link>
                                                <Link to={"/member/vw/newapplication"} className={"draw-li-item"}> <T>New application</T> </Link>
                                            </li>

                                            <li onClick={this.handleToggleUpc}>
                                                <div className={"draw-li-item-a"}><FontAwesomeIcon icon={this.state.toggleUpc? faCaretUp : faCaretDown} /> <T>UPC</T></div>
                                            </li>
                                            <li className={"group " + classUpc}>
                                                <Link to={"/member/upc/list"} className={"draw-li-item"}> <T>UPC List</T> </Link>
                                                <Link to={"/member/upc/myapplications"} className={"draw-li-item"}> <T>My applications</T> </Link>
                                                <Link to={"/member/upc/newapplication"} className={"draw-li-item"}> <T>New application</T> </Link>
                                            </li>

                                            <li onClick={this.handleToggleGtin12}>
                                                <div className={"draw-li-item-a"}><FontAwesomeIcon icon={this.state.toggle12? faCaretUp : faCaretDown} /> <T>GTIN-12</T></div>
                                            </li>
                                            <li className={"group " + class12}>
                                                <Link to={"/member/gtin12/newapplication"} className={"draw-li-item"}> <T>GTIN-12 Data Entry</T> </Link>
                                                <Link to={"/member/gtin12/list"} className={"draw-li-item"}> <T>GTIN-12 List</T> </Link>
                                            </li>

                                            {/*  <li><Link to={"/member/myservices"}> <T>My Services</T> </Link></li> */}

                                            <li onClick={this.handleToggleTransfer}>
                                                <div className={"draw-li-item-a"}><FontAwesomeIcon icon={this.state.toggleTransfer? faCaretUp : faCaretDown} /> <T>Transfer</T></div>
                                            </li>
                                            <li className={"group " + classTransfer}>
                                                <Link to={"/member/transfer/tomember"} className={"draw-li-item"}> <T>Transfer To Member</T> </Link>
                                                <Link to={"/member/transfer/frommember"} className={"draw-li-item"}> <T>Receive From Member</T> </Link>
                                                <Link to={"/member/transfer/merge"} className={"draw-li-item"}> <T>Merge With An Existing Member</T> </Link>
                                            </li>

                                            <li onClick={this.handleTogglePayment}>
                                                <div className={"draw-li-item-a"}><FontAwesomeIcon icon={this.state.togglePayment? faCaretUp : faCaretDown} /> <T>Payment</T></div>
                                            </li>
                                            <li className={"group " + classPayment}>
                                                <Link to={"/member/payment/servicefee"} className={"draw-li-item"}> <T>Service Fees</T> </Link>
                                                <Link to={"/member/payment/subscription"} className={"draw-li-item"}> <T>Subscription Fees</T> </Link>
                                            </li>

                                            <li onClick={this.handleOtherServices}>
                                                <div className={"draw-li-item-a"}><FontAwesomeIcon icon={this.state.toggleOtherServices? faCaretUp : faCaretDown} /> <T>Other Services</T></div>
                                            </li>
                                            <li className={"group " + classOtherServices}>
                                                <a href={"https://learning.gs1.org/login/index.php"} className={"draw-li-item"} target={"_blank"}> <T>GS1 Learn</T> </a>
                                            </li>

                                        </ul>
                                    </ContentModule>
                                </div>
                                <div className={"drawer-content"}>
                                    <MemberRouter/>
                                </div>
                            </div>
                }
            </div>
        )
    }
}

export default withRouter(MemberHome);