import React, {Component} from 'react'
import tick_ok from '../../images/tick_ok.png'


class FileLink extends Component{
    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        this.props.handleUploadFile(event);
    }

    render(){
        const {inputId, inputTitle, inputValue, errorText, multiple} = this.props;
        const _dflex    = multiple ? '' : 'd-flex';

        if (!inputValue)
            return null;

        let filename = !multiple ? inputValue.split('/') : "";
        filename = !multiple && filename ? filename[filename.length - 1]: "";

        return(
            <div className={"child-wrapper"}>
                <div className="child d-flex">
                    <div className="child d-flex">
                        <label htmlFor={inputId}>{inputTitle}</label>
                        <div className={_dflex}>
                            {
                                multiple ?
                                    inputValue.map((file, i) =>{
                                        let filename = file.split('/');
                                        filename = filename[filename.length - 1];
                                        return <p key={i}>
                                            <a href={file} target={"_blank"} className={"multiple-file-link"}>
                                                <span className={"d-flex"}><img src={tick_ok} alt={""} className={"mr-5"}/> {filename} </span>
                                            </a>
                                        </p>
                                    })
                                :
                                <a href={inputValue} target={"_blank"} className={"file-link"}>
                                    <span className={"d-flex"}><img src={tick_ok} alt={""} className={"mr-5"}/> {filename} </span>
                                </a>
                            }
                        </div>
                    </div>
                </div>
                <div className={"t-center"}>
                    {
                        errorText && errorText.length ?
                            (
                                <span className={"input-error"}>{errorText}</span>
                            )
                            :
                            ''
                    }
                </div>
            </div>
        )
    }
}

export default FileLink;