import React, {Component} from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";
import {connect} from "react-redux";
import {makeGtin8TableData} from "../TableUtils";
import {makeGtin8ListTableData} from "../TableUtils";
import * as gtin8Actions from "../../actions/gtin8Actions";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";

const DEFAULT_PAGE_SIZE = 5;

class MyApplications extends Component{
    constructor(props){
        super(props);

        this.state = {
            table: {
                data: [],
                pages: null,
                loading: true,
            },
            editRevisionTable: {
                data: [],
                pages: null,
                loading: true,
            },
        };

        this.fetchDataApplication = this.fetchDataApplication.bind(this);
        this.fetchDataRev = this.fetchDataRev.bind(this);
    }

    loadDataApplicationCallback = (state, result) =>{
        const tableData = makeGtin8TableData(result.data);
        const totalTableSize = result.total_size;

        if(result.status === "ok"){
            this.setState({
                table:{
                    ...this.state.table,
                    data: tableData,
                    pages: Math.ceil(totalTableSize / state.pageSize),
                    loading: false
                },
            })
        }
    };

    loadDataRevCallback = (state, result) =>{
        const revTableData = makeGtin8ListTableData(result.data);
        const revTotalTableSize = result.total_size;

        if(result.status === "ok"){
            this.setState({
                editRevisionTable:{
                    ...this.state.editRevisionTable,
                    data: revTableData,
                    pages: Math.ceil(revTotalTableSize / state.pageSize),
                    loading: false
                },
            });
        }

    };

    fetchDataApplication(state, instance) {
        this.setState({
            table:{
                ...state,
                loading: true
            }
        });

        this.props.requestDataForGtin8(state.pageSize, state.page, state.sorted, this.loadDataApplicationCallback, state);
    }

    fetchDataRev(state, instance) {
        this.setState({
            allNumbersTable:{
                ...state,
                loading: true
            }
        });

        let tableType = 1;

        this.props.requestDataForGtin8List(tableType, state.pageSize, state.page, state.sorted, this.loadDataRevCallback, state);
    }

    render(){
        const {table, editRevisionTable} = this.state;
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>Uncompleted GTIN-8 Applications</T></h2>

                <ContentModule>
                    <T>Below GTIN-8 applications require your attention. Without clearing out this list, you cannot apply for a new GTIN-8.</T>
                </ContentModule>

                <div className={"table-content "}>
                    <ReactTable
                        columns={[
                            {
                                Header: <T>Status</T>,
                                accessor: "status"
                            },
                            {
                                Header: <T>Date</T>,
                                accessor: "created_at"
                            },
                            {
                                Header: <T>Actions</T>,
                                accessor: "actions",
                                sortable: false
                            },
                        ]}
                        manual
                        data={table.data}
                        pages={table.pages}
                        loading={table.loading}
                        onFetchData={this.fetchDataApplication}
                        defaultPageSize={DEFAULT_PAGE_SIZE}
                        className="-striped -highlight"
                        multiSort={false}

                        previousText={<T>Previous</T>}
                        nextText={<T>Next</T>}
                        loadingText={<T>Loading...</T>}
                        noDataText={<T>No rows found</T>}
                        pageText={<T>Page</T>}
                        ofText={<T>of</T>}
                        rowsText={t('rows')}
                    />
                </div>
                <div className={"mt-20"}>
                    <h2 className={"t-center"}><T>Numbers Waiting for Revisions or Edit</T></h2>
                    <ReactTable
                        columns={[
                            {
                                Header: <T>GTIN-8</T>,
                                accessor: "gtin8"
                            },
                            {
                                Header: <T>Info Ok</T>,
                                accessor: "app_item_info_ok",
                                sortable: false
                            },
                            {
                                Header: <T>Registry Date</T>,
                                accessor: "registry_dt"
                            },
                            {
                                Header: <T>Actions</T>,
                                accessor: "actions",
                                sortable: false
                            },
                        ]}
                        manual
                        data={editRevisionTable.data}
                        pages={editRevisionTable.pages}
                        loading={editRevisionTable.loading}
                        onFetchData={this.fetchDataRev}
                        defaultPageSize={DEFAULT_PAGE_SIZE}
                        className="-striped -highlight"
                        multiSort={false}

                        previousText={<T>Previous</T>}
                        nextText={<T>Next</T>}
                        loadingText={<T>Loading...</T>}
                        noDataText={<T>No rows found</T>}
                        pageText={<T>Page</T>}
                        ofText={<T>of</T>}
                        rowsText={t('rows')}
                        id={"editRevisionTable"}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        requestDataForGtin8: (pagesize, page, sorted, callback, state) => {
            let sortedFieldName="", sortedIsDesc="";
            if (sorted.length !== 0){
                sortedFieldName = sorted[0].id;
                sortedIsDesc = sorted[0].desc ? "desc": "asc";
            }

            dispatch(gtin8Actions.dbRequestDataForGtin8(pagesize, page, sortedFieldName, sortedIsDesc, callback, state));
        },
        requestDataForGtin8List: (tableType, pagesize, page, sorted, callback, state) => {
            let sortedFieldName="", sortedIsDesc="";
            if (sorted.length !== 0){
                sortedFieldName = sorted[0].id;
                sortedIsDesc = sorted[0].desc ? "desc": "asc";
            }

            dispatch(gtin8Actions.dbRequestDataForGtin8List(tableType, pagesize, page, sortedFieldName, sortedIsDesc, callback, state));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(MyApplications));