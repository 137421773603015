import {getLocale} from "../api/services/authToken";
import swal from 'sweetalert'

const MAX_FILE_LIMIT_TEXT = '10M';
const MAX_FILE_LIMIT_VALUE = 10485760; // 10*1024*1024;


export const isFileSizeExceeded = (formData) =>{
    let size = 0;

    for (let pair of formData.entries())
        if (pair[1] instanceof File)
            size += pair[1].size;

    return size > MAX_FILE_LIMIT_VALUE
};


export const showFileError = ()=> {
    const language = getLocale();
    let titleText = 'Error';
    let bodyText = 'Maximum file size exceeded. Limit is: ' + MAX_FILE_LIMIT_TEXT;

    if (language === 'tr'){
        titleText = 'Hata';
        bodyText = 'Maksimum dosya boyutu aşıldı. Sınır: ' + MAX_FILE_LIMIT_TEXT;
    }

    swal({
        title: titleText,
        text: bodyText,
        icon: 'error'
    });
};