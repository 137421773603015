import React, {Component} from 'react'
import '../../styles/Dashboard.css'
import * as applicationActions from "../../actions/applicationActions";
import {connect} from "react-redux";
import Cookies from 'universal-cookie';
import {
    APPLICATION_ACTION_CREATE,
    APPLICATION_ACTION_EDIT,
    APPLICATION_ACTION_VIEW,
    APPLICATION_STATUS_MAN_CHECK,
    APPLICATION_STATUS_NEW,
    APPLICATION_STATUS_NOT_CREATED,
    APPLICATION_STATUS_ONLINE_DOC,
    APPLICATION_STATUS_OP_CHECK,
    APPLICATION_STATUS_OP_REVISION,
    APPLICATION_STATUS_PHYSICAL_DOC, APPLICATION_STATUS_REJECTED
} from "../../constants/Statuses";
import ApplicationView from "./ApplicationView";
import EditApplication from "./EditApplication";
import NewApplication from "./NewApplication";
import WithdrawApplication from "./WithdrawApplication";
import * as gcpActions from "../../actions/gcpActions";
import * as authorizeActions from "../../actions/authorizeActions";
import {T, withTranslators} from "lioness";


class ApplicationHandler extends Component{

    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            application: null,
            actionType: 0,
        };

        this.handleEditAction = this.handleEditAction.bind(this);
        this.getStatus = this.getStatus.bind(this);

        this.handleWithdrawApplication = this.handleWithdrawApplication.bind(this);
        this.handleWithdrawYesApplication = this.handleWithdrawYesApplication.bind(this);
        this.handleWithdrawNoApplication = this.handleWithdrawNoApplication.bind(this);
        this.handleCreateNewApplication = this.handleCreateNewApplication.bind(this);
        this.handleCreateNewApplicationFromRejection = this.handleCreateNewApplicationFromRejection.bind(this);
    }

    componentWillMount(){
        const cookies = new Cookies();
        if(!cookies.get('token'))
            this.props.logout();

        this.getStatus();
    }

    getStatus(){
        this.props.getApplicationStatus(this.applicationStatusCallBack);
    }

    applicationStatusCallBack = (result) => {
        if(result.status === 200){

            let status = result.data.application_status;
            let actionType = null;
            const {t} = this.props;

            let status_description = '';
            switch (status) {
                case APPLICATION_STATUS_NOT_CREATED:
                    actionType = APPLICATION_ACTION_CREATE;
                    break;
                case APPLICATION_STATUS_ONLINE_DOC:
                    actionType = APPLICATION_ACTION_EDIT;
                    status_description = t('There are rejected docs');
                    break;
                case APPLICATION_STATUS_NEW:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('New');
                    break;
                case APPLICATION_STATUS_OP_CHECK:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('Your Application is being inspected');
                    break;
                case APPLICATION_STATUS_PHYSICAL_DOC:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('You must send your physical documents');
                    break;
                case APPLICATION_STATUS_MAN_CHECK:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('Your Application is being inspected');
                    break;
                case APPLICATION_STATUS_OP_REVISION:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('Your Application is being inspected');
                    break;
                default:
                    break;
            }

            let application = result.data;

            if (status === APPLICATION_STATUS_REJECTED){
                this.setState({
                    currentStep: -1,
                    rejectReason: result.data.latest_application_note
                })
            }
            else {
                application['status_description'] = status_description;
                this.setState({
                    currentStep: 2,
                    application: application,
                    actionType: actionType
                })
            }
        }
    };

    handleEditAction(){
        this.setState({
            actionType: APPLICATION_ACTION_EDIT
        })
    }

    withdrawCallback = (result) =>{
        if (result.status === 200){
            this.setState({
                currentStep: 4
            })
        }
        else {
            this.setState({
                currentStep: 5
            })
        }
    };

    handleWithdrawApplication(){
        this.setState({
            currentStep: 3
        })
    }

    handleWithdrawYesApplication(){
        this.props.withdrawApplication(this.state.application.application_id, this.withdrawCallback)
    }

    handleWithdrawNoApplication(){
        this.setState({
            currentStep: 2
        })
    }

    handleCreateNewApplication(){
        this.setState({
            currentStep: 1
        });

        this.getStatus();
    }

    handleCreateNewApplicationFromRejection(){
        this.setState({
            currentStep: 2,
            actionType: APPLICATION_ACTION_CREATE
        })
    }

    getComponent(){
        if (this.state.currentStep === 1)
            return <div className={"t-center mt-20"}>
                    <T>Loading...</T>
                </div>;
        else if (this.state.currentStep === -1)
            return <div className={"t-center mt-20"}>
                <h2><T>Your application is rejected for the following reason:</T></h2>
                <h3 className={"mt-20"}>
                    {this.state.rejectReason}
                </h3>

                <div className={"mt-20"}>
                    <button className={"signup-button small"} onClick={this.handleCreateNewApplicationFromRejection}><T>Create new application</T></button>
                </div>
                </div>;
        else if (this.state.currentStep === 2){
            if (this.state.actionType === APPLICATION_ACTION_CREATE)
                return <NewApplication getStatus={this.getStatus}/>;
            else if (this.state.actionType === APPLICATION_ACTION_VIEW)
                return  <ApplicationView application={this.state.application} handleEditAction={this.handleEditAction} handleWithdrawApplication={this.handleWithdrawApplication}/>;
            else if (this.state.actionType === APPLICATION_ACTION_EDIT)
                return <EditApplication application={this.state.application} getStatus={this.getStatus} handleWithdrawApplication={this.handleWithdrawApplication}/>;
            else
                return <div className={"t-center mt-20"}>
                    <T>No available application to show</T>
                </div>;
        }
        else if (this.state.currentStep === 3)
            return <WithdrawApplication handleYes={this.handleWithdrawYesApplication} handleNo={this.handleWithdrawNoApplication}/>;
        else if (this.state.currentStep === 4)
            return <div className={"t-center mt-20"}>
                <h2><T>Application is withdrawn successfully.</T></h2>

                <div className={"mt-10"}>
                    <button className={"signup-button"} onClick={this.handleCreateNewApplication}>
                        <T>Create new application</T>
                    </button>
                </div>
            </div>;
        else if (this.state.currentStep === 5)
            return <div className={"t-center mt-10"}>
                <T>Could not withdraw application</T>
            </div>
    }

    render(){
        const component = this.getComponent();

        return(
            <div>
                {
                    component
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getApplicationStatus: (callback) => {
            dispatch(applicationActions.dbGetApplicationStatus(0, callback));
        },
        withdrawApplication: (applicationId, callback) =>{
            dispatch(gcpActions.dbWithdrawGcpApplication(applicationId, callback))
        },
        logout: () => {
            dispatch(authorizeActions.dbLogout())
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(ApplicationHandler));
