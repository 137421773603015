import {siteUrl} from './UrlConstants';

export const gtin8ValidateNewApplication = siteUrl + '/api/gtin8/validatenewapplication';
export const gtin8NewApplication = siteUrl + '/api/gtin8/newapplication';
export const gtin8LoadApplication = siteUrl + '/api/gtin8/application';
export const requestDataForGtin8 = siteUrl + '/api/gtin8/myapplications';
export const requestDataForGtin8List = siteUrl + '/api/gtin8/list';
export const requestDataForGtin8ApplicationItemList = siteUrl + '/api/gtin8/applicationitemlist';
export const gtin8ApplicationEdit = siteUrl + '/api/gtin8/application/edit';
export const gtin8ApplicationItem = siteUrl + '/api/gtin8/applicationitem';
export const gtin8NewApplicationItem = siteUrl + '/api/gtin8/newapplicationitem';
export const gtin8DeleteApplicationItem = siteUrl + '/api/gtin8/deleteapplicationitem';
export const gtin8EditApplicationItemFromNew = siteUrl + '/api/gtin8/editapplicationitem';
