import {siteUrl} from './UrlConstants';

export const loginUrl = siteUrl + '/api/member/tauth';
export const impersonateUrl = siteUrl + '/api/member/impersonate_auth';
export const memberTitleUrl = siteUrl + '/api/member/title';
export const validateTokenUrl = siteUrl + '/api/member/validatetoken';
export const logoutUrl = siteUrl + '/api/member/logout';
export const emailCheckUrl = siteUrl + '/api/member/emailcheck';
export const emailTcknTaxNoCheckUrl = siteUrl + '/api/member/emailtckntaxnocheck';
export const updateRequestUrl = siteUrl + '/api/member/update_status';
export const signupUrl = siteUrl + '/api/member/signup';
export const updateUrl = siteUrl + '/api/member/update_member';
export const updateProfileTitleUrl = siteUrl + '/api/member/update_member_title';
export const updateProfileCommitmentFileUrl = siteUrl + '/api/member/update_member_commitment_file';
export const updateProfileDetailsUrl = siteUrl + '/api/member/update_member_details';
export const poDataUrl = siteUrl + '/api/member/parent_organization_data';
export const toDataUrl = siteUrl + '/api/member/tax_office_data';
export const countryDataUrl = siteUrl + '/api/member/countries';
export const cityDataUrl = siteUrl + '/api/member/cities';
export const countyDataUrl = siteUrl + '/api/member/counties';
export const getUserUrl = siteUrl + '/api/member/getuser';
export const registerOrResetUrl = siteUrl + '/api/member/registerorreset';
export const forgotPasswordUrl = siteUrl + '/api/member/forgotpassword';
export const memberStatusUrl = siteUrl + '/api/member/memberstatus';
export const memberDataUrl = siteUrl + '/api/member/memberdata';
export const termsOfUseUrl = siteUrl + '/api/member/termsofuse';
export const verifyPostCodeUrl = siteUrl + '/api/member/verifypostcode';
export const loadMemberStatsDataUrl = siteUrl + '/api/member/memberstats';
export const memberHasDebtUrl = siteUrl + '/api/member/memberdbebt';
export const memberServiceFeeListUrl = siteUrl + '/api/member/servicefeelist';
export const memberServiceFeeDetailUrl = siteUrl + '/api/member/servicefee/detail';
export const memberSubscriptionFeeListUrl = siteUrl + '/api/member/subscriptionfeelist';
export const memberSubscriptionDetailUrl = siteUrl + '/api/member/subscription/detail';
export const memberFeeDetailUrl = siteUrl + '/api/member/fee/detail';
export const measurementsDataUrl = siteUrl + '/api/member/measurements';
