import React, {Component} from 'react'
import '../../../styles/Dashboard.css'
import * as applicationActions from "../../../actions/applicationActions";
import {connect} from "react-redux";
import {
    APPLICATION_ACTION_CREATE, APPLICATION_ACTION_EDIT, APPLICATION_ACTION_VIEW, APPLICATION_STATUS_MAN_CHECK,
    APPLICATION_STATUS_NEW, APPLICATION_STATUS_NOT_CREATED,
    APPLICATION_STATUS_ONLINE_DOC,
    APPLICATION_STATUS_OP_CHECK, APPLICATION_STATUS_OP_REVISION, APPLICATION_STATUS_PHYSICAL_DOC,
    DOC_STATUS_REJECT,
} from "../../../constants/Statuses";
import FileInput from "../../input_types/FileInput";
import FileLink from "../../input_types/FileLink";
import TextInput from "../../input_types/TextInput";
import {withRouter} from "react-router-dom";
import {T, withTranslators} from "lioness";
import ContentModule from "../../ContentModule";
import {isFileSizeExceeded, showFileError} from "../../FileUtils";

class EditApplication extends Component{

    constructor(props){
        super(props);
        this.state = {
            currentStep: 1,
            application: null,
            submitting: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.handleOnApplicationUpdate = this.handleOnApplicationUpdate.bind(this);
    }

    componentWillMount(){
        const {applicationId} = this.props.match.params;
        this.props.getApplicationStatus(applicationId, this.applicationStatusCallBack);
    }

    applicationStatusCallBack = (result) => {
        if(result.status === 200){

            let status = result.data.application_status;
            let actionType = null;
            const {t} = this.props;

            let status_description = '';
            switch (status) {
                case APPLICATION_STATUS_NOT_CREATED:
                    actionType = APPLICATION_ACTION_CREATE;
                    break;
                case APPLICATION_STATUS_ONLINE_DOC:
                    actionType = APPLICATION_ACTION_EDIT;
                    status_description = t('There are rejected docs');
                    break;
                case APPLICATION_STATUS_NEW:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('New');
                    break;
                case APPLICATION_STATUS_OP_CHECK:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('Your Application is being inspected');
                    break;
                case APPLICATION_STATUS_PHYSICAL_DOC:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('You must send your physical documents');
                    break;
                case APPLICATION_STATUS_MAN_CHECK:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('Your Application is being inspected');
                    break;
                case APPLICATION_STATUS_OP_REVISION:
                    actionType = APPLICATION_ACTION_VIEW;
                    status_description = t('Your Application is being inspected');
                    break;
                default:
                    break;
            }

            let application = result.data;
            application['status_description'] = status_description;

            if (application.latest_application_note === null)
                application['latest_application_note'] = t("No notes recorded!");

            this.setState({
                currentStep: 2,
                application: application,
                actionType: actionType
            })
        }
    };

    handleInputChange(event) {
        this.setState({
            application: {
                ...this.state.application,
                [event.target.id]: event.target.value
            }
        });
    }

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            application: {
                ...this.state.application,
                [event.target.id]: submitFile
            }
        });

    };

    applicationUpdateCallBack = (result) => {
        if (result.status === "ok")
            this.setState({
                currentStep: 3,
                submitting: false
            });
        else
            this.setState({
                submitting: false
            })
    };

    handleOnEdit(){
        let appStatus = this.state.applicationStatus;
        appStatus.application_status_code = APPLICATION_STATUS_ONLINE_DOC;

        this.setState({
            applicationStatus: appStatus
        })
    };

    handleOnApplicationUpdate(event){
        event.preventDefault();

        const formData = new FormData();

        if (this.state.application.commercialCertificateFile)
            formData.append('commercial_certificate', this.state.application.commercialCertificateFile);
        if (this.state.application.commitmentLetterFile)
            formData.append('commitment_letter', this.state.application.commitmentLetterFile);
        if (this.state.application.incomeStatementFile)
            formData.append('income_statement', this.state.application.incomeStatementFile);

        formData.append('latest_application_note', this.state.application.latest_application_note);

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        this.props.updateApplication(
            formData,
            this.state.application.application_id,
            this.applicationUpdateCallBack);
    }

    render(){
        const {t} = this.props;
        const digitsText = t("digits");

        const status = this.state.application && this.state.application.application_status;
        const requestedDigits = this.state.application && this.state.application.requested_digit + " " + digitsText;
        const annualTaxAssessment = this.state.application && this.state.application.annual_tax_assessment;
        const commercialCertificate = this.state.application && this.state.application.commercial_certificate;
        const commercialCertificateStatus = this.state.application && this.state.application.commercial_certificate_status;

        const commitmentLetter = this.state.application && this.state.application.commitment_letter;
        const commitmentLetterStatus = this.state.application && this.state.application.commitment_letter_status;

        const incomeStatement = this.state.application && this.state.application.income_statement;
        const incomeStatementStatus = this.state.application && this.state.application.income_statement_status;

        const commercialCertificateReject = this.state.application && this.state.application.commercial_certificate_reject;
        const commitmentLetterReject = this.state.application && this.state.application.commitment_letter_reject;
        const incomeStatementReject = this.state.application && this.state.application.income_statement_reject;
        const latestApplicationNotes = this.state.application && this.state.application.latest_application_note;

        const isSubmitting = this.state.submitting ? "btn-disabled": "";

        return(
            <div>
                <h2 className={"t-center"}><T>Update Application</T></h2>

                <ContentModule>
                    <T>Checks regarding your application has been done, to complete your application process please upload missing/wrong documents to the system.</T>
                </ContentModule>

                <div className={"application-edit"}>
                    {
                        this.state.currentStep === 1 ?
                            <div> <T>Loading...</T></div> :
                            this.state.currentStep === 3 ?
                                <div>
                                    <T>Application is successfully updated</T>
                                </div>:
                            this.state.currentStep === 2 ?
                                this.state.currentStep === 2 && status === 2 ?
                                    <form onSubmit={this.handleOnApplicationUpdate}>
                                        <fieldset className="editor small signin">
                                            <div>
                                                <TextInput key={"requestedDigits"} inputId={"requestedDigits"} required={false} inputTitle={t("Requested Digits")} inputValue={requestedDigits} disabled/>
                                                <TextInput key={"annualTaxAssessment"} inputId={"annualTaxAssessment"} required={false} inputTitle={t("Annual Tax Assessment")} inputValue={annualTaxAssessment} disabled maxLength={22}/>
                                                {
                                                    commercialCertificateStatus === DOC_STATUS_REJECT ?
                                                        <FileInput inputId={"commercialCertificateFile"} inputTitle={t("Commercial Certificate")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={commercialCertificateReject}/>
                                                        :
                                                        <FileLink inputId={"commercialCertificateFile"} inputTitle={t("Commercial Certificate")} inputValue={commercialCertificate}/>
                                                }
                                                {
                                                    commitmentLetter ?
                                                    commitmentLetterStatus === DOC_STATUS_REJECT ?
                                                        <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={commitmentLetterReject}/>
                                                        :
                                                        <FileLink inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} inputValue={commitmentLetter}/> : null
                                                }
                                                {
                                                    incomeStatementStatus === DOC_STATUS_REJECT ?
                                                        <FileInput inputId={"incomeStatementFile"} inputTitle={t("Income Statement")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={incomeStatementReject}/>
                                                        :
                                                        <FileLink inputId={"incomeStatementFile"} inputTitle={t("Income Statement")} inputValue={incomeStatement}/>
                                                }
                                                <TextInput key={"latest_application_note"} inputId={"latest_application_note"} required={false} inputTitle={t("Additional Notes")} inputValue={latestApplicationNotes} handleInputChange={this.handleInputChange} isTextArea maxLength={255}/>
                                            </div>
                                            <div className="btnInline t-center">
                                                <input className={"signup-button " + isSubmitting} type="submit" value={t("Submit")}/>
                                            </div>
                                        </fieldset>
                                    </form>:
                                    <div>
                                        <T>Invalid operation</T>
                                    </div>: null
                    }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getApplicationStatus: (applicationId, callback) => {
            dispatch(applicationActions.dbGetApplicationStatus(applicationId, callback));
        },
        updateApplication: (data, application_id, callback) => {
            dispatch(applicationActions.dbUpdateApplication(data, application_id, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(EditApplication)));
