import React, {Component} from 'react'
import '../styles/Login.css'
import {Link, withRouter} from "react-router-dom";
import {
    LINK_TYPE_GCP, LINK_TYPE_GTIN12,
    LINK_TYPE_GTIN13,
    LINK_TYPE_GTIN8,
    LINK_TYPE_UPC,
    LINK_TYPE_VW
} from "../constants/ApplicationLinkTypes";
import {T, withTranslators} from "lioness";


class ApplicationMessageController extends Component{
    constructor(props){
        super(props);

        this.state = {
            message: "",
            applicationType: "",
            newApplicationUrl: ""
        }
    }

    componentWillMount(){
        const linkId = this.props.match.params.linkId;
        const {t} = this.props;
        const APPLICATION_SUBMITTED = t("Application is successfully submitted");


        if (linkId === LINK_TYPE_GTIN8)
            this.setState({
                message: APPLICATION_SUBMITTED,
                applicationType: t("GTIN-8"),
                newApplicationUrl: "/member/gtin8/newapplication"
            });
        else if (linkId === LINK_TYPE_VW)
            this.setState({
                message: APPLICATION_SUBMITTED,
                applicationType: t("Varying Weight Number"),
                newApplicationUrl: "/member/vw/newapplication"
            });
        else if (linkId === LINK_TYPE_UPC)
            this.setState({
                message: APPLICATION_SUBMITTED,
                applicationType: t("UPC"),
                newApplicationUrl: "/member/upc/newapplication"
            });
        else if (linkId === LINK_TYPE_GTIN13)
            this.setState({
                message: APPLICATION_SUBMITTED,
                applicationType: t("GTIN-13"),
                newApplicationUrl: "/member/gtin13/newapplication"
            });
        else if (linkId === LINK_TYPE_GTIN12)
            this.setState({
                message: APPLICATION_SUBMITTED,
                applicationType: t("GTIN-12"),
                newApplicationUrl: "/member/gtin12/newapplication"
            });
        else if (linkId === LINK_TYPE_GCP)
            this.setState({
                message: APPLICATION_SUBMITTED,
                applicationType: t("GCP"),
                newApplicationUrl: "/member/gcp/newapplication"
            });
    }

    render(){
        return(
            <section id="main-section">
                <h2 className={"t-center"}>
                    {
                        this.state.message
                    }
                </h2>
                <div className="btnInline mt-10 t-center nohover">
                    <Link to={this.state.newApplicationUrl} className={"signup-button small"} ><T>Create new application:</T> {this.state.applicationType} </Link>
                </div>
            </section>
        )
    }
}

export default withRouter(withTranslators(ApplicationMessageController));