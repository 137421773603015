import React, {Component} from 'react'
import {Redirect, withRouter} from "react-router-dom";
import {EMAIL_TYPE_ACTIVATE, EMAIL_TYPE_RESET_PASSWORD} from "../constants/EmailTypes";
import ActivateResetImp from "./ActivateResetImp";

class ActivateReset extends Component{
    constructor(props){
        super(props);

        this.state = {
            linkType: null,
            tokenId: ''
        }
    }

    componentWillMount(){
        const emailType = this.props.match.params.emailType;
        const tokenId = this.props.match.params.tokenId;

        if (emailType === EMAIL_TYPE_ACTIVATE){
            this.setState({
                linkType: EMAIL_TYPE_ACTIVATE,
                tokenId
            })
        }
        else if (emailType === EMAIL_TYPE_RESET_PASSWORD){
            this.setState({
                linkType: EMAIL_TYPE_RESET_PASSWORD,
                tokenId
            })
        }
    }

    getLinkType(){
        if(this.state.linkType === EMAIL_TYPE_ACTIVATE)
            return <ActivateResetImp linkType={EMAIL_TYPE_ACTIVATE} tokenId={this.state.tokenId}/>;
        else if(this.state.linkType === EMAIL_TYPE_RESET_PASSWORD)
            return <ActivateResetImp linkType={EMAIL_TYPE_RESET_PASSWORD} tokenId={this.state.tokenId}/>;
        else
            return <Redirect to={"/"}/>;
    }

    render(){
        const componentToRender = this.getLinkType();

        return(
            <div>
                {
                    componentToRender
                }
            </div>
        )
    }
}

export default withRouter(ActivateReset);