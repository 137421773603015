import React, {Component} from 'react'
import ReactDOMServer from 'react-dom/server';

class SmartSearchInput extends Component {

    constructor(props){
        super(props);

        this.state = {
            errorMessage: '',
        };

    }

    htmlCode() {
        const {number, type, description, imageUrl} = this.props;
        const _number = number ? number : '0123456789';
        const _type = type ? type : 'gtin13';
        const _description = description ? description : '';
        const _imageUrl = imageUrl ? imageUrl : '';

        let jsonLD = {
                  "@context": {
                    "gs1": "http://gs1.org/voc/",
                    "schema": "http://schema.org/",
                    "xsd": "http://www.w3.org/2001/XMLSchema#",
                    "TradeItem": "schema:Product",
                    "Offering": "schema:Offer",
                    "offerDescription": {
                      "@id": "schema:description",
                      "@language": "en"
                    },
                    "tradeItemDescription": {
                      "@id": "schema:description",
                      "@language": "en"
                    },
                    _type: {
                      "@id": "schema:" + _type,
                      "@type": "xsd:string"
                    },
                    "image": {
                      "@id": "schema:image",
                      "@type": "@id"
                    },
                    "includes": {
                      "@id": "schema:itemOffered",
                      "@type": "@id"
                    }
                  },
                  "@id": "https://www.gs1.org/product/" + _number,
                  "@type": "Offering",
                  _type: _number,
                  "offerDescription": _description,
                  "image": _imageUrl,
                  "includes": {
                    "@id": "https://www.gs1.org/product/" + _number,
                    _type: _number,
                    "@type": [
                      "TradeItem"
                    ],
                    "tradeItemDescription": _description
                  }
                };

        let scriptBegin = "<script type=\"application/ld+json\">";
        let scriptEnd = "</script>";

        jsonLD = JSON.stringify(jsonLD);

        let widgetHtml = ReactDOMServer.renderToString(this.displayWidget());

        let html = widgetHtml + scriptBegin + jsonLD + scriptEnd;

        return <textarea style={{margin: 'auto 0'}} cols={80} rows={5} defaultValue={html}>
            </textarea>
    }

    displayWidget() {
        const {number, type, description, imageUrl, brand, target} = this.props;
        const _number = number ? number : '0123456789';
        const _type = type ? type : 'gtin13';
        const _description = description ? description : '';
        const _imageUrl = imageUrl ? imageUrl : '';
        const _brand = brand ? brand : '';
        const _target = target ? target : '';

        return <div  typeof="http://schema.org/Product">
            <React.Fragment>
            {imageUrl !== '' &&
                <img src={_imageUrl} property="http://schema.org/image" alt={_description}/>
            }
            </React.Fragment>
            <fieldset>
                <div><label htmlFor={_type}>{_type}:</label> {_number}</div>
                <div><label htmlFor="target-market">Hedef Pazar:</label> {_target}</div>
                <div><label htmlFor="brand">Marka:</label> {_brand}</div>
                <div><label htmlFor="item-description">Etiket:</label> {_description}</div>
            </fieldset>
        </div>
    }

    render() {
        const {style} = this.props;
        const _style = style ? style : {};
        const htmlTextarea = this.htmlCode();
        const widget = this.displayWidget();
        return (
            <div className="child-wrapper" style={_style}>
                {widget}
                <label style={{marginTop: '50px',}}>Embed Code:</label><br/>
                {htmlTextarea}
                <p id={'error'}>{this.state.errorMessage}</p>
            </div>
        );
    }

}

export default SmartSearchInput;
