import React, {Component} from 'react'
import MasterRouter from "../routes/MasterRouter";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {Footer} from "./Footer";
import * as authorizeActions from "../actions/authorizeActions";

// Translation
import { LionessProvider} from 'lioness';
import messages from '../translations/frontend.json';

import '../styles/Master.css'
import * as dataActions from "../actions/dataActions";

class MasterComponent extends Component{
    componentWillMount() {
        let token = localStorage.getItem("authToken");
        if(token){
            this.props.validateToken(this.validateTokenCallBack);
        }

        let locale = localStorage.getItem("locale");
        this.props.setLocale(locale);
    }

    validateTokenCallBack = (result) => {
        this.props.getMemberStatus();
    };

    render(){
        return(
            <LionessProvider
                messages={{"tr": messages}}
                locale={this.props.locale}
                debug={false}
            >
                <div id={"master"}>
                    <MasterRouter/>
                    <Footer/>
                </div>
            </LionessProvider>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        validateToken: (callback) => {
            dispatch(authorizeActions.dbValidateToken(callback))
        },
        getMemberStatus: () => {
            dispatch(authorizeActions.dbGetMemberStatus());
        },
        setLocale: (locale) => {
            dispatch(dataActions.setLocale(locale));
        }
    }
};


const mapStateToProps = (state, ownProps) => {
    const {data} = state;
    return {
        locale: data.locale,
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)( MasterComponent));