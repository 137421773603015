import React, {Component} from 'react'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select';
import {withTranslators} from "lioness";


class CountyInput extends Component{
    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getCountyComponent = this.getCountyComponent.bind(this);
    }

    handleInputChange(event){
        this.props.handleCountyChange(event, this.props.selectedCounty);
    }

    getCountyComponent(){
        const loadedCountiesData = this.props.loadedCountiesData;
        const {t} = this.props;

        //city is selected and the related counties are fetched.
        if (this.props.selectedCity != null && this.props.selectedCity.value in loadedCountiesData){
            return <Select
                required
                value={this.props.value}
                onChange={this.handleInputChange}
                options={loadedCountiesData[this.props.selectedCity.value]}
                id={this.props.inputId}
                placeholder={t("Select...")}
            />
        }
        //either no city is selected or the data is being requested
        else {
            return <Select
                disabled={true}
            />;
        }
    }

    render(){
        const {inputId, inputTitle, dataToolTip, noFlex} = this.props;
        const component = this.getCountyComponent();
        const _flexClass = noFlex ? '' : 'child d-flex';

        return(
            <div className="child d-flex">
                <div className={_flexClass}>
                    <label htmlFor={inputId}>
                        {inputTitle}
                        <span className={"required-field"}>*</span>
                    </label>
                    <div className={"d-flex"}>
                        {
                            component
                        }
                        {
                            dataToolTip
                                ?
                                <span>
                                    <i className={"tooltip-icon"} data-tip={dataToolTip} data-event='click focus'>?</i>
                                    <ReactTooltip globalEventOff='click' place={"right"}/>
                                </span>
                                : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslators(CountyInput);