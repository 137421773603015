import React, {Component} from 'react'
import '../styles/Login.css'
import {withRouter} from "react-router-dom";
import {LINK_TYPE_COMPLETE, LINK_TYPE_EXPIRED, LINK_TYPE_FORGOT_PASSWORD} from "../constants/LinkTypes";

import {withTranslators} from 'lioness';

class MessageController extends Component{
    constructor(props){
        super(props);

        this.state = {
            message: ""
        }
    }

    componentWillMount(){
        const linkId = this.props.match.params.linkId;
        const {email} = this.props.match.params;

        if (linkId === LINK_TYPE_COMPLETE)
            if(email){
                this.setState({
                    message: "Application is successfully submitted. Please check your email ({{ email }}) to complete the registration."
                });
            }else {
                this.setState({
                    message: "Application is successfully submitted. Please check your email to complete the registration.",
                });
            }
        else if (linkId === LINK_TYPE_EXPIRED)
            this.setState({
                message: "This link has expired."
            });
        else if (linkId === LINK_TYPE_FORGOT_PASSWORD)
            if(email){
                this.setState({
                    message: "Please check your email ({{ email }}) to reset the password.",
                });
            }else {
                this.setState({
                    message: "Please check your email to reset the password.",
                });
            }

    }

    render(){
        const {t} = this.props;
        const {email} = this.props.match.params;

        return(
            <section id="main-section">
                {
                    t(this.state.message, { email: email })
                }
            </section>
        )
    }
}

export default withRouter(withTranslators(MessageController));