import React from 'react'
import {Link} from "react-router-dom";
import {T} from 'lioness';

import ContentModule from './ContentModule'

export const Settings = () => {

    return(
        <div>
            <h1 className={"t-center"}>
                <T>Settings</T>
            </h1>
            <ContentModule colorClass={"bg-raspberry"}>
                <div className={"mt-10"}>
                    <h3><T>Danger Zone</T></h3>
                    <Link to={"/member/cancel/membership/"}> <T>Cancel my membership</T> </Link>
                </div>
            </ContentModule>
        </div>
    )
};