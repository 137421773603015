import React, {Component} from 'react'
import {T} from "lioness";
import ContentModule from "../ContentModule";

class WithdrawApplication extends Component{

    constructor(props){
        super(props);

        this.handleNo = this.handleNo.bind(this);
        this.handleYes = this.handleYes.bind(this);
    }

    handleNo(){
        this.props.handleNo();
    }

    handleYes(){
        this.props.handleYes()
    }

    render(){
        return(
            <div className={"t-center"}>
                <h2 className={"t-center"}><T>Withdraw Application</T></h2>

                <ContentModule containerClass={"mt-20 content-module-sized"}>
                    <p><T>Are you sure you want to withdraw this application?</T></p>
                    <div className="btnInline mt-10 t-center">
                        <span className="signup-button small" onClick={this.handleNo}><T>No</T></span>
                        <span className="signup-button small ml-5" onClick={this.handleYes}> <T>Yes</T></span>
                    </div>
                </ContentModule>
            </div>
        )
    }
}

export default WithdrawApplication;
