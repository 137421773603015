import axios from 'axios';
import {axiosError} from "./errorHandler";
import {getHeaderTokenWithMultipart, getHeaderWithToken} from "./authToken";
import {
    requestDataForVw,
    requestDataForVwList,
    vwApplicationEdit,
    vwApplicationItem,
    vwLoadApplication,
    vwNewApplication,
    vwValidateNewApplication
} from "../../constants/VwUrlConstants";
import {
    vwDeleteApplicationItem,
    vwEditApplicationItemFromNew, vwNewApplicationItem,
    requestDataForVwApplicationItemList
} from "../../constants/VwUrlConstants";


export const dbValidateVwApplication = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(vwValidateNewApplication + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbCreateVwApplication = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(vwNewApplication, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbLoadVwApplication = (applicationId, callback) => {
    return new Promise(((resolve, reject) => {
        axios.get(vwLoadApplication + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbRequestDataForVw = (pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForVw +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbRequestDataForVwList = (tableType, pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForVwList +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc + "&table_type=" + tableType,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbLoadVwApplicationEdit = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(vwApplicationEdit + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbEditVwApplication = (data, applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.post(vwApplicationEdit + "?application_id=" + applicationId, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbLoadVwApplicationItem = (applicationId, edit) => {
    return new Promise(((resolve, reject) => {
        axios.get(vwApplicationItem + "?application_id=" + applicationId + "&edit=" + edit, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbEditVwApplicationItem = (data, applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.post(vwApplicationItem + "?application_id=" + applicationId, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};


export const dbCreateVwApplicationItem = (data, applicationId) => {
    const query = applicationId ? "?application_id=" + applicationId : "";
    return new Promise(((resolve, reject) => {
        axios.post(vwNewApplicationItem + query, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbRequestDataForVwApplicationItemList = (applicationId, pagesize, page) => {
    const query = applicationId ? "&application_id=" + applicationId : "";

    return new Promise(((resolve, reject) => {
        axios.get(requestDataForVwApplicationItemList +
            "?pagesize=" + pagesize + "&page=" + page + query,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbDeleteVwApplicationItem = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.post(vwDeleteApplicationItem + "?application_id=" + applicationId, {}, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbEditApplicationItemFromNew = (applicationId, data) => {
    const query = applicationId ? "?application_id=" + applicationId : "";
    return new Promise(((resolve, reject) => {
        axios.post(vwEditApplicationItemFromNew + query, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};
