import React, {Component} from 'react'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select';
import {withTranslators} from "lioness";


class MultiSelectInput extends Component{
    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(value){
        this.props.handleInputChange(value);
    }

    getMultiSelectComponent(){
        const optionsData = this.props.optionsData;
        const isDataLoaded = this.props.isDataLoaded;
        const {t} = this.props;

        if (isDataLoaded){
            return <Select
                multi
                required
                closeOnSelect={false}
                onChange={this.handleInputChange}
                options={optionsData}
                value={this.props.value}
                placeholder={t("Select...")}
            />
        }
        else {
            return <Select
                isLoading
                disabled
            />;
        }
    }

    render(){
        const {inputId, inputTitle, dataToolTip} = this.props;
        const component = this.getMultiSelectComponent();

        return(
            <div className="child d-flex">
                <div className="child d-flex">
                    <label htmlFor={inputId}>
                        {inputTitle}
                        <span className={"required-field"}>*</span>
                    </label>
                    <div className={"d-flex"}>
                        {
                            component
                        }
                        {
                            dataToolTip
                                ?
                                <span>
                                    <i className={"tooltip-icon"} data-tip={dataToolTip} data-event='click focus'>?</i>
                                    <ReactTooltip globalEventOff='click' place={"right"}/>
                                </span>
                                : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslators(MultiSelectInput);