import React, {Component} from 'react'
import {Route, Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import SubscriptionDetail from "../components/payment/SubscriptionDetail";
import Pay from "../components/payment/Pay";
import DebtInfo from "../components/DebtInfo";

class WithDebtRouter extends Component{

    render(){
        return(
            <Switch>
                <PrivateRoute exact path='/member/payment/subscription/:id' component={<SubscriptionDetail/>} />
                <PrivateRoute exact path='/member/pay/subscription/:id' component={<Pay serviceType={"subscription"}/>} />

                <Route path="/" component={DebtInfo}/>
            </Switch>
        )
    }
}

export default WithDebtRouter;