import {siteUrl} from './UrlConstants';

export const upcNewApplication = siteUrl + '/api/upc/newapplication';
export const upcApplicationDetail = siteUrl + '/api/upc/application';
export const upcDetail = siteUrl + '/api/upc/upcdetail';
export const requestDataForUpcApplications = siteUrl + '/api/upc/myapplications';
export const requestDataForUpcList = siteUrl + '/api/upc/list';

// gtin 12 urls
export const gtin12NewApplicationUrl = siteUrl + '/api/upc/gtin12/application';
export const requestDataForGtin12List = siteUrl + '/api/upc/gtin12/list';
export const gtin12ApplicationDetail = siteUrl + '/api/upc/gtin12/application/detail';
export const gtin12ApplicationEdit = siteUrl + '/api/upc/gtin12/application/edit';
