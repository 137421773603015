import React, {Component} from 'react'
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";
import {TOKEN_IN_VALIDATION} from "../constants/AuthedStatus";

class PrivateRoute extends Component{

    render () {
        const {path, component} = this.props;
        return (
            <Route path={path} render={() => {
                return (
                    this.props.authed === false && !localStorage.getItem("authToken") ?
                    <Redirect to={"/login"}/> :

                        this.props.tokenStatus === TOKEN_IN_VALIDATION ?
                            <div className={"content-wrapper"}>
                                Loading...
                            </div> :
                            (() => component)()
                )
            }} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { authorize } = state;
    return {
        authed: authorize.authed,
        tokenStatus: authorize.tokenStatus,
    }
};

export default connect(mapStateToProps)(PrivateRoute)