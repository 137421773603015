import {axiosError} from "./errorHandler";
import axios from "axios/index";
import {getHeaderTokenWithMultipart, getHeaderWithToken} from "./authToken";
import {
    gcpDeleteUrl,
    gcpWithdrawApplicationUrl,
    loadActiveGcpsUrl,
    membershipDeleteUrl,
} from "../../constants/GcpUrlConstants";
import {memberHasDebtUrl} from "../../constants/MemberUrlConstants";

export const dbWithdrawGcpApplication = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.post(gcpWithdrawApplicationUrl + '?application_id=' + applicationId, null, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetMemberDebt = () => {
    return new Promise(((resolve, reject) => {
        axios.get(memberHasDebtUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetGcpNumber = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(gcpDeleteUrl + '?application_id=' + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGcpCancel = (applicationId, formData) => {
    return new Promise(((resolve, reject) => {
        axios.post(gcpDeleteUrl + "?application_id=" + applicationId,
            formData,
            getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbCheckMembership = () => {
    return new Promise(((resolve, reject) => {
        axios.get(membershipDeleteUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbMembershipCancel = (formData) => {
    return new Promise(((resolve, reject) => {
        axios.post(membershipDeleteUrl,
            formData,
            getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbLoadActiveGcps = () => {
    return new Promise(((resolve, reject) => {
        axios.get(loadActiveGcpsUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};
