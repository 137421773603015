export const APPLICATION_STATUS_NOT_NA = -99;
export const APPLICATION_STATUS_NOT_CREATED = -1;

export const APPLICATION_STATUS_NEW = 1;
export const APPLICATION_STATUS_ONLINE_DOC = 2;
export const APPLICATION_STATUS_OP_CHECK= 3;
export const APPLICATION_STATUS_PHYSICAL_DOC = 4;
export const APPLICATION_STATUS_OP_REVISION = 5;
export const APPLICATION_STATUS_MAN_CHECK = 6;
export const APPLICATION_STATUS_APPROVED = 7;
export const APPLICATION_STATUS_REJECTED = 8;


export const DOC_STATUS_REJECT = 2;

export const APPLICATION_TYPE_MEMBERSHIP = 1;
export const APPLICATION_TYPE_NUMBER = 2;


export const APPLICATION_ACTION_CREATE = 1;
export const APPLICATION_ACTION_VIEW = 2;
export const APPLICATION_ACTION_EDIT = 3;

export const REQUEST_STATUS_NOT_CREATED = 0;
export const REQUEST_STATUS_COMMITMENT_LETTER = 1;
export const REQUEST_STATUS_NEW = 2;
export const REQUEST_STATUS_ACCEPTED = 3;
export const REQUEST_STATUS_REJECTED = 4;
