import React, {Component} from 'react'
import {T, withTranslators} from "lioness";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TextInput from "../../input_types/TextInput";
import ContentModule from "../../ContentModule";
import * as applicationActions from "../../../actions/applicationActions";
import FileLink from "../../input_types/FileLink";
import FileInput from "../../input_types/FileInput";
import {DOC_STATUS_REJECT} from "../../../constants/Statuses";
import {isFileSizeExceeded, showFileError} from "../../FileUtils";

class TransferEdit extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            application: {},
            initialApplication: {},
            submitting: false,
        };

        this.handleOnApplicationUpdate = this.handleOnApplicationUpdate.bind(this);
    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }
        else if (this.props && this.props.applicationId)
            applicationId = this.props.applicationId;

        this.props.loadApplicationDetail(applicationId, this.applicationItemCallback);

        this.setState({
            applicationId
        })
    }

    applicationItemCallback = (result) => {

        if (result.status === 200){
            this.setState({
                initialApplication: {
                    status: result.data.application_status_name,
                    transferType: result.data.transfer_type_name,

                    commitmentLetter: result.data.commitment_letter,
                    commitmentLetterStatus: result.data.commitment_letter_status,
                    commitmentLetterReject: result.data.commitment_letter_reject,

                    incomeStatementFile: result.data.transfer_income_statement,
                    incomeStatementFileStatus: result.data.transfer_income_statement_status,
                    incomeStatementFileReject: result.data.transfer_income_statement_reject,

                    petitionFile: result.data.petition,
                    petitionFileStatus: result.data.petition_status,
                    petitionFileReject: result.data.petition_reject,

                    transferFile: result.data.transfer_paper,
                    transferFileStatus: result.data.transfer_paper_status,
                    transferFileReject: result.data.transfer_paper_reject,

                    inheritanceFile: result.data.inheritance_document,
                    inheritanceFileStatus: result.data.inheritance_document_status,
                    inheritanceFileReject: result.data.inheritance_document_reject,

                    consentFile: result.data.consent_document,
                    consentFileStatus: result.data.consent_document_status,
                    consentFileReject: result.data.consent_document_reject,

                    createdAt: result.data.created_at,
                    latestApplicationNote: result.data.latest_application_note,
                },
                currentStep: 2
            })
        }
    };

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            application: {
                ...this.state.application,
                [event.target.id]: submitFile
            }
        });

    };

    applicationUpdateCallBack = (result) => {
        if (result.status === 200)
            this.setState({
                currentStep: 3,
                submitting: false
            });
        else
            this.setState({
                submitting: false
            })
    };

    handleOnApplicationUpdate(event){
        event.preventDefault();

        const {application} = this.state;
        const formData = new FormData();

        if (application.commitmentLetterFile)
            formData.append('commitment_letter', application.commitmentLetterFile);
        if (application.incomeStatementFile)
            formData.append('income_statement', application.incomeStatementFile);
        if (application.petitionFile)
            formData.append('petition_file', application.petitionFile);
        if (application.transferFile)
            formData.append('transfer_file', application.transferFile);
        if (application.inheritanceFile)
            formData.append('inheritance_file', application.inheritanceFile);
        if (application.consentFile)
            formData.append('consent_file', application.consentFile);

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        this.props.updateApplication(
            formData,
            this.state.applicationId,
            this.applicationUpdateCallBack);
    }

    render(){
        const {t} = this.props;
        const {initialApplication} = this.state;

        const commitmentLetter = initialApplication && initialApplication.commitmentLetter;
        const commitmentLetterStatus = initialApplication && initialApplication.commitmentLetterStatus;
        const commitmentLetterReject = initialApplication && initialApplication.commitmentLetterReject;

        const incomeStatementFile = initialApplication && initialApplication.incomeStatementFile;
        const incomeStatementFileStatus = initialApplication && initialApplication.incomeStatementFileStatus;
        const incomeStatementFileReject = initialApplication && initialApplication.incomeStatementFileReject;

        const petitionFile = initialApplication && initialApplication.petitionFile;
        const petitionFileStatus = initialApplication && initialApplication.petitionFileStatus;
        const petitionFileReject = initialApplication && initialApplication.petitionFileReject;

        const transferFile = initialApplication && initialApplication.transferFile;
        const transferFileStatus = initialApplication && initialApplication.transferFileStatus;
        const transferFileReject = initialApplication && initialApplication.transferFileReject;

        const inheritanceFile = initialApplication && initialApplication.inheritanceFile;
        const inheritanceFileStatus = initialApplication && initialApplication.inheritanceFileStatus;
        const inheritanceFileReject = initialApplication && initialApplication.inheritanceFileReject;

        const consentFile = initialApplication && initialApplication.consentFile;
        const consentFileStatus = initialApplication && initialApplication.consentFileStatus;
        const consentFileReject = initialApplication && initialApplication.consentFileReject;

        const isSubmitting = this.state.submitting ? "btn-disabled": "";

        return(
            <div>
                <h2 className={"t-center"}><T>Update Transfer Application</T></h2>

                <ContentModule>
                    <T>Checks regarding your application has been done, to complete your application process please upload missing/wrong documents to the system.</T>
                </ContentModule>

                {
                    this.state.currentStep === 1 ?
                        <div><T>Loading...</T></div> :

                        this.state.currentStep === 3 ?
                            <div>
                                <T>Application is successfully updated</T>
                            </div>:

                            <ContentModule>
                                <form onSubmit={this.handleOnApplicationUpdate}>
                                    <div className="editor small signin">
                                        <div>
                                            <TextInput key={"status"} inputId={"status"} inputTitle={t("Status")} inputValue={t(initialApplication["status"])} disabled required={false}/>
                                            <TextInput key={"transferType"} inputId={"transferType"} inputTitle={t("Transfer Type")} inputValue={t(initialApplication["transferType"])} disabled required={false}/>
                                            {
                                                commitmentLetter ?
                                                    commitmentLetterStatus === DOC_STATUS_REJECT ?
                                                        <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={commitmentLetterReject}/>
                                                        :
                                                        <FileLink inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} inputValue={commitmentLetter}/> : null
                                            }
                                            {
                                                incomeStatementFile ?
                                                    incomeStatementFileStatus === DOC_STATUS_REJECT ?
                                                        <FileInput inputId={"incomeStatementFileFile"} inputTitle={t("Income Statement")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={incomeStatementFileReject}/>
                                                        :
                                                        <FileLink inputId={"incomeStatementFileFile"} inputTitle={t("Income Statement")} inputValue={incomeStatementFile}/> : null
                                            }
                                            {
                                                petitionFile ?
                                                    petitionFileStatus === DOC_STATUS_REJECT ?
                                                        <FileInput inputId={"petitionFile"} inputTitle={t("Petition Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={petitionFileReject}/>
                                                        :
                                                        <FileLink inputId={"petitionFile"} inputTitle={t("Petition Letter")} inputValue={petitionFile}/> : null
                                            }
                                            {
                                                transferFile ?
                                                    transferFileStatus === DOC_STATUS_REJECT ?
                                                        <FileInput inputId={"transferFile"} inputTitle={t("Transfer Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={transferFileReject}/>
                                                        :
                                                        <FileLink inputId={"transferFile"} inputTitle={t("Transfer Letter")} inputValue={transferFile}/> : null
                                            }
                                            {
                                                inheritanceFile ?
                                                    inheritanceFileStatus === DOC_STATUS_REJECT ?
                                                        <FileInput inputId={"inheritanceFile"} inputTitle={t("Inheritance Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={inheritanceFileReject}/>
                                                        :
                                                        <FileLink inputId={"inheritanceFile"} inputTitle={t("Inheritance Letter")} inputValue={inheritanceFile}/> : null
                                            }
                                            {
                                                consentFile ?
                                                    consentFileStatus === DOC_STATUS_REJECT ?
                                                        <FileInput inputId={"consentFile"} inputTitle={t("Consent File")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={consentFileReject}/>
                                                        :
                                                        <FileLink inputId={"consentFile"} inputTitle={t("Consent File")} inputValue={consentFile}/> : null
                                            }
                                            <TextInput key={"createdAt"} inputId={"createdAt"} inputTitle={t("Date")} inputValue={initialApplication["createdAt"]} disabled required={false}/>
                                            <TextInput key={"latestApplicationNote"} inputId={"latestApplicationNote"} inputTitle={t("Latest Application Notes")} inputValue={initialApplication["latestApplicationNote"]} disabled required={false}/>
                                        </div>
                                        <div className="btnInline t-center mt-10">
                                            <input className={"signup-button " + isSubmitting} type="submit" value={t("Submit")}/>
                                        </div>
                                    </div>
                                </form>
                            </ContentModule>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationDetail: (applicationId, callback) => {
            dispatch(applicationActions.dbGetTransferApplicationDetail(applicationId, callback));
        },
        updateApplication: (data, application_id, callback) => {
            dispatch(applicationActions.dbUpdateTransferApplication(data, application_id, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(TransferEdit)));