import React, {Component, Fragment} from 'react'
import '../../styles/Dashboard.css'
import TextInput from "../input_types/TextInput";
import * as applicationActions from "../../actions/applicationActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import FileLink from "../input_types/FileLink";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import Gtin13List from "../gtin13/Gtin13List";

class GcpDetail extends Component{

    constructor(props){
        super(props);
        this.state = {
            currentStep: 1,
            application: null
        };
    }

    componentWillMount(){
        const {applicationId} = this.props.match.params;
        this.props.getGcpDetail(applicationId, this.gcpDetailCallBack);
    }

    gcpDetailCallBack = (result) => {
        if(result.status === 200){
            this.setState({
                currentStep: 2,
                application: result.data,
            })
        }
    };

    getConstants(){
        const {t} = this.props;
        const productText = t("Type of Products");
        const digitText = t("Digits");

        const selectDigitOptions = {
            7: '7 ' + digitText + ' (100.000 ' + productText + ')',
            8: '8 ' + digitText + ' (10.000 ' + productText + ')',
            9: '9 ' + digitText + ' (1.000 ' + productText + ')',
            10: '10 ' + digitText + ' (100 ' + productText + ')',
            11: '11 ' + digitText + ' (10 ' + productText + ')',
            12: '12 ' + digitText + ' (1 ' + productText + ')',
        };


        return {
            gcp: this.state.application.gcp,
            requestedDigits: selectDigitOptions[this.state.application.digits] ,
            registryDate: this.state.application.registry_dt,
            registryCertificate: this.state.application.registry_certificate,
            status: this.state.application.status,
        }
    }

    render(){
        const {gcp, requestedDigits, registryDate, registryCertificate, status} =  this.state.currentStep === 2 && this.getConstants();
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>View GCP</T></h2>

                <ContentModule>
                    <T
                        message={"This page shows basic information about your GCP and the GTIN-13 list created using this GCP ({{ gcp }})."}
                        gcp={gcp}
                    /><br/><br/>
                    <T>The list of all your GTIN-13's can be accessed from 'GTIN-13 List' on the left menu.</T>
                </ContentModule>

                <div className={"application-view"}>
                    {
                        this.state.currentStep === 1 ?
                            <div className={"t-center mt-10"}>
                                <T>Loading...</T>
                            </div>:

                            this.state.currentStep === 2 ?
                                <Fragment>
                                    <div className={"mb-20"}>
                                        <ContentModule>
                                            <form className="editor small signin">
                                                <div>
                                                    <TextInput key={"gcp"} inputId={"gcp"} required={false} inputTitle={t("GCP")} inputValue={gcp} disabled/>
                                                    <TextInput key={"requestedDigits"} inputId={"requestedDigits"} required={false} inputTitle={t("Digit Count")} inputValue={requestedDigits} disabled/>
                                                    <TextInput key={"status"} inputId={"status"} required={false} inputTitle={t("Status")} inputValue={t(status)} disabled/>
                                                    <TextInput key={"registryDate"} inputId={"registryDate"} required={false} inputTitle={t("Registry Date")} inputValue={registryDate} disabled/>
                                                    {
                                                        registryCertificate &&
                                                        <FileLink inputId={"registryCertificate"} inputTitle={t("Registry Certificate")} required={false} inputValue={registryCertificate}/>
                                                    }
                                                </div>
                                            </form>
                                        </ContentModule>
                                    </div>

                                    <Gtin13List
                                        hideHeader
                                        defaultPageSize={5}
                                    />
                                </Fragment>
                                : null
                    }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getGcpDetail: (gcpId, callback) => {
            dispatch(applicationActions.dbGetGcpDetail(gcpId, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(GcpDetail)));
