import * as AuthorizeActionType from "../constants/AuthorizeActionType";
import {TOKEN_NOT_VALIDATED} from "../constants/AuthedStatus";


const initialState = {
    authed: false,
    tokenStatus: TOKEN_NOT_VALIDATED,
    guest: true,
    memberId: -1,
    memberStatus: -1,
    memberType: -1,
    memberName: null,
    memberTitle: null,
    memberMasterGLN: null,
    memberStatusLoaded: false,
    impersonatedMember: null,
    debt: null,
    expiredSubscriptionFeeList: null,
    nextUrl: null,
    isProfileComplete: false,
    udy_token: null
};

export let authReducer = (state = initialState, action) => {
    const { payload } = action;

    switch(action.type) {
        case AuthorizeActionType.LOGIN:
            return{
                ...state,
                authed: true,
                guest: false,
            };

        case AuthorizeActionType.LOGOUT:
            return{
                ...state,
                authed: false,
                guest: true,

                tokenStatus: TOKEN_NOT_VALIDATED,

                memberId: -1,
                memberStatus: -1,
                memberType: -1,
                memberName: null,
                memberTitle: null,
                memberMasterGLN: null,
                memberStatusLoaded: false,
                impersonatedMember: null,
                debt: null,
                expiredSubscriptionFeeList: null,
                isProfileComplete: false,
                udyToken: null,
                udyBaseUrl: null
            };

        case AuthorizeActionType.MEMBER_STATUS:
            return{
                ...state,
                memberId: payload.memberId,
                memberStatus: payload.memberStatus,
                memberType: payload.memberType,
                memberName: payload.memberName,
                memberTitle: payload.memberTitle,
                memberMasterGLN: payload.memberMasterGLN,
                memberStatusLoaded: true,
                impersonatedMember: payload.impersonatedMember,
                debt: payload.debt,
                expiredSubscriptionFeeList: payload.expiredSubscriptionFeeList,
                isProfileComplete: payload.isProfileComplete,
                udyToken: payload.udyToken,
                udyBaseUrl: payload.udyBaseUrl
            };

        case AuthorizeActionType.MEMBER_DATA:
            return{
                ...state,
                member: payload.member,
                memberDataLoaded: true
            };
        case AuthorizeActionType.TOKEN_STATUS:
            return{
                ...state,
                tokenStatus: payload.tokenStatus
            };
        case AuthorizeActionType.ADD_NEXT_URL:
            return{
                ...state,
                nextUrl: payload.nextUrl
            };
        case AuthorizeActionType.REMOVE_NEXT_URL:
            return{
                ...state,
                nextUrl: null
            };
        default:
            return state
        }
};