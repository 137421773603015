import React, {Component} from 'react'
import {connect} from "react-redux";
import {Redirect, Route} from "react-router-dom";
import GuestWrapper from "../components/GuestWrapper";

class AuthedRoute extends Component{

    render () {
        const {path, authed, component} = this.props;
        return (
            <Route path={path} render={() => {
                return (
                    authed
                        ? <Redirect to='/' />
                        : <GuestWrapper component={component}/>
                )
            }} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { authorize } = state;
    return {
        authed: authorize.authed
    }
};

export default connect(mapStateToProps)(AuthedRoute)