import axios from 'axios';
import {axiosError} from "./errorHandler";
import {getHeaderWithToken} from "./authToken";
import {
    gtin12ApplicationDetail,
    gtin12ApplicationEdit,
    gtin12NewApplicationUrl,
    requestDataForGtin12List
} from "../../constants/UpcUrlConstants";


export const dbCreateGtin12Application = (data, callback) => {
    return new Promise(((resolve, reject) => {
        axios.post(gtin12NewApplicationUrl, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbRequestDataForGtin12List = (applicationId, pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForGtin12List + "?application_id=" + applicationId +
            "&pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbLoadGtin12ApplicationDetail = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(gtin12ApplicationDetail + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbLoadGtin12Application = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(gtin12ApplicationEdit + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbEditGtin12Application = (data, applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.post(gtin12ApplicationEdit + "?application_id=" + applicationId, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

