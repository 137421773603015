import React, {Component} from 'react'
import '../../../styles/Dashboard.css'
import Select from 'react-select';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {
    commitmentLetterDownloadUrl
} from "../../../constants/GcpUrlConstants";
import * as applicationActions from "../../../actions/applicationActions";
import TextInput from "../../input_types/TextInput";
import FileInput from "../../input_types/FileInput";
import {T, withTranslators} from "lioness";
import ContentModule from "../../ContentModule";
import Iframe from "../../Iframe";
import {isFileSizeExceeded, showFileError} from "../../FileUtils";

class NewApplication extends Component{

    constructor(props){
        super(props);
        this.state = {
            currentStep: 0,
            isCommitmentLetterValid: null,
            hasActiveApplications: false,

            applicationStatus: null,
            applicationId: null,

            requestedDigits: null,
            annualTaxAssessment: '',
            annualTaxAssessmentPrefix: '0',
            annualTaxAssessmentPrefixError: "",
            annualTaxAssessmentSuffix: '00',

            latestApplicationNote: '',

            commitmentLetterFile: null,
            incomeStatementFile: null,
            extraFiles: null,
            submitting: false,

            requestedAmount: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        this.props.checkCommitmentLetterValidity(this.commitmentLetterValidCallback)
    };

    commitmentLetterValidCallback = (result) => {
        if (result.status === 200){
            this.setState({
                currentStep: 2,
                isCommitmentLetterValid: result.data.commitment_letter_valid,
                hasActiveApplications: result.data.active_applications
            });
        }
    };

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleDigitChange = (requestedDigits) => {
        this.setState({ requestedDigits });
    };

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            [event.target.id]: submitFile,
        });

    };

    newApplicationCallBack = (result) => {
        if (result.status === 200)
            this.setState({
                currentStep: 3,
                submitting: false
            });
        else
            this.setState({
                submitting: false
            })
    };

    handleSubmit(event) {
        event.preventDefault();

        const parsedAnnualTaxAssessmentPrefix = this.state.annualTaxAssessmentPrefix.replace(/\D+/g, '');

        let annualTaxAssessment = parsedAnnualTaxAssessmentPrefix + "." + this.state.annualTaxAssessmentSuffix;
        let extraFiles= this.state.extraFiles;

        const formData = new FormData();
        formData.append('requested_digit', this.state.requestedDigits.value);

        if (!this.state.isCommitmentLetterValid)
            formData.append('commitment_letter', this.state.commitmentLetterFile);
        formData.append('income_statement', this.state.incomeStatementFile);
        formData.append('latest_application_note', this.state.latestApplicationNote);
        formData.append('annual_tax_assessment', annualTaxAssessment);
        formData.append('requested_amount', this.state.requestedAmount);

        if(extraFiles)
            for (let i = 0; i < extraFiles.length ; i++) {
                formData.append('extra_files', extraFiles[i]);
            }

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.submitting){
            this.setState({
                submitting: true,
                annualTaxAssessmentPrefixError: ""
            })
        }

        this.props.submitApplication(
            formData,
            this.newApplicationCallBack
        );
    }

    render(){
        const {t} = this.props;
        const productText = t("Type of Products");
        const digitText = t("Digits");

        const selectDigitOptions = [
            { value: 7, label: '7 ' + digitText + ' (100.000 ' + productText + ')' },
            { value: 8, label: '8 ' + digitText + ' (10.000 ' + productText + ')' },
            { value: 9, label: '9 ' + digitText + ' (1.000 ' + productText + ')' },
            { value: 10, label: '10 ' + digitText + ' (100 ' + productText + ')' },
            { value: 11, label: '11 ' + digitText + ' (10 ' + productText + ')' },
            { value: 12, label: '12 ' + digitText + ' (1 ' + productText + ')' },
        ];
        const isSubmitting = this.state.submitting ? "btn-disabled": "";

        return(
            <div>
                <h2 className={"t-center"}><T>Additional GCP Application</T></h2>

                <ContentModule>
                    <T>You can apply for additional GCP (Global Company Prefix) in case you used all of your allocated GCP limits.</T><br/>
                    <T>In case your firm has founded this year and if you do not have you income statement, you may supply your Trade Registry Gazette which contains your foundation advert.</T>
                </ContentModule>

                <Iframe
                    source={"GCP_extra_number/h2.html"}
                />

                {
                    this.state.currentStep === 1 ?
                        <div>
                            <T>Loading...</T>
                        </div>:

                        this.state.currentStep === 2 ?
                            <div className={"new-application"}>
                                {
                                    !this.state.hasActiveApplications ?
                                         <ContentModule>
                                            <form onSubmit={this.handleSubmit} className="editor small signin">
                                                <div>
                                                    <div className="child d-flex">
                                                        <div className="child d-flex">
                                                            <label htmlFor={"requestedDigits"}>
                                                                <T>Requested Digit</T> <span className={"required-field"}>*</span>
                                                            </label>
                                                            <div className={"d-flex"}>
                                                                <Select
                                                                    required
                                                                    value={this.state.requestedDigits}
                                                                    onChange={this.handleDigitChange}
                                                                    options={selectDigitOptions}
                                                                    placeholder={t("Select...")}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <TextInput key={"requestedAmount"} inputId={"requestedAmount"} inputTitle={t("Requested Number Amount")} inputValue={this.state.requestedAmount} handleInputChange={this.handleInputChange} inputType={'number'}/>

                                                    {
                                                        !this.state.isCommitmentLetterValid &&
                                                        <div>
                                                            <T
                                                                message={"Please first download the {{ link }}"}
                                                                link={<a href={commitmentLetterDownloadUrl} target={"_blank"}><T>Commitment Letter</T></a>}
                                                            />
                                                            <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile}/>
                                                        </div>
                                                    }

                                                    <FileInput inputId={"incomeStatementFile"} inputTitle={t("Income Statement")} handleUploadFile={this.handleUploadFile} />
                                                    <TextInput key={"annualTaxAssessment"} inputId={"annualTaxAssessmentPrefix"} inputId2={"annualTaxAssessmentSuffix"} inputTitle={t("Annual Tax Assessment")} inputValue={this.state.annualTaxAssessmentPrefix} inputValue2={this.state.annualTaxAssessmentSuffix} handleInputChange={this.handleInputChange} isATS />

                                                    <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false} />
                                                    <TextInput key={"latestApplicationNote"} inputId={"latestApplicationNote"} required={false} inputTitle={t("Additional Notes")} inputValue={this.state.latestApplicationNote} handleInputChange={this.handleInputChange} isTextArea maxLength={255}/>
                                                </div>
                                                <div className="btnInline t-center">
                                                    <input className={"signup-button " + isSubmitting} type="submit" value={t("Submit")}/>
                                                </div>
                                            </form>
                                         </ContentModule>:
                                        <ContentModule>
                                            <T>You have an active GCP application</T>.
                                            <T>You cannot apply for a new one!</T><br/>
                                            <T>Please try again after you finalize your current application.</T>
                                        </ContentModule>
                                }
                            </div>:

                            this.state.currentStep === 3 ?
                                <div>
                                    <Redirect to={"/member/to/gcp"}/>
                                </div>: null
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        checkCommitmentLetterValidity: (callback) => {
            dispatch(applicationActions.dbCheckCommitmentLetterValidity(callback));
        },
        submitApplication: (data, callback) => {
            dispatch(applicationActions.dbSubmitNewNumberApplication(data, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(NewApplication));
