import React, {Component} from 'react'
import TextInput from "../input_types/TextInput";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as vwActions from "../../actions/vwActions";
import FileLink from "../input_types/FileLink";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";

class ApplicationItemDetail extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            applicationItem: {}
        };
    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }

        this.props.loadApplicationItem(applicationId, this.applicationItemCallback);
    }

    applicationItemCallback = (result) => {
        if (result.status === 200){
            this.setState({
                applicationItem: result.data,
                currentStep: 2
            })
        }
    };

    constructViewNumber(){
        const {t} = this.props;

        return <div>
                <TextInput key={"registryDate"} inputId={"registryDate"} inputTitle={t("Registry Date")} inputValue={this.state.applicationItem["registry_dt"]} disabled required={false}/>
                <TextInput key={"vw"} inputId={"vw"} inputTitle={t("Varying Weight Number")} inputValue={this.state.applicationItem["vw"]} disabled required={false}/>
                <TextInput key={"brand"} inputId={"brand"} inputTitle={t("Brand")} inputValue={this.state.applicationItem["brand"]} disabled required={false}/>
                <TextInput key={"labelDescription"} inputId={"labelDescription"} inputTitle={t("Label Description")} inputValue={this.state.applicationItem["labelDescription"]} disabled required={false}/>
                <FileLink inputId={"registryCertificate"} inputTitle={t("Registry Certificate")} required={false} inputValue={this.state.applicationItem["registry_certificate"]}/>
            </div>
    }

    render(){
        return(
            <div>
                <h2 className={"t-center"}><T>View Varying Weight Number</T></h2>

                <ContentModule>
                    <T>You can see product information identified by Varying Weight Number</T>
                </ContentModule>
                {
                    this.state.currentStep === 1 ?
                        <div><T>Loading...</T></div> :

                        <ContentModule>
                            <form className="editor small signin">
                                {
                                    this.constructViewNumber()
                                }
                            </form>
                        </ContentModule>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationItem: (applicationId, callback) => {
            dispatch(vwActions.dbLoadVwApplicationItem(applicationId, callback))
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ApplicationItemDetail)));
