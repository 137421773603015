export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const MEMBER_STATUS = 'MEMBER_STATUS';
export const MEMBER_DATA = 'MEMBER_DATA';
export const EMAIL_CHECK = 'EMAIL_CHECK';
export const TOKEN_STATUS = 'TOKEN_STATUS';

export const REQUEST_STATUS_CHECK = 'REQUEST_STATUS_CHECK';

export const ADD_NEXT_URL = 'ADD_NEXT_URL';
export const REMOVE_NEXT_URL = 'REMOVE_NEXT_URL';
