import React, {Component} from 'react'
import {Link, Route, Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ViewProfile from "../components/ViewProfile";
import {T} from "lioness";

class WithEditProfileRouter extends Component{

    render(){
        return(
            <Switch>
                <PrivateRoute exact path='/member/profile/' component={<ViewProfile fromProfileRouter/>} />

                <Route path="/" render={() => {
                    return (
                        <div className={"t-center mt-20"}>
                            <T>In order to use GS1 Turkey Membership, you need to provide your membership information.</T>
                            <span className={"ml-5"}>
                                <Link to={"/member/profile/"}> <T>Update it now.</T> </Link>
                            </span>
                        </div>
                    )
                }}/>
            </Switch>
        )
    }
}

export default WithEditProfileRouter;