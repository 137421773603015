import {axiosError} from "../api/services/errorHandler";
import * as vwService from "../api/services/vwService";

export const dbValidateVwApplication = (applicationId, callback) => {
    return (dispatch, getState) => {
        return vwService.dbValidateVwApplication(applicationId, callback).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbCreateVwApplication = (data, callback) => {
    return (dispatch, getState) => {
        return vwService.dbCreateVwApplication(data).then((result) => {
                callback(result.request);
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbLoadVwApplication = (applicationId, callback) => {
    return (dispatch, getState) => {
        return vwService.dbLoadVwApplication(applicationId, callback).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};


export const dbRequestDataForVw = (pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return vwService.dbRequestDataForVw(pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                // callback(messageError);
                axiosError(error, callback);
            });
    }
};


export const dbRequestDataForVwList = (tableType, pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return vwService.dbRequestDataForVwList(tableType, pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                // callback(messageError);
                axiosError(error, callback);
            });
    }
};


export const dbLoadVwApplicationEdit = (applicationId, callback) => {
    return (dispatch, getState) => {
        return vwService.dbLoadVwApplicationEdit(applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbEditVwApplication = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return vwService.dbEditVwApplication(data, applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbLoadVwApplicationItem = (applicationId, callback, edit="") => {
    return (dispatch, getState) => {
        return vwService.dbLoadVwApplicationItem(applicationId, edit).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbEditVwApplicationItem = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return vwService.dbEditVwApplicationItem(data, applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};


export const dbCreateVwApplicationItem = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return vwService.dbCreateVwApplicationItem(data, applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};


export const dbRequestDataForVwApplicationItemList = (applicationId, pagesize, page, callback) => {
    return (dispatch, getState) => {
        return vwService.dbRequestDataForVwApplicationItemList(applicationId, pagesize, page).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
            });
    }
};

export const dbDeleteVwApplicationItem = (applicationId, callback) => {
    return (dispatch, getState) => {
        return vwService.dbDeleteVwApplicationItem(applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};


export const dbEditApplicationItemFromNew = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return vwService.dbEditApplicationItemFromNew(applicationId, data).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};

