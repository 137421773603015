import React, {Component} from 'react'
import {T, withTranslators} from "lioness";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TextInput from "../../input_types/TextInput";
import ContentModule from "../../ContentModule";
import * as applicationActions from "../../../actions/applicationActions";
import FileLink from "../../input_types/FileLink";
import PayInfo from "../../payment/PayInfo";

class TransferView extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            application: {
                status: "",
                transferType: "",
                incomeStatement: "",
                commitmentLetter: "",
                petitionFile: "",
                transferFile: "",
                inheritanceFile: "",
                consentFile: "",
                createdAt: "",
            }
        };
    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }
        else if (this.props && this.props.applicationId)
            applicationId = this.props.applicationId;

        this.props.loadApplicationDetail(applicationId, this.applicationItemCallback);
    }

    applicationItemCallback = (result) => {

        if (result.status === 200){
            this.setState({
                application: {
                    status: result.data.application_status_name,
                    transferType: result.data.transfer_type_name,

                    incomeStatement: result.data.transfer_income_statement,

                    commitmentLetter: result.data.commitment_letter,
                    petitionFile: result.data.petition,
                    transferFile: result.data.transfer_paper,

                    inheritanceFile: result.data.inheritance_document,
                    consentFile: result.data.consent_document,

                    extraFiles: result.data.extra_files,

                    latestApplicationNote: result.data.latest_application_note,
                    createdAt: result.data.created_at,
                    service_fee: result.data.service_fee,
                    invoice_number: result.data.invoice_number,
                    paid_amount: result.data.paid_amount,
                    remaining_amount: result.data.remaining_amount,
                    service_id: result.data.service_id,
                    payment_status: result.data.payment_status,
                },
                currentStep: 2
            })
        }
    };

    render(){
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>View Transfer Application</T></h2>

                <ContentModule>
                    <T>You can follow the status of your application from this page.</T>
                </ContentModule>

                {
                    this.state.currentStep === 1 ?
                        <div><T>Loading...</T></div> :

                        <React.Fragment>
                            {
                                this.state.application["service_id"] ?
                                    <PayInfo service_fee={this.state.application["service_fee"]}
                                             is_cancelled={this.state.application["service_fee_cancelled"]}
                                             remaining_amount={this.state.application["remaining_amount"]}
                                             payment_status={this.state.application["payment_status"]}
                                             pre_sales_agreement={this.state.application["pre_sales_agreement"]}
                                             distant_sales_agreement={this.state.application["distant_sales_agreement"]}
                                             path={"/member/pay/servicefee/" + this.state.application["service_id"]}/>: null
                            }

                            <ContentModule>
                                <form>
                                    <div className="editor small signin">
                                        <div>
                                            <TextInput key={"status"} inputId={"status"} inputTitle={t("Status")} inputValue={t(this.state.application["status"])} disabled required={false}/>
                                            <TextInput key={"transferType"} inputId={"transferType"} inputTitle={t("Transfer Type")} inputValue={t(this.state.application["transferType"])} disabled required={false}/>
                                            {
                                                this.state.application["commitmentLetter"] &&
                                                <FileLink inputId={"commitmentLetter"} inputTitle={t("Commitment Letter")} required={false} inputValue={this.state.application["commitmentLetter"]}/>
                                            }

                                            <FileLink inputId={"incomeStatement"} inputTitle={t("Income Statement")} required={false} inputValue={this.state.application["incomeStatement"]}/>
                                            <FileLink inputId={"petitionFile"} inputTitle={t("Petition Letter")} required={false} inputValue={this.state.application["petitionFile"]}/>
                                            <FileLink inputId={"transferFile"} inputTitle={t("Transfer Letter")} required={false} inputValue={this.state.application["transferFile"]}/>

                                            {
                                                this.state.application["inheritanceFile"] &&
                                                <FileLink inputId={"inheritanceFile"} inputTitle={t("Inheritance Letter")} required={false} inputValue={this.state.application["inheritanceFile"]}/>
                                            }
                                            {
                                                this.state.application["consentFile"] &&
                                                <FileLink inputId={"consentFile"} inputTitle={t("Consent File")} required={false} inputValue={this.state.application["consentFile"]}/>
                                            }
                                            {
                                                this.state.application["extraFiles"].length ?
                                                <FileLink inputId={"extraFiles"} inputTitle={t("Extra Files")}  multiple={true} inputValue={this.state.application["extraFiles"]} required={false}/> :null
                                            }
                                            <TextInput key={"createdAt"} inputId={"createdAt"} inputTitle={t("Date")} inputValue={this.state.application["createdAt"]} disabled required={false}/>
                                            <TextInput key={"latestApplicationNote"} inputId={"latestApplicationNote"} inputTitle={t("Latest Application Notes")} inputValue={this.state.application["latestApplicationNote"]} disabled required={false}/>
                                        </div>
                                    </div>
                                </form>
                            </ContentModule>
                        </React.Fragment>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationDetail: (applicationId, callback) => {
            dispatch(applicationActions.dbGetTransferApplicationDetail(applicationId, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(TransferView)));