import React, {Component} from 'react'
import {RadioButton, RadioGroup} from "react-radio-buttons";
import FileInput from "../../input_types/FileInput";
import {connect} from "react-redux";
import * as applicationActions from "../../../actions/applicationActions";
import {commitmentLetterDownloadUrl} from "../../../constants/GcpUrlConstants";
import {T, withTranslators} from "lioness";
import ContentModule from "../../ContentModule";
import Iframe from "../../Iframe";
import TextInput from "../../input_types/TextInput";
import {isFileSizeExceeded, showFileError} from "../../FileUtils";

class TransferNew extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            transferType: "normal",

            isCommitmentLetterValid: null,
            commitmentLetterFile: null,

            annualTaxAssessment: '',
            annualTaxAssessmentPrefix: '0',
            annualTaxAssessmentSuffix: '00',

            incomeStatementFile: null,
            petitionFile: null,
            transferFile: null,
            inheritanceFile: null,
            consentFile: null,
            submitting: false,
            extraFiles: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onChangeTransferType = this.onChangeTransferType.bind(this);
    }

    componentWillMount(){
        this.props.checkCommitmentLetterValidity(this.commitmentLetterValidCallback)
    };

    commitmentLetterValidCallback = (result) => {
        if (result.status === 200){
            this.setState({
                currentStep: 2,
                isCommitmentLetterValid: result.data.commitment_letter_valid
            });
        }
    };

    newApplicationCallBack = (result) => {
        if (result.status === 200)
            this.setState({
                currentStep: 3,
                submitting: false
            });
        else
            this.setState({
                submitting: false
            })
    };

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            [event.target.id]: submitFile,
        });

    };

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    onChangeTransferType(value) {
        this.setState({
            transferType: value
        })
    }

    handleSubmit(event){
        event.preventDefault();

        // submit application
        const formData = new FormData();
        formData.append('transfer_type', this.state.transferType);

        formData.append('income_statement', this.state.incomeStatementFile);
        formData.append('petition_file', this.state.petitionFile);
        formData.append('transfer_file', this.state.transferFile);

        const parsedAnnualTaxAssessmentPrefix = this.state.annualTaxAssessmentPrefix.replace(/\D+/g, '');
        let annualTaxAssessment = parsedAnnualTaxAssessmentPrefix + "." + this.state.annualTaxAssessmentSuffix;
        formData.append('annual_tax_assessment', annualTaxAssessment);

        if (!this.state.isCommitmentLetterValid)
            formData.append('commitment_letter', this.state.commitmentLetterFile);

        if (this.state.transferType === "death"){
            formData.append('inheritance_file', this.state.inheritanceFile);
            if (this.state.consentFile)
                formData.append('consent_file', this.state.consentFile);
        }

        let extraFiles= this.state.extraFiles;
        if(extraFiles) {
            for (let i = 0; i < extraFiles.length; i++) {
                formData.append('extra_files', extraFiles[i]);
            }
        }

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        this.props.submitApplication(
            formData,
            this.newApplicationCallBack
        );
    }

    render(){
        const currentStep = this.state.currentStep;
        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}> <T>Receive GCP/Membership From Member</T></h2>
                <ContentModule>
                    <T>Bu sayfa üzerinden devir başvurusu yapılmaktaıdr. Bu başvuruyu devralmak isteyen firmanın yapması gerekmektedir.</T><br/>
                    <T>Sistem üyeleri üyeliklerini ve/veya kendilerine tahsis edilen Firma Önek Numalarını (GCP) GS1 Türkiye'nin onayıyla devredebilirler.</T>
                    <T>Bu sayfadan üretilecek devir belgesinin her iki tarafca noter onayıyla imzalanması gerekmektedir.</T>
                    <T>Devir alacak firma bu belge ile devir başvurusunu yapacak ve başvuru takibi devir alacak firma tarafından yapılacaktır.</T>
                </ContentModule>

                <div className={"mt-10"}>
                {
                    currentStep === 1 ?
                        <div>
                            <T>Loading...</T>
                        </div> :

                        currentStep === 2 ?
                            <div className="mt-10">
                                <Iframe
                                    source={"GCP_Devri/h8.html"}
                                />

                                <div>
                                    <h4><T>Is the trasfer due to death of the current member?</T></h4>
                                    <RadioGroup onChange={ this.onChangeTransferType } value={""} horizontal>
                                        <RadioButton value="normal">
                                            <T>No</T>
                                        </RadioButton>
                                        <RadioButton value="death">
                                            <T>Yes</T>
                                        </RadioButton>
                                    </RadioGroup>
                                </div>
                                <ContentModule containerClass={"mt-20"}>
                                    <form onSubmit={this.handleSubmit} className={"mt-10 editor small signin"}>
                                        <div>
                                            {
                                                !this.state.isCommitmentLetterValid &&
                                                <div>
                                                    <div className={"mt-10"}>
                                                        <T
                                                            message={"Please first download the {{ link }}"}
                                                            link={<a href={commitmentLetterDownloadUrl} target={"_blank"}><T>Commitment Letter</T></a>}
                                                        />
                                                    </div>
                                                    <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile}/>
                                                </div>
                                            }
                                            <TextInput key={"annualTaxAssessment"} inputId={"annualTaxAssessmentPrefix"} inputId2={"annualTaxAssessmentSuffix"} inputTitle={t("Annual Tax Assessment")} inputValue={this.state.annualTaxAssessmentPrefix} inputValue2={this.state.annualTaxAssessmentSuffix} handleInputChange={this.handleInputChange} isATS />
                                            <FileInput inputId={"incomeStatementFile"} inputTitle={t("Income Statement")} handleUploadFile={this.handleUploadFile} />
                                             {
                                                this.state.transferType != "death" ?
                                                    <div>
                                                        <FileInput inputId={"transferFile"} inputTitle={t("Transfer Letter")} handleUploadFile={this.handleUploadFile} />

                                                    </div>: null
                                            }
                                             <FileInput inputId={"petitionFile"} inputTitle={t("Petition Letter")} handleUploadFile={this.handleUploadFile}/>
                                            {
                                                this.state.transferType === "death" ?
                                                    <div>
                                                        <FileInput inputId={"inheritanceFile"} inputTitle={t("Inheritance Letter")} handleUploadFile={this.handleUploadFile}/>
                                                        <FileInput inputId={"consentFile"} inputTitle={'Mirascilar Arası Devir Belgesi'} handleUploadFile={this.handleUploadFile} required={true}/>
                                                    </div>: null
                                            }
                                            <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false} />
                                        </div>
                                        <div className="btnInline mt-10 t-center">
                                            <input className={"signup-button " + isSubmitting} type="submit" value={t("Submit")}/>
                                        </div>
                                    </form>
                                </ContentModule>
                            </div>:

                            currentStep === 3 ?
                                <div>
                                    <T>Application is successfully submitted</T>
                                </div> : null
                }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        checkCommitmentLetterValidity: (callback) => {
            dispatch(applicationActions.dbCheckCommitmentLetterValidity(callback));
        },
        submitApplication: (data, callback) => {
            dispatch(applicationActions.dbSubmitTransferApplication(data, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(TransferNew));
