import React, {Component} from 'react'
import '../styles/MessageBox.css'

class MessageBox extends Component{
    constructor(props){
        super(props);

        this.state = {
            isDismissible: this.props.isDismissible,
            message: this.props.message,
            messageBoxType: this.props.messageBoxType
        };

        this.hideAlert = this.hideAlert.bind(this);
    }

    hideAlert(){
        this.props.handleOnChange()
    }

    render(){
        const {show} = this.props;

        return(
            <div>
                {
                    show &&
                    <div className={"alert alert-dismissible " + this.props.messageBoxType}>
                        {
                            this.state.isDismissible &&
                            <button type="button" className="close" onClick={this.hideAlert}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                        {
                            this.props.message
                        }
                    </div>
                }
            </div>
        )
    }
}

export default MessageBox;