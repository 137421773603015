import React, {Component} from 'react'
import {push} from "react-router-redux";
import {connect} from "react-redux";
import * as gcpActions from "../../../actions/gcpActions";
import {withRouter} from "react-router-dom";
import {T} from "lioness";
import ContentModule from "../../ContentModule";

class WithdrawApplication extends Component{

    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
        };

        this.handleNo = this.handleNo.bind(this);
        this.handleYes = this.handleYes.bind(this);
    }

    handleNo(){
        this.props.goTo("/member/gcp/myapplications");
    }

    handleYes(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }

        this.props.withdrawApplication(applicationId, this.withdrawCallback)
    }

    withdrawCallback = (result) =>{
        if (result.status === 200){
            this.setState({
                currentStep: 2
            })
        }
        else {
            this.setState({
                currentStep: 3
            })
        }
    };

    render(){
        return(
            <div>
                <h2 className={"t-center"}><T>Withdraw Application</T></h2>

                {
                    this.state.currentStep === 1 ?
                        <div className={"mt-20"}>
                            <ContentModule>
                                <p><T>Are you sure you want to withdraw this application?</T></p>
                                <div className="btnInline mt-10 t-center">
                                    <span className="signup-button small" onClick={this.handleNo}><T>No</T></span>
                                    <span className="signup-button small ml-5" onClick={this.handleYes}><T>Yes</T></span>
                                </div>
                            </ContentModule>
                        </div> :

                        this.state.currentStep === 2 ?
                            <div>
                                <T>Application is withdrawn successfully</T>
                            </div> :
                            <div className="validation-summary-errors">
                                <span><T>Could not withdraw application</T></span>
                            </div>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        goTo: (url) => {
            dispatch(push(url))
        },
        withdrawApplication: (applicationId, callback) =>{
            dispatch(gcpActions.dbWithdrawGcpApplication(applicationId, callback))
        }
    }
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WithdrawApplication));
