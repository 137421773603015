import React, {Component} from 'react'
import "../styles/Background.css";

class ContentModule extends Component{
    static defaultProps = {
        colorClass: 'bg-orange',
        containerClass: '',
        moduleClass: '',
        topless: false,
        padless: false,
    }

    constructor(props){
        super(props);

        let containerClass = '';
        let colorClass = props.colorClass;
        let moduleClass = props.moduleClass;

        if (props.topless === true){
            containerClass = 'content-module-border-top ';
            colorClass = 'bg-none';
        }

        if (props.padless !== true){
            moduleClass = "content-module-padding " + moduleClass
        }

        this.state = {
            colorClass: 'content-module-bar ' + colorClass,
            containerClass: containerClass + " " + (props.containerClass || ''),
            extraClass: moduleClass || '',
            extraStyle: props.style || {},
        };
    }

    render(){
        return (
            <div className={"content-module-container " + this.state.containerClass}>
                <div className={this.state.colorClass} />
                <div className={"content-module " + this.state.extraClass} style={this.state.extraStyle}>
                    { this.props.children }

                </div>
            </div>
        )
    }
}

export default ContentModule;