import React, {Component} from 'react'
import ContentModule from "../ContentModule";
import {T, withTranslators} from "lioness";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import * as auxActions from "../../actions/auxActions";
import TextInput from "../input_types/TextInput";


class ServiceFeeDetail extends Component{
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 1,
            acceptPreSales: false
        };
        this.handleAcceptPreSalesOnChange = this.handleAcceptPreSalesOnChange.bind(this);
    }

    handleAcceptPreSalesOnChange(event){
        this.setState({
            acceptPreSales: !this.state.acceptPreSales
        })
    }

    componentWillMount(){
        const {id} = this.props.match.params;
        this.props.getServiceFeeDetail(id, this.serviceDetailCallback)
    }

    serviceDetailCallback = (result) => {
        let colorClass = 'bg-orange';
        if(result.data.payment_status === 'Paid'){
            colorClass = 'bg-teal';
        }
        if(result.data.is_cancelled === true){
            colorClass = 'bg-danger';
        }

        this.setState({
            colorClass: colorClass,
            currentStep: 2,
            item:{
                id: result.data.id,
                order: result.data.order,
                is_cancelled: result.data.is_cancelled,
                invoice_number: result.data.invoice_number,
                invoice_date: result.data.invoice_date,
                product_type: result.data.product_type,
                amount: result.data.amount,
                price: result.data.price,
                paid_amount: result.data.paid_amount,
                exemption_amount: result.data.exemption_amount,
                remaining_amount: result.data.remaining_amount,
                payment_type: result.data.payment_type,
                payment_status: result.data.payment_status,
            }
        });
    };

    render(){
        const {t} = this.props;
        const {currentStep, colorClass} = this.state;

        let preSalesAgreementDownloadUrl = 'https://sistem.gs1tr.org';
        let distantSalesAgreementDownloadUrl = 'https://sistem.gs1tr.org';

        return(
            <div>
                <h2 className={"t-center"}><T>Service Fee Info</T></h2>

                <ContentModule>
                    <T>The service fee information is shown below. If you did not pay your fee, you can use online payment methods by clicking Pay Now button.</T>
                </ContentModule>

               {
                   currentStep === 1 ?
                   <div><T>Loading...</T></div>:

                       <React.Fragment>
                           <ContentModule>
                               <form>
                                   <div className="editor small signin">
                                       <div>
                                           <TextInput key={"order"} inputId={"order"} inputTitle={t("Order Number")} inputValue={this.state.item["order"]} disabled required={false}/>
                                           <TextInput key={"invoice_number"} inputId={"invoice_number"} inputTitle={t("Invoice Number")} inputValue={this.state.item["invoice_number"]} disabled required={false}/>
                                           <TextInput key={"invoice_date"} inputId={"invoice_date"} inputTitle={t("Invoice Date")} inputValue={this.state.item["invoice_date"]} disabled required={false}/>
                                           <TextInput key={"product_type"} inputId={"product_type"} inputTitle={t("Product Type")} inputValue={this.state.item["product_type"]} disabled required={false}/>
                                           <TextInput key={"amount"} inputId={"amount"} inputTitle={t("Amount")} inputValue={this.state.item["amount"]} disabled required={false}/>
                                           <TextInput key={"payment_type"} inputId={"payment_type"} inputTitle={t("Payment Type")} inputValue={t(this.state.item["payment_type"])} disabled required={false}/>
                                           <TextInput key={"payment_status"} inputId={"payment_status"} inputTitle={t("Payment Status")} inputValue={t(this.state.item["payment_status"])} disabled required={false}/>
                                       </div>
                                   </div>
                               </form>
                           </ContentModule>

                           <ContentModule colorClass={"" + colorClass}>
                               <div className="editor small signin">
                                   <div className="editor small signin mt-10">
                                       <div className={"d-flex item-priced"}>
                                           <div className={"on-left"}>
                                               <T>Price</T>
                                           </div>
                                           <div className={"on-right"}>
                                               {(this.state.item["price"] * 100/120).toFixed(2)}₺
                                           </div>
                                       </div>
                                       <div className={"d-flex item-priced"}>
                                           <div className={"on-left"}>
                                               <T>KDV</T>
                                           </div>
                                           <div className={"on-right"}>
                                               {(this.state.item["price"] * 20/120).toFixed(2)}₺
                                           </div>
                                       </div>
                                       <div className={""}>
                                           <div className={"total-amount"}>
                                               <T>Total</T>: {this.state.item["price"].toFixed(2)}₺
                                           </div>
                                       </div>
                                   </div>

                                   <div className={"d-flex item-priced mt-20"}>
                                       <div className={"on-left"}>
                                           <T>Price</T>
                                       </div>
                                       <div className={"on-right"}>
                                           {this.state.item["price"].toFixed(2)}₺
                                       </div>
                                   </div>
                                   <div className={"d-flex item-priced"}>
                                       <div className={"on-left"}>
                                           <T>Paid Amount</T>
                                       </div>
                                       <div className={"on-right"}>
                                           {this.state.item["paid_amount"].toFixed(2)}₺
                                       </div>
                                   </div>
                                   {
                                        this.state.item["exemption_amount"] > 0 &&
                                            <div className={"d-flex item-priced"}>
                                                <div className={"on-left"}>
                                                    <T>Exemption Amount</T>
                                                </div>
                                                <div className={"on-right"}>
                                                    {this.state.item["exemption_amount"].toFixed(2)}₺
                                                </div>
                                            </div>
                                   }
                                   <div className={"d-flex item-priced"}>
                                       <div className={"on-left"}>
                                           <T>Remaining Amount</T>
                                       </div>
                                       <div className={"on-right"}>
                                           {this.state.item["remaining_amount"].toFixed(2)}₺
                                       </div>
                                   </div>
                               </div>

                               {
                                   this.state.item['is_cancelled'] === true ?
                                       <div className={"bolded mt-20"}>
                                           <T>Your order has been cancelled.</T>
                                       </div>:
                                       <div>
                                       {
                                            this.state.item['payment_status'] !== 'Paid' ?
                                                <div>
                                                    <div className={"t-center mt-20"}>
                                                        <input type="checkbox" id={"acceptPreSales"} className={"mr-10"} checked={this.state.acceptPreSales} onChange={this.handleAcceptPreSalesOnChange} />
                                                        <T
                                                            message={"I have read and accepted the {{ pre_sales_agreement }} and {{ distant_sales_aggrement }}."}
                                                            pre_sales_agreement={<a href={preSalesAgreementDownloadUrl} target={"_blank"}><T>Pre Sales Agreement</T></a>}
                                                            distant_sales_aggrement={<a href={distantSalesAgreementDownloadUrl} target={"_blank"}><T>Distant Sales Agreement</T></a>}
                                                        />
                                                    </div>
                                                    <div className="btnInline mt-10 t-center pay mt-20">
                                                        {
                                                            this.state.acceptPreSales ?
                                                                <Link to={"/member/pay/servicefee/" + this.state.item["id"]} className={"signup-button small"}>
                                                                    <T>Pay Now</T>
                                                                </Link>:
                                                                <a className={"signup-button small bg-orange-disabled"} title={t("Please accept the terms.")}>
                                                                    <T>Pay Now</T>
                                                                </a>
                                                        }
                                                    </div>

                                                </div>:
                                                <div className={"bolded mt-20"}>
                                                    <T>Payment was successfully received.</T>
                                                </div>
                                       }
                                       </div>
                               }

                           </ContentModule>
                       </React.Fragment>
               }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getServiceFeeDetail: (id, callback) =>{
            dispatch(auxActions.dbServiceFeeDetail(id, callback))
        },
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ServiceFeeDetail)));