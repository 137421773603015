import React, {Component} from 'react'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select';
import {findDOMNode} from "react-dom";
import {withTranslators} from "lioness";


// SERVER SELECT INPUT
class SSInput extends Component{
    constructor(props){
        super(props);

        this.handleFocus = this.handleFocus.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getSSComponent = this.getSSComponent.bind(this);
        this.selectedInput  = this.props.select;
    }

    handleFocus(event){
        ReactTooltip.show(findDOMNode(this.refs.tooltip));
    }


    handleInputChange(event){
        const {parentKey} = this.props;

        if (parentKey)
            this.props.handleInputChange(event, parentKey);
        else
            this.props.handleInputChange(event, this.selectedInput);

        ReactTooltip.show(findDOMNode(this.refs.tooltip));
    }

    getSSComponent(){
        const SSLoaded = this.props.isSSloaded;
        const {t} = this.props;

        if (SSLoaded){
            return <Select
                required
                value={this.props.value}
                onChange={this.handleInputChange}
                onFocus={this.handleFocus}
                options={this.props.ssData}
                placeholder={t("Select...")}
            />
        }
        else {
            return <Select
                isLoading
                disabled
            />;
        }
    }

    render(){
        const {inputId, inputTitle, dataToolTip, noFlex} = this.props;
        const component = this.getSSComponent();
        const _flexClass = noFlex ? '' : 'child d-flex';

        return(
            <div className="child-wrapper">
                <div className={_flexClass}>
                    <label htmlFor={inputId}>
                        {inputTitle}
                        <span className={"required-field"}>*</span>
                    </label>
                    <div className={"d-flex"}>
                        {
                            component
                        }
                        {
                            dataToolTip
                                ?
                                <span>
                                    <i className={"tooltip-icon"} ref="tooltip" data-tip={dataToolTip} data-delay-show='500'>?</i>
                                </span>
                                : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslators(SSInput);