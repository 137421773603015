import React, {Component} from 'react'
import {connect} from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import * as dataActions from "../../../actions/dataActions";
import {makeGcpApplicationTableData} from "../../TableUtils";
import {T, withTranslators} from "lioness";
import ContentModule from "../../ContentModule";

const DEFAULT_PAGE_SIZE = 5;

class MyApplications extends Component{

    constructor(props) {
        super(props);
        this.state = {
            gcpTable: {
                data: [],
                pages: null,
                loading: true,
            }
        };
        this.fetchDataForGcpTable = this.fetchDataForGcpTable.bind(this);
    }

    callbackForGcpTable = (state, result) =>{
        const dataForGcpTable = makeGcpApplicationTableData(result.data);
        const totalTableSize = result.total_size;

        if(result.status === "ok"){
            this.setState({
                gcpTable:{
                    ...this.state.gcpTable,
                    data: dataForGcpTable,
                    pages: Math.ceil(totalTableSize / state.pageSize),
                    loading: false
                },
            })
        }
    };

    fetchDataForGcpTable(state, instance){
        this.props.requestDataForApplicationGcpTable(state.pageSize, state.page, state.sorted, this.callbackForGcpTable, state);
    }

    render(){
        const gcpTable = this.state.gcpTable;
        const {t} = this.props;

        return(
            <div>

                <h2 className={"t-center"}><T>My GCP Applications</T></h2>

                <ContentModule>
                    <T>Your GCP applications during your membership are listed below.</T>
                </ContentModule>

                <div className={"table-content "}>
                    <ReactTable
                        columns={[
                            {
                                Header: <T>Digits</T>,
                                accessor: "digits",
                                sortable: false
                            },
                            {
                                Header: <T>Status</T>,
                                accessor: "status"
                            },
                            {
                                Header: <T>Date</T>,
                                accessor: "created_at"
                            },
                            {
                                Header: <T>Actions</T>,
                                accessor: "actions",
                                sortable: false
                            },
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={gcpTable.data}
                        pages={gcpTable.pages}
                        loading={gcpTable.loading}
                        onFetchData={this.fetchDataForGcpTable} // Request new data when things change
                        defaultPageSize={DEFAULT_PAGE_SIZE}
                        className="-striped -highlight"
                        multiSort={false}

                        previousText={<T>Previous</T>}
                        nextText={<T>Next</T>}
                        loadingText={<T>Loading...</T>}
                        noDataText={<T>No rows found</T>}
                        pageText={<T>Page</T>}
                        ofText={<T>of</T>}
                        rowsText={t('rows')}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        requestDataForApplicationGcpTable: (pagesize, page, sorted, callback, state) => {
            let sortedFieldName="", sortedIsDesc="";
            if (sorted.length !== 0){
                sortedFieldName = sorted[0].id;
                sortedIsDesc = sorted[0].desc ? "desc": "asc";
            }

            dispatch(dataActions.dbRequestApplicationsDataForGcpTable(pagesize, page, sortedFieldName, sortedIsDesc, callback, state));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(MyApplications));