import axios from 'axios';
import {getDefaultHeader, getHeaderTokenWithMultipart, getHeaderWithToken} from "./authToken";
import {axiosError} from "./errorHandler";
import {
    emailCheckUrl,
    emailTcknTaxNoCheckUrl,
    forgotPasswordUrl,
    impersonateUrl,
    loginUrl,
    logoutUrl,
    memberTitleUrl,
    signupUrl,
    updateProfileCommitmentFileUrl,
    updateProfileDetailsUrl,
    updateProfileTitleUrl,
    updateRequestUrl,
    updateUrl,
    validateTokenUrl,
    verifyPostCodeUrl
} from "../../constants/MemberUrlConstants";

export const validateToken = (username, password) => {
    return new Promise(((resolve, reject) => {
        axios.get(validateTokenUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
    }));

};

export const loginUser = (username, password) => {
    return new Promise(((resolve, reject) => {
        axios.post(loginUrl, {
            'username': username,
            'password': password
        }, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject, false);
            });
    }));

};


export const impersonateLoginUser = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(impersonateUrl, data, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject, false);
            });
    }));
};


export const getMemberTitle = (memberId) => {
    return new Promise(((resolve, reject) => {
        axios.get(memberTitleUrl + "?member_id=" + memberId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const logoutUser = () => {
    return new Promise(((resolve, reject) => {
        axios.post(logoutUrl)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const signUp = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(signupUrl, data, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
                axiosError(error, reject);
            });
    }));
};

export const update = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(updateUrl, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbUpdateProfileTitle = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(updateProfileTitleUrl, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error);
            });
    }));
};


export const dbUpdateProfileCommitmentFile = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(updateProfileCommitmentFileUrl, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error);
            });
    }));
};


export const dbUpdateProfileDetails = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(updateProfileDetailsUrl, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error);
            });
    }));
};


export const emailCheck = (data, callback) => {
    data = {'email': data};
    return new Promise(((resolve, reject) => {
        axios.post(emailCheckUrl, data, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                callback(false);
                axiosError(error, reject);
            });
    }));
};

export const checkEmailTcknTaxNo = (data, callback) => {
    return new Promise(((resolve, reject) => {
        axios.post(emailTcknTaxNoCheckUrl, data, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                callback(false);
                axiosError(error, reject);
            });
    }));
};

export const checkRequestStatus = () => {
    return new Promise(((resolve, reject) => {
        axios.get(updateRequestUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
                axiosError(error, reject);
            });
    }));
};

export const forgotPassword = (username) => {
    return new Promise(((resolve, reject) => {
        axios.post(forgotPasswordUrl + "?email=" + username, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject, false);
            });
    }));

};

export const dbVerifyPostCode = (billingCityId, billingPostCode, cityId, cityPostCode) => {
    return new Promise(((resolve, reject) => {
        axios.post(verifyPostCodeUrl +
            "?billing_city_id="+billingCityId + "&billing_post_code=" + billingPostCode +
            "&city_id=" + cityId + "&post_code=" + cityPostCode, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
                axiosError(error, reject);
            });
    }));
};
