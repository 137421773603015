import axios from 'axios';
import {axiosError} from "./errorHandler";
import {getUserUrl, registerOrResetUrl} from "../../constants/MemberUrlConstants";
import {getDefaultHeader} from "./authToken";


export const dbGetUser = (tokenId) => {
    return new Promise(((resolve, reject) => {
        axios.get(getUserUrl + "?tokenId=" + tokenId, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbRegisterOrResetUser = (memberId, password, isRegister) => {
    return new Promise(((resolve, reject) => {
        axios.post(registerOrResetUrl, {
            member_id: memberId,
            password: password,
            is_register: isRegister
        }, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

