export const TITLE_HELP = "Registered Company Name";
export const SELECTED_PO_HELP = "Chamber or Commodity Exchange or any other parent organization type";
export const PO_NO_HELP = "If You Are Registered To Trade Registry Provide Trade Registry Number; If You Are Registered To TESK Provide Craftsman Registry Number";
export const TAX_REGISTER_NO_HELP = "Company Tax Registry Number";
export const MERSIS_NO_HELP = "MERSİS No";
export const TCKN_HELP = "Individual or Individual Company Applicants Should Supply Identity Number of Republic of Turkey (TCKN)";
export const COMPANY_EMAIL_HELP = "This Email Address Will Be Used For Login And All The Notifications";
export const POST_PHONE_HELP = "Provide Company Phone Number";
export const FAX_NO_HELP = "Provide Company Fax Number";
export const WEBSITE_HELP = "Provide Company Website; Please Make Sure That The URL Starts With Either 'http://' or 'https://'";
export const NACE_CODE_HELP = "Please Provide NACE Code That You Have Provided To Revenue Administration";

export const FIRST_NAME_HELP = "Please Provide First Name Of The Authorized Person; In Case Of Cosignatory Powers, Information Of Just One Is Sufficient";
export const LAST_NAME_HELP = "Please Provide Last Name Of The Authorized Person";
export const EMAIL_HELP = "Please Provide Email Of The Authorized Person";

export const PHONE_HELP = "Please Provide Mobile Phone Number Of The Authorized Person";
export const BILLING_EMAIL_HELP = "Please Provide Your Billing Email Address; This Email Address Will Be Used For Your Online Invoices";
export const BILLING_ADDRESS_HELP = "Please Provide Your Address That You Have Provided To Revenue Administration";
export const BILLING_POSTCODE_HELP = "Please Provide Your Postcode";
export const BILLING_PHONE_HELP = "Please Provide Billing Phone Number; This Phone Number Will Be Used To Contact Accounting Departments In Case Of Any Problems";
export const SAME_POST_ADDRESS_HELP = "If Post Address Will Not Be The Same As Billing Address Uncheck This";
export const POST_ADDRESS_HELP = "Please Provide Your Post Address";
export const POSTCODE_HELP = "Please Provide Your Postcode";
