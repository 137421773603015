import * as gcpService from "../api/services/gcpService";

export const dbWithdrawGcpApplication = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gcpService.dbWithdrawGcpApplication(applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};


export const dbGetMemberDebt = (callback) => {
    return (dispatch, getState) => {
        return gcpService.dbGetMemberDebt().then((result) => {
                callback({
                    "status": result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGetGcpNumber = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gcpService.dbGetGcpNumber(applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbGcpCancel = (applicationId, formData, callback) => {
    return (dispatch, getState) => {
        return gcpService.dbGcpCancel(applicationId, formData).then((result) => {
                callback({
                    "status":result.request.status
                });
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbCheckMembership = (callback) => {
    return (dispatch, getState) => {
        return gcpService.dbCheckMembership().then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbMembershipCancel = (formData, callback) => {
    return (dispatch, getState) => {
        return gcpService.dbMembershipCancel(formData).then((result) => {
                callback({
                    "status":result.request.status
                });
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbLoadActiveGcps = (callback) => {
    return (dispatch, getState) => {
        return gcpService.dbLoadActiveGcps().then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                // callback(messageError);
            });
    }
};
