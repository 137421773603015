import React, {Component} from 'react'
import TextInput from "../input_types/TextInput";
import {withRouter} from "react-router-dom";
import * as gtin8Actions from "../../actions/gtin8Actions";
import {connect} from "react-redux";
import FileLink from "../input_types/FileLink";
import {T, withTranslators} from "lioness";
import BarcodeInput from "../input_types/BarcodeInput";
import BarcodeInputJpeg from "../input_types/BarcodeInputJpeg";

import ContentModule from "../ContentModule";
import GcpSelectDetail from "../input_types/GcpSelectDetail";
import SmartSearchInput from "../input_types/SmartSearchInput";

class ApplicationItemDetail extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            applicationItem: {}
        };


    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }

        this.props.loadApplicationItem(applicationId, this.applicationItemCallback);
    }

    applicationItemCallback = (result) => {
        if (result.status === 200){
             this.setState({
                 applicationItem: result.data,
                 currentStep: 2
             })
        }
    };

    constructViewNumber(){
        const {t} = this.props;

        return <div>
                <TextInput key={"registryDate"} inputId={"registryDate"} inputTitle={t("Registry Date")} inputValue={this.state.applicationItem["registry_dt"]} disabled required={false}/>
                <TextInput key={"gtin8"} inputId={"gtin8"} inputTitle={t("GTIN-8")} inputValue={this.state.applicationItem["gtin8"]} disabled required={false}/>
                
                {this.state.applicationItem.brand_array.map((value, i) => {     
                   return (<TextInput key={"brand"} inputId={"brand"} inputTitle={t("Brand")+" ("+value.label+")"} inputValue={value.input} disabled required={false}/>) 
                })}

                {this.state.applicationItem.description_array.map((value, i) => {     
                   return (<TextInput key={"labelDescription"} inputId={"labelDescription"} inputTitle={t("Label Description")+" ("+value.label+")"} inputValue={value.input} disabled required={false}/>) 
                })}

                {this.state.applicationItem.target_market_array.map((value, i) => {     
                   return (<TextInput key={"targetMarket"} inputId={"targetMarket"} inputTitle={t("Target Market")} inputValue={value.label} disabled required={false}/>) 
                })}
               
                {this.state.applicationItem.image_url.map((value, i) => {     
                   return (<TextInput key={"imageUrl"} inputId={"imageUrl"} inputTitle={t("Ürün Görseli(URL)")+" ("+value.label+")"} inputValue={value.input} disabled required={false}/>) 
                })}

                {this.state.applicationItem.image_url.length<1 &&
                     (<TextInput key={"imageUrl"} inputId={"imageUrl"} inputTitle={t("Ürün Görseli(URL)")} inputValue={""} disabled required={false}/>) 
                }

                {this.state.applicationItem.netmeasure_array.map((value, i) => {     
                   // Return the element. Also pass key     
                   return (<TextInput key={"netMesauser"} inputId={"netMesauser"} inputTitle={t("Net Miktar")+" ("+value.label+")"} inputValue={value.input} disabled required={false}/>) 
                })}

                {
                    
                    (this.state.applicationItem.target_market_array.length===0)  ?  <TextInput key={"targetMarket"} inputId={"targetMarket"} inputTitle={t("Target Market")} inputValue={this.state.applicationItem["targetMarket"]['label']} disabled required={false}/> : ''
                   
                }
                    {(this.state.applicationItem.target_market_array.length===0)  ?  <TextInput key={"brand"} inputId={"brand"} inputTitle={t("Brand")} inputValue={this.state.applicationItem["brand"]} disabled required={false}/>:''}
                    {(this.state.applicationItem.target_market_array.length===0)  ?  <TextInput key={"labelDescription"} inputId={"labelDescription"} inputTitle={t("Label Description")} inputValue={this.state.applicationItem["labelDescription"]} disabled required={false}/>:''}
                    {(this.state.applicationItem.target_market_array.length===0)  ? <TextInput key={"imageUrl"} inputId={"imageUrl"} inputTitle={t("Image Url")} inputValue={this.state.applicationItem["imageUrl"]} disabled required={false}/>:''}
                

                <div>
                    {
                        this.state.applicationItem.has_brick ?
                            <GcpSelectDetail key={"category"} inputId={"category"} inputTitle={t("GS1 Küresel Ürün Kategori Bilgisi")} segment={this.state.applicationItem.brick.label.segment} family={this.state.applicationItem.brick.label.family} _class={this.state.applicationItem.brick.label.class} brick={this.state.applicationItem.brick.label.brick} required={false}/>:
                            <TextInput key={"category"} inputId={"category"} inputTitle={t("Category")} inputValue={"-"} disabled required={false}/>
                    }
                </div>
                <FileLink inputId={"registryCertificate"} inputTitle={t("Registry Certificate")} required={false} inputValue={this.state.applicationItem["registry_certificate"]}/>
        </div>
    }

    render(){
        return(
            <div>
                <h2 className={"t-center"}><T>View GTIN-8</T></h2>

                <ContentModule>
                    <T>You can see product information identified by GTIN-8</T>
                </ContentModule>

                {
                    this.state.currentStep === 1 ?
                        <div><T>Loading...</T></div> :

                    <React.Fragment>
                        <ContentModule>
                            <form>
                                <div className="editor small signin">
                                    {
                                        this.constructViewNumber()
                                    }
                                </div>
                            </form>
                        </ContentModule>

                        <ContentModule>
                            <h3 className={"t-center"}><T>Barcode</T></h3>
                            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                <BarcodeInput id={'ean8'} type={'ean8'} number={this.state.applicationItem["gtin8"]}/>
                            </div>
                            <p className={"t-center"}><T>The quality of Barcode and Data matrix images may vary depending on the device you will print.</T></p>
                        </ContentModule>

                       

                        <ContentModule style={{textAlign: 'center'}}>
                            <h3 className={"t-center"}><T>GS1 SmartSearch</T></h3>
                            <SmartSearchInput number={this.state.applicationItem["gtin8"]} type={'GTIN-8'}
                                              description={this.state.applicationItem['labelDescription']}
                                              brand={this.state.applicationItem['brand']}
                                              target={this.state.applicationItem['targetMarket'].label}
                                              imageUrl={this.state.applicationItem['imageUrl']}  />
                        </ContentModule>

                    </React.Fragment>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationItem: (applicationId, callback) => {
            dispatch(gtin8Actions.dbLoadGtin8ApplicationItem(applicationId, callback))
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ApplicationItemDetail)));
