import React, {Component} from 'react'
import ReactTooltip from 'react-tooltip'


class FileInput extends Component{
    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        this.props.handleUploadFile(event);
    }

    render(){
        const {inputId, inputTitle, dataToolTip, errorFileClass, required, multiple, name, accept} = this.props;
        const _required = required !== false;
        const _multiple = multiple === true;
        const _name     = name ? name : '';
        const _accept   = accept ? accept: 'application/pdf';

        return(
            <div className="child d-flex">
                <div className="child d-flex">
                    <label htmlFor={inputId}>
                        {inputTitle}
                        {
                            _required ? <span className={"required-field"}>*</span> :''
                        }
                    </label>
                    <div className={"d-flex"}>
                        <input type="file" id={inputId} name={_name} className={errorFileClass} required={_required} multiple={_multiple} onChange={this.handleInputChange} accept={_accept} />
                        {
                            dataToolTip
                                ?
                                <span className={"aligned-tooltip"}>
                                    <i className={"tooltip-icon error-tooltip"} data-tip={dataToolTip} data-event='click focus'>?</i>
                                    <ReactTooltip globalEventOff='click' place={"right"}/>
                                </span>
                                : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default FileInput;