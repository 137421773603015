import React, {Component} from 'react'
import "react-table/react-table.css";
import * as authorizeActions from "../../actions/authorizeActions";
import {connect} from "react-redux";
import * as dataActions from "../../actions/dataActions";
import {T, withTranslators} from "lioness";
import {makeMemberStatsTableData} from "../TableUtils";
import ReactTable from "react-table";
import ContentModule from "../ContentModule";
import {Link} from "react-router-dom";

class Dashboard extends Component{
    constructor(props) {
        super(props);

        this.state = {
            memberStatsTable: {
                gcpStatsData: [],
                numberStats: [],
                loading: true,
            },

            applicationStatsLoaded: false,

            gcp_application_active: null,
            gtin8_application_active: null,
            vw_application_active: null,
            upc_application_active: null,
            transfer_application: {},
            merge_application: {},
            has_active_applications: 0,

            member_has_debt: null,
            debt_value: null
        };
    }

    componentWillMount(){
        this.props.loadMemberStats(this.callbackForMemberStatsData)
    }

    callbackForMemberStatsData = (result) => {
        const allStats = makeMemberStatsTableData(result.data);

        if(result.status === 200){
            this.setState({
                gcp_application_active: result.data.gcp_application_active,
                gtin8_application_active: result.data.gtin8_application_active,
                vw_application_active: result.data.vw_application_active,
                upc_application_active: result.data.upc_application_active,
                transfer_application: result.data.transfer_application,
                merge_application: result.data.merge_application,
                has_active_applications: result.data.has_active_applications,

                member_has_debt: result.data.member_has_debt,
                debt_value: result.data.debt_value,

                applicationStatsLoaded: true,
                memberStatsTable:{
                    ...this.state.memberStatsTable,
                    gcpStatsData: allStats.gcpStats,
                    numberStats: allStats.numberStats,
                    loading: false
                },
            })
        }
    };

    render(){
        const memberStatsTable = this.state.memberStatsTable;

        return(
            <div>
                <ContentModule>
                    <h3>{this.props.memberTitle}</h3>
                    <p><T>Your Master GLN</T>: {this.props.memberMasterGLN}</p>
                    <p><T>Below you can see the statistics for your company prefixes and identification keys.</T> <T>Based on your membership obligations you have to inform us about any change related with the information you provided to GS1 Turkey.</T></p>
                </ContentModule>
                {
                    this.state.member_has_debt ?
                        <ContentModule colorClass={"bg-danger"}>

                            {/* iterate over assesments */}
                            <h2 className={"debdt-title"}><T>You Have Existing Debts</T></h2>
                            <p>
                                <T>Borcunuz</T> {this.state.debt_value}₺ 'dir.
                                <br/>
                                <T>Invoice has been sent to your billing address.</T>
                                <br/>
                                <br/>
                                {/* Show total additional debts */}
                                <T>Other debts</T>: {this.state.debt_value} ₺
                                <br/>
                                <T>Please get in touch with GS1 Turkey in order to continue your membership services.</T>
                            </p>

                        </ContentModule>: null
                }
                {
                    this.state.applicationStatsLoaded ?
                        this.state.has_active_applications ?
                            <div className={"mt-10"}>
                                <ContentModule>
                                    {
                                        this.state.gcp_application_active ?
                                        <div>
                                            <T>You have an active GCP application</T>:
                                            <Link to={"/member/gcp/application/view/" + this.state.gcp_application_active} className={"ml-10"}>
                                                <T>View</T>
                                            </Link>
                                        </div>: null
                                    }
                                    {
                                        this.state.gtin8_application_active ?
                                        <div>
                                            <T>You have an active GTIN-8 application</T>:
                                            <Link to={"member/gtin8/application/" + this.state.gtin8_application_active} className={"ml-10"}>
                                                <T>View</T>
                                            </Link>
                                        </div>: null
                                    }
                                    {
                                        this.state.vw_application_active ?
                                        <div>
                                            <T>You have an active Varying Weight Number application</T>:
                                            <Link to={"member/vw/application/" + this.state.vw_application_active} className={"ml-10"}>
                                                <T>View</T>
                                            </Link>
                                        </div>: null
                                    }
                                    {
                                        this.state.upc_application_active ?
                                        <div>
                                            <T>You have an active UPC application</T>:
                                            <Link to={"member/upc/application/" + this.state.upc_application_active} className={"ml-10"}>
                                                <T>View</T>
                                            </Link>
                                        </div>: null
                                    }
                                    {
                                        this.state.transfer_application.id ?
                                        <div>
                                            <T>You have an active Transfer application</T>:
                                            {
                                                this.state.transfer_application.status === 2 ?
                                                    <Link to={"member/transfer/edit/" + this.state.transfer_application.id} className={"ml-10"}>
                                                        <T>View</T>
                                                    </Link>:
                                                    <Link to={"member/transfer/detail/" + this.state.transfer_application.id} className={"ml-10"}>
                                                        <T>View</T>
                                                    </Link>
                                            }
                                        </div>: null
                                    }
                                    {
                                        this.state.merge_application.id ?
                                        <div>
                                            <T>You have an active Merge application</T>:
                                            <Link to={"member/transfer/merge/detail/" + this.state.merge_application.id} className={"ml-10"}>
                                                <T>View</T>
                                            </Link>
                                        </div>: null
                                    }
                                </ContentModule>
                            </div>: null: null
                }
                <div className={"dashboard"}>
                    <div className={"mt-10"}>
                        <div className={"table-title"}>
                            <b><T>Global Company Prefix (GCP) Numbers Allocated For Your Company</T></b>
                        </div>
                        <ReactTable
                            columns={[
                                {
                                    Header: <b><T>Digit Count</T></b>,
                                    accessor: "digit_count",
                                    sortable: false
                                },
                                {
                                    Header: <b><T>Total</T></b>,
                                    accessor: "total",
                                    sortable: false
                                },
                                {
                                    Header: <b><T>Active</T></b>,
                                    accessor: "active",
                                    sortable: false
                                },
                                {
                                    Header: <b><T>Cancelled</T></b>,
                                    accessor: "cancelled",
                                    sortable: false
                                },
                            ]}
                            data={memberStatsTable.gcpStatsData}
                            loading={memberStatsTable.loading}
                            className="-striped"
                            showPagination={false}
                            minRows={5}
                            sortable={false}
                        />
                    </div>

                    <div className={"mt-10"}>
                        <div className={"table-title"}>
                            <b><T>Number Statistics</T></b>
                        </div>
                        <ReactTable
                            columns={[
                                {
                                    Header: <b><T>Type</T></b>,
                                    accessor: "type",
                                    sortable: false
                                },
                                {
                                    Header: <b><T>Total</T></b>,
                                    accessor: "total",
                                    sortable: false
                                },
                                {
                                    Header: <b><T>Active</T></b>,
                                    accessor: "active",
                                    sortable: false
                                },
                                {
                                    Header: <b><T>Cancelled</T></b>,
                                    accessor: "cancelled",
                                    sortable: false
                                },
                            ]}
                            data={memberStatsTable.numberStats}
                            loading={memberStatsTable.loading}
                            className="-striped"
                            showPagination={false}
                            minRows={4}
                            sortable={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => {
            dispatch(authorizeActions.dbLogout())
        },
        loadMemberStats: (callback) => {
            dispatch(dataActions.dbLoadMemberStatsData(callback))
        },
    }
};

const mapStateToProps = (state, ownProps) => {
    const {authorize} = state;
    return{
        memberTitle: authorize.memberTitle,
        memberMasterGLN: authorize.memberMasterGLN,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(Dashboard));