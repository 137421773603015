import React, {Component} from 'react'
import '../styles/Dashboard.css'
import '../styles/Guest.css'
import {T} from "lioness";

class NotFound extends Component{

    render(){
        return(
            <div className={"main-body"}>
                <T>Page not found</T>
            </div>
        )
    }
}

export default NotFound;