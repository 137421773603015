import axios from 'axios';
import {getDefaultHeader, getHeaderWithToken} from "./authToken";
import {axiosError} from "./errorHandler";
import {
    cityDataUrl, countryDataUrl,
    countyDataUrl, loadMemberStatsDataUrl, measurementsDataUrl,
    memberDataUrl,
    memberStatusUrl,
    poDataUrl,
    toDataUrl
} from "../../constants/MemberUrlConstants";
import {
    requestApplicationDataForGcpTableUrl,
    requestDataForGcpTableUrl, requestDataForTransferTable
} from "../../constants/GcpUrlConstants";
import {brickUrl, classUrl, familyUrl, segmentsUrl} from "../../constants/CommonUrlConstants";


export const dbGetPOdata = () => {
    return new Promise(((resolve, reject) => {
        axios.get(poDataUrl, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetTOdata = () => {
    return new Promise(((resolve, reject) => {
        axios.get(toDataUrl, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetCountryData = () => {
    return new Promise(((resolve, reject) => {
        axios.get(countryDataUrl, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetCityData = () => {
    return new Promise(((resolve, reject) => {
        axios.get(cityDataUrl, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetCountyData = (city) => {
    return new Promise(((resolve, reject) => {
        axios.get(countyDataUrl + '?city=' + city, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetSegmentsData = () => {
    return new Promise(((resolve, reject) => {
        axios.get(segmentsUrl, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetFamiliesForSegment = (segmentId) => {
    return new Promise(((resolve, reject) => {
        axios.get(familyUrl + "?segment_id=" + segmentId, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetClassForFamily = (familyId) => {
    return new Promise(((resolve, reject) => {
        axios.get(classUrl + "?family_id=" + familyId, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbGetBrickForClass = (classId) => {
    return new Promise(((resolve, reject) => {
        axios.get(brickUrl + "?class_id=" + classId, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbMemberStatus = () => {
    return new Promise(((resolve, reject) => {
        axios.get(memberStatusUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbMemberData = () => {
    return new Promise(((resolve, reject) => {
        axios.get(memberDataUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbRequestDataForGcpTable = (applicationId, pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForGcpTableUrl +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc + "&application_id=" + applicationId,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbRequestApplicationsDataForGcpTable = (pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestApplicationDataForGcpTableUrl +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbRequestDataForTransferTable = (pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForTransferTable +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbLoadMemberStatsData = () => {
    return new Promise(((resolve, reject) => {
        axios.get(loadMemberStatsDataUrl,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetMeasurementsData = () => {
    return new Promise(((resolve, reject) => {
        axios.get(measurementsDataUrl, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};
