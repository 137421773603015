import * as gtin12Service from "../api/services/gtin12Service";

export const dbCreateGtin12Application = (data, callback) => {
    return (dispatch, getState) => {
        return gtin12Service.dbCreateGtin12Application(data, callback).then((result) => {
                callback(result.request);
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbRequestDataForGtin12List = (applicationId, pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return gtin12Service.dbRequestDataForGtin12List(applicationId, pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                // callback(messageError);
            });
    }
};

export const dbLoadGtin12ApplicationDetail = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin12Service.dbLoadGtin12ApplicationDetail(applicationId, callback).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbLoadGtin12Application = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin12Service.dbLoadGtin12Application(applicationId, callback).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbEditGtin12Application = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin12Service.dbEditGtin12Application(data, applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};

