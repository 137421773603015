export const PO_TYPE_CHAMBER_COMMERCE = 1;
export const PO_TYPE_CHAMBER_INDUSTRY = 2;
export const PO_TYPE_CHAMBER_COMMERCE_AND_INDUSTRY = 3;
export const PO_TYPE_CHAMBER_MARITIME_TRADE = 4;
export const PO_TYPE_EXCHANGE_COMMODITY = 5;
export const PO_TYPE_CHAMBER_TRADESMAN_CRAFTSMAN = 6;
export const PO_TYPE_ASSOCIATION = 7;
export const PO_TYPE_FOUNDATION = 8;
export const PO_TYPE_PUBLIC_INSTITUTION = 9;
export const PO_TYPE_PUBLIC_PROFESSIONAL_ORG = 10;
export const PO_TYPE_PUBLIC_PROFESSIONAL_ORG_MEMBER = 11;


export const PERSONAL_PO_TYPES = [
    PO_TYPE_CHAMBER_TRADESMAN_CRAFTSMAN,
    PO_TYPE_PUBLIC_PROFESSIONAL_ORG_MEMBER
];
