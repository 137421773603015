import React, {Component} from 'react'
import {withTranslators} from "lioness";
import {siteUrl} from '../constants/UrlConstants'


class Iframe extends Component{
    constructor(props){
        super(props);

        this.state = {
            animationShown: false,

            outerClass: "",
            iframeClass: "",
            source: "",
            width: "100%",
            height: "450px",
            title: "New Application"
        };

        this.toggleAnimation = this.toggleAnimation.bind(this);
    }

    componentWillMount(){
        this.setState({
            ...this.props
        })
    }

    toggleAnimation(){
        this.setState({
            animationShown: !this.state.animationShown
        })
    }

    render(){
        const {outerClass, iframeClass, source, width, height, title, animationShown} = this.state;
        const {t} = this.props;

        const buttonText = animationShown ? t("Hide Help") : t("Show Help");

        return(
            <div className={outerClass}>
                <button className={"signup-button mb-5"} onClick={this.toggleAnimation}>
                    {buttonText}
                </button>
                {
                    animationShown && source &&
                    <iframe title={title} className={"no-border " + iframeClass} src={siteUrl + "/static/animations/" + source} width={width} height={height}/>
                }
            </div>
        )
    }
}

export default withTranslators(Iframe);