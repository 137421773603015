import React, {Component} from 'react'
import * as authorizeActions from "../../actions/authorizeActions";
import TextInput from "../input_types/TextInput";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {T, withTranslators} from 'lioness';

import '../../styles/Login.css'
import ContentModule from "../ContentModule";
import {SITE_KEY} from "../../constants/Common";
import Reaptcha from "reaptcha";


class Login extends Component{
    constructor(props){
        super(props);

        this.state = {
            emailInput: '',
            passwordInput: '',

            loginError: false,
            loginErrorMessage: '',

            isCaptchaLoaded: false,
            isCaptchaRendered: false,
            isCaptchaVerified: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    loginCallBack = (messageError) => {
        const {t} = this.props;
        this.setState({
            loginError: true,
            loginErrorMessage: t(messageError)
        })
    };

    onLoad = () => {
        this.setState({
            isCaptchaLoaded: true
        })
    };

    onRender = () => {
        this.setState({
            isCaptchaRendered: true
        })
    };

    verifyCallback = (response) => {
        this.setState({
            isCaptchaVerified: true,
            loginError: false,
            loginErrorMessage: '',
        })
    };

    expiredCallback = () => {
        this.setState({
            isCaptchaVerified: false,
        })
    };

    handleSubmit(event) {
        event.preventDefault();
        const {t} = this.props;

        if (!this.state.isCaptchaVerified)
            return this.setState({
                loginError: true,
                loginErrorMessage: t('Please check the box'),
            });

        if(this.state.emailInput.trim() && this.state.passwordInput.trim()){
            this.props.login(
                this.state.emailInput,
                this.state.passwordInput,
                this.loginCallBack,
                this.props.nextUrl
            );
        }
    }

    render(){
        const {t} = this.props;
        const isLoginDisabled = !(this.state.isCaptchaLoaded && this.state.isCaptchaRendered);

        return(
            <div className={"pos-abs"}>

                <h1 className={"t-center"}><T>Login</T></h1>
                <ContentModule>
                    <form onSubmit={this.handleSubmit} className={"editor small sigin"}>
                        <div className={"after-submit t-center"}>
                            {
                                this.state.loginError &&
                                <div className="validation-summary-errors">
                                    <span dangerouslySetInnerHTML={{ __html: this.state.loginErrorMessage }}></span>
                                </div>
                            }
                        </div>
                        <div>
                            <TextInput key={"emailInput"} inputType={"email"} inputId={"emailInput"} inputTitle={t("Email")} inputValue={this.state.emailInput} handleInputChange={this.handleInputChange}/>
                            <TextInput key={"passwordInput"} inputType={"password"} inputId={"passwordInput"} inputTitle={t("Password")} inputValue={this.state.passwordInput} handleInputChange={this.handleInputChange}/>
                        </div>
                        <div className={"recaptcha-position"}>
                            <Reaptcha
                                sitekey={SITE_KEY}
                                onLoad={this.onLoad}
                                onRender={this.onRender}
                                onVerify={this.verifyCallback}
                                onExpire={this.expiredCallback}
                            />
                        </div>
                        <div className="btnInline d-flex-center mt-10">
                            <button className="btn" type="submit" disabled={isLoginDisabled}><T>Login</T></button>
                            <Link className="btn" to={"forgot-password"} ><T>Forgot Password</T></Link>
                        </div>
                    </form>
                </ContentModule>

                <p className={"before-footer"}>
                    <b>
                        <span className={"mr-5"}>
                            <T
                                message="If you need help with your Email/Password or adding users visit {{ link }}."
                                link={<a href={"login-help"} target="_blank">online.gs1tr.org/login-help</a>}
                            />
                        </span>
                    </b>
                </p>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        login: (email, password, callBack, nextUrl) => {
            dispatch(authorizeActions.dbLogin(email, password, callBack, nextUrl))
        },
    }
};

const mapStateToProps = (state) => {
    const {authorize} = state;

    return {
        nextUrl: authorize.nextUrl,
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(Login)));
