import React, {Component} from 'react'
import ReactTooltip from 'react-tooltip'
import {findDOMNode} from "react-dom";

import '../../styles/CheckBoxList.css'

class LeftCheckBoxInput extends Component{


    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleFocus(event){
        event.preventDefault();
        ReactTooltip.show(findDOMNode(this.refs.tooltip));
    }

    handleInputChange(event){
        this.props.handleInputChange(event);
    }

    getInputType(){
        const {inputId, inputValue} = this.props;
        const _inputType = 'checkbox';

        return <input type={_inputType} id={inputId}  checked={inputValue} onChange={this.handleInputChange} />;
    }

    render(){
        const {inputId, inputTitle, dataToolTip, errorText, isFull} = this.props;
        const inputComponent = this.getInputType();

        return(
            <div className={"checkbox-list-item" + (isFull ? "-full":'')}>
                <div className={"d-flex "}>
                    <div>
                        {
                            inputComponent
                        }
                        {
                            dataToolTip
                                ?
                                <span>
                                    <i className={"tooltip-icon"} ref="tooltip" data-tip={dataToolTip} data-delay-show='500'>?</i>
                                </span>
                                : ''
                        }
                    </div>
                    <label htmlFor={inputId}>
                        {inputTitle}
                    </label>
                </div>
                <div className={"t-center"}>
                    {
                        errorText && errorText.length ?
                            (
                                <span className={"input-error"}>{errorText}</span>
                            )
                            :
                            ''
                    }
                </div>
            </div>
        )
    }
}

export default LeftCheckBoxInput;