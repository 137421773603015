import React, {Component} from 'react'
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";

class NewApplication extends Component{
    constructor(props){
        super(props);
    }

    componentWillMount(){
    };

    render(){
        return(
            <div>
                <h2 className={"t-center"}><T>New UPC Application</T></h2>

                <ContentModule>
                    <T>GS1 Türkiye aracılığı ile GS1 A.B.D.’ den aldığınız UPC (Universal Product Number) olarak adlandırılan numaraların, GS1 A.B.D tarafından yapılan sistem değişikliği nedeniyle direkt GS1 A.B.D.'ye başvuru yapılarak alınması gerekmektedir.</T>
                    <br/><br/>
                    <T>GS1 Türkiye üzerinden yapılan başvurular 30 Temmuz 2020 tarihinden itibaren kabul edilmeyecektir.</T>
                    <br/><br/>
                    <T
                        message="Başvuru işlemleri ile ilgili ayrıntılı bilgi edinmek ve UPC numarası almak için {{ link }} adresini ziyaret edebilirsiniz."
                        link={<a href={"https://www.gs1us.org"} target={"_blank"}>https://www.gs1us.org </a>}
                    />
                </ContentModule>


            </div>
        )
    }
}

export default withTranslators(NewApplication);