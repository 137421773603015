import React, {Component} from 'react'
import ContentModule from "../ContentModule";
import {T, withTranslators} from "lioness";
import {connect} from "react-redux";
import * as auxActions from "../../actions/auxActions";
import ReactTable from "react-table";
import {makeSubscriptionFeeTableData} from "../TableUtils";
import Select from 'react-select';

const DEFAULT_PAGE_SIZE = 5;


class Subscription extends Component{
    constructor(props) {
        super(props);

        const {t} = this.props;

        this.state = {
            paymentTable: {
                data: [],
                pages: null,
                loading: true,
            },
            pageSize: DEFAULT_PAGE_SIZE,
            page: 0,
            sorted: [],

            selectedFilterOption:{
                value: 1,
                label: t('Not Paid')
            },
        };

        this.fetchDataForPaymentTable = this.fetchDataForPaymentTable.bind(this);
    }

    tableEventHandler = (result) => {
        const dataForPaymentTable = makeSubscriptionFeeTableData(result.data.data);
        const totalTableSize = result.data.total_size;

        this.setState({
            paymentTable:{
                ...this.state.paymentTable,
                data: dataForPaymentTable,
                pages: Math.ceil(totalTableSize / this.state.pageSize),
                loading: false
            },
        })
    };

    fetchDataForPaymentTable(state, instance){
        this.setState({
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
        });

        this.props.getSubsrciptionFeeList(state.pageSize, state.page, state.sorted, this.state.selectedFilterOption.value, this.tableEventHandler);
    }

    handleFilterChange = (value) => {
        if(value.value !== this.state.selectedFilterOption.value){
            this.setState({
                selectedFilterOption: value
            });

            this.props.getSubsrciptionFeeList(this.state.pageSize, this.state.page, this.state.sorted, value.value, this.tableEventHandler);
        }
    };

    render(){
        const paymentTable = this.state.paymentTable;
        const {t} = this.props;

        const filterOptions = [
            { value: 1, label: t('Not Paid')},
            { value: 2, label: t('Paid')},
            { value: 3, label: t('All')},
        ];

        return(
            <div>
                <h2 className={"t-center"}><T>Subscription Fee List</T></h2>

                <ContentModule>
                    <T>The annual usage fees are listed below. You can see details and pay.</T>
                </ContentModule>

                <div className="editor small signin mt-10">
                    <div className="child d-flex">
                        <div className="child d-flex">
                            <label htmlFor={"requestedDigits"}>
                                <T>Showing results for</T>
                            </label>

                            <div className={"d-flex"}>
                                <Select
                                    clearable={false}
                                    value={this.state.selectedFilterOption}
                                    onChange={this.handleFilterChange}
                                    options={filterOptions}
                                    placeholder={t("Select...")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"table-content mt-10"}>
                    <ReactTable
                        columns={[
                            {
                                Header: <T>Year</T>,
                                id: "year",
                                accessor: "year",
                            },
                            {
                                Header: <T>Invoice Number</T>,
                                accessor: "invoice_number",
                            },
                            {
                                Header: <T>Invoice Date</T>,
                                accessor: "invoice_date",
                            },
                            {
                                Header: <T>Price</T>,
                                accessor: "subscription_fee",
                            },
                            {
                                Header: <T>Status</T>,
                                accessor: "payment_status",
                            },
                            {
                                Header: <T>Invoice</T>,
                                accessor: "invoice",
                                Cell: (props) => (
                                  <span><b><a href={'https://sistem.gs1tr.org/media/invoice/'+props.row.year+'/'+props.row.invoice} target="_blank">{props.row.invoice}</a></b></span>
                                ),
                            },
                            {
                                Header: <T>Actions</T>,
                                accessor: "actions",
                                sortable: false
                            },
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={paymentTable.data}
                        pages={paymentTable.pages}
                        loading={paymentTable.loading}
                        onFetchData={this.fetchDataForPaymentTable} // Request new data when things change
                        defaultPageSize={this.state.pageSize}
                        className="-striped -highlight"
                        multiSort={false}

                        previousText={<T>Previous</T>}
                        nextText={<T>Next</T>}
                        loadingText={<T>Loading...</T>}
                        noDataText={<T>No rows found</T>}
                        pageText={<T>Page</T>}
                        ofText={<T>of</T>}
                        rowsText={t('rows')}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSubsrciptionFeeList: (pagesize, page, sorted, filterOption, callback) =>{
            let sortedFieldName="", sortedIsDesc="";
            if (sorted.length !== 0){
                sortedFieldName = sorted[0].id;
                sortedIsDesc = sorted[0].desc ? "desc": "asc";
            }

            dispatch(auxActions.dbGetMemberSubscriptionFeeList(pagesize, page, sortedFieldName, sortedIsDesc, filterOption, callback))
        },
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(Subscription));