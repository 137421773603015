import React, {Component} from 'react'
import '../../styles/Dashboard.css'
import * as applicationActions from "../../actions/applicationActions";
import {connect} from "react-redux";
import {APPLICATION_STATUS_ONLINE_DOC, DOC_STATUS_REJECT,} from "../../constants/Statuses";
import FileInput from "../input_types/FileInput";
import FileLink from "../input_types/FileLink";
import TextInput from "../input_types/TextInput";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import {isFileSizeExceeded, showFileError} from "../FileUtils";

class EditApplication extends Component{

    constructor(props){
        super(props);
        this.state = {
            application: null,
            submitting: false,
            operatorNote: "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.handleOnApplicationUpdate = this.handleOnApplicationUpdate.bind(this);
        this.handleOnWithdrawClick = this.handleOnWithdrawClick.bind(this);
    }

    componentWillMount(){
        let application = this.props.application;
        const {t} = this.props;
        let operatorNote = "";
        if (application.latest_application_note === null){
            application['latest_application_note'] = t("No notes recorded!");
            operatorNote = t("No notes recorded!");
        } else {
            operatorNote= application['latest_application_note'];
        }

        application['latest_application_note'] = "";

        this.setState({
            application,
            operatorNote: operatorNote,
        });
    }

    handleInputChange(event) {
        this.setState({
            application: {
                ...this.state.application,
                [event.target.id]: event.target.value
            }
        });
    }

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            application: {
                ...this.state.application,
                [event.target.id]: submitFile
            }
        });

    };

    applicationUpdateCallBack = (result) => {
        this.setState({
            submitting: false
        });
        if (result.status === "ok")
            this.props.getStatus();
    };

    handleOnEdit(){
        let appStatus = this.state.applicationStatus;
        appStatus.application_status_code = APPLICATION_STATUS_ONLINE_DOC;

        this.setState({
            applicationStatus: appStatus
        })
    };

    handleOnWithdrawClick(){
        this.props.handleWithdrawApplication();
    }

    handleOnApplicationUpdate(event){
        event.preventDefault();

        const formData = new FormData();

        let app = this.state.application

        if (app.commercialCertificateFile)
            formData.append('commercial_certificate', app.commercialCertificateFile);
        if (app.commitmentLetterFile)
            formData.append('commitment_letter', app.commitmentLetterFile);
        if (app.incomeStatementFile)
            formData.append('income_statement', app.incomeStatementFile);

        if (app.petitionFile)
            formData.append('petition_file', app.petitionFile);
        if (app.transferFile)
            formData.append('transfer_file', app.transferFile);
        if (app.inheritanceFile)
            formData.append('inheritance_file', app.inheritanceFile);
        if (app.consentFile)
            formData.append('consent_file', app.consentFile);

        formData.append('latest_application_note', app.latest_application_note);
        formData.append('application_type', app.application_type);

        if(app.extraFiles)
            for (let i = 0; i < app.extraFiles.length ; i++) {
                formData.append('extra_files', app.extraFiles[i]);
            }

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        this.props.updateApplication(
            formData,
            app.application_id,
            this.applicationUpdateCallBack);
    }

    render(){
        const {t} = this.props;
        const digitsText = t("digits");
        let app = this.state.application;
        const requestedDigits = app.requested_digit + " " + digitsText;
        const annualTaxAssessment = app.annual_tax_assessment;
        const commercialCertificate = app.commercial_certificate;
        const commercialCertificateStatus = app.commercial_certificate_status;

        const commitmentLetter = app.commitment_letter;
        const commitmentLetterStatus = app.commitment_letter_status;

        const incomeStatement = app.income_statement;
        const incomeStatementStatus = app.income_statement_status;

        const commercialCertificateReject = app.commercial_certificate_reject;
        const commitmentLetterReject = app.commitment_letter_reject;
        const incomeStatementReject = app.income_statement_reject;
        const operatorNote = this.state.operatorNote;
        const latestApplicationNotes = app.latest_application_note;

        const isTransfer = app.is_transfer;

        const applicationType = app.application_type;

        const isSubmitting = this.state.submitting ? "btn-disabled": "";

        return(
            <div>
                <h2 className={"t-center"}><T>Edit</T></h2>

                <ContentModule containerClass={"content-module-sized"}>
                    <T>Checks regarding your application has been done, to complete your application process please upload missing/wrong documents to the system.</T>
                </ContentModule>


                <div className={"application-edit mt-10"}>
                    <form onSubmit={this.handleOnApplicationUpdate}>
                        <fieldset className="editor small signin">
                            <div>
                                {
                                    applicationType !== 'merge' && !isTransfer &&
                                    <div>
                                        <TextInput key={"requestedDigits"} inputId={"requestedDigits"} required={false} inputTitle={t("Requested Digits")} inputValue={requestedDigits} disabled/>
                                        <TextInput key={"annualTaxAssessment"} inputId={"annualTaxAssessment"} required={false} inputTitle={t("Annual Tax Assessment")} inputValue={annualTaxAssessment} disabled maxLength={22}/>
                                    </div>
                                }
                                {
                                    commercialCertificateStatus === DOC_STATUS_REJECT ?
                                        <FileInput inputId={"commercialCertificateFile"} inputTitle={t("Commercial Certificate")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={commercialCertificateReject}/>
                                        :
                                        <FileLink inputId={"commercialCertificateFile"} inputTitle={t("Commercial Certificate")} inputValue={commercialCertificate}/>
                                }
                                {
                                    commitmentLetterStatus === DOC_STATUS_REJECT ?
                                        <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={commitmentLetterReject}/>
                                        :
                                        <FileLink inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} inputValue={commitmentLetter}/>
                                }
                                {
                                    incomeStatementStatus === DOC_STATUS_REJECT ?
                                        <FileInput inputId={"incomeStatementFile"} inputTitle={t("Income Statement")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={incomeStatementReject}/>
                                        :
                                        <FileLink inputId={"incomeStatementFile"} inputTitle={t("Income Statement")} inputValue={incomeStatement}/>
                                }
                                {
                                    isTransfer ?
                                    <div>
                                        {
                                            this.state.application.petition_status === DOC_STATUS_REJECT ?
                                                <FileInput inputId={"petitionFile"} inputTitle={t("Petition Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={this.state.application.petition_reject}/>
                                                :
                                                <FileLink inputId={"petitionFile"} inputTitle={t("Petition Letter")} inputValue={this.state.application.petition}/>
                                        }
                                        {
                                            this.state.application.transfer_paper_status === DOC_STATUS_REJECT ?
                                                <FileInput inputId={"transferFile"} inputTitle={t("Transfer Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={this.state.application.transfer_paper_reject}/>
                                                :
                                                <FileLink inputId={"transferFile"} inputTitle={t("Transfer Letter")} inputValue={this.state.application.transfer_paper}/>
                                        }
                                        {
                                            this.state.application.transfer_type === 2 ?
                                                <div>
                                                    <div>
                                                        {
                                                            this.state.application.inheritance_document_status === DOC_STATUS_REJECT ?
                                                                <FileInput inputId={"inheritanceFile"} inputTitle={t("Inheritance Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={this.state.application.inheritance_document_reject}/>
                                                                :
                                                                <FileLink inputId={"inheritanceFile"} inputTitle={t("Inheritance Letter")} inputValue={this.state.application.inheritance_document}/>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            this.state.application.consent_document ?
                                                            <div>
                                                                {
                                                                    this.state.application.consent_document_status === DOC_STATUS_REJECT ?
                                                                        <FileInput inputId={"consentFile"} inputTitle={t("Consent Letter")} handleUploadFile={this.handleUploadFile} errorFileClass={"error-file"} dataToolTip={this.state.application.consent_document_reject}/>
                                                                        :
                                                                        <FileLink inputId={"consentFile"} inputTitle={t("Consent Letter")} inputValue={this.state.application.consent_document}/>
                                                                }
                                                            </div>: null
                                                        }
                                                    </div>
                                                </div>: null
                                        }
                                    </div>: null
                                }
                                <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false} />
                                <TextInput key={"operator_note"} inputId={"operator_note"} required={false} inputTitle={t("Operator Notes")} inputValue={operatorNote} isTextArea disabled={true} maxLength={255}/>
                                <TextInput key={"latest_application_note"} inputId={"latest_application_note"} required={false} inputTitle={t("Additional Notes")} inputValue={latestApplicationNotes} handleInputChange={this.handleInputChange} isTextArea maxLength={255}/>
                            </div>
                            <div className="btnInline t-center">
                                <input className={"signup-button " + isSubmitting} type="submit" value={t("Submit")}/>
                                <button className="signup-button ml-5" onClick={this.handleOnWithdrawClick}><T>Withdraw Application</T></button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateApplication: (data, application_id, callback) => {
            dispatch(applicationActions.dbUpdateApplication(data, application_id, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(EditApplication));
