import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import * as dataActions from "../../actions/dataActions";
import {makeGcpTableData} from "../TableUtils";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";

const DEFAULT_PAGE_SIZE = 5;

class GcpList extends Component{

    constructor(props) {
        super(props);
        this.state = {
            gcpTable: {
                data: [],
                pages: null,
                loading: true,
            },
            applicationId: this.props.applicationId ? this.props.applicationId : 0
        };
        this.fetchDataForGcpTable = this.fetchDataForGcpTable.bind(this);
    }

    callbackForGcpTable = (state, result) =>{
        const dataForGcpTable = makeGcpTableData(result.data);
        const totalTableSize = result.total_size;

        if(result.status === "ok"){
            this.setState({
                gcpTable:{
                    ...this.state.gcpTable,
                    data: dataForGcpTable,
                    pages: Math.ceil(totalTableSize / state.pageSize),
                    loading: false
                },
            })
        }
    };

    fetchDataForGcpTable(state, instance){
        this.props.requestDataForGcpTable(this.state.applicationId, state.pageSize, state.page, state.sorted, this.callbackForGcpTable, state);
    }

    render(){
        const gcpTable = this.state.gcpTable;
        const {t} = this.props;

        return(
            <div>
                {
                    !this.props.hideHeader &&
                        <Fragment>
                            <h2 className={"t-center"}><T>My GCP Numbers</T></h2>
                            <ContentModule>
                                <T>GCPs allocated to you during your membership are listed below.</T><span className={"mr-5"}/>
                                <T
                                    message={"Your Master GLN ({{ master_gln }}) is generated from the first GCP allocated to you."}
                                    master_gln={this.props.memberMasterGLN}
                                />
                            </ContentModule>
                        </Fragment>
                }

                <div className={"table-content "}>
                    <ReactTable
                        columns={[
                            {
                                Header: <T>GCP</T>,
                                id: "gcp",
                                accessor: "gcp"
                            },
                            {
                                Header: <T>Digits</T>,
                                accessor: "digits",
                                sortable: false
                            },
                            {
                                Header: <T>Status</T>,
                                accessor: "status"
                            },
                            {
                                Header: <T>Date</T>,
                                accessor: "registry_dt"
                            },
                            {
                                Header: <T>Actions</T>,
                                accessor: "actions",
                                sortable: false
                            },
                        ]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={gcpTable.data}
                        pages={gcpTable.pages}
                        loading={gcpTable.loading}
                        onFetchData={this.fetchDataForGcpTable} // Request new data when things change
                        defaultPageSize={DEFAULT_PAGE_SIZE}
                        className="-striped -highlight"
                        multiSort={false}

                        previousText={<T>Previous</T>}
                        nextText={<T>Next</T>}
                        loadingText={<T>Loading...</T>}
                        noDataText={<T>No rows found</T>}
                        pageText={<T>Page</T>}
                        ofText={<T>of</T>}
                        rowsText={t('rows')}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        requestDataForGcpTable: (applicationId, pagesize, page, sorted, callback, state) => {
            let sortedFieldName="", sortedIsDesc="";
            if (sorted.length !== 0){
                sortedFieldName = sorted[0].id;
                sortedIsDesc = sorted[0].desc ? "desc": "asc";
            }

            dispatch(dataActions.dbRequestDataForGcpTable(applicationId, pagesize, page, sortedFieldName, sortedIsDesc, callback, state));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    const {authorize} = state;
    return{
        memberMasterGLN: authorize.memberMasterGLN,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(GcpList));