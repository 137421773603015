import React, {Component} from 'react'
import Select from 'react-select';
import {T, withTranslators} from "lioness";
import '../../styles/GcpSelectInput.css'
import axios from "axios/index";
import {categoryItemsUrl} from "../../constants/CommonUrlConstants";
import {getHeaderWithToken} from "../../api/services/authToken";

class CategoryItemOption extends Component{
    constructor(props){
        super(props);

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    handleMouseDown (event) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    }
    handleMouseEnter (event) {
        this.props.onFocus(this.props.option, event);
    }
    handleMouseMove (event) {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    }

    render () {
        return (
            <div className={this.props.className}
                 onMouseDown={this.handleMouseDown}
                 onMouseEnter={this.handleMouseEnter}
                 onMouseMove={this.handleMouseMove}
                 title={this.props.option.title}>
                <div>
                    <div>
                        {this.props.children.segment}
                    </div>
                    <div>
                        └─> {this.props.children.family}
                    </div>
                    <div>
                        &nbsp;└─> {this.props.children.class}
                    </div>
                    <div>
                        &nbsp;&nbsp;└─> {this.props.children.brick}
                    </div>
                </div>
            </div>
        );
    }
}

class GcpSelectInput extends Component{
    constructor(props){
        super(props);

        this.state = {
            backspaceRemoves: true,
        };

        this.onChange = this.onChange.bind(this);
        this.getCategories = this.getCategories.bind(this);
    }

    onChange (value) {
        this.props.handleInputChange(this.props.applicationKey, value);
    }

    getCategories (input) {
        if (!input) {
            return Promise.resolve({ options: [] });
        }

        const formData = new FormData();
        formData.append("keyword", input);

        return axios.post(categoryItemsUrl, formData, getHeaderWithToken())
            .then(function (response) {
                return { options: response.data.data };
            })
            .catch(function (error) {
            });
    }

    itemRenderer(value) {
        return (
            <div className={"gcp-selected-item"}>
                <div>{value.children.segment}</div>
                <div>└─> {value.children.family}</div>
                <div>&nbsp;└─> {value.children.class}</div>
                <div>&nbsp;&nbsp;└─> {value.children.brick}</div>
            </div>
        );
    }

    render(){
        const {t, selectedBrick} = this.props;
        const {fromClass} = this.props;

        return(
            <div className="child">
                <div className="child d-flex">
                    <label htmlFor={"gcpBrowserInput"}>
                        <T>Category</T>
                        <span className={"required-field"}>*</span>
                    </label>
                    <div className={"d-flex custom-select " + fromClass}>
                        <Select.Async
                            required
                            value={selectedBrick}
                            onChange={this.onChange}
                            optionComponent={CategoryItemOption}
                            loadOptions={this.getCategories}
                            backspaceRemoves={this.state.backspaceRemoves}
                            searchPromptText={t("type to search...")}
                            valueComponent={this.itemRenderer}
                            filterOptions={(options, filter, currentValues) => {
                                // Do no filtering, just return all options
                                return options;
                            }}
                            ignoreAccents={false}
                            placeholder={t("Select...")}
                            loadingPlaceholder={t("Loading...")}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslators(GcpSelectInput);