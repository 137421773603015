import {siteUrl} from './UrlConstants';

export const vwValidateNewApplication = siteUrl + '/api/vw/validatenewapplication';
export const vwNewApplication = siteUrl + '/api/vw/newapplication';
export const vwLoadApplication = siteUrl + '/api/vw/application';
export const requestDataForVw = siteUrl + '/api/vw/myapplications';
export const requestDataForVwList = siteUrl + '/api/vw/list';
export const requestDataForVwApplicationItemList = siteUrl + '/api/vw/applicationitemlist';
export const vwApplicationEdit = siteUrl + '/api/vw/application/edit';
export const vwApplicationItem = siteUrl + '/api/vw/applicationitem';
export const vwNewApplicationItem = siteUrl + '/api/vw/newapplicationitem';
export const vwDeleteApplicationItem = siteUrl + '/api/vw/deleteapplicationitem';
export const vwEditApplicationItemFromNew = siteUrl + '/api/vw/editapplicationitem';
