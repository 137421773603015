import {
    SET_LOCALE,
    GET_PO,
    GET_TO,
    GET_CITIES,
    LOAD_COUNTY,
    LOAD_SEGMENTS,
    LOAD_FAMILY, LOAD_CLASS, LOAD_BRICK, GET_COUNTRIES, LOAD_MEASUREMENTS
} from "../constants/DataActionType";


const initialState = {
    locale: 'tr',

    isPOloaded: false,
    isTOloaded: false,
    citiesLoaded: false,
    isSegmentsloaded: false,

    countriesLoaded: false,
    countriesData: null,

    measurementsLoaded: false,
    measurementsData: null,

    citiesData: {},
    poData: null,
    toData: null,
    loadedCountiesName: {},
    loadedCountiesData: {},

    segmentsData:{},

    loadedSegmentIdsForFamilies:{},
    segmentFamiliesData:{},

    loadedFamilyIdsForClasses:{},
    familyClassesData:{},

    loadedClassIdsForBricks:{},
    classBricksData:{},
};


export let dataReducer = (state = initialState, action) => {
    const { payload } = action;
    switch(action.type) {
        case SET_LOCALE:
            return {
                ...state,
                locale: payload.locale
            };
        case GET_PO:
            return {
                ...state,
                isPOloaded: payload.isPOloaded,
                poData: payload.poData
            };
        case GET_TO:
            return {
                ...state,
                isTOloaded: payload.isTOloaded,
                toData: payload.toData
            };
        case GET_CITIES:
            return {
                ...state,
                citiesLoaded: payload.citiesLoaded,
                citiesData: payload.citiesData
            };
        case GET_COUNTRIES:
            return {
                ...state,
                countriesLoaded: payload.countriesLoaded,
                countriesData: payload.countriesData
            };
        case LOAD_COUNTY:
            return {
                ...state,
                loadedCountiesName: {
                    ...state.loadedCountiesName,
                    [payload.countyName]: true
                },
                loadedCountiesData: {
                    ...state.loadedCountiesData,
                    [payload.countyName]: payload.countyData
                }
            };
        case LOAD_SEGMENTS:
            return {
                ...state,
                isSegmentsloaded: payload.isSegmentsloaded,
                segmentsData: payload.segmentsData
            };
        case LOAD_FAMILY:
            return {
                ...state,
                loadedSegmentIdsForFamilies: {
                    ...state.loadedSegmentIdsForFamilies,
                    [payload.segmentId]: true
                },
                segmentFamiliesData: {
                    ...state.segmentFamiliesData,
                    [payload.segmentId]: payload.familyData
                }
            };
        case LOAD_CLASS:
            return {
                ...state,
                loadedFamilyIdsForClasses: {
                    ...state.loadedFamilyIdsForClasses,
                    [payload.familyId]: true
                },
                familyClassesData: {
                    ...state.familyClassesData,
                    [payload.familyId]: payload.classData
                }
            };
        case LOAD_BRICK:
            return {
                ...state,
                loadedClassIdsForBricks: {
                    ...state.loadedClassIdsForBricks,
                    [payload.classId]: true
                },
                classBricksData: {
                    ...state.classBricksData,
                    [payload.classId]: payload.brickData
                }
            };
        case LOAD_MEASUREMENTS:
            return {
                ...state,
                measurementsLoaded: payload.measurementsLoaded,
                measurementsData: payload.measurementsData
            };
        default:
            return state
    }
};
