import axios from 'axios';
import {axiosError} from "./errorHandler";
import {
    memberFeeDetailUrl,
    memberServiceFeeDetailUrl,
    memberServiceFeeListUrl,
    memberSubscriptionDetailUrl,
    memberSubscriptionFeeListUrl,
    termsOfUseUrl
} from "../../constants/MemberUrlConstants";
import {getDefaultHeader, getHeaderWithToken} from "./authToken";


export const dbGetTermsOfUse = () => {
    return new Promise(((resolve, reject) => {
        axios.get(termsOfUseUrl, getDefaultHeader())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbGetServiceFeeList = (pagesize, page, sortedFieldName, sortedIsDesc, filterOption) => {
    return new Promise(((resolve, reject) => {
        axios.get(memberServiceFeeListUrl +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc + "&filter_option=" + filterOption,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetServiceFeeDetail = (id) => {
    return new Promise(((resolve, reject) => {
        axios.get(memberServiceFeeDetailUrl + "?id=" + id, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetSubscriptionFeeList = (pagesize, page, sortedFieldName, sortedIsDesc, filterOption) => {
    return new Promise(((resolve, reject) => {
        axios.get(memberSubscriptionFeeListUrl +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc + "&filter_option=" + filterOption,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbGetSubscriptionDetail = (id) => {
    return new Promise(((resolve, reject) => {
        axios.get(memberSubscriptionDetailUrl + "?id=" + id, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetFeeDetail = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(memberFeeDetailUrl, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};