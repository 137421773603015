import * as gtin8Service from "../api/services/gtin8Service";
import {axiosError} from "../api/services/errorHandler";

export const dbValidateGtin8Application = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbValidateGtin8Application(applicationId, callback).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbCreateGtin8Application = (data, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbCreateGtin8Application(data).then((result) => {
                callback(result.request);
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbLoadGtin8Application = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbLoadGtin8Application(applicationId, callback).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbRequestDataForGtin8 = (pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return gtin8Service.dbRequestDataForGtin8(pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                // callback(messageError);
                axiosError(error, callback);
            });
    }
};

export const dbRequestDataForGtin8List = (tableType, pagesize, page, sortedFieldName, sortedIsDesc, callback, state) => {
    return (dispatch, getState) => {
        return gtin8Service.dbRequestDataForGtin8List(tableType, pagesize, page, sortedFieldName, sortedIsDesc).then((result) => {
                callback(state, result.data)
            },
            (error) =>{
                // callback(messageError);
                axiosError(error, callback);
            });
    }
};

export const dbLoadGtin8ApplicationEdit = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbLoadGtin8ApplicationEdit(applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbEditGtin8Application = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbEditGtin8Application(data, applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbLoadGtin8ApplicationItem = (applicationId, callback, edit="") => {
    return (dispatch, getState) => {
        return gtin8Service.dbLoadGtin8ApplicationItem(applicationId, edit).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
            });
    }
};

export const dbEditGtin8ApplicationItem = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbEditGtin8ApplicationItem(data, applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};


export const dbCreateGtin8ApplicationItem = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbCreateGtin8ApplicationItem(data, applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};


export const dbRequestDataForGtin8ApplicationItemList = (applicationId, pagesize, page, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbRequestDataForGtin8ApplicationItemList(applicationId, pagesize, page).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
            });
    }
};

export const dbDeleteGtin8ApplicationItem = (applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbDeleteGtin8ApplicationItem(applicationId).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};


export const dbEditApplicationItemFromNew = (data, applicationId, callback) => {
    return (dispatch, getState) => {
        return gtin8Service.dbEditApplicationItemFromNew(applicationId, data).then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};

