import React, {Component} from 'react'
import headerLogo from '../images/header-logo.png'
import * as authorizeActions from "../actions/authorizeActions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {withRouter} from 'react-router';

import {T} from 'lioness';
import LanguageSwitcher from './LanguageSwitcher';

import Dropdown, {MenuItem} from '@trendmicro/react-dropdown';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt, faUserCircle, faWrench} from '@fortawesome/free-solid-svg-icons';

import '../styles/Header.css';

class MemberHeader extends Component{
    constructor(props){
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(){
        this.props.logout();
    }

    goToRoute(routeName){
        if(routeName !== this.props.location.pathname){
            this.props.history.push(routeName);
        }
    }

    render(){
        const isImpersonateAndHasDebt = this.props.memberType === 2 && this.props.debt && this.props.impersonatedMember;
        const extraMarginClass = isImpersonateAndHasDebt ? "mb-30": "";

        return(
            <header className={extraMarginClass}>
                <div className={"header-content"}>
                    <div className="logo-container">
                        <Link className="logo" to={"/"}>
                            <img src={headerLogo} alt={"Logo"}/>
                        </Link>
                        <p className="title">
                            <strong><T>Welcome to GS1 Turkey Membership System</T></strong><br/>
                            <T>The Global Language of Business</T>
                        </p>
                    </div>
                    <div className={"menu-container"}>
                        <LanguageSwitcher />
                        <div className={"menu-item"}>
                            <Dropdown>
                                <Dropdown.Toggle btnStyle="flat">
                                    {this.props.memberName}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={"profile-menu"}>
                                    {
                                        (this.props.memberType === 0 || this.props.memberType === 1 || this.props.memberType === 3) &&
                                            <MenuItem onSelect={()=>{this.goToRoute("/candidate/profile")}}>
                                                <FontAwesomeIcon icon={faUserCircle} /> <T>Profile</T>
                                            </MenuItem>
                                    }
                                    {
                                        this.props.memberType === 2 && !this.props.debt &&
                                        <React.Fragment>
                                            <MenuItem onSelect={()=>{this.goToRoute("/member/profile")}}>
                                                <FontAwesomeIcon icon={faUserCircle} /> <T>Profile</T>
                                            </MenuItem>
                                            <MenuItem onSelect={()=>{this.goToRoute("/member/settings")}}>
                                                <FontAwesomeIcon icon={faWrench} /> <T>Settings</T>
                                            </MenuItem>
                                            <MenuItem divider />
                                        </React.Fragment>
                                    }
                                    <MenuItem onSelect={()=>{this.props.logout()}}>
                                        <FontAwesomeIcon icon={faSignOutAlt} /> <T>Logout</T>
                                    </MenuItem>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {
                    this.props.impersonatedMember &&
                    <div className={"t-center imp"}>
                        <T>Impersonating member</T>: <span className={"ml-5 text"}>{this.props.memberTitle}</span>
                    </div>
                }
            </header>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => {
            dispatch(authorizeActions.dbLogout())
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    const {authorize, data} = state;
    return{
        impersonatedMember: authorize.impersonatedMember,
        memberTitle: authorize.memberTitle,
        memberName: authorize.memberName,
        memberType: authorize.memberType,
        locale: data.locale,
        debt: authorize.debt,
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberHeader));
