export const SET_LOCALE = 'SET_LOCALE';
export const GET_PO = 'GET_PO';
export const GET_TO = 'GET_TO';
export const GET_CITIES = 'GET_CITIES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const LOAD_COUNTY = 'LOAD_COUNTY';
export const GET_NACECODES = 'GET_NACECODES';
export const LOAD_SEGMENTS = 'LOAD_SEGMENTS';
export const LOAD_FAMILY = 'LOAD_FAMILY';
export const LOAD_CLASS = 'LOAD_CLASS';
export const LOAD_BRICK = 'LOAD_BRICK';
export const LOAD_MEASUREMENTS = 'LOAD_MEASUREMENTS';
