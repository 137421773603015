import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux'
import ConnectedRouter from "react-router-redux/es/ConnectedRouter";
import store, {history} from "./store/configureStore";
import MasterComponent from "./components/MasterComponent";

require("babel-polyfill");

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <MasterComponent/>
        </ConnectedRouter>
    </Provider>
, document.getElementById('root'));


registerServiceWorker();
