import React, {Component} from 'react'
import {Route, Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Pay from "../components/payment/Pay";
import ViewProfile from "../components/ViewProfile";
import ApplicationHandler from "../components/membership/ApplicationHandler";

class CandidateRouter extends Component{

    render(){
        return(
            <Switch>
                <PrivateRoute exact path='/candidate/pay/servicefee/:id' component={<Pay serviceType={"service_fee"}/>} />
                <PrivateRoute exact path='/candidate/profile/' component={<ViewProfile isCandidateView/>} />

                <Route path="/" component={ApplicationHandler}/>
            </Switch>
        )
    }
}

export default CandidateRouter;