import React, {Component} from 'react'
import '../../styles/Master.css'
import '../../styles/Dashboard.css'
import Select from 'react-select';
import {connect} from "react-redux";
import {commitmentLetterDownloadUrl} from "../../constants/GcpUrlConstants";
import * as applicationActions from "../../actions/applicationActions";
import TextInput from "../input_types/TextInput";
import FileInput from "../input_types/FileInput";
import {RadioButton, RadioGroup} from "react-radio-buttons";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import {isFileSizeExceeded, showFileError} from "../FileUtils";

class NewApplication extends Component{

    constructor(props){
        super(props);
        this.state = {
            currentStep: 1,
            applicationType: "normal",
            transferType: "normal",

            applicationStatus: null,
            applicationId: null,

            requestedDigits: null,
            annualTaxAssessment: '',
            annualTaxAssessmentPrefix: '0',
            annualTaxAssessmentSuffix: '00',

            latestApplicationNote: '',

            commercialCertificateFile: null,
            commitmentLetterFile: null,
            incomeStatementFile: null,

            petitionFile: null,
            transferFile: null,
            inheritanceFile: null,
            consentFile: null,

            extraFiles: null,
            submitting: false,

            registerDate: "",
            registerNumber: "",
            registerPage: "",

            mergeFile: null,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeApplicationType = this.onChangeApplicationType.bind(this);
        this.onChangeTransferType = this.onChangeTransferType.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    onChangeApplicationType(value) {
        if (value === "normal")
            this.setState({
                applicationType: value,
                transferType: "normal"
            });
        else
            this.setState({
                applicationType: value,
            })
    }

    onChangeTransferType(value) {
        this.setState({
            transferType: value
        })
    }

    handleDigitChange = (requestedDigits) => {
        this.setState({ requestedDigits });
    };

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            [event.target.id]: submitFile,
        });

    };

    newApplicationCallBack = (result) => {
        this.setState({
            submitting: false
        });

        if (result.status === "ok")
            this.props.getStatus();
    };

    handlePrevious(){
        if(this.state.currentStep === 2){
            this.setState({
                currentStep: 1
            })
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        if(this.state.currentStep === 1){
            this.setState({
                currentStep: 2
            });
            return;
        }

        const parsedAnnualTaxAssessmentPrefix = this.state.annualTaxAssessmentPrefix.replace(/\D+/g, '');

        let annualTaxAssessment = parsedAnnualTaxAssessmentPrefix + "." + this.state.annualTaxAssessmentSuffix;
        let extraFiles= this.state.extraFiles;

        const formData = new FormData();
        formData.append('commercial_certificate', this.state.commercialCertificateFile);
        formData.append('commitment_letter', this.state.commitmentLetterFile);
        if (this.state.applicationType !== "merge")
            formData.append('income_statement', this.state.incomeStatementFile);
        formData.append('latest_application_note', this.state.latestApplicationNote);
        formData.append('annual_tax_assessment', annualTaxAssessment);

        formData.append('application_type', this.state.applicationType);
        formData.append('transfer_type', this.state.transferType);

        let parseRegisterDate = "";
        if (this.state.registerDate)
            parseRegisterDate = this.state.registerDate.substring(6, 10) + "-" + this.state.registerDate.substring(3, 5) + "-" + this.state.registerDate.substring(0, 2);

        formData.append('registry_gazette_date', parseRegisterDate);
        formData.append('registry_gazette_number', this.state.registerNumber ? this.state.registerNumber :'');
        formData.append('registry_gazette_page', this.state.registerPage ? this.state.registerPage : '');

        formData.append('merge_file', this.state.mergeFile);

        if (this.state.applicationType === "transfer"){
            formData.append('petition_file', this.state.petitionFile);
            formData.append('transfer_file', this.state.transferFile);

            if (this.state.transferType === "death"){
                formData.append('inheritance_file', this.state.inheritanceFile);
                if (this.state.consentFile)
                    formData.append('consent_file', this.state.consentFile);
            }
        }
        else
            formData.append('requested_digit', this.state.requestedDigits ? this.state.requestedDigits.value : null);

        if(extraFiles)
            for (let i = 0; i < extraFiles.length ; i++) {
                formData.append('extra_files', extraFiles[i]);
            }

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        if(this.state.applicationType === 'merge'){
            this.props.submitMergeApplication(
                formData,
                this.newApplicationCallBack
            );
        } else {
            this.props.submitApplication(
                formData,
                this.newApplicationCallBack
            );
        }
    }

    render(){
        const {t} = this.props;
        const productText = t("Type of Products");
        const digits = t("digits");

        const selectDigitOptions = [
            { value: 7, label: '7 ' + digits + ' (100.000 ' + productText + ')' },
            { value: 8, label: '8 ' + digits + ' (10.000 ' + productText + ')' },
            { value: 9, label: '9 ' + digits + ' (1.000 ' + productText + ')' },
            { value: 10, label: '10 ' + digits + ' (100 ' + productText + ')' },
            { value: 11, label: '11 ' + digits + ' (10 ' + productText + ')' },
            { value: 12, label: '12 ' + digits + ' (1 ' + productText + ')' },
        ];
        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const previousClass = this.state.currentStep === 1 ? "btn-disabled" : "";
        const valueOfButton = this.state.currentStep === 1 ? t("Next"): t("Submit");


        return(
            <div>
                <h2 className={"t-center"}><T>New Application</T></h2>

                <ContentModule containerClass={"content-module-sized"}>
                    <T>In order to a become GS1 Turkey System member please check “Membership Application”. To start your membership with transferring from another GS1 system member (you will need to supply transfer agreement in the following stages) check "Transfer Application". If your company is merging with an existing GS1 system meber please check "Merge Application" (you will be supplyin corresponding trade registry gazette information).</T><br/>
                    <T>In case your firm has founded this year and if you do not have you income statement, you may supply your Trade Registry Gazette which contains your foundation advert.</T>
                </ContentModule>

                <div className={"new-application"}>
                    <form onSubmit={this.handleSubmit}>
                        {
                        this.state.currentStep === 1 ?
                            <div>
                                <h4><T>What kind of application would you like to do?</T></h4>
                                <RadioGroup onChange={ this.onChangeApplicationType } value={this.state.applicationType} horizontal>
                                    <RadioButton value="normal">
                                        <T>Membership Application</T>
                                    </RadioButton>
                                    <RadioButton value="transfer">
                                        <T>Transfer Application</T>
                                    </RadioButton>
                                    <RadioButton value="merge">
                                        <T>Merge Application</T>
                                    </RadioButton>
                                </RadioGroup>
                                {
                                    this.state.applicationType === "transfer" &&
                                    <div>
                                        <h4><T>Is the trasfer due to death of the current member?</T></h4>
                                        <RadioGroup onChange={ this.onChangeTransferType } value={this.state.transferType} horizontal>
                                            <RadioButton value="normal">
                                                <T>No</T>
                                            </RadioButton>
                                            <RadioButton value="death">
                                                <T>Yes</T>
                                            </RadioButton>
                                        </RadioGroup>
                                    </div>
                                }
                            </div>:
                            <ContentModule containerClass={"mt-20"}>
                            <div className="editor small signin mt-10">
                                <div>
                                    {
                                        this.state.applicationType === "normal" &&
                                        <div className="child d-flex">
                                            <div className="child d-flex">
                                                <label htmlFor={"requestedDigits"}>
                                                    <T>Requested Digit</T> <span className={"required-field"}>*</span>
                                                </label>
                                                <div className={"d-flex"}>
                                                    <Select
                                                        required
                                                        value={this.state.requestedDigits}
                                                        onChange={this.handleDigitChange}
                                                        options={selectDigitOptions}
                                                        placeholder={t("Select...")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.applicationType === "merge" &&
                                            <div>
                                            <TextInput key={"registerDate"} inputId={"registerDate"}
                                                       inputTitle={t("Registry Gazette Date (dd/mm/yyyy)")} isDate
                                                       inputValue={this.state.registerDate}
                                                       handleInputChange={this.handleInputChange} required
                                                       />
                                            <TextInput key={"registerNumber"} inputId={"registerNumber"} inputTitle={t("Registry Gazette Number")} maxLength={20} inputValue={this.state.registerNumber} handleInputChange={this.handleInputChange}/>
                                            <TextInput key={"registerPage"} inputId={"registerPage"} inputTitle={t("Registry Gazette Page")} inputType={"number"} maxLength={20} inputValue={this.state.registerPage} handleInputChange={this.handleInputChange}/>
                                            <FileInput inputId={"mergeFile"} inputTitle={t("Merge File")} handleUploadFile={this.handleUploadFile} />
                                            </div>
                                    }
                                    <div className={"mt-10"}>
                                        <T
                                            message={"Please first download the {{ link }}"}
                                            link={<a href={commitmentLetterDownloadUrl} target={"_blank"}><T>Commitment Letter</T></a>}
                                        />
                                    </div>
                                    <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile}/>
                                    <FileInput inputId={"commercialCertificateFile"} inputTitle={t("Commercial Certificate")} handleUploadFile={this.handleUploadFile}/>
                                    {
                                        this.state.applicationType !== "merge" &&
                                            <div>
                                                <FileInput inputId={"incomeStatementFile"} inputTitle={t("Income Statement")} handleUploadFile={this.handleUploadFile} />
                                                <TextInput key={"annualTaxAssessment"} inputId={"annualTaxAssessmentPrefix"} inputId2={"annualTaxAssessmentSuffix"} inputTitle={t("Annual Tax Assessment")} inputValue={this.state.annualTaxAssessmentPrefix} inputValue2={this.state.annualTaxAssessmentSuffix} handleInputChange={this.handleInputChange} isATS />
                                            </div>
                                    }
                                    {
                                        this.state.applicationType === "transfer" &&
                                            <div>
                                                <FileInput inputId={"petitionFile"} inputTitle={t("Petition Letter")} handleUploadFile={this.handleUploadFile}/>
                                                <FileInput inputId={"transferFile"} inputTitle={t("Transfer Letter")} handleUploadFile={this.handleUploadFile} />
                                            </div>
                                    }
                                    {
                                        (this.state.applicationType === "transfer" && this.state.transferType === "death") ?
                                            <div>
                                                <FileInput inputId={"inheritanceFile"} inputTitle={t("Inheritance Letter")} handleUploadFile={this.handleUploadFile}/>
                                                <FileInput inputId={"consentFile"} inputTitle={t("Consent Letter")} handleUploadFile={this.handleUploadFile} required={false}/>
                                            </div>: null

                                    }
                                    <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false} />
                                    <TextInput key={"latestApplicationNote"} inputId={"latestApplicationNote"} required={false} inputTitle={t("Additional Notes")} inputValue={this.state.latestApplicationNote} handleInputChange={this.handleInputChange} isTextArea maxLength={255}/>
                                </div>
                            </div>
                            </ContentModule>
                        }
                        <div className="btnInline mt-10 gtn8-new-app d-flex">
                            <span className={"signup-button small " + previousClass} onClick={this.handlePrevious}><T>Previous</T></span>
                            <input className={"signup-button small" + isSubmitting} type="submit" value={valueOfButton}/>
                        </div>
                        </form>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        submitApplication: (data, callback) => {
            dispatch(applicationActions.dbSubmitApplication(data, callback));
        },
        submitMergeApplication: (data, callback) => {
            dispatch(applicationActions.dbSubmitMergeMembershipApplication(data, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(NewApplication));
