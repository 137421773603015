import React, {Component} from 'react'


class GcpSelectDetail extends Component{

    render(){
        const {inputId, inputTitle, required, segment, family, _class, brick} = this.props;
        const _required = required !== false;

        return(
            <div className="child d-flex">
                <div className="child d-flex">
                    <label htmlFor={inputId}>
                        {inputTitle}
                        {
                            _required ? <span className={"required-field"}>*</span> :''
                        }
                    </label>
                    <div className={"d-flex"}>
                        <div className={"brick-input"}>
                            <div>
                                {
                                    segment
                                }
                            </div>
                            <div>
                                └─>
                                {
                                    family
                                }
                            </div>
                            <div>
                                &nbsp;└─>
                                {
                                    _class
                                }
                            </div>
                            <div>
                                &nbsp;&nbsp;└─>
                                {
                                    brick
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GcpSelectDetail;