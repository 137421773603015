import React, {Component} from 'react'
import Select from 'react-select';
import {T, withTranslators} from "lioness";
import '../../styles/GcpSelectInput.css'
import axios from "axios/index";
import {naceCodesUrl} from "../../constants/GcpUrlConstants";
import ReactTooltip from "react-tooltip";
import {findDOMNode} from "react-dom";


class NaceCodeSelectInput extends Component{
    constructor(props){
        super(props);

        this.state = {
            backspaceRemoves: true,
        };

        this.onChange = this.onChange.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleFocus(event){
        event.preventDefault();
        ReactTooltip.show(findDOMNode(this.refs.tooltip));
    }

    onChange (value) {
        this.props.handleInputChange(this.props.applicationKey, value);
    }

    getCategories (input) {
        if (!input) {
            return Promise.resolve({ options: [] });
        }

        const formData = new FormData();
        formData.append("keyword", input);

        return axios.post(naceCodesUrl, formData)
            .then(function (response) {
                return { options: response.data.data };
            })
            .catch(function (error) {
            });
    }

    render(){
        const {t, selectedNace, dataToolTip} = this.props;

        return(
            <div className="child">
                <div className="child d-flex">
                    <label htmlFor={"gcpBrowserInput"}>
                        <T>NACE Code</T>
                        <span className={"required-field"}>*</span>
                    </label>
                    <div className={"d-flex custom-select nace-select"}>
                        <Select.Async
                            required
                            value={selectedNace}
                            onChange={this.onChange}
                            onFocus={this.handleFocus}
                            loadOptions={this.getCategories}
                            backspaceRemoves={this.state.backspaceRemoves}
                            searchPromptText={t("type to search...")}
                            filterOptions={(options, filter, currentValues) => {
                                // Do no filtering, just return all options
                                return options;
                            }}
                            ignoreAccents={false}
                            placeholder={t("Select...")}
                            loadingPlaceholder={t("Loading...")}
                        />
                        {
                            dataToolTip
                                ?
                                <span>
                                    <i className={"tooltip-icon"} ref="tooltip" data-tip={dataToolTip} data-delay-show='500'>?</i>
                                </span>
                                : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslators(NaceCodeSelectInput);