import React, {Component, Fragment} from 'react'
import FileInput from "../input_types/FileInput";
import '../../styles/Gtin8.css'
import TextInput from "../input_types/TextInput";
import {connect} from "react-redux";
import * as vwActions from "../../actions/vwActions";
import {T, withTranslators} from "lioness";
import ReactTable from "react-table";
import ReactModal from "react-modal";
import '../../styles/Modal.css'
import {makeApplicationItemTableData} from "../TableUtils";
import {commitmentLetterDownloadUrl} from "../../constants/GcpUrlConstants";
import swal from 'sweetalert'
import ContentModule from "../ContentModule";
import {faEdit, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {Redirect} from "react-router-dom";
import Iframe from "../Iframe";
import * as dataActions from "../../actions/dataActions";
import {isFileSizeExceeded, showFileError} from "../FileUtils";

const DEFAULT_PAGE_SIZE = 5;

class NewApplication extends Component{

    constructor(props){
        super(props);

        this.state = {
            table: {
                data: [],
                pages: null,
                loading: false,
                pageSize: DEFAULT_PAGE_SIZE,
                pageNumber: 0
            },

            showModal: false,
            showEditModal: false,
            showDeleteModal: false,

            isCommitmentLetterValid: this.props.isCommitmentLetterValid,
            commitmentLetterFile: null,

            isNumberSubmitting: false,
            isNumberEditSubmitting: false,
            isNumberDeletionSubmitting: false,

            extraFiles: null,
            applicationId: null,
            applicationItemToBeDeleted: null,
            applicationItemToBeEdited: null,

            currentApplicationItem: {
                brand: "",
                labelDescription: "",
            },
            editApplicationItem: {
                brand: "",
                labelDescription: "",
            },

            numberOfApplicationItems: 0,
            itemPrice: 0,

            applicationCompleted: false
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.fetchData = this.fetchData.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.submitRemoveItem = this.submitRemoveItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.handleSubmitApplication = this.handleSubmitApplication.bind(this);
        this.handleSubmitApplicationItem = this.handleSubmitApplicationItem.bind(this);
        this.handleSubmitEditApplicationItem = this.handleSubmitEditApplicationItem.bind(this);
    }

    componentWillMount(){
        let {applicationId, itemPrice} = this.props;
        if (!applicationId)
            applicationId = null;

        this.setState({
            applicationId: applicationId,
            itemPrice: itemPrice
        });

        if (!this.props.citiesLoaded)
            this.props.getCountryData();
    }

    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false, showDeleteModal: false, showEditModal: false });
    }

    handleInputChange(event, parentKey) {
        if (parentKey === "edit")
            this.setState({
                editApplicationItem: {
                    ...this.state.editApplicationItem,
                    [event.target.id]: event.target.value
                }
            });
        else
            this.setState({
                currentApplicationItem: {
                    ...this.state.currentApplicationItem,
                    [event.target.id]: event.target.value
                }
            });
    }

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            [event.target.id]: submitFile,
        });

    };

    submitApplicationItemFormCallback = (result) => {
        if (result.status === 200){
            this.setState({
                applicationId: result.data.application_id,
                isNumberSubmitting: false,
                showModal: false,

                currentApplicationItem: {
                    brand: "",
                    labelDescription: "",
                },

                table: {
                    ...this.state.table,
                    loading: true
                }
            });

            this.props.requestDataForVwApplicationItemList(result.data.application_id, this.state.table.pageSize, 0, this.loadDataCallback);
        }
        else {
            this.setState({
                isNumberSubmitting: false
            })
        }
    };

    submitApplicationFormCallback = (result) => {
        if (result.status === 200){
            this.setState({
                submitting: false,
                applicationCompleted: true
            })
        }
        else {
            this.setState({
                submitting: false
            })
        }
    };

    loadDataCallback = (result) => {
        const tableData = makeApplicationItemTableData(result.data.data);
        const totalTableSize = result.data.total_size;
        let pageSize = this.state.table.pageSize;

        if(result.status === 200){
            this.setState({
                table:{
                    ...this.state.table,
                    data: tableData,
                    pages: Math.ceil(totalTableSize / pageSize),
                    loading: false
                },
                numberOfApplicationItems: totalTableSize
            })
        }
    };

    deleteApplicationItemCallback = (result) => {
        if (result.status === 200){
            this.setState({
                isNumberDeletionSubmitting: false,
                showDeleteModal: false
            });

            this.props.requestDataForVwApplicationItemList(this.state.applicationId, this.state.table.pageSize, 0, this.loadDataCallback);
        }
    };

    submitEditApplicationItemFormCallback = (result) => {
        if (result.status === 200){
            this.setState({
                isNumberEditSubmitting: false,
                showEditModal: false,
                table: {
                    ...this.state.table,
                    loading: true
                }
            });

            this.props.requestDataForVwApplicationItemList(this.state.applicationId, this.state.table.pageSize, this.state.table.pageNumber, this.loadDataCallback);
        }
        else {
            this.setState({
                isNumberEditSubmitting: false
            })
        }
    };

    fetchData(state, instance) {
        this.setState({
            table: {
                ...this.state.table,
                pageNumber: state.page,
                pageSize: state.pageSize,
                loading: true
            }
        });

        this.props.requestDataForVwApplicationItemList(this.state.applicationId, state.pageSize, state.page, this.loadDataCallback);
    }

    removeItem(value){
        this.setState({
            showDeleteModal: true,
            applicationItemToBeDeleted: value
        });
    }

    submitRemoveItem(){
        this.setState({
            isNumberDeletionSubmitting: true
        });

        this.props.submitDeleteApplicationItem(this.state.applicationItemToBeDeleted, this.deleteApplicationItemCallback)
    }

    editItem(value){
        const data = this.state.table.data;
        let indexItem = -1;

        data.map((item, index) => {
            if (item.id === value)
                indexItem = index;
            return item;
        });

        const item = this.state.table.data[indexItem];

        this.setState({
            showEditModal: true,
            applicationItemToBeEdited: value,
            editApplicationItem: {
                brand: item.brand,
                labelDescription: item.label_description,
            }
        });
    }

    handleSubmitApplicationItem(event) {
        event.preventDefault();

        if (!this.state.isNumberSubmitting){
            this.setState({
                isNumberSubmitting: true
            })
        }

        const formData = new FormData();
        formData.append('brand', this.state.currentApplicationItem.brand);
        formData.append('label_description', this.state.currentApplicationItem.labelDescription);

        this.props.submitApplicationItemForm(formData, this.state.applicationId, this.submitApplicationItemFormCallback)
    }

    handleSubmitEditApplicationItem(event) {
        event.preventDefault();

        if (!this.state.isNumberEditSubmitting){
            this.setState({
                isNumberEditSubmitting: true
            })
        }

        const formData = new FormData();
        formData.append('brand', this.state.editApplicationItem.brand);
        formData.append('label_description', this.state.editApplicationItem.labelDescription);

        this.props.submitEditApplicationItem(formData, this.state.applicationItemToBeEdited, this.submitEditApplicationItemFormCallback)
    }

    handleSubmitApplication(event) {
        event.preventDefault();

        if (!this.state.table.data.length) {
            const {t} = this.props;

            swal({
                title: t('Error'),
                text: t('You need to get at least one number!'),
                icon: 'error'
            });
            return;
        }

        const formData = new FormData();
        formData.append('application_id', this.state.applicationId);


        let extraFiles= this.state.extraFiles;
        if(extraFiles) {
            for (let i = 0; i < extraFiles.length; i++) {
                formData.append('extra_files', extraFiles[i]);
            }
        }

        if (this.state.commitmentLetterFile !== null)
            formData.append('commitment_letter', this.state.commitmentLetterFile);

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.isNumberSubmitting){
            this.setState({
                isNumberSubmitting: true
            })
        }

        this.props.submitApplicationForm(formData, this.submitApplicationFormCallback)
    }

    render(){
        const table = this.state.table;
        const {t} = this.props;
        const {currentApplicationItem, editApplicationItem} = this.state;

        const isNumberSubmitting = this.state.isNumberSubmitting ? "btn-disabled": "";
        const isNumberDeletionSubmitting = this.state.isNumberDeletionSubmitting ? "btn-disabled": "";
        const isNumberEditSubmitting = this.state.isNumberEditSubmitting ? "btn-disabled": "";

        return(
            <div className={"new-application"}>
                {
                    !this.state.applicationCompleted ?
                        <Fragment>
                            <Iframe
                                source={"VW_new_application/h5.html"}
                            />

                            <div className={"table-content mt-10"}>
                                <div className="mt-10 mb-20 gtn8-new-app t-center">
                                    <span className={"signup-button small mr-5 add-number"} onClick={this.handleOpenModal}>
                                        <FontAwesomeIcon icon={faPlus} className={"mr-5"}/>
                                        <T>Add number</T>
                                    </span>
                                </div>
                                <ReactTable
                                    columns={[
                                        {
                                            Header: <T>Brand</T>,
                                            accessor: "brand",
                                            sortable: false
                                        },
                                        {
                                            Header: <T>Label Description</T>,
                                            accessor: "label_description",
                                            sortable: false
                                        },
                                        {
                                            Header: <T>Actions</T>,
                                            accessor: "id",
                                            Cell: ({value}) => (
                                                <span>
                                                <span onClick={() => this.editItem(value)}><FontAwesomeIcon icon={faEdit} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/></span>
                                                <span onClick={() => this.removeItem(value)}><FontAwesomeIcon icon={faTrashAlt} size={"lg"} color={"red"} className={"curpnt ml-10"}/></span>
                                            </span>),
                                            sortable: false
                                        },
                                    ]}
                                    manual
                                    data={table.data}
                                    pages={table.pages}
                                    loading={table.loading}
                                    onFetchData={this.fetchData}
                                    defaultPageSize={DEFAULT_PAGE_SIZE}
                                    className="-striped -highlight"
                                    multiSort={false}

                                    previousText={<T>Previous</T>}
                                    nextText={<T>Next</T>}
                                    loadingText={<T>Loading...</T>}
                                    noDataText={<T>No rows found</T>}
                                    pageText={<T>Page</T>}
                                    ofText={<T>of</T>}
                                    rowsText={t('rows')}
                                />
                            </div>

                            <ReactModal
                                isOpen={this.state.showModal}
                                ariaHideApp={false}
                                className="Modal"
                                overlayClassName="Overlay"
                            >
                                <ContentModule moduleClass={"no-padding"}>
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="modal-title"><T>New Varying Weight Number</T></h2>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={this.handleSubmitApplicationItem}>
                                                <fieldset className="editor small signin no-border">
                                                    <TextInput key={"brand"} inputId={"brand"} inputTitle={t("Brand")} inputValue={currentApplicationItem.brand} maxLength={64} handleInputChange={this.handleInputChange}/>
                                                    <TextInput key={"labelDescription"} isTextArea inputId={"labelDescription"} inputTitle={t("Label Description")} inputValue={currentApplicationItem.labelDescription} maxLength={500} handleInputChange={this.handleInputChange}/>
                                                </fieldset>
                                                <div className="modal-footer">
                                                    <button type="button" className={"btn-modal btn-modal-close mr-10 " + isNumberSubmitting} onClick={this.handleCloseModal}><T>Close</T></button>
                                                    <input className={"btn-modal btn-modal-submit " + isNumberSubmitting} type={"submit"} value={t("Submit")}/>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </ContentModule>
                            </ReactModal>

                            <ReactModal
                                isOpen={this.state.showDeleteModal}
                                ariaHideApp={false}
                                className="Modal"
                                overlayClassName="Overlay"
                            >
                                <ContentModule moduleClass={"no-padding"}>
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="modal-title"><T>Delete Varying Weight Number</T></h2>
                                        </div>
                                        <div className="modal-body">
                                            <T>Are you sure you want to delete this number?</T>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className={"btn-modal btn-modal-close mr-10 " + isNumberDeletionSubmitting} onClick={this.handleCloseModal}><T>No</T></button>
                                            <button className={"btn-modal btn-modal-submit " + isNumberDeletionSubmitting} onClick={this.submitRemoveItem}><T>Yes</T></button>
                                        </div>
                                    </div>
                                </ContentModule>
                            </ReactModal>

                            <ReactModal
                                isOpen={this.state.showEditModal}
                                ariaHideApp={false}
                                className="Modal"
                                overlayClassName="Overlay"
                            >
                                <ContentModule moduleClass={"no-padding"}>
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="modal-title"><T>Update Varying Weight Number Data</T></h2>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={this.handleSubmitEditApplicationItem}>
                                                <fieldset className="editor small signin no-border">
                                                    <TextInput parentKey={"edit"} key={"brand"} inputId={"brand"} inputTitle={t("Brand")} inputValue={editApplicationItem.brand} maxLength={64} handleInputChange={this.handleInputChange}/>
                                                    <TextInput parentKey={"edit"} key={"labelDescription"} inputId={"labelDescription"} inputTitle={t("Label Description")} inputValue={editApplicationItem.labelDescription} maxLength={64} handleInputChange={this.handleInputChange}/>
                                                </fieldset>
                                                <div className="modal-footer">
                                                    <button type="button" className={"btn-modal btn-modal-close mr-10 " + isNumberEditSubmitting } onClick={this.handleCloseModal}><T>Close</T></button>
                                                    <input className={"btn-modal btn-modal-submit " + isNumberEditSubmitting } type={"submit"} value={t("Submit")}/>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </ContentModule>
                            </ReactModal>

                            <ContentModule containerClass={"mt-20"}>
                                <form onSubmit={this.handleSubmitApplication} className="editor small signin">

                                    {
                                        this.state.isCommitmentLetterValid !== null && !this.state.isCommitmentLetterValid &&
                                        <div>
                                            <T
                                                message={"Please first download the {{ link }}"}
                                                link={<a href={commitmentLetterDownloadUrl} target={"_blank"}><T>Commitment Letter</T></a>}
                                            />
                                            <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile}/>
                                        </div>
                                    }
                                    <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false}  />

                                    <p><b><T>Money to be paid</T>: {this.state.itemPrice * this.state.numberOfApplicationItems} ₺</b></p>

                                    <div className="btnInline mt-10 gtn8-new-app t-center">
                                        <input className="signup-button small ml-5" type={"submit"} value={t("Apply")}/>
                                    </div>
                                </form>
                            </ContentModule>
                        </Fragment>:

                        <Redirect to={"/member/to/vw"}/>

                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        submitApplicationItemForm: (data, applicationId, callBack) => {
            dispatch(vwActions.dbCreateVwApplicationItem(data, applicationId, callBack))
        },
        submitDeleteApplicationItem: (applicationId, callBack) => {
            dispatch(vwActions.dbDeleteVwApplicationItem(applicationId, callBack))
        },
        submitEditApplicationItem: (data, applicationId, callBack) => {
            dispatch(vwActions.dbEditApplicationItemFromNew(data, applicationId, callBack))
        },
        submitApplicationForm: (data, callBack) => {
            dispatch(vwActions.dbCreateVwApplication(data, callBack))
        },
        requestDataForVwApplicationItemList: (applicationId, pagesize, page, callback) => {
            dispatch(vwActions.dbRequestDataForVwApplicationItemList(applicationId, pagesize, page, callback));
        },
        getCountryData: () => {
            dispatch(dataActions.dbGetCountryData());
        },
    }
};

const mapStateToProps = (state) => {
    const { data } = state;
    return {
        countriesLoaded: data.countriesLoaded,
        countriesData: data.countriesData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(NewApplication));