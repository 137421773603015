import React, {Component} from 'react'
import {T, withTranslators} from "lioness";
import {connect} from "react-redux";
import * as applicationAction from "../../../actions/applicationActions";
import MergeNew from "./MergeNew";
import MergeView from "./MergeView";

class MergeHandler extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            applicationId: 0
        }
    }

    applicationCallback = (result) => {
        if (!result.data.has_application)
            this.setState({
                currentStep: 2
            });
        else {
            const status = result.data.status;
            // if application status is in online docs(status=2) render edit(4) otherwise render view(3)
            const currentStep = status === 2 ? 4: 3;

            this.setState({
                currentStep: currentStep,
                applicationId: result.data.application_id
            })
        }
    };

    componentWillMount(){
        this.props.getExistingMergeApplication(this.applicationCallback);
    };

    getComponent(){
        const {currentStep} = this.state;

        if (currentStep === 1)
            return  <div className={"t-center mt-20"}>
                        <T>Loading...</T>
                    </div>;
        else if (currentStep === 2)
            return  <div className={"t-center mt-20"}>
                       <MergeNew/>
                    </div>;
        else if (currentStep === 3)
            return  <div className={"t-center mt-20"}>
                       <MergeView applicationId={this.state.applicationId}/>
                    </div>;
    }

    render(){
        const component = this.getComponent();

        return(
            <div>
                {
                    component
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getExistingMergeApplication: (callback) =>{
            dispatch(applicationAction.dbGetMergeApplication(callback))
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(MergeHandler));