import React, {Component} from 'react'
import Login from "../components/auth/Login";
import PublicRoute from "./PublicRoute";
import SignUp from "../components/auth/SignUp";
import ActivateReset from "../components/ActivateReset";
import {Route, Switch} from "react-router-dom";
import ForgotPassword from "../components/auth/ForgotPassword";
import MessageController from "../components/MessageController";
import AuthedRoute from "./AuthedRoute";
import Logout from "../components/auth/Logout";
import PassiveUser from "../components/PassiveUser";
import TermsOfUse from "../components/TermsOfUse";
import Home from "../components/Home";
import ImpersonateLogin from "../components/auth/ImpersonateLogin";

class MasterRouter extends Component{

    render(){
        return(
            <Switch>
                <PublicRoute exact path="/to/:linkId/:email?" component={<MessageController/>} />
                <AuthedRoute exact path="/forgot-password/" component={<ForgotPassword/>} />
                <AuthedRoute exact path="/impersonate/:memberId" component={<ImpersonateLogin/>} />
                <AuthedRoute exact path="/login/" component={<Login/>} />
                <PublicRoute exact path="/logout/" component={<Logout/>} />
                <AuthedRoute exact path="/signup/" component={<SignUp/>} />
                <PublicRoute exact path="/email/:emailType/:tokenId" component={<ActivateReset/>} />
                <PublicRoute exact path="/passiveuser" component={<PassiveUser/>} />
                <PublicRoute exact path="/terms-of-use" component={<TermsOfUse/>} fullwidth/>

                <Route path="/" component={Home}/>
            </Switch>
        )
    }
}

export default MasterRouter;