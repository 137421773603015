import React, {Component} from 'react'
import Dashboard from "../components/gcp/Dashboard";
import {Route, Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import NotFound from "../components/NotFound";
import {Settings} from "../components/Settings";
import ViewProfile from "../components/ViewProfile";

import GcpMyApplications from "../components/gcp/application/MyApplications";
import GcpWithdrawApplication from "../components/gcp/application/WithdrawApplication";
import CancelGcp from "../components/gcp/CancelGcp";
import GcpList from "../components/gcp/GcpList";
import GcpCancelMembership from "../components/gcp/CancelMembership";
import GcpNewApplication from "../components/gcp/application/NewApplication";
import GcpApplicationView from "../components/gcp/application/ApplicationView";
import GcpEditApplication from "../components/gcp/application/EditApplication";
import GcpDetail from "../components/gcp/GcpDetail";

import TransferToMember from "../components/gcp/transfer_log/TransferToMember";

import TransferHandler from "../components/gcp/transfer/TransferHandler";
import TransferView from "../components/gcp/transfer/TransferView";
import TransferEdit from "../components/gcp/transfer/TransferEdit";

import MergeHandler from "../components/gcp/merge/MergeHandler";
import MergeView from "../components/gcp/merge/MergeView";

import Gtin13NewApplication from "../components/gtin13/NewApplication";
import Gtin13List from "../components/gtin13/Gtin13List";
import Gtin13ApplicationDetail from "../components/gtin13/ApplicationDetail";
import Gtin13ApplicationEdit from "../components/gtin13/ApplicationEdit";

import Gtin12NewApplication from "../components/gtin12/NewApplication";
import Gtin12List from "../components/gtin12/Gtin12List";
import Gtin12ApplicationDetail from "../components/gtin12/ApplicationDetail";
import Gtin12ApplicationEdit from "../components/gtin12/ApplicationEdit";

import Gtin8MyApplications from "../components/gtin8/MyApplications";
import Gtin8NewApplicationWrapper from "../components/gtin8/NewApplicationWrapper";
import Gtin8ApplicationDetail from "../components/gtin8/ApplicationDetail";
import Gtin8List from "../components/gtin8/Gtin8List";
import Gtin8ApplicationEdit from "../components/gtin8/ApplicationEdit";
import Gtin8ApplicationItemDetail from "../components/gtin8/ApplicationItemDetail";
import Gtin8ApplicationItemEdit from "../components/gtin8/ApplicationItemEdit";

import VwNewApplicationWrapper from "../components/vw/NewApplicationWrapper";
import VwMyApplications from "../components/vw/MyApplications";
import VwApplicationDetail from "../components/vw/ApplicationDetail";
import VwList from "../components/vw/VwList";
import VwApplicationEdit from "../components/vw/ApplicationEdit";
import VwApplicationItemDetail from "../components/vw/ApplicationItemDetail";
import VwApplicationItemEdit from "../components/vw/ApplicationItemEdit";

import UpcNewApplication from "../components/upc/NewApplication";
import UpcMyApplications from "../components/upc/MyApplications";
import UpcList from "../components/upc/UpcList";
import UpcApplicationDetail from "../components/upc/ApplicationDetail";
import UpcDetail from "../components/upc/UpcDetail";

import ApplicationMessageController from "../components/ApplicationMessageController";
import MyServices from "../components/MyServices";

import ServiceFee from "../components/payment/ServiceFee";
import Subscription from "../components/payment/Subscription";
import ServiceFeeDetail from "../components/payment/ServiceFeeDetail";
import SubscriptionDetail from "../components/payment/SubscriptionDetail";
import Pay from "../components/payment/Pay";

class MemberRouter extends Component{

    render(){
        return (
            <div>
                <Switch>
                    <PrivateRoute exact path='/member/profile/' component={<ViewProfile/>} />
                    <PrivateRoute exact path='/member/gcp/newapplication' component={<GcpNewApplication/>} />
                    <PrivateRoute exact path='/member/gcp/application/view/:applicationId/' component={<GcpApplicationView/>} />
                    <PrivateRoute exact path='/member/gcp/application/edit/:applicationId/' component={<GcpEditApplication/>} />
                    <PrivateRoute exact path='/member/gcp/withdraw/:applicationId/' component={<GcpWithdrawApplication/>} />
                    <PrivateRoute exact path='/member/gcp/myapplications/' component={<GcpMyApplications/>} />
                    <PrivateRoute exact path='/member/gcp/list/' component={<GcpList/>} />
                    <PrivateRoute exact path='/member/gcp/detail/:applicationId' component={<GcpDetail/>} />
                    <PrivateRoute exact path='/member/gcp/cancel/:applicationId' component={<CancelGcp/>} />

                    <PrivateRoute exact path='/member/gtin13/newapplication' component={<Gtin13NewApplication/>} />
                    <PrivateRoute exact path='/member/gtin13/list/:applicationId?' component={<Gtin13List/>} />
                    <PrivateRoute exact path='/member/gtin13/detail/:applicationId' component={<Gtin13ApplicationDetail/>} />
                    <PrivateRoute exact path='/member/gtin13/edit/:applicationId' component={<Gtin13ApplicationEdit/>} />

                    <PrivateRoute exact path='/member/cancel/membership' component={<GcpCancelMembership/>} />
                    <PrivateRoute exact path='/member/settings/' component={<Settings/>} />

                    <PrivateRoute exact path='/member/gtin8/newapplication/:applicationId?' component={<Gtin8NewApplicationWrapper/>} />
                    <PrivateRoute exact path='/member/gtin8/myapplications' component={<Gtin8MyApplications/>} />
                    <PrivateRoute exact path='/member/gtin8/application/edit/:applicationId' component={<Gtin8ApplicationEdit/>} />
                    <PrivateRoute exact path='/member/gtin8/application/:applicationId' component={<Gtin8ApplicationDetail/>} />
                    <PrivateRoute exact path='/member/gtin8/applicationitem/:applicationId' component={<Gtin8ApplicationItemDetail/>} />
                    <PrivateRoute exact path='/member/gtin8/applicationitem/edit/:applicationId' component={<Gtin8ApplicationItemEdit/>} />
                    <PrivateRoute exact path='/member/gtin8/list' component={<Gtin8List/>} />

                    <PrivateRoute exact path='/member/vw/newapplication/:applicationId?' component={<VwNewApplicationWrapper/>} />
                    <PrivateRoute exact path='/member/vw/myapplications' component={<VwMyApplications/>} />
                    <PrivateRoute exact path='/member/vw/application/edit/:applicationId' component={<VwApplicationEdit/>} />
                    <PrivateRoute exact path='/member/vw/application/:applicationId' component={<VwApplicationDetail/>} />
                    <PrivateRoute exact path='/member/vw/list' component={<VwList/>} />
                    <PrivateRoute exact path='/member/vw/applicationitem/:applicationId' component={<VwApplicationItemDetail/>} />
                    <PrivateRoute exact path='/member/vw/applicationitem/edit/:applicationId' component={<VwApplicationItemEdit/>} />

                    <PrivateRoute exact path='/member/upc/newapplication' component={<UpcNewApplication/>} />
                    <PrivateRoute exact path='/member/upc/myapplications' component={<UpcMyApplications/>} />
                    <PrivateRoute exact path='/member/upc/list' component={<UpcList/>} />
                    <PrivateRoute exact path='/member/upc/application/:applicationId' component={<UpcApplicationDetail/>} />
                    <PrivateRoute exact path='/member/upc/detail/:applicationId' component={<UpcDetail/>} />

                    <PrivateRoute exact path='/member/gtin12/newapplication' component={<Gtin12NewApplication/>} />
                    <PrivateRoute exact path='/member/gtin12/list/:applicationId?' component={<Gtin12List/>} />
                    <PrivateRoute exact path='/member/gtin12/detail/:applicationId' component={<Gtin12ApplicationDetail/>} />
                    <PrivateRoute exact path='/member/gtin12/edit/:applicationId' component={<Gtin12ApplicationEdit/>} />

                    <PrivateRoute exact path='/member/transfer/tomember' component={<TransferToMember/>} />
                    <PrivateRoute exact path='/member/transfer/frommember' component={<TransferHandler/>} />
                    <PrivateRoute exact path='/member/transfer/detail/:applicationId' component={<TransferView/>} />
                    <PrivateRoute exact path='/member/transfer/edit/:applicationId' component={<TransferEdit/>} />

                    <PrivateRoute exact path='/member/transfer/merge' component={<MergeHandler/>} />
                    <PrivateRoute exact path='/member/transfer/merge/detail/:applicationId' component={<MergeView/>} />
                    {/*<PrivateRoute exact path='/member/transfer/merge/edit/:applicationId' component={<MergeWithMember/>} />*/}

                    <PrivateRoute exact path='/member/payment/servicefee' component={<ServiceFee/>} />
                    <PrivateRoute exact path='/member/payment/servicefee/:id' component={<ServiceFeeDetail/>} />
                    <PrivateRoute exact path='/member/payment/subscription' component={<Subscription/>} />
                    <PrivateRoute exact path='/member/payment/subscription/:id' component={<SubscriptionDetail/>} />
                    <PrivateRoute exact path='/member/pay/servicefee/:id' component={<Pay serviceType={"service_fee"}/>} />
                    <PrivateRoute exact path='/member/pay/subscription/:id' component={<Pay serviceType={"subscription"}/>} />

                    <PrivateRoute exact path='/member/to/:linkId' component={<ApplicationMessageController/>} />

                    <PrivateRoute exact path='/member/myservices' component={<MyServices/>} />

                    <Route exact path="/member" component={Dashboard}/>
                    <Route exact path="/" component={Dashboard}/>

                    <Route path="/" component={NotFound}/>
                </Switch>
            </div>
        )
    }
}

export default MemberRouter;