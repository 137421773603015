import React, {Component, Fragment} from 'react'
import '../../../styles/Dashboard.css'
import TextInput from "../../input_types/TextInput";
import FileLink from "../../input_types/FileLink";
import * as applicationActions from "../../../actions/applicationActions";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {T, withTranslators} from "lioness";
import ContentModule from "../../ContentModule";
import GcpList from "../GcpList";
import PayInfo from "../../payment/PayInfo";

class ApplicationView extends Component{

    constructor(props){
        super(props);
        this.state = {
            currentStep: 1,
            application: null
        };
    }

    componentWillMount(){
        const {applicationId} = this.props.match.params;
        this.props.getApplicationDetail(applicationId, this.applicationStatusCallBack);
    }

    applicationStatusCallBack = (result) => {
        if(result.status === 200){
            this.setState({
                currentStep: 2,
                application: result.data,
            })
        }
    };

    getConstants(){
        const {t} = this.props;
        const digitsText = t("digits");

        return {
            requestedDigits: this.state.application.requested_digit + " " + digitsText,
            annualTaxAssessment: this.state.application.annual_tax_assessment,
            commercialCertificate: this.state.application.commercial_certificate,
            commitmentLetter: this.state.application.commitment_letter,
            incomeStatement: this.state.application.income_statement,
            extraFiles: this.state.application.extra_files,
            commercialCertificateReject: this.state.application.commercial_certificate_reject,
            commitmentLetterReject: this.state.application.commitment_letter_reject,
            incomeStatementReject: this.state.application.income_statement_reject,
            latestApplicationNotes: this.state.application.latest_application_note ? this.state.application.latest_application_note: t("No notes recorded!"),
            isTransfer: this.state.application.is_transfer,
            applicationStatus: this.state.application.application_status,
            applicationStatusName: this.state.application.application_status_name,
            applicationId: this.state.application.application_id,
            createdAt: this.state.application.created_at,
            updatedAt: this.state.application.updated_at,
        }
    }

    render(){
        const {requestedDigits, annualTaxAssessment, commercialCertificate, commitmentLetter,
            incomeStatement, extraFiles, commercialCertificateReject, commitmentLetterReject,
            incomeStatementReject, latestApplicationNotes, isTransfer, applicationStatus, applicationId, createdAt,
            applicationStatusName, updatedAt} =  this.state.currentStep === 2 && this.getConstants();

        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>View Application</T></h2>

                <ContentModule>
                    {
                        applicationStatus === 7 &&
                        <T>The GCPs allocated regarding this application are listed below. You can also access the documents provided related with this application.</T>
                    }
                    {
                        applicationStatus === 2 &&
                        <T>Checks regarding your application has been done, to complete your application process please upload missing/wrong documents to the system.</T>
                    }
                    {
                        applicationStatus === 4 &&
                        <T>Please send physical documents to GS1 and pay your service fee. The brief is available at your email.</T>
                    }
                    {
                        [1, 3, 5, 6].indexOf(applicationStatus) > -1 &&
                        <T>Your Application is being inspected.</T>
                    }
                    {
                        applicationStatus === 8 &&
                        <T>Your application has been rejected. You may see the details below.</T>
                    }
                    {
                        applicationStatus === 9 &&
                        <T>Your application has been withdrawn.</T>
                    }
                </ContentModule>

                <div className={"application-view"}>
                    {
                        this.state.currentStep === 1 ?
                            <div className={"t-center mt-10"}>
                                <T>Loading...</T>
                            </div>:

                            this.state.currentStep === 2 ?
                                <Fragment>
                                    <PayInfo service_fee={this.state.application["service_fee"]}
                                             is_cancelled={this.state.application["service_fee_cancelled"]}
                                             remaining_amount={this.state.application["remaining_amount"]}
                                             payment_status={this.state.application["payment_status"]}
                                             pre_sales_agreement={this.state.application["pre_sales_agreement"]}
                                             distant_sales_agreement={this.state.application["distant_sales_agreement"]}
                                             path={"/member/pay/servicefee/" + this.state.application["service_id"]}/>

                                    <ContentModule>
                                        <form className="editor small signin">
                                            <div>
                                                <TextInput key={"applicationStatusName"} inputId={"applicationStatusName"} required={false} inputTitle={t("Status")} inputValue={t(applicationStatusName)} disabled/>
                                                <TextInput key={"createdAt"} inputId={"createdAt"} required={false} inputTitle={t("Application Date")} inputValue={createdAt} disabled/>
                                                <TextInput key={"updatedAt"} inputId={"updatedAt"} required={false} inputTitle={t("Last Update Date")} inputValue={updatedAt} disabled/>
                                                {
                                                    !isTransfer &&
                                                    <TextInput key={"requestedDigits"} inputId={"requestedDigits"} required={false} inputTitle={t("Requested Digits")} inputValue={t(requestedDigits)} disabled/>
                                                }
                                                <TextInput key={"annualTaxAssessment"} inputId={"annualTaxAssessment"} required={false} inputTitle={t("Annual Tax Assessment")} inputValue={annualTaxAssessment} disabled/>
                                                <FileLink inputId={"commercialCertificateFile"} inputTitle={t("Commercial Certificate")} required={false} inputValue={commercialCertificate} errorText={commercialCertificateReject}/>
                                                {
                                                    commitmentLetter &&
                                                    <FileLink inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} required={false} inputValue={commitmentLetter} errorText={commitmentLetterReject}/>
                                                }
                                                {
                                                    incomeStatement &&
                                                    <FileLink inputId={"incomeStatementFile"} inputTitle={t("Income Statement")} required={false} inputValue={incomeStatement} errorText={incomeStatementReject}/>
                                                }
                                                {
                                                    isTransfer ?
                                                        <div>
                                                            <FileLink key={"petitionFile"} inputId={"petitionFile"} required={false} inputTitle={t("Petition Letter")} inputValue={this.state.application.petition} errorText={this.state.application.petition_reject}/>
                                                            <FileLink key={"transferFile"} inputId={"transferFile"} required={false} inputTitle={t("Transfer Letter")} inputValue={this.state.application.transfer_paper} errorText={this.state.application.transfer_paper_reject}/>
                                                            {
                                                                this.state.application.transfer_type === 2 ?
                                                                    <div>
                                                                        <FileLink key={"inheritanceFile"} inputId={"inheritanceFile"} required={false} inputTitle={t("Inheritance Letter")} inputValue={this.state.application.inheritance_document} errorText={this.state.application.inheritance_document_reject}/>
                                                                        {
                                                                            this.state.application.consent_document &&
                                                                            <FileLink key={"consentFile"} inputId={"consentFile"} required={false} inputTitle={t("Consent Letter")} inputValue={this.state.application.consent_document} errorText={this.state.application.consent_document_reject}/>
                                                                        }
                                                                    </div>: null
                                                            }
                                                        </div>: null
                                                }
                                                {
                                                    extraFiles !== undefined && extraFiles !== null && extraFiles.length ?
                                                        <FileLink inputId={"extraFiles"} inputTitle={t("Extra Files")}  multiple={true} inputValue={extraFiles} required={false}/> :null
                                                }
                                                <TextInput key={"latestApplicationNote"} inputId={"latestApplicationNote"} required={false} inputTitle={t("Additional Notes")} inputValue={latestApplicationNotes} isTextArea disabled/>
                                                {
                                                    applicationStatus === 2 &&
                                                    <div className={"btnInline mt-10 t-center mt-20 nohover"}>
                                                        <Link to={"/member/gcp/application/edit/" + this.props.match.params.applicationId} className={"signup-button small"}>
                                                            <T>Edit</T>
                                                        </Link>
                                                    </div>
                                                }
                                            </div>
                                        </form>
                                    </ContentModule>

                                    {
                                        applicationStatus === 7 &&
                                        <div>
                                            <h2 className={"t-center"}><T>Numbers Related With This Application</T></h2>
                                            <GcpList
                                                hideHeader
                                                applicationId={applicationId}
                                            />
                                        </div>
                                    }
                                </Fragment>
                                : null
                    }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getApplicationDetail: (applicationId, callback) => {
            dispatch(applicationActions.dbGetApplicationDetail(applicationId, callback));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ApplicationView)));
