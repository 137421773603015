import React, { useState } from 'react'
import {T, withTranslators} from "lioness";
import 'bootstrap/dist/css/bootstrap.css';

import $ from 'jquery';

class ApplicationInformationForm extends React.Component {

  constructor(props){
    super(props);

    var selectboxlist = []
     if( this.props.list!=undefined)
    {
      var selectboxlist = this.props.list
    }


    if(this.props.type=='birim')
    {
      var list = [
                  {'value':'1','name':'Adet'},
                  {'value':'GRM','name':'Gram'},
                  {'value':'MLT','name':'Mililitre'},
                  {'value':'KGM','name':'Kilogram'},
                  {'value':'MTR','name':'Metre'},
                  {'value':'LTR','name':'Litre'},
                  {'value':'XRO','name':'Rulo'},
                  {'value':'PR','name':'Çift'},
                  {'value':'MMT','name':'Milimetre'},
                  {'value':'CLT','name':'Santilitre'},
                  {'value':'CMT','name':'Santimetre'},
                  {'value':'Q33','name':'Pikolitre'},
                  {'value':'KSD','name':'%90 kuru madde kilogramı'},
                  {'value':'AMP','name':'Amper'},
                  {'value':'AMH','name':'Amper Saat'},
                  {'value':'X_MTC','name':'Ana tentür (kuru malzeme)'},
                  {'value':'A11','name':'Angstrom'},
                  {'value':'AXU','name':'Anti XA Ünitesi'},
                  {'value':'D43','name':'Atomik Kütle Birimleri (AMU)'},
                  {'value':'MON','name':'Ay'},
                  {'value':'AWG','name':'Ayar Ölçüsü'},
                  {'value':'LK','name':'Bağ'},
                  {'value':'BAR','name':'Bar (basınç birimi)'},
                  {'value':'AD','name':'Bayt'},
                  {'value':'BB','name':'Baz kutusu'},
                  {'value':'BQL','name':'Bekerel'},
                  {'value':'K43','name':'Beygir gücü '},
                  {'value':'D40','name':'Bin Litre'},
                  {'value':'R9','name':'Bin metreküp'},
                  {'value':'T3','name':'Bin parça'},
                  {'value':'PD','name':'Bloknot'},
                  {'value':'FJ','name':'Boyutlandırma faktörü'},
                  {'value':'GFI','name':'Bölünebilir İzotop Gramı'},
                  {'value':'E4','name':'Brüt kilogram'},
                  {'value':'X_CHD','name':'Centisimal Hahnemannian Dilüsyonu (CH)'},
                  {'value':'G25','name':'Çay kaşığı'},
                  {'value':'G23','name':'Çeyrek kile'},
                  {'value':'NPR','name':'Çift sayısı'},
                  {'value':'MIN','name':'Dakika (zaman birimi)'},
                  {'value':'G2','name':'Dakikada ABD Galonu'},
                  {'value':'BPM','name':'Dakikada darbe'},
                  {'value':'FR','name':'Dakikada fut'},
                  {'value':'2L','name':'Dakikada fut küp'},
                  {'value':'M63','name':'Dakikada İnç'},
                  {'value':'2X','name':'Dakikada Metre'},
                  {'value':'M57','name':'Dakikada Mil '},
                  {'value':'H81','name':'Dakikada Milimetre'},
                  {'value':'OPM','name':'Dakikada Salınım'},
                  {'value':'M65','name':'Dakikada Yarda'},
                  {'value':'RPM','name':'Dakikadaki devir sayısı'},
                  {'value':'D63','name':'Defter'},
                  {'value':'GG','name':'Derece (Açı Birimi)'},
                  {'value':'X_DBA','name':'Desibar'},
                  {'value':'2N','name':'Desibel'},
                  {'value':'DG','name':'Desigram'},
                  {'value':'DLT','name':'Desilitre'},
                  {'value':'DMT','name':'Desimetre'},
                  {'value':'DMK','name':'Desimetre kare'},
                  {'value':'DMQ','name':'Desimetre küp'},
                  {'value':'DRA','name':'Dirhem (ABD)'},
                  {'value':'DRI','name':'Dirhem (BK)'},
                  {'value':'E27','name':'Doz'},
                  {'value':'DZN','name':'Düzine'},
                  {'value':'ELU','name':'ELISA Birimleri '},
                  {'value':'MPN','name':'En Yüksek Olasılıklı Sayı'},
                  {'value':'FAH','name':'Fahrenheit derecesi'},
                  {'value':'Q32','name':'Femtolitre'},
                  {'value':'A71','name':'Femtometre'},
                  {'value':'KPP','name':'Fosfor pentoksitin kilogramı (fosforik anhidrit)'},
                  {'value':'H79','name':'Fransız ölçeği'},
                  {'value':'FOT','name':'Fut'},
                  {'value':'FTK','name':'Fut kare'},
                  {'value':'FP','name':'Fut karede pound'},
                  {'value':'FTQ','name':'Fut küp'},
                  {'value':'GLL','name':'Galon (ABD)'},
                  {'value':'GLI','name':'Galon (BK)'},
                  {'value':'X_MPG','name':'Galonda Mil'},
                  {'value':'E34','name':'Gigabayt'},
                  {'value':'GBQ','name':'Gigabekerel'},
                  {'value':'A86','name':'Gigahertz'},
                  {'value':'GWH','name':'Gigavat saat'},
                  {'value':'X_CFG','name':'Gram başına Koloni Oluşturan Birimler (CFU/g)'},
                  {'value':'GRO','name':'Gros'},
                  {'value':'GÜN','name':'Günler'},
                  {'value':'WEE','name':'Hafta'},
                  {'value':'HGM','name':'Hektogram'},
                  {'value':'HLT','name':'Hektolitre'},
                  {'value':'EA','name':'Her bir'},
                  {'value':'HTZ','name':'Hertz'},
                  {'value':'KHY','name':'Hidrojen peroksitin kilogramı'},
                  {'value':'HEP','name':'Histamin Eşdeğeri İğne'},
                  {'value':'NCL','name':'Hücre sayısı'},
                  {'value':'D55','name':'Isı Aktarım Katsayısı'},
                  {'value':'INH','name':'İnç'},
                  {'value':'E39','name':'İnç başına nokta sayısı'},
                  {'value':'INK','name':'İnç kare'},
                  {'value':'PS','name':'İnç kare başına pound kuvveti'},
                  {'value':'80','name':'İnç karede pound - Mutlak'},
                  {'value':'INQ','name':'İnç küp'},
                  {'value':'X_UIN','name':'İnçin On Binde Biri'},
                  {'value':'X_SIN','name':'İnçin Otuz Saniyesi'},
                  {'value':'X_HIN','name':'İnçin yüzde biri'},
                  {'value':'TPI','name':'İnçte diş'},
                  {'value':'X_PPI','name':'İnçte piksel'},
                  {'value':'64','name':'İnçte pound - Ölçer'},
                  {'value':'BTU','name':'İngiliz ısı birimi'},
                  {'value':'JOU','name':'Jul'},
                  {'value':'KIU','name':'Kallikrein inaktivatör ünitesi.'},
                  {'value':'D70','name':'Kalori - Uluslararası Tablo (IT)'},
                  {'value':'G21','name':'Kap (ABD)'},
                  {'value':'CG','name':'Kart'},
                  {'value':'LR','name':'Katman'},
                  {'value':'KEL','name':'Kelvin'},
                  {'value':'BFT','name':'Kereste ölçü birimi'},
                  {'value':'PTN','name':'Kısım'},
                  {'value':'BUA','name':'Kile (ABD)'},
                  {'value':'BUI','name':'Kile (BK)'},
                  {'value':'2Q','name':'Kilo Bekerel'},
                  {'value':'2P','name':'Kilobayt'},
                  {'value':'X_IUK','name':'Kilogram Başına Uluslararası Birimler (IU/kg)'},
                  {'value':'B37','name':'Kilogram Kuvvet(Newton)'},
                  {'value':'KDW','name':'Kilogram süzme net ağırlığı'},
                  {'value':'NA','name':'Kilogramda miligram (mg/kg)'},
                  {'value':'KHZ','name':'Kilohertz'},
                  {'value':'KJO','name':'Kilojul'},
                  {'value':'E14','name':'Kilokalori (uluslararası tablo)'},
                  {'value':'K6','name':'Kilolitre'},
                  {'value':'KMT','name':'Kilometre'},
                  {'value':'B47','name':'Kilonewton'},
                  {'value':'KPA','name':'Kilopascal'},
                  {'value':'KWT','name':'Kilovat'},
                  {'value':'KWH','name':'Kilovat Saat'},
                  {'value':'D03','name':'Kilovat/saat'},
                  {'value':'KT','name':'Kit'},
                  {'value':'CFU','name':'Koloni Oluşturan Birimler'},
                  {'value':'X_KVN','name':'Korsakoviyen (K)'},
                  {'value':'QTD','name':'Kuart (ABD kuru)'},
                  {'value':'E55','name':'Kullanım Sayısı'},
                  {'value':'PTD','name':'Kuru Paynt (ABD)'},
                  {'value':'X_DWT','name':'Kuruş ağırlığı'},
                  {'value':'CWI','name':'Libre Ağırlığı (BK)'},
                  {'value':'LF','name':'Lineer fut'},
                  {'value':'LM','name':'Lineer metre'},
                  {'value':'LD','name':'Litre / Gün'},
                  {'value':'GL','name':'Litrede Gram'},
                  {'value':'LUX','name':'Lüks'},
                  {'value':'B64','name':'Lüks saniye'},
                  {'value':'LUM','name':'Lümen'},
                  {'value':'B62','name':'Lümen Saniye'},
                  {'value':'4L','name':'Megabayt'},
                  {'value':'4N','name':'Megabekerel'},
                  {'value':'MHZ','name':'Megahertz'},
                  {'value':'MAW','name':'Megavat'},
                  {'value':'MWH','name':'Megavat saat (1000 kW.h)'},
                  {'value':'KMA','name':'Metilamin kilogramı'},
                  {'value':'MTK','name':'Metrekare'},
                  {'value':'S4','name':'Metrekare / Saniye'},
                  {'value':'A24','name':'Metrekare başına kandela'},
                  {'value':'KNM','name':'Metrekare başına kilonewton'},
                  {'value':'GM','name':'Metrekarede gram'},
                  {'value':'28','name':'Metrekarede kilogram'},
                  {'value':'B60','name':'Metrekarede Lümen'},
                  {'value':'MTQ','name':'Metreküp'},
                  {'value':'CTM','name':'Metrik Karat'},
                  {'value':'MC','name':'Mikrogram'},
                  {'value':'4G','name':'Mikrolitre'},
                  {'value':'4H','name':'Mikrometre'},
                  {'value':'FH','name':'Mikromol'},
                  {'value':'SMI','name':'Mil (kara mili)'},
                  {'value':'MIK','name':'Mil kare'},
                  {'value':'E09','name':'Miliamper saat'},
                  {'value':'MBR','name':'Milibar'},
                  {'value':'MEQ','name':'Miliekivalan'},
                  {'value':'MGM','name':'Miligram'},
                  {'value':'MMK','name':'Milimetre kare'},
                  {'value':'MMQ','name':'Milimetre küp'},
                  {'value':'C18','name':'Milimol'},
                  {'value':'C26','name':'Milisaniye'},
                  {'value':'X_MLM','name':'Millesimai (LM)'},
                  {'value':'59','name':'Milyon başına parça'},
                  {'value':'MIU','name':'Milyon Uluslararası Birim (NIE)'},
                  {'value':'C34','name':'Mol'},
                  {'value':'X_NGM','name':'Nanogram'},
                  {'value':'Q34','name':'Nanolitre'},
                  {'value':'C45','name':'Nanometre'},
                  {'value':'C47','name':'Nanosaniye'},
                  {'value':'A43','name':'Net Ağırlık Tonajı'},
                  {'value':'58','name':'Net kilogram'},
                  {'value':'NEW','name':'Newton'},
                  {'value':'NU','name':'Newton Metre'},
                  {'value':'KNI','name':'Nitrojen kilogramı'},
                  {'value':'PNT','name':'Nokta'},
                  {'value':'KNT','name':'Not'},
                  {'value':'OHM','name':'Ohm'},
                  {'value':'ONZ','name':'Ons'},
                  {'value':'APZ','name':'Ons veya eczacı ölçüsü'},
                  {'value':'H87','name':'Parça'},
                  {'value':'PAL','name':'Paskal'},
                  {'value':'C65','name':'Paskal Saniye'},
                  {'value':'PTI','name':'Paynt (BK)'},
                  {'value':'E36','name':'Petabayt'},
                  {'value':'Q30','name':'pH (Hidrojenin potansiyeli)'},
                  {'value':'C52','name':'Pikometre'},
                  {'value':'C75','name':'Pikovat'},
                  {'value':'E37','name':'Piksel'},
                  {'value':'PFU','name':'Plak Oluşturucu Birim(ler)'},
                  {'value':'PRS','name':'Potansiyel Renal Çözünen Yük'},
                  {'value':'KPH','name':'Potasyum hidroksit kilogramı (yakıcı potas)'},
                  {'value':'KPO','name':'Potasyum oksit kilogramı'},
                  {'value':'LBR','name':'Pound'},
                  {'value':'X_CFP','name':'Pound başına Koloni Oluşturan Birimler (CFU/lb)'},
                  {'value':'X_RAE','name':'Retinol Aktivite Eşdeğerleri'},
                  {'value':'XRE','name':'Retinol Eşdeğeri (RE)'},
                  {'value':'HUR','name':'Saat'},
                  {'value':'K14','name':'Saatte fut'},
                  {'value':'F27','name':'Saatte gram'},
                  {'value':'KMH','name':'Saatte kilometre'},
                  {'value':'E32','name':'Saatte litre'},
                  {'value':'M60','name':'Saatte Metre'},
                  {'value':'MQH','name':'Saatte Metreküp'},
                  {'value':'HM','name':'Saatte Mil (kara mili)'},
                  {'value':'H67','name':'Saatte milimetre'},
                  {'value':'H49','name':'Saatte santimetre'},
                  {'value':'M66','name':'Saatte yarda'},
                  {'value':'XST','name':'Sac'},
                  {'value':'LPA','name':'Saf alkol litresi'},
                  {'value':'SEC','name':'Saniye (zaman birimi)'},
                  {'value':'B10','name':'Saniyede bit'},
                  {'value':'FS','name':'Saniyede fut'},
                  {'value':'K30','name':'Saniyede Galon (ABD Sıvı)'},
                  {'value':'IU','name':'Saniyede inç'},
                  {'value':'M62','name':'Saniyede Kilometre'},
                  {'value':'MTS','name':'Saniyede Metre'},
                  {'value':'M58','name':'Saniyede Mil'},
                  {'value':'C16','name':'Saniyede Milimetre'},
                  {'value':'X_SPS','name':'Saniyede Numune'},
                  {'value':'2M','name':'Saniyede Santimetre'},
                  {'value':'M64','name':'Saniyede yarda'},
                  {'value':'CGM','name':'Santigram'},
                  {'value':'CEL','name':'Santigrat derece (Celsius)'},
                  {'value':'CMK','name':'Santimetre kare'},
                  {'value':'CMQ','name':'Santimetre küp'},
                  {'value':'23','name':'Santimetre küpte gram'},
                  {'value':'D5','name':'Santimetrede kilogram'},
                  {'value':'X_PPC','name':'Santimetrede piksel'},
                  {'value':'QB','name':'Sayfa - basılı'},
                  {'value':'5B','name':'Seri:'},
                  {'value':'SET','name':'Set'},
                  {'value':'SX','name':'Sevkiyat'},
                  {'value':'QTL','name':'Sıvı kuart (ABD)'},
                  {'value':'OZA','name':'Sıvı ons (ABD)'},
                  {'value':'OZI','name':'Sıvı ons (BK)'},
                  {'value':'PTL','name':'Sıvı Paynt (ABD)'},
                  {'value':'KSH','name':'Sodyum hidroksit kilogramı (kostik soda)'},
                  {'value':'X_CCA','name':'Soğuk Kramp Amperi'},
                  {'value':'SQE','name':'SQ-E'},
                  {'value':'ATM','name':'Standart Atmosfer'},
                  {'value':'G26','name':'Ster'},
                  {'value':'U2','name':'Tablet'},
                  {'value':'ATT','name':'Teknik Atmosfer'},
                  {'value':'E35','name':'Terabayt'},
                  {'value':'D29','name':'Terahertz'},
                  {'value':'D30','name':'Terajul'},
                  {'value':'D32','name':'Terevat saat'},
                  {'value':'TNE','name':'Ton'},
                  {'value':'STN','name':'Ton (ABD) veya kısa ton (BK)'},
                  {'value':'LTN','name':'Ton (BK) veya uzun ton (ABD)'},
                  {'value':'UA','name':'Tor'},
                  {'value':'GRN','name':'Tutam'},
                  {'value':'NIU','name':'Uluslararası Birim Sayısı'},
                  {'value':'KUR','name':'Uranyum Kilogramı'},
                  {'value':'AS','name':'Ürün çeşidi'},
                  {'value':'KO','name':'Ürünün gramı başına milielivalans yakıcı potaş.'},
                  {'value':'BLL','name':'Varil ABD'},
                  {'value':'WTT','name':'Vat'},
                  {'value':'B61','name':'Vat Başına Lümen'},
                  {'value':'D19','name':'Vat başına metre kare kelvin.'},
                  {'value':'WHR','name':'Vat saat'},
                  {'value':'VLT','name':'Volt'},
                  {'value':'YRD','name':'Yarda'},
                  {'value':'YDK','name':'Yarda kare'},
                  {'value':'ON','name':'Yarda kare başına ons'},
                  {'value':'HD','name':'Yarım düzine'},
                  {'value':'G24','name':'Yemek kaşığı'},
                  {'value':'ANN','name':'Yıl'},
                  {'value':'HC','name':'Yüz Adet'},
                  {'value':'BP','name':'Yüz panelli fut'},
                  {'value':'CWA','name':'Yüz pound (cwt)/yüz ağırlık (ABD)'},
                  {'value':'P1','name':'Yüzde'}
                ];
    }
    else if(this.props.type=='hedefpazar')
    {
      var list = []
      for(var i in this.props.data)
      {
        list.push({'value':this.props.data[i].code,'name':this.props.data[i].name})
      }
    }
    else
    {
      var list = [
                  {'value':'tr','name':'Türkçe'},
                  {'value':'en','name':'İngilizce'},
                  {'value':'aa','name':'Afar'},
                  {'value':'ab','name':'Abkhazian'},
                  {'value':'af','name':'Afganca'},
                  {'value':'ak','name':'Akanca'},
                  {'value':'sq','name':'Arnavutlukça'},
                  {'value':'ar','name':'Amharic'},
                  {'value':'an','name':'Aragonezce'},
                  {'value':'hy','name':'Ermenice'},
                  {'value':'as','name':'Assam'},
                  {'value':'av','name':'Avar'},
                  {'value':'ae','name':'Avestan'},
                  {'value':'ay','name':'Aymara'},
                  {'value':'az','name':'Azerice'},
                  {'value':'ba','name':'Bashkir'},
                  {'value':'eu','name':'Bask'},
                  {'value':'be','name':'Beyaz Rusça'},
                  {'value':'bn','name':'Bengal'},
                  {'value':'bh','name':'Bihari'},
                  {'value':'bi','name':'Bislama'},
                  {'value':'bo','name':'Tibetçe'},
                  {'value':'bs','name':'Boşnakça'},
                  {'value':'br','name':'Bretonca'},
                  {'value':'bg','name':'Bulgarca'},
                  {'value':'my','name':'Burma'},
                  {'value':'ca','name':'Catalan; Valencian'},
                  {'value':'cs','name':'Slav'},
                  {'value':'ca','name':'Catalan; Valencian'},
                  {'value':'ch','name':'Chamorro'},
                  {'value':'ce','name':'Çeçence'},
                  {'value':'zh','name':'Çince'},
                  {'value':'cu','name':'Church Slavic; Old Slavonic; Church Slavonic'},
                  {'value':'cv','name':'Çuvaş'},
                  {'value':'kw','name':'Cornish'},
                  {'value':'co','name':'Korsika dili'},
                  {'value':'cr','name':'Cree'},
                  {'value':'hr','name':'Hırvatistan'},
                  {'value':'cy','name':'Galliler'},
                  {'value':'da','name':'Danca'},
                  {'value':'de"','name':'Almanca'},
                  {'value':'dv','name':'Divehi; Dhivehi; Maldivian'},
                  {'value':'nl','name':'Hollandalı; Flaman'},
                  {'value':'dz','name':'Dzongkha'},
                  {'value':'el','name':'Yunanca'},
                  {'value':'eo','name':'Esperanto'},
                  {'value':'et','name':'Estonya'},
                  {'value':'eu','name':'Bask'},
                  {'value':'ee','name':'Ewe'},
                  {'value':'fo','name':'Faroe'},
                  {'value':'fa','name':'Farsça'},
                  {'value':'fj','name':'Fiji'},
                  {'value':'fi','name':'Fince'},
                  {'value':'fr','name':'Fransızca'},
                  {'value':'fy','name':'Batı Frizce'},
                  {'value':'ff','name':'Fulah'},
                  {'value':'ka','name':'Gürcü'},
                  {'value':'gd','name':'Gal; İskoç Gaelic'},
                  {'value':'ga','name':'İrlandaca'},
                  {'value':'gl','name':'Galiçya'},
                  {'value':'gv','name':'Man'},
                  {'value':'gn','name':'Guarani'},
                  {'value':'gu','name':'Gujarati'},
                  {'value':'ht','name':'Haiti; Haiti Creole'},
                  {'value':'ha','name':'Hausa'},
                  {'value':'he','name':'İbrani'},
                  {'value':'hz','name':'Herero'},
                  {'value':'hi','name':'Hindu'},
                  {'value':'ho','name':'Hiri Motu'},
                  {'value':'hu','name':'Macarca'},
                  {'value':'hy','name':'Ermenice'},
                  {'value':'ig','name':'Igbo'},
                  {'value':'is','name':'İzlanda'},
                  {'value':'io','name':'Ido'},
                  {'value':'ii','name':'Sichuan Yi'},
                  {'value':'iu','name':'Eskimo'},
                  {'value':'ie','name':'Interlingue'},
                  {'value':'ia','name':'Interlingua (Uluslararası Yardımcı Dil Derneği)'},
                  {'value':'id','name':'Endonezya'},
                  {'value':'ik','name':'Inupiak'},
                  {'value':'is','name':'İzlanda'},
                  {'value':'it','name':'İtalyanca'},
                  {'value':'jv','name':'Cava'},
                  {'value':'ja','name':'Japonca'},
                  {'value':'kl','name':'"Kalaallisut; Grönlandca"'},
                  {'value':'kn','name':'Kannada'},
                  {'value':'ks','name':'Kashmiri'},
                  {'value':'ka','name':'Gürcü'},
                  {'value':'kr','name':'Kanuri'},
                  {'value':'kk','name':'Kazak'},
                  {'value':'km','name':'Merkez Khmer'},
                  {'value':'ki','name':'"Kikuyu; Gikuyu"'},
                  {'value':'rw','name':'Kinyarwanda'},
                  {'value':'ky','name':'Kırgızca'},
                  {'value':'kv','name':'Komi'},
                  {'value':'kg','name':'Kongo'},
                  {'value':'ko','name':'Korece'},
                  {'value':'kj','name':'"Kuanyama; Kwanyama"'},
                  {'value':'ku','name':'Kürtçe'},
                  {'value':'lo','name':'Lao'},
                  {'value':'la','name':'Latince'},
                  {'value':'lv','name':'Letonya'},
                  {'value':'li','name':'"Limburgan; Limburger; Limburgish"'},
                  {'value':'ln','name':'Lingala'},
                  {'value':'lt','name':'Litvanya'},
                  {'value':'lb','name':'Lüksemburgca'},
                  {'value':'lu','name':'Luba-Katanga'},
                  {'value':'lg','name':'Ganda'},
                  {'value':'mk','name':'Makedonya'},
                  {'value':'mh','name':'Marshall Adaları'},
                  {'value':'ml','name':'Malayalam'},
                  {'value':'mi','name':'Yeni Zelenda'},
                  {'value':'mr','name':'Marathi'},
                  {'value':'ms','name':'Malay'},
                  {'value':'mg','name':'Madagaskar'},
                  {'value':'mt','name':'Malta'},
                  {'value':'mo','name':'Moldova'},
                  {'value':'mn','name':'Moğolistan'},
                  {'value':'my','name':'Burma'},
                  {'value':'na','name':'Nauru'},
                  {'value':'nv','name':'"Navajo; Navaho"'},
                  {'value':'nr','name':'"Ndebele, South; South Ndebele"'},
                  {'value':'nd','name':'"Ndebele, Kuzey; Kuzey Ndebele" '},
                  {'value':'ng','name':'Ndonga'},
                  {'value':'ne','name':'Nepal'},
                  {'value':'nn','name':'"Norveççe Nynorsk; Nynorsk, Norveççe"'},
                  {'value':'no','name':'Norveç'},
                  {'value':'ny','name':'"Chichewa; Chewa; Nyanja"'},
                  {'value':'oc','name':'"Occitan (post 1500); Provenal"'},
                  {'value':'oj','name':'Ojibwa'},
                  {'value':'or','name':'Oriya'},
                  {'value':'om','name':'Oromo'},
                  {'value':'os','name':'"Ossetian; Ossetic"'},
                  {'value':'pa','name':'"Panjabi; Punjabi"'},
                  {'value':'fa','name':'Farsça'},
                  {'value':'pi','name':'Pali'},
                  {'value':'pl','name':'Polonyaca'},
                  {'value':'pt','name':'Portekizce'},
                  {'value':'ps','name':'Peştuca'},
                  {'value':'qu','name':'Quechua'},
                  {'value':'rm','name':'Romansh'},
                  {'value':'ro','name':'Romanyaca'},
                  {'value':'rn','name':'Kirundi'},
                  {'value':'ru','name':'Rusça'},
                  {'value':'sg','name':'Sango'},
                  {'value':'sa','name':'Sanskrit'},
                  {'value':'sr','name':'Sırpça'},
                  {'value':'si','name':'"Sinhala; Sinhalese"'},
                  {'value':'sk','name':'Slovakça'},
                  {'value':'sl','name':'Slovence'},
                  {'value':'se','name':'Northern Sami'},
                  {'value':'sm','name':'Samoan'},
                  {'value':'sn','name':'Shona'},
                  {'value':'sd','name':'Sindhi'},
                  {'value':'so','name':'Somali'},
                  {'value':'st','name':'Sotho, Southern'},
                  {'value':'es','name':'"İspanyolca; Castilian"'},
                  {'value':'sq','name':'Arnavutlukça'},
                  {'value':'sc','name':'Sardunya'},
                  {'value':'ss','name':'Swati'},
                  {'value':'su','name':'Sunda'},
                  {'value':'sw','name':'Swahili'},
                  {'value':'sv','name':'İsveç'},
                  {'value':'ty','name':'Tahiti'},
                  {'value':'ta','name':'Tamil'},
                  {'value':'tt','name':'Tatarca'},
                  {'value':'te','name':'Telugu'},
                  {'value':'tg','name':'Tacikistan'},
                  {'value':'tl','name':'Tagalog'},
                  {'value':'th','name':'Tayland'},
                  {'value':'bo','name':'Tibetçe'},
                  {'value':'ti','name':'Tigrinya'},
                  {'value':'to','name':'Tonga (Tonga Adaları)'},
                  {'value':'tn','name':'Tswana'},
                  {'value':'ts','name':'Tsonga'},
                  {'value':'tk','name':'Türkmenistan'},
                  {'value':'tw','name':'Twi'},
                  {'value':'ug','name':'"Uighur; Uygur"'},
                  {'value':'uk','name':'Ukrayna'},
                  {'value':'ur','name':'Urdu'},
                  {'value':'uz','name':'Özbekistan'},
                  {'value':'ve','name':'Venda'},
                  {'value':'vi','name':'Vietnam'},
                  {'value':'vo','name':'Volapk'},
                  {'value':'cy','name':'Galliler'},
                  {'value':'wa','name':'Valon'},
                  {'value':'wo','name':'Wolof'},
                  {'value':'xh','name':'Xhosa'},
                  {'value':'yi','name':'Yiddish'},
                  {'value':'yo','name':'Yoruba'},
                  {'value':'za','name':'"Zhuang; Chuang"'},
                  {'value':'zh','name':'Çince'},
                  {'value':'zu','name':'Zulu'}
                ]
    }

    if(this.props.data!=undefined)
    {
      if(this.props.type!='hedefpazar')
      {
        for(var i in  this.props.data)
        {
          this.props.data[i].name = this.props.data[i].label
        }
        var list = this.props.data
      }
      else
      {
        for(var i in  this.props.data)
        {
          this.props.data[i].name = this.props.data[i].label
          this.props.data[i].value = this.props.data[i].code
        }
        var list = this.props.data
      }
      
    }

    var val = '';
    var secimli = 0;
    if(this.props.inputValue!=undefined)
    {
      val = this.props.inputValue
    }
    else
    {
      val = [];
    }

    if(this.props.inputValue_!=undefined)
    {
      if(this.props.id=='segment')
      {
        secimli=this.props.inputValue_.segment
        this.props.fnks(secimli,this.props.inputValue_);
      }
      if(this.props.id=='family')
      {
        secimli=this.props.inputValue_.family;
        //this.props.fnks(secimli,this.props.inputValue_.class)
      }
      if(this.props.id=='class')
      {
        secimli=this.props.inputValue_.class;
        
       
        //this.props.fnks(secimli)
      }
      if(this.props.id=='brick')
      {
        secimli=this.props.inputValue_.pk
      }
    }


    this.state = {
      items:val,
      icons:'+',
      langlist:list,
      secimli:secimli,
      label:'',
      borderColor: 'border_color_black'
    }
    


    this.onInputchange = this.onInputchange.bind(this);
    this.clickc = this.clickc.bind(this);
    this.clickhedef = this.clickhedef.bind(this);
    this.clickbirim = this.clickbirim.bind(this);
    this.onInputchangeHedef = this.onInputchangeHedef.bind(this)
  }
  



  onInputchange(event) {

    /*if(event.target.value!='')
    {
       var items = this.state['items']
       if(items.length==0)
       {
          if(this.state.label=='')
            this.setState({items: [...items, {'input':event.target.value,'lng':this.state['lng_'+this.props.name],'label':'Türkçe'}] })
          else
            this.setState({items: [...items, {'input':event.target.value,'lng':this.state['lng_'+this.props.name],'label':this.state.label}] })
       }
      
    }*/
    console.log("1")
    
    this.setState({secimli:event.target.value})
    if(event.target.name!='lng_brick')
    {
      console.log("2")
      if(this.props.handler!=undefined && this.props.type!=undefined)
        this.props.handler(event.target.value)

      if(event.target.options!=undefined)
      {
        console.log("3")
        this.setState({
          [event.target.name]: event.target.value,
          'label':event.target.options[event.target.selectedIndex].text
        });
      }
      else
      {
        console.log("4")
        this.setState({
          [event.target.name]: event.target.value
        });
      }
    }
    else
    {
      this.props.handler(event.target.value)
    }
  }


  onInputchangeHedef(event) {

    /*if(event.target.value!='')
    {
       var items = this.state['items']
       if(items.length==0)
       {
          if(this.state.label=='')
            this.setState({items: [...items, {'input':event.target.value,'lng':this.state['lng_'+this.props.name],'label':'Türkçe'}] })
          else
            this.setState({items: [...items, {'input':event.target.value,'lng':this.state['lng_'+this.props.name],'label':this.state.label}] })
       }
      
    }*/
    if(this.state.items.length<1)
    {
       
      
    }
    if(event.target.name!='lng_brick')
      {

       
        if(this.props.handler!=undefined && this.props.type!=undefined)
          this.props.handler(this.state.items)



        if(event.target.options!=undefined)
        {
          this.setState({
            [event.target.name]: event.target.value,
            'label':event.target.options[event.target.selectedIndex].text
          });
        }
        else
        {
          this.setState({
            [event.target.name]: event.target.value
          });
        }
      }
      else
      {
        this.props.handler(this.state.items)
      }

   
  }



  clickc(event) {
    console.log("******")
      var items = this.state['items']
      if(event.target.innerHTML=='+')
      {
        var deger = this.state[this.props.name]

        if(deger==undefined)
        {
          this.setState({borderColor:'border_color'})
        }
        else
        {
          this.setState({borderColor:'border_color_black'})
        }

        if(this.state['lng_'+this.props.name]==undefined)
          this.state['lng_'+this.props.name] = 'tr';
        var is_lng = false;
        

        for (var itm of items) {
          if(itm.lng==this.state['lng_'+this.props.name])
            is_lng=true;
        }


        if(!is_lng)
        {
          if(deger!=undefined && deger!='')
          {
            if(this.state['lng_'+this.props.name]==undefined)
            {
              this.setState({items: [...items, {'input':this.state[this.props.name],'lng':'tr','label':'Türkçe'}] })
            }
            else
            {
              if(this.state.label=='')
                this.setState({items: [...items, {'input':this.state[this.props.name],'lng':this.state['lng_'+this.props.name],'label':'Türkçe'}] })
              else
                this.setState({items: [...items, {'input':this.state[this.props.name],'lng':this.state['lng_'+this.props.name],'label':this.state.label}] })
              
            }
          }
        }
      }
      else
      {
        var index = event.target.getAttribute('index')
        items.splice(index,1)
        this.setState({items: items })
      }

      if(this.props.handler!=undefined)
      {
        setTimeout(() => {   this.props.handler(this.state.items) }, 500);
       
      }
    }

    clickbirim(event) {
      var items = this.state['items']
      if(event.target.innerHTML=='+')
      {
        var deger = this.state[this.props.name]

        if(deger==undefined)
        {
          this.setState({borderColor:'border_color'})
        }
        else
        {
          this.setState({borderColor:'border_color_black'})
        }

        if(this.state['lng_'+this.props.name]==undefined)
          this.state['lng_'+this.props.name] = '1N';
        var is_lng = false;
        

        for (var itm of items) {
          if(itm.lng==this.state['lng_'+this.props.name])
            is_lng=true;
        }


        if(!is_lng)
        {
          if(deger!=undefined && deger!='')
          {
            if(this.state['lng_'+this.props.name]==undefined)
            {
              this.setState({items: [...items, {'input':this.state[this.props.name],'lng':'1N','label':'Adet'}] })
            }
            else
            {
              if(this.state.label=='')
                this.setState({items: [...items, {'input':this.state[this.props.name],'lng':this.state['lng_'+this.props.name],'label':'Adet'}] })
              else
                this.setState({items: [...items, {'input':this.state[this.props.name],'lng':this.state['lng_'+this.props.name],'label':this.state.label}] })
              
            }
          }
        }
      }
      else
      {
        var index = event.target.getAttribute('index')
        items.splice(index,1)
        this.setState({items: items })
      }

      if(this.props.handler!=undefined)
      {

        setTimeout(() => {   console.log(this.state.items); this.props.handler(this.state.items) }, 500);
       
      }
    }


    clickhedef(event) {
      console.log("***1")
      var items = this.state['items']
      var is_lng = false;

      if(event.target.innerHTML=='+')
      {
          for (var itm of items) {
            if(itm.input==$('#hedef').val())
              is_lng=true;
          }
          if(!is_lng)
          {

            if(this.state['lng_'+this.props.name]==undefined)
            {
              this.setState({items: [...items, {'input':'792','lng':'Türkiye','label':'Türkiye'}] })
              this.setState({'label':'Türkiye'})
            }
            else
            {
              console.log(this.state['lng_'+this.props.name])
              this.setState({items: [...items, {'input':this.state['lng_'+this.props.name],'lng':this.state.label,'label':this.state.label}] })
            }
          }
      }
      else
      {
        var index = event.target.getAttribute('index')
        items.splice(index,1)
        this.setState({items: items })
      }

      if(this.props.handler!=undefined)
      {
        setTimeout(() => {   this.props.handler(this.state.items) }, 500);
       
      }
    }



   selectbox(btn)
    {
      return (<div className="input-group mb-3 " ref="content">
                <select className={`form-select ${this.state.borderColor}`} id={this.props.id} value={this.state.secimli} name={'lng_'+this.props.name} onChange={this.onInputchange}>
                  { ((this.props.list) ? this.props.list:[]).map((x, i) => {
                    return (<option _ngcontent-c3="" value={x.pk} ng-reflect-value="en" key={i}> {(x.code!='') ? `[${x.code}]` :''} {x.label} </option>)
                  })}
                </select>
                {btn=='true' &&
                <button className="input-group-text" onClick={this.clickc}>+</button>
                }
                </div>)
    }

    selectbox_input(btn)
    {
      return ( <div className="input-group mb-3" ref="content">
                <input onChange={this.onInputchange} type="text" id={this.props.id}  name={this.props.name} className={`input_hh form-control ${this.state.borderColor}`} placeholder={this.props.placeholder} aria-label="Server" />
                <select className={`form-select ${this.state.borderColor}`} id={this.props.id} name={'lng_'+this.props.name} onChange={this.onInputchange}>
                  {this.state.langlist.map((x, i) => {
                    return (<option _ngcontent-c3="" value={x.value} ng-reflect-value="en" key={i}> {x.name} </option>)
                  })}
                </select>
                
                <button className="input-group-text" onClick={this.clickc}>+</button>
              </div>)
    }

    selectbox_input_birim(btn)
    {
      return (   <div className="input-group mb-3" ref="content">
                <input onChange={this.onInputchange} type="text" id={this.props.id} name={this.props.name} className={`input_hh form-control ${this.state.borderColor}`}  placeholder={this.props.placeholder} aria-label="Server" />
                <select className={`form-select ${this.state.borderColor}`}  name={'lng_'+this.props.name} onChange={this.onInputchange}>
                  {this.state.langlist.map((x, i) => {
                    return (<option _ngcontent-c3="" value={x.value} ng-reflect-value="en" key={i}> {x.name} </option>)
                  })}
                </select>
                
                <button className="input-group-text" onClick={this.clickbirim}>+</button>
              </div>)
    }



    selectboxHedef(btn)
    {
      return (<div className="input-group mb-3" ref="content">
                <select className={`form-select ${this.state.borderColor}`} id={this.props.id} name={'lng_'+this.props.name} onChange={this.onInputchangeHedef}>
                  { ((this.state.langlist) ? this.state.langlist:[]).map((x, i) => {
                    return (<option _ngcontent-c3="" value={x.code} ng-reflect-value="en" key={'t_'+i}> {x.name} </option>)
                  })}
                </select>
                {btn=='true' &&
                <button className="input-group-text" onClick={this.clickhedef}>+</button>
                }
                </div>)
    }
   

  render() {
   
    const {items,icons} = this.state;
    console.log(items)
    return (<div>
              {
                this.props.type=='segments' &&
                this.selectbox(this.props.addbtn)
              }

              {
                this.props.type=='hedefpazar' &&
                this.selectboxHedef(this.props.btn)
              }

              {
                this.props.type=='birim' &&
                this.selectbox_input_birim(this.props.btn)
              }

              {
                this.props.type==undefined &&
                this.selectbox_input()
              }
              

              {this.state.items.map((x, i) => {
                if(this.props.name=='hedefpazar')
                {
                  return (<div className="input-group mb-3" ref="content"  key={ this.props.type+'div_'+i}>
                  
                  <input type="text" disabled value={x.label} key={ this.props.type+'_'+i} className="input_hh form-control" />
                  <button className="input-group-text" key={this.props.type+'__'+{i}} onClick={this.clickc} index={i}>-</button>
                </div>)
                }
                else
                {
                  return (<div className="input-group mb-3" ref="content" key={ this.props.type+'div_'+i}>
        
                  <input disabled value={x.input} type="text" id={this.props.id} name={this.props.name+"_"+i} className="input_hh form-control" placeholder={this.props.placeholder} key={ this.props.type+'_'+i}/>
                  <input type="text" disabled value={x.label} className=" input_hh form-control" />
                  <button className="input-group-text" onClick={this.clickc} index={i} key={ this.props.type+'__'+i}>-</button>
                </div>)
                }
                  
              })}
              </div>);
  }
}


export default withTranslators(ApplicationInformationForm);