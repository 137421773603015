import React, {Component, Fragment} from 'react'
import ReactTable from "react-table";
import "react-table/react-table.css";
import {connect} from "react-redux";
import {makeGtin13ListTableData} from "../TableUtils";
import * as gtin13Actions from "../../actions/gtin13Actions";
import {withRouter} from "react-router-dom";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import {faEdit, faSearch, faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";

const DEFAULT_PAGE_SIZE = 10;

class Gtin13List extends Component{
    constructor(props){
        super(props);

        this.state = {
            applicationId: "",
            defaultPageSize: this.props.defaultPageSize ? this.props.defaultPageSize : DEFAULT_PAGE_SIZE,
            table: {
                data: [],
                pages: null,
                loading: true,
            },
        };

        this.fetchData = this.fetchData.bind(this);
    }

    componentWillMount(){
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            const applicationId = this.props.match.params["applicationId"];
            if (applicationId !== undefined && applicationId !== null)
                this.setState({
                    applicationId: this.props.match.params["applicationId"]
                })
        }
    }

    loadDataCallback = (state, result) =>{
        const tableData = makeGtin13ListTableData(result.data, {
            memberId: this.props.memberId,
            udyBaseUrl: this.props.udyBaseUrl,
            udyToken: this.props.udyToken,
            locale: this.props.locale,
        });
        const totalTableSize = result.total_size;

        if(result.status === "ok"){
            this.setState({
                table:{
                    ...this.state.table,
                    data: tableData,
                    pages: Math.ceil(totalTableSize / state.pageSize),
                    loading: false
                },
            })
        }
    };

    fetchData(state, instance) {
        this.setState({
            table:{
                ...state,
                loading: true
            }
        });

        this.props.requestDataForGtin13List(this.state.applicationId, state.pageSize, state.page, state.sorted, this.loadDataCallback, state);
    }

    render(){
        const table = this.state.table;
        const {t} = this.props;

        return(
            <div>
                {
                    !this.props.hideHeader ?
                        <Fragment>
                            <h2 className={"t-center"}><T>GTIN-13 List</T></h2>
                            <ContentModule>
                                <T>All of the GTIN-13s created from all Company Prefix Numbers (GCP) assigned to you are listed below.</T><br/><br/>

                                <T>After the product information is entered, it appears as "Registered", "Active" if the product information is shared with GS1 Head Office 3 hours later, and "Delist" if the product is withdrawn from the market.</T><br/><br/>

                                <T>The functions of the buttons in the GTIN-13 List are as follows;</T><br/><br/>

                                <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-10"}/>: <T>It is used to display the basic attribute information you have defined your product within the scope of "Verified by GS1". You can also access the visual of your product barcode and the GS1 SmartSearch code.</T><br/><br/>

                                <FontAwesomeIcon icon={faEdit} size={"lg"} color={"green"} className={"curpnt mr-10"}/>: <T>The attribute information can be edited within the first 3 (three) hours after registering the product you have entered basic attribute information. 3 (three) hours after the baseline information is recorded.</T>
                                <T>Since the GS1 is sent to the Head Office, your product information cannot be updated outside of this period. For the request for update, the company representative will provide the old and new required information of your product / products.</T>
                                <T>The petition signed and stamped by the company and the authorized signature circular of the company must be sent to us. Also, for all your questions, you can apply to "feedback@gs1tr.org" e-mail address.</T><br/><br/>

                                <FontAwesomeIcon icon={faExternalLinkAlt} size={"lg"} color={"gray"} className={"curpnt mr-10"}/>: <T>The attribute information can be edited within the first 3 (three) hours after registering the product you have entered basic attribute information. 3 (three) hours after the baseline information is recorded.</T>
                                <T>Since the GS1 is sent to the Head Office, your product information cannot be updated outside of this period. For the request for update, the company representative will provide the old and new required information of your product / products.</T>
                                <T>The petition signed and stamped by the company and the authorized signature circular of the company must be sent to us. Also, for all your questions, you can apply to "feedback@gs1tr.org" e-mail address.</T><br/><br/>
                            </ContentModule>
                        </Fragment> : null
                }

                <div className={"table-content "}>
                    <ReactTable
                        columns={[
                            {
                                Header: <T>GTIN-13</T>,
                                accessor: "gtin13"
                            },
                            {
                                Header: <T>Status</T>,
                                accessor: "status",
                            },
                            {
                                Header: <T>Date</T>,
                                accessor: "registry_dt"
                            },
                            {
                                Header: <T>Actions</T>,
                                accessor: "actions",
                                sortable: false
                            },
                        ]}
                        manual
                        data={table.data}
                        pages={table.pages}
                        loading={table.loading}
                        onFetchData={this.fetchData}
                        defaultPageSize={this.state.defaultPageSize}
                        className="-striped -highlight"
                        multiSort={false}

                        previousText={<T>Previous</T>}
                        nextText={<T>Next</T>}
                        loadingText={<T>Loading...</T>}
                        noDataText={<T>No rows found</T>}
                        pageText={<T>Page</T>}
                        ofText={<T>of</T>}
                        rowsText={t('rows')}
                    />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        requestDataForGtin13List: (applicationId, pagesize, page, sorted, callback, state) => {
            let sortedFieldName="", sortedIsDesc="";
            if (sorted.length !== 0){
                sortedFieldName = sorted[0].id;
                sortedIsDesc = sorted[0].desc ? "desc": "asc";
            }

            dispatch(gtin13Actions.dbRequestDataForGtin13List(applicationId, pagesize, page, sortedFieldName, sortedIsDesc, callback, state));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    const { data, authorize } = state;
    return {
        memberId: authorize.memberId,
        udyBaseUrl: authorize.udyBaseUrl,
        udyToken: authorize.udyToken,
        locale: data.locale,
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(Gtin13List)));