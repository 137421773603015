import * as applicationService from "../api/services/applicationService";


export const dbSubmitApplication = (data, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbSubmitApplication(data).then((result) => {
                callback({
                    status: "ok",
                    data: result.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbSubmitMergeMembershipApplication = (data, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbSubmitMergeMembershipApplication(data).then((result) => {
                callback({
                    status: "ok",
                    data: result.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbUpdateApplication = (data, application_id, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbUpdateApplication(data, application_id).then((result) => {
                callback({
                    status: "ok",
                    data: result.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};



export const dbGetApplicationStatus = (application_id, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbGetApplicationStatus(application_id).then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data.data
                })
            },
            (error) =>{
                let messageError = error.response && error.response.data;

                callback({
                    status: "error",
                    data: messageError
                })
            });
    }
};

export const dbCheckCommitmentLetterValidity = (callback) => {
    return (dispatch, getState) => {
        return applicationService.dbCheckCommitmentLetterValidity().then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data.data
                })
            },
            (error) =>{
            });
    }
};

export const dbSubmitNewNumberApplication = (data, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbSubmitNewNumberApplication(data).then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbSubmitTransferApplicationDocLog = (data, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbSubmitTransferApplicationDocLog(data).then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};


export const dbSubmitTransferApplication = (data, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbSubmitTransferApplication(data).then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbSubmitMergeApplication = (data, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbSubmitMergeApplication(data).then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbGetApplicationDetail = (application_id, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbGetApplicationDetail(application_id).then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbGetGcpDetail = (application_id, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbGetGcpDetail(application_id).then((result) => {
                callback({
                    status: result.request.status,
                    data: result.data.data
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

/*---------------------------------------------------Transfer---------------------------------------------------------*/

export const dbGetTransferApplication = (callback) => {
    return (dispatch, getState) => {
        return applicationService.dbGetTransferApplication().then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
            });
    }
};

export const dbGetTransferApplicationDetail = (applicationId, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbGetTransferApplicationDetail(applicationId).then((result) => {

                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
            });
    }
};

export const dbUpdateTransferApplication = (data, application_id, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbUpdateTransferApplication(data, application_id).then((result) => {
                callback({
                    "status": result.request.status,
                })
            },
            (error) =>{
                callback(error);
            });
    }
};

/*---------------------------------------------------Merge------------------------------------------------------------*/

export const dbGetMergeApplication = (callback) => {
    return (dispatch, getState) => {
        return applicationService.dbGetMergeApplication().then((result) => {
                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
            });
    }
};

export const dbGetMergeApplicationDetail = (applicationId, callback) => {
    return (dispatch, getState) => {
        return applicationService.dbGetMergeApplicationDetail(applicationId).then((result) => {

                callback({
                    "status":result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
            });
    }
};
