import React, {Component} from 'react'
import ContentModule from "../ContentModule";
import {T, withTranslators} from "lioness";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import * as auxActions from "../../actions/auxActions";
import TextInput from "../input_types/TextInput";


class SubscriptionDetail extends Component{
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 1,
            acceptPreSales: false
        };
        this.handleAcceptPreSalesOnChange = this.handleAcceptPreSalesOnChange.bind(this);
    }

     handleAcceptPreSalesOnChange(event){
        this.setState({
            acceptPreSales: !this.state.acceptPreSales,
        })
    }

    componentWillMount(){
        const {id} = this.props.match.params;
        this.props.getSubscriptionDetail(id, this.subscriptionDetailCallback)
    }

    subscriptionDetailCallback = (result) => {
        let colorClass = 'bg-orange';
        if(result.data.payment_status === 'Paid'){
            colorClass = 'bg-teal';
        }
        if(result.data.is_cancelled === true){
            colorClass = 'bg-danger';
        }

        this.setState({
            colorClass: colorClass,
            currentStep: 2,
            item:{
                id: result.data.id,
                year: result.data.year,
                order: result.data.order,
                is_cancelled: result.data.is_cancelled,
                invoice_number: result.data.invoice_number,
                invoice_date: result.data.invoice_date,
                subscription_fee: result.data.subscription_fee,
                paid_amount: result.data.paid_amount,
                exemption_amount: result.data.exemption_amount,
                remaining_amount: result.data.remaining_amount,
                payment_status: result.data.payment_status,
                minimum_digit: result.data.minimum_digit,
            }
        });

    };

    render(){
        const {t} = this.props;
        const {currentStep, colorClass} = this.state;

        let preSalesAgreementDownloadUrl = 'https://sistem.gs1tr.org';
        let distantSalesAgreementDownloadUrl = 'https://sistem.gs1tr.org';

        return(
            <div>
                <div className={"content-module-sized"}>
                    <h2 className={"t-center"}><T>Subscription Fee Info</T></h2>

                   {
                       currentStep === 1 ?
                       <div><T>Loading...</T></div>:

                           <React.Fragment>
                               <ContentModule>
                                   <T
                                      message={"The information regarding to your subscription fee ({{ price }}) for {{ year }} is shown. If you did not pay your fee, you can use online payment methods by clicking Pay Now button."}
                                      price={<b>{this.state.item["subscription_fee"].toFixed(2)}₺</b>}
                                      year={<b>{this.state.item["year"]}</b>}
                                   />
                               </ContentModule>
                               <ContentModule>
                                   <form>
                                       <div className="editor small signin">
                                           <div>
                                               <TextInput key={"year"} inputId={"year"} inputTitle={t("Year")} inputValue={this.state.item["year"]} disabled required={false}/>
                                               <TextInput key={"order"} inputId={"order"} inputTitle={t("Order Number")} inputValue={this.state.item["order"]} disabled required={false}/>
                                               <TextInput key={"invoice_number"} inputId={"invoice_number"} inputTitle={t("Invoice Number")} inputValue={this.state.item["invoice_number"]} disabled required={false}/>
                                               <TextInput key={"invoice_date"} inputId={"invoice_date"} inputTitle={t("Invoice Date")} inputValue={this.state.item["invoice_date"]} disabled required={false}/>
                                               <TextInput key={"payment_status"} inputId={"payment_status"} inputTitle={t("Payment Status")} inputValue={t(this.state.item["payment_status"])} disabled required={false}/>
                                               <TextInput key={"minimum_digit"} inputId={"minimum_digit"} inputTitle={t("Minimum Digit")} inputValue={this.state.item["minimum_digit"]} disabled required={false}/>
                                           </div>
                                       </div>
                                   </form>
                               </ContentModule>

                               <ContentModule colorClass={"" + colorClass}>
                                   <div className="editor small signin">
                                       <div className="editor small signin mt-10">
                                           <div className={"d-flex item-priced"}>
                                               <div className={"on-left"}>
                                                   <T>Price</T>
                                               </div>
                                               <div className={"on-right"}>
                                                   {(this.state.item["subscription_fee"] * 100/120).toFixed(2)}₺
                                               </div>
                                           </div>
                                           <div className={"d-flex item-priced"}>
                                               <div className={"on-left"}>
                                                   <T>KDV</T>
                                               </div>
                                               <div className={"on-right"}>
                                                   {(this.state.item["subscription_fee"] * 20/120).toFixed(2)}₺
                                               </div>
                                           </div>
                                           <div className={""}>
                                               <div className={"total-amount"}>
                                                   <T>Total</T>: {this.state.item["subscription_fee"].toFixed(2)}₺
                                               </div>
                                           </div>
                                       </div>

                                       <div className={"d-flex item-priced mt-20"}>
                                           <div className={"on-left"}>
                                               <T>Total Price</T>
                                           </div>
                                           <div className={"on-right"}>
                                               {this.state.item["subscription_fee"].toFixed(2)}₺
                                           </div>
                                       </div>
                                       <div className={"d-flex item-priced"}>
                                           <div className={"on-left"}>
                                               <T>Paid Amount</T>
                                           </div>
                                           <div className={"on-right"}>
                                               {this.state.item["paid_amount"].toFixed(2)}₺
                                           </div>
                                       </div>
                                       {
                                            this.state.item["exemption_amount"] > 0 &&
                                                <div className={"d-flex item-priced"}>
                                                    <div className={"on-left"}>
                                                        <T>Exemption Amount</T>
                                                    </div>
                                                    <div className={"on-right"}>
                                                        {this.state.item["exemption_amount"].toFixed(2)}₺
                                                    </div>
                                                </div>
                                       }
                                       <div className={"d-flex item-priced"}>
                                           <div className={"on-left"}>
                                               <T>Remaining Amount</T>
                                           </div>
                                           <div className={"on-right"}>
                                               {this.state.item["remaining_amount"].toFixed(2)}₺
                                           </div>
                                       </div>
                                   </div>

                                   {
                                       this.state.item['is_cancelled'] === true ?
                                           <div className={"bolded mt-20"}>
                                               <T>Your order has been cancelled.</T>
                                           </div>:
                                           <div>
                                           {
                                                this.state.item['payment_status'] !== 'Paid' ?
                                                    <div>
                                                        <div className={"t-center mt-20"}>
                                                            <input type="checkbox" id={"acceptPreSales"} className={"mr-10"} checked={this.state.acceptPreSales} onChange={this.handleAcceptPreSalesOnChange} />
                                                            <T
                                                                message={"I have read and accepted the {{ pre_sales_agreement }} and {{ distant_sales_aggrement }}."}
                                                                pre_sales_agreement={<a href={preSalesAgreementDownloadUrl} target={"_blank"}><T>Pre Sales Agreement</T></a>}
                                                                distant_sales_aggrement={<a href={distantSalesAgreementDownloadUrl} target={"_blank"}><T>Distant Sales Agreement</T></a>}
                                                            />
                                                        </div>
                                                        <div className="btnInline mt-10 t-center pay mt-20">
                                                            {
                                                                this.state.acceptPreSales ?
                                                                    <Link to={"/member/pay/subscription/" + this.state.item["id"]} className={"signup-button small"}>
                                                                        <T>Pay Now</T>
                                                                    </Link>:
                                                                    <a className={"signup-button small bg-orange-disabled"} title={t("Please accept the terms.")}>
                                                                        <T>Pay Now</T>
                                                                    </a>
                                                            }
                                                        </div>

                                                    </div>:
                                                    <div className={"bolded mt-20"}>
                                                        <T>Payment was successfully received.</T>
                                                    </div>
                                           }
                                           </div>
                                   }
                               </ContentModule>
                           </React.Fragment>
                   }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSubscriptionDetail: (id, callback) =>{
            dispatch(auxActions.dbSubscriptionDetail(id, callback))
        },
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(SubscriptionDetail)));