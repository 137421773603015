import React, {Component} from 'react'
import {connect} from "react-redux";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import HelpFrame from "../Iframe";
import Iframe from 'react-iframe'

class NewApplication extends Component{

    render(){
        const {memberId, udyBaseUrl, udyToken, locale, t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>GTIN-12 Data Entry</T></h2>

                <ContentModule colorClass={"bg-danger"}>
                    <T>GS1 Türkiye aracılığı ile aldığınız mevcut UPC numaralarınıza ait bilgi girişi yapabilirsiniz.</T>
                    <br/><br/>
                    <T
                        message="Yeni UPC temini direkt GS1 A.B.D.’ye başvurularak yapılmaktadır. Gerekli bilgiye {{ link }} sitesinden ulaşabilirsiniz."
                        link={<a href={"https://www.gs1us.org"} target={"_blank"}>https://www.gs1us.org</a>}
                    />
                    <br/><br/>
                    <T>30 Temmuz 2020 tarihine dek GS1 Türkiye üzerinden alınan UPC numaralarına ait bilgi girişi sistemimiz üzerinden yapılabilecektir.</T>
                    <br/><br/>
                    <T>Direkt GS1 A.B.D üzerinden alınmış UPC numaralarına ilişkin ürün bilgi girişi sistemimiz üzerinden yapılamayacak olup, konuyla ilgili olarak GS1 US ile temasa geçilmesi gerekmektedir.</T>
                </ContentModule>

                <ContentModule>
                    <T>GTIN-12's are being used in US and Canada like GTIN-13's are in Turkey. If you aim US and Canada retail markets you need to get a UPC. However for the online market at US and Canada, there are different types of applications. If you want to sell your products online you can contact to GS1 Turkey for assistance.</T><br/><br/>
                    <T>Please enter product information to make your products visible in 114 countries. Your product will be queried on the following seven basic attribute information:</T>
                    <ul>
                        <li><strong><T>GTIN-12</T></strong>: <T>12-Digit Barcode Number used in the USA and Canada markets</T> <b><T>(A GTIN-12 can only be entered once, the financial and legal responsibility of entering the old number in the new product connected to GTIN-12 or deliberately entering the new product belongs to the company)</T></b></li>
                        <li><strong><T>Company Name</T></strong>: <T>The company name registered to the system is automatically assigned</T></li>
                        <li><strong><T>Brand</T></strong>: <T>Brand name on the product (Not a company name)</T></li>
                        <li><strong><T>Label Description</T></strong>: <T>Description on the product label</T></li>
                        <li><strong><T>Target Market</T></strong>: <T>Country where the product is sold (All 7 qualification information must be entered in accordance with the language of this country)</T></li>
                        <li><strong><T>Product Image URL</T></strong>: <T>URL where the product photo is published</T></li>
                        <li><strong><T>Net Content</T></strong>: <T>Net Content of the product which is specified on the product</T></li>
                        <li><strong><T>Unit Of Measurements</T></strong>: <T>The unit of net content</T></li>
                        <li><strong><T>Category</T></strong>: <T>Product category according to GS1 Global Product Category</T></li>
                    </ul>
                    <T>As of 08.06.2020, our automatic barcode number generation service has started on the system.</T><br/><br/>
                    <T>You can enter basic attributes of your product and you can have the system automatically generate the barcode number (GTIN-Global Trade Item Number) for you, product specific and singular.</T><br/><br/>
                    <T>However, since the generation of the product barcode number by the system is carried out with the numbers obtained from the pool of barcodes that have not been used yet; It is important that you do not generate a new number for a product that you have put on the market before and has a barcode number on it.</T><br/><br/>

                    <T
                        message="For detailed information about product registy {{ link }}."
                        link={<a href={"https://gs1tr.org/dosyalar/" + t("infoaccess") + ".pdf"} target={"_blank"}><T>click</T></a>}
                    />
                </ContentModule>

                <HelpFrame
                    source={"GTIN_12/GTIN-12.html"}
                />

                <ContentModule padless={true}>
                    <Iframe
                        url={udyBaseUrl + "/#/login?token=" + udyToken + "&member_id=" + memberId + "&module=catalogue_new_gtin12&lang=" + locale}
                        width="100%"
                        height="600px"
                        id="new-gtin12-iframe"
                        frameBorder={0}
                    />
                </ContentModule>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { data, authorize } = state;
    return {
        memberId: authorize.memberId,
        udyBaseUrl: authorize.udyBaseUrl,
        udyToken: authorize.udyToken,
        locale: data.locale,
    }
};

export default connect(mapStateToProps)(withTranslators(NewApplication));
