import React, {Component} from 'react'
import * as authorizeActions from "../actions/authorizeActions";
import {connect} from "react-redux";
import {T} from "lioness";

class PassiveUser extends Component{
    componentWillMount(){
        setTimeout(() =>{
            this.props.logout()
        }, 500);
    }

    render(){
        return(
            <div className={"main-body"}>
                <T>This user is passive</T>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => {
            dispatch(authorizeActions.dbLogout())
        },
    }
};

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PassiveUser);
