import React, {Component} from 'react'
import ContentModule from "./ContentModule";
import {T} from "lioness";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class DebtInfo extends Component{

    render(){
        return(
            <ContentModule containerClass={"content-module-sized"}>
                <h2 className={"debdt-title"}><T>You Have Existing Debts</T></h2>
                <div>
                    {
                        this.props.expiredSubscriptionFeeList.length ?
                            <div>
                                {

                                    this.props.expiredSubscriptionFeeList.map( (elem)=>
                                        <div key={Math.floor(Math.random() * 1000)}>
                                            {elem[0]} <T>Dönemi Borcunuz</T> {elem[2]}₺ 'dir.
                                            <Link to={"/member/payment/subscription/" + elem[1]} className={"ml-5"} style={{ fontWeight: 'bolder', color:'red' }}>
                                                <T>Pay Now</T>
                                            </Link><br/>
                                            <a href={"https://sistem.gs1tr.org/media/invoice/"+elem[0]+'/'+elem[3]+'.pdf'} target="_blank">{elem[0]} <T>Dönem faturanızı buradan indirebilirsiniz</T></a>
                                        </div>
                                    )
                                }
                            </div>:
                            <div>
                                <T>You have expired debts, please contact to GS1 to pay. GS1 may take legal action. The debt amount:</T> {this.props.debt}₺
                            </div>
                    }
                
                    
                    <br/>
                    <T>Invoice has been sent to your billing address.</T>
                    <br/><br/>
                    <T>Please get in touch with GS1 Turkey in order to continue your membership services.</T>
                </div>
            </ContentModule>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

const mapStateToProps = (state, ownProps) => {
    const {authorize} = state;
    return {
        debt: authorize.debt,
        expiredSubscriptionFeeList: authorize.expiredSubscriptionFeeList,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DebtInfo);