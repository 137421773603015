import axios from 'axios';
import {axiosError} from "./errorHandler";
import {
    gtin8ApplicationEdit,
    gtin8ApplicationItem, gtin8DeleteApplicationItem, gtin8EditApplicationItemFromNew,
    gtin8LoadApplication,
    gtin8NewApplication, gtin8NewApplicationItem,
    gtin8ValidateNewApplication,
    requestDataForGtin8, requestDataForGtin8ApplicationItemList,
    requestDataForGtin8List,
} from "../../constants/Gtin8UrlConstants";
import {getHeaderTokenWithMultipart, getHeaderWithToken} from "./authToken";


export const dbValidateGtin8Application = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(gtin8ValidateNewApplication + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbCreateGtin8Application = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(gtin8NewApplication, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbLoadGtin8Application = (applicationId, callback) => {
    return new Promise(((resolve, reject) => {
        axios.get(gtin8LoadApplication + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbRequestDataForGtin8 = (pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForGtin8 +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbRequestDataForGtin8List = (tableType, pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForGtin8List +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc + "&table_type=" + tableType,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbLoadGtin8ApplicationEdit = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(gtin8ApplicationEdit + "?application_id=" + applicationId, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbEditGtin8Application = (data, applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.post(gtin8ApplicationEdit + "?application_id=" + applicationId, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbLoadGtin8ApplicationItem = (applicationId, edit) => {
    return new Promise(((resolve, reject) => {
        axios.get(gtin8ApplicationItem + "?application_id=" + applicationId + "&edit=" + edit, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbEditGtin8ApplicationItem = (data, applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.post(gtin8ApplicationItem + "?application_id=" + applicationId, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};


export const dbCreateGtin8ApplicationItem = (data, applicationId) => {
    const query = applicationId ? "?application_id=" + applicationId : "";
    return new Promise(((resolve, reject) => {
        axios.post(gtin8NewApplicationItem + query, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbRequestDataForGtin8ApplicationItemList = (applicationId, pagesize, page) => {
    const query = applicationId ? "&application_id=" + applicationId : "";

    return new Promise(((resolve, reject) => {
        axios.get(requestDataForGtin8ApplicationItemList +
            "?pagesize=" + pagesize + "&page=" + page + query,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};


export const dbDeleteGtin8ApplicationItem = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.post(gtin8DeleteApplicationItem + "?application_id=" + applicationId, {}, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbEditApplicationItemFromNew = (applicationId, data) => {
    const query = applicationId ? "?application_id=" + applicationId : "";
    return new Promise(((resolve, reject) => {
        axios.post(gtin8EditApplicationItemFromNew + query, data, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};
