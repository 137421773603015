import thunk from 'redux-thunk'
import {routerMiddleware} from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'
// import {createLogger} from 'redux-logger'
import {applyMiddleware, combineReducers, createStore} from 'redux';

import {authReducer} from "../reducers/authReducer";
import {dataReducer} from "../reducers/dataReducer";

export const history = createHistory();

const middleware = routerMiddleware(history);
// const logger = createLogger();

// - Reducers
let reducer = combineReducers({
    authorize: authReducer,
    data: dataReducer,
});

const store = applyMiddleware(thunk, middleware)(createStore)(reducer);

export default store;
