import React, {Component} from 'react'
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import {Link} from "react-router-dom";

class PayInfo extends Component{

    constructor(props){
        super(props);
        this.state = {
            acceptPreSales: false,
        };

        this.handleAcceptPreSalesOnChange = this.handleAcceptPreSalesOnChange.bind(this);
    }

    handleAcceptPreSalesOnChange(event){
        this.setState({
            acceptPreSales: !this.state.acceptPreSales
        })
    }

    render(){
        const {service_fee, is_cancelled, payment_status, path, pre_sales_agreement, distant_sales_agreement, sized, t} = this.props;

        let colorClass = 'bg-orange';

        if(payment_status === 'P'){
            colorClass="bg-teal";
        }

        if(is_cancelled === true){
            colorClass="bg-danger";
        }

        let containerClass = "";
        if(sized === true){
            containerClass = "content-module-sized";
        }

        return(
            <div>
                {
                    service_fee ?
                        <div className={"mb-10"}>
                            <ContentModule containerClass={containerClass} colorClass={colorClass}>
                                <div className="editor small signin mt-10">
                                    <div className={"d-flex item-priced"}>
                                        <div className={"on-left"}>
                                            <T>Price</T>
                                        </div>
                                        <div className={"on-right"}>
                                            {(service_fee * 100/120).toFixed(2)}₺
                                        </div>
                                    </div>
                                    <div className={"d-flex item-priced"}>
                                        <div className={"on-left"}>
                                            <T>KDV</T>
                                        </div>
                                        <div className={"on-right"}>
                                            {(service_fee * 20/120).toFixed(2)}₺
                                        </div>
                                    </div>
                                    <div className={""}>
                                        <div className={"total-amount"}>
                                            <T>Total</T>: {service_fee.toFixed(2)}₺
                                        </div>
                                    </div>
                                </div>
                                {
                                    is_cancelled === true ?
                                        <div className={"bolded mt-20"}>
                                            <T>Your order has been cancelled.</T>
                                        </div>:
                                        <div>
                                            {
                                                payment_status !== 'P' ?
                                                    <div>
                                                        <div className={"t-center mt-20"}>
                                                            <input type="checkbox" id={"acceptPreSales"} className={"mr-10"} checked={this.state.acceptPreSales} onChange={this.handleAcceptPreSalesOnChange} />
                                                            <T
                                                                message={"I have read and accepted the {{ pre_sales_agreement }} and {{ distant_sales_aggrement }}."}
                                                                pre_sales_agreement={<a href={pre_sales_agreement} target={"_blank"}><T>Pre Sales Agreement</T></a>}
                                                                distant_sales_aggrement={<a href={distant_sales_agreement} target={"_blank"}><T>Distant Sales Agreement</T></a>}
                                                            />
                                                        </div>
                                                        <div className="btnInline mt-10 t-center pay mt-20">
                                                            {
                                                                this.state.acceptPreSales ?
                                                                    <Link to={path}
                                                                          className={"signup-button small"}>
                                                                        <T>Pay Now</T>
                                                                    </Link>:
                                                                    <a className={"signup-button small bg-orange-disabled"} title={t("Please accept the terms.")}>
                                                                        <T>Pay Now</T>
                                                                    </a>
                                                            }
                                                        </div>

                                                    </div>:
                                                    <div className={"bolded mt-20"}>
                                                        <T>Payment was successfully received.</T>
                                                    </div>
                                            }
                                        </div>
                                }

                            </ContentModule>
                        </div>:null
                }
            </div>
        )
    }
}


export default withTranslators(PayInfo);