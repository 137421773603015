import {siteUrl} from './UrlConstants';

export const newApplicationUrl = siteUrl + '/api/gcp/application';
export const applicationDetailUrl = siteUrl + '/api/gcp/application/detail';
export const gcpDetailUrl = siteUrl + '/api/gcp/detail';
export const newNumberApplicationUrl = siteUrl + '/api/gcp/newnumberapplication';
export const applicationStatus = siteUrl + '/api/gcp/application/status';
export const updateApplicationUrl = siteUrl + '/api/gcp/application/update';
export const naceCodesUrl = siteUrl + '/api/gcp/nace_codes';
export const gcpDeleteUrl = siteUrl + '/api/gcp/gcpcancel';
export const membershipDeleteUrl = siteUrl + '/api/gcp/membershipcancel';
export const commitmentLetterDownloadUrl = siteUrl + '/api/gcp/download/commitmentfile';
export const updateCommitmentLetterDownloadUrl = siteUrl + '/api/gcp/download/updatecommitmentfile';
export const applicationFormDownloadUrl = siteUrl + '/api/gcp/download/applicationform';
export const gcpWithdrawApplicationUrl = siteUrl + '/api/gcp/withdraw';
export const requestApplicationDataForGcpTableUrl = siteUrl + '/api/gcp/applications';
export const cancellingGcpCommitmentLetterDownloadUrl = siteUrl + '/api/gcp/download/cancelgcpcommitmentfile';
export const cancellingMembershipCommitmentLetterDownloadUrl = siteUrl + '/api/gcp/download/cancelmembershipcommitmentfile';
export const requestDataForGcpTableUrl = siteUrl + '/api/gcp/gcplist';

// transfer urls
export const loadActiveGcpsUrl = siteUrl + '/api/gcp/activegcplist';
export const transferPetitionLetterDownloadUrl = siteUrl + '/api/gcp/download/transferpetitionfile';
export const transferofInheritnceLetterDownloadUrl = siteUrl + '/api/gcp/download/transferofinheritancefile';

export const requestDataForTransferTable = siteUrl + '/api/gcp/transfer/list';
export const transferLetterDownloadUrl = siteUrl + '/api/gcp/download/transferfile';
export const newTransferApplicationDocLogUrl = siteUrl + '/api/gcp/transfer/doclog';

export const newTransferApplicationUrl = siteUrl + '/api/gcp/transfer/new';
export const transferApplicationDetailUrl = siteUrl + '/api/gcp/transfer/application/detail';
export const updateTransferApplicationUrl = siteUrl + '/api/gcp/transfer/update';

//merge urls
export const mergeLetterDownloadUrl = siteUrl + '/api/gcp/download/mergefile';
export const newMergeApplicationUrl = siteUrl + '/api/gcp/transfer/merge/new';
export const mergeApplicationDetailUrl = siteUrl + '/api/gcp/transfer/merge/detail';
export const updateMergeApplicationlUrl = siteUrl + '/api/gcp/transfer/merge/edit';


// gtin 13 urls
export const gtin13NewApplicationUrl = siteUrl + '/api/gcp/gtin13/application';
export const requestDataForGtin13List = siteUrl + '/api/gcp/gtin13/list';
export const gtin13ApplicationDetail = siteUrl + '/api/gcp/gtin13/application/detail';
export const gtin13ApplicationEdit = siteUrl + '/api/gcp/gtin13/application/edit';
