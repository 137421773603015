import React, {Component} from 'react'
import '../styles/Login.css'
import {connect} from "react-redux";

import TextInput from "./input_types/TextInput";
import * as activateActions from "../actions/activateActions";
import {push} from "react-router-redux";
import {EMAIL_TYPE_ACTIVATE, EMAIL_TYPE_RESET_PASSWORD} from "../constants/EmailTypes";
import {withTranslators} from "lioness";
import ContentModule from "./ContentModule";


class ActivateResetImp extends Component{
    constructor(props){
        super(props);

        this.state = {
            emailInput: '',
            passwordInput1: '',
            passwordInput2: '',

            activateResetError: false,
            activateResetErrorMessage: '',

            linkType: null,
            memberId: null,
            headerTitle: '',

            loaded: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        const {linkType, tokenId} = this.props;
        if (linkType === EMAIL_TYPE_ACTIVATE){
            this.setState({
                linkType,
                headerTitle: 'Register'
            })
        }
        else if (linkType === EMAIL_TYPE_RESET_PASSWORD){
            this.setState({
                linkType,
                headerTitle: 'Reset Password'
            })
        }
        this.props.getUser(tokenId, this.getUserCallBack)
    }

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    getUserCallBack = (messageResult) => {
        if (messageResult.status === "ok"){
            if (Object.keys(messageResult.data).length === 0){
                //not found
                this.props.goTo('/not-found');
            }
            else {
                if (messageResult.data.is_expired){
                    console.log('redirecting...');
                    this.props.goTo('/to/expired');
                }
                else {
                    this.setState({
                        loaded: true,
                        emailInput: messageResult.data.email,
                        memberId: messageResult.data.pk
                    })
                }
            }
        }
        else {

        }
    };

    registerOrResetCallBack = (messageResult) => {
    };

    validateInputs(){
        let errorName = '';
        let errorFound = false;
        const {t} = this.props;

        if (this.state.passwordInput1 !== this.state.passwordInput2){
            errorName = t('Passwords do not match!');
            errorFound = true;
        }
        else if(this.state.passwordInput1.length < 6 || this.state.passwordInput2.length < 6){
            errorName = t('Password is too short');
            errorFound = true;
        }

        this.setState({
            activateResetError: errorFound,
            activateResetErrorMessage: errorName
        });

        return errorFound;
    }

    handleSubmit(event) {
        event.preventDefault();

        if(!this.validateInputs()){
            if (this.state.linkType === EMAIL_TYPE_ACTIVATE) 
                this.props.registerOrResetUser(this.state.memberId, this.state.passwordInput1, true, this.registerOrResetCallBack);
            else if (this.state.linkType === EMAIL_TYPE_RESET_PASSWORD)
                this.props.registerOrResetUser(this.state.memberId, this.state.passwordInput1, false, this.registerOrResetCallBack);
        }
    }

    render(){
        const {t} = this.props;
        return(
            <div>
                {
                    this.state.loaded
                    &&
                <div>
                    <h2 className={"t-center"}>{t(this.state.headerTitle)}</h2>
                    <ContentModule>
                        <form onSubmit={this.handleSubmit}  className="editor small signin">
                            <div>
                                <TextInput key={"emailInput"} inputType={"email"} inputId={"emailInput"} inputTitle={t("Email")} disabled={true} inputValue={this.state.emailInput}/>
                                <TextInput key={"passwordInput1"} inputType={"password"} inputId={"passwordInput1"} inputTitle={t("Password")} inputValue={this.state.passwordInput1} handleInputChange={this.handleInputChange}/>
                                <TextInput key={"passwordInput2"} inputType={"password"} inputId={"passwordInput2"} inputTitle={t("Repeat Password")} inputValue={this.state.passwordInput2} handleInputChange={this.handleInputChange}/>
                            </div>
                            <div className="btnInline t-center mt-10">
                                <input className="signup-button" type="submit" value={t("Submit")}/>
                            </div>
                            <div className={"after-submit"}>
                                <br/>
                                {
                                    this.state.activateResetError &&
                                    <div className="validation-summary-errors">
                                        <span>{this.state.activateResetErrorMessage}</span>
                                    </div>
                                }
                            </div>
                        </form>
                    </ContentModule>
                </div>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUser: (tokenId, callBack) => {
            dispatch(activateActions.dbGetUser(tokenId, callBack))
        },
        registerOrResetUser: (memberId, password, isRegister, callBack) => {
            dispatch(activateActions.dbRegisterOrResetUser(memberId, password, isRegister, callBack))
        },
        goTo: (url) => {
          dispatch(push(url))
        }
    }
};

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(ActivateResetImp));
