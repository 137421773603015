import React, {Component} from 'react'
import * as authorizeActions from "../../actions/authorizeActions";
import {connect} from "react-redux";
import {Redirect} from 'react-router'


class Logout extends Component{
    constructor(props){
        super(props);
        props.logout();
    }

    render(){
        return(
            <Redirect to="/login" />
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => {
            dispatch(authorizeActions.dbLogout())
        },
    }
};

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
