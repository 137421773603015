import React, {Component} from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TextInput from "../input_types/TextInput";
import FileInput from "../input_types/FileInput";

import '../../styles/GcpCancel.css'
import * as gcpActions from "../../actions/gcpActions";
import {cancellingGcpCommitmentLetterDownloadUrl} from "../../constants/GcpUrlConstants";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import {isFileSizeExceeded, showFileError} from "../FileUtils";

class CancelGcp extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            gcpInput: "",
            signedCommitmentLetterFile: null,
            signatoryCircularFile: null,
            applicationId: null,
            hasDebt: false,
            submitting: false,

            extraFiles: null,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            [event.target.id]: submitFile,
        });

    };

    memberDebtCallback = (result) => {
        if (result.status === 200){
            this.setState({currentStep: 2, hasDebt: result.data.has_debt});

            if (!result.data.has_debt){
                const {applicationId} = this.props.match.params;
                this.props.getGcpNumber(applicationId, this.gcpNumberCallback);
                this.setState({applicationId: applicationId})
            }
        }
    };

    gcpNumberCallback = (result) => {
        if (result.status === 200){
            if (result.data.is_membership_deletion){
                this.setState({
                    currentStep: 3,
                })
            }
            else if (result.data.has_unapproved){
                this.setState({
                    currentStep: 4,
                })
            }
            else {
                this.setState({
                    currentStep: 5,
                    gcpInput: result.data.gcp_number,
                })
            }
        }
    };

    gcpCancelCallback = (result) => {
        if (result.status === 200) {
            this.setState({
                currentStep: 6,
                submitting: false
            })
        }
        else
            this.setState({
                submitting: false
            })
    };

    componentWillMount(){
        //load debt
        this.props.getMemberDebt(this.memberDebtCallback);
    }

    handleSubmit(event) {
        event.preventDefault();

        const formData = new FormData();
        formData.append('signed_commitment_letter', this.state.signedCommitmentLetterFile);

        if (this.state.signatoryCircularFile)
            formData.append('signatory_circular', this.state.signatoryCircularFile);

        let extraFiles= this.state.extraFiles;
        if(extraFiles) {
            for (let i = 0; i < extraFiles.length; i++) {
                formData.append('extra_files', extraFiles[i]);
            }
        }

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        this.props.gcpCancel(
            this.state.applicationId,
            formData,
            this.gcpCancelCallback
        );
    }

    render(){
        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>Cancel GCP</T></h2>

                <ContentModule>
                    <T>You can cancel your GCPs. However GTIN-13's which are generated from the cancelling GCP should not be used. Otherwise legal processes regarding unlawful usage of GCP will be started.</T>
                </ContentModule>


                <div className={"mt-20"}>
                {
                    this.state.currentStep === 1 ?
                        <div className={"t-center"}><T>Loading...</T></div> :

                        this.state.currentStep === 2 && this.state.hasDebt ?
                            <ContentModule colorClass={"bg-danger"}>

                            {/* iterate over assesments */}
                            <h2 className={"debdt-title"}><T>You Have Existing Debts</T></h2>
                            <p>
                                <T>The deletion of this gcp can not be performed because of existing debts!</T>
                            </p>

                        </ContentModule>:

                            this.state.currentStep === 3 ?
                                <div className={"debt-error"}>
                                    <T>This operation removes all your membership. Please cancel your membership from the menu.</T>
                                </div> :

                                this.state.currentStep === 4?
                                    <div className={"debt-error"}>
                                        <T>You have an existing unapproved gcp numbers/membership for cancellation.</T>
                                    </div> :

                                    this.state.currentStep === 5 ?
                                <form onSubmit={this.handleSubmit}>
                                    <fieldset className="editor small signin">
                                        {
                                            <div>
                                                <TextInput key={"gcpInput"} inputId={"gcpInput"} inputTitle={t("Gcp Number")} inputValue={this.state.gcpInput} disabled required={false}/>
                                                <div className={"mt-10"}>
                                                    <T
                                                        message={"Please first download the {{ link }}"}
                                                        link={<a href={cancellingGcpCommitmentLetterDownloadUrl + "?id=" + this.state.applicationId} target={"_blank"}><T>Cancel Commitment Letter</T></a>}
                                                    />
                                                </div>
                                                <FileInput inputId={"signedCommitmentLetterFile"} inputTitle={t("Signed Commitment Letter File")} handleUploadFile={this.handleUploadFile} required/>
                                                <FileInput inputId={"signatoryCircularFile"} inputTitle={t("Signatory Circular File")} handleUploadFile={this.handleUploadFile} required/>
                                                <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false} />
                                            </div>
                                        }
                                    </fieldset>
                                    <div className="btnInline mt-10 t-center">
                                        <input className={"signup-button " + isSubmitting} type="submit" value="Submit"/>
                                    </div>
                                </form>:

                                this.state.currentStep === 6 ?
                                    <div>
                                        <T>The deletion of this gcp is successfully submitted</T>
                                    </div> : null
                }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMemberDebt: (callback) =>{
            dispatch(gcpActions.dbGetMemberDebt(callback))
        },
        getGcpNumber: (applicationId, callback) =>{
            dispatch(gcpActions.dbGetGcpNumber(applicationId, callback))
        },
        gcpCancel: (applicationId, formData, callback) =>{
            dispatch(gcpActions.dbGcpCancel(applicationId, formData, callback))
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(CancelGcp)));