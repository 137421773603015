import React, {Component} from 'react'
import * as authorizeActions from "../../actions/authorizeActions";
import TextInput from "../input_types/TextInput";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {T, withTranslators} from 'lioness';

import '../../styles/Login.css'
import ContentModule from "../ContentModule";

class ImpersonateLogin extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            memberId: '',
            memberTitle: '',
            emailInput: '',
            passwordInput: '',
            keyInput: '',

            loginError: false,
            loginErrorMessage: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        this.setState({
            memberId: this.props.match.params.memberId,
        });

        this.props.getMemberTitle(
            this.props.match.params.memberId,
            this.memberTitleCallback
        )
    }

    memberTitleCallback = (result) => {
        if (result.status === 200){
            this.setState({
                currentStep: 2,
                memberTitle: result.data.title
            })
        }
    };

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    loginCallBack = (messageError) => {
        this.setState({
            loginError: true,
            loginErrorMessage: messageError
        })
    };

    handleSubmit(event) {
        event.preventDefault();

        if(this.state.emailInput.trim() && this.state.passwordInput.trim()){
            const formData = new FormData();
            formData.append("username", this.state.emailInput);
            formData.append("password", this.state.passwordInput);
            formData.append("member_id", this.state.memberId);
            formData.append("key", this.state.keyInput);

            this.props.login(
                formData,
                this.loginCallBack
            );
        }
    }

    render(){
        const {t} = this.props;
        return(
            <div className={"pos-abs"}>
                <h1 className={"t-center"}><T>Login</T></h1>
                {
                    this.state.currentStep === 1 ?
                    <div className={"t-center"}>
                        <T>Loading..</T>
                    </div>:

                    <ContentModule>
                        <form onSubmit={this.handleSubmit} className={"editor small sigin"}>
                            <div>
                                <TextInput key={"memberIdInput"} inputId={"memberIdInput"} inputTitle={t("Member Id")} inputValue={this.state.memberTitle} required={false} disabled/>
                                <TextInput key={"emailInput"} inputType={"email"} inputId={"emailInput"} inputTitle={t("Email")} inputValue={this.state.emailInput} handleInputChange={this.handleInputChange}/>
                                <TextInput key={"passwordInput"} inputType={"password"} inputId={"passwordInput"} inputTitle={t("Password")} inputValue={this.state.passwordInput} handleInputChange={this.handleInputChange}/>
                                <TextInput key={"keyInput"} inputType={"password"} inputId={"keyInput"} inputTitle={t("Key")} inputValue={this.state.keyInput} handleInputChange={this.handleInputChange} maxLength={6}/>
                            </div>
                            <div className="btnInline d-flex-center mt-10">
                                <button className="btn" type="submit"><T>Login</T></button>
                            </div>
                            <div className={"after-submit"}>
                                {
                                    this.state.loginError &&
                                    <div className="validation-summary-errors">
                                        <span dangerouslySetInnerHTML={{ __html: this.state.loginErrorMessage }}/>
                                    </div>
                                }
                            </div>
                        </form>
                    </ContentModule>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        login: (data, callBack) => {
            dispatch(authorizeActions.dbImpersonateLogin(data, callBack))
        },
        getMemberTitle: (memberId, callBack) => {
            dispatch(authorizeActions.dbGetMemberTitle(memberId, callBack))
        },
    }
};

const mapStateToProps = (state) => {
    return {
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ImpersonateLogin)));
