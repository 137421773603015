import React, {Component} from 'react'
import {connect} from "react-redux";
import * as auxActions from "../actions/auxActions";
import {T} from "lioness";

class TermsOfUse extends Component{
    constructor(props){
        super(props);

        this.state = {
            text: ''
        }
    }

    termsOfUseCallback = (text) => {
        this.setState({
            text
        })
    };

    componentWillMount(){
        this.props.getTermsOfUse(this.termsOfUseCallback)
    }

    render(){
        return(
            <div>
                <h2 className={"t-center"}>
                    <T>Terms of use</T>
                </h2>
                {
                    this.state.text &&
                    this.state.text.split('\n').map((part, index) =>
                        <div className={"mt-10"} key={index}>
                            {part}
                        </div>
                    )
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getTermsOfUse: (callback) => {
            dispatch(auxActions.dbGetTermsOfUse(callback))
        },
    }
};

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUse);
