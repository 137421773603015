import React, {Component} from 'react'
import TextInput from "../input_types/TextInput";
import {withRouter} from "react-router-dom";
import * as gtin8Actions from "../../actions/gtin8Actions";
import {connect} from "react-redux";
import {T, withTranslators} from "lioness";
import GcpSelectInput from "../input_types/GcpSelectInput";
import ContentModule from "../ContentModule";
import SSInput from "../input_types/SSInput";
import * as dataActions from "../../actions/dataActions";

class ApplicationItemEdit extends Component{

    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            applicationItem: {},
            submitting: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStateNameChange= this.handleStateNameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleGcpBrowserInputChange = this.handleGcpBrowserInputChange.bind(this);
    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }

        this.props.loadApplicationItem(applicationId, this.applicationItemCallback);

        if (!this.props.citiesLoaded)
            this.props.getCountryData();
    }

    applicationItemCallback = (result) => {
        if (result.status === 200){
            let imageUrl = result.data.imageUrl;
            if (imageUrl === "-")
                imageUrl = "";

            this.setState({
                applicationItem: {
                    "gtin8": result.data.gtin8,
                    "targetMarket": result.data.targetMarket,
                    "brand": result.data.brand,
                    "labelDescription": result.data.labelDescription,
                    "imageUrl": imageUrl,
                    "hasBrick": result.data.has_brick,
                    "selectedBrick": result.data.brick,
                },
                currentStep: 2
            })
        }
    };

    constructViewNumber(){
        const {t} = this.props;
        const selectedBrick =  this.state.applicationItem.selectedBrick && Object.keys(this.state.applicationItem.selectedBrick).length ? this.state.applicationItem.selectedBrick : null;

        return <div>
                    <TextInput key={"gtin8"} inputId={"gtin8"} inputTitle={t("Gtin8 number")} inputValue={this.state.applicationItem["gtin8"]} disabled/>
                    <SSInput select={"targetMarket"} inputId={"targetMarket"} inputTitle={t("Target Market")} value={this.state.applicationItem['targetMarket']} handleInputChange={this.handleStateNameChange} isSSloaded={this.props.countriesLoaded} ssData={this.props.countriesData} />
                    <TextInput key={"brand"} inputId={"brand"} inputTitle={t("Brand")} inputValue={this.state.applicationItem["brand"]}  maxLength={64} handleInputChange={this.handleInputChange}/>
                    <TextInput key={"labelDescription"} isTextArea inputId={"labelDescription"} inputTitle={t("Label Description")} inputValue={this.state.applicationItem["labelDescription"]} maxLength={500} handleInputChange={this.handleInputChange}/>
                    <TextInput key={"imageUrl"} inputId={"imageUrl"} inputTitle={t("Image Url")} inputValue={this.state.applicationItem["imageUrl"]} inputType={"url"}  placeholder="https://example.com/path/to/image" handleInputChange={this.handleInputChange} required={false}/>
                    <GcpSelectInput fromClass={"select-modal"} selectedBrick={selectedBrick} handleInputChange={this.handleGcpBrowserInputChange}/>
                </div>
    }

    handleStateNameChange(event, parentKey) {
        this.setState({
            applicationItem: {
                ...this.state.applicationItem,
                'targetMarket': event
            }
        });
    }

    handleInputChange(event) {
        this.setState({
            ...this.state,
            applicationItem: {
                ...this.state.applicationItem,
                [event.target.id]: event.target.value
            }
        });
    }

    handleGcpBrowserInputChange(key, selectedBrick){
        this.setState({
            ...this.state,
            applicationItem: {
                ...this.state.applicationItem,
                "selectedBrick": selectedBrick
            }
        });
    }

    submitFormCallback = (result) =>{
        if (result.status === 200){
            this.setState({
                currentStep: 3
            })
        }
        else {
            this.setState({
                submitting: false
            })
        }
    };

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        const formData = new FormData();
        let application = this.state.applicationItem;
        application["brick"] = this.state.applicationItem.selectedBrick.pk;
        application["targetMarket"] = this.state.applicationItem.targetMarket.pk;

        formData.append('application', JSON.stringify(application));
        const {applicationId} = this.props.match.params;

        this.props.submitForm(formData, applicationId, this.submitFormCallback)
    }

    render(){
        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>Update GTIN-8 Data</T></h2>
                <ContentModule>
                    <T>You need to update following product data.</T><br/>
                    <T>In order to apply new identifiers you should correct all product information.</T>
                </ContentModule>
                {
                    this.state.currentStep === 1 ?
                        <div><T>Loading...</T></div> :

                        this.state.currentStep === 2 ?
                            <ContentModule>
                                <form onSubmit={this.handleSubmit} className="editor small signin">
                                    {
                                        this.constructViewNumber()
                                    }
                                    <div className="btnInline mt-10 t-center">
                                        <input className={"signup-button small t-center " + isSubmitting} type={"submit"} value={t("Submit")}/>
                                    </div>
                                </form>
                            </ContentModule> :
                            <ContentModule>
                                <h3><T>GTIN-8 is successfully edited.</T></h3>
                            </ContentModule>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationItem: (applicationId, callback) => {
            dispatch(gtin8Actions.dbLoadGtin8ApplicationItem(applicationId, callback, 1))
        },
        submitForm: (data, applicationId, callBack) => {
            dispatch(gtin8Actions.dbEditGtin8ApplicationItem(data, applicationId, callBack))
        },
        getCountryData: () => {
            dispatch(dataActions.dbGetCountryData());
        },
    }
};

const mapStateToProps = (state) => {
    const { data } = state;
    return {
        countriesLoaded: data.countriesLoaded,
        countriesData: data.countriesData,
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ApplicationItemEdit)));
