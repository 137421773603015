import React, {Component, Fragment} from 'react'
import FileInput from "../input_types/FileInput";
import '../../styles/Gtin8.css'
import TextInput from "../input_types/TextInput";
import {connect} from "react-redux";
import * as gtin8Actions from "../../actions/gtin8Actions";
import {T, withTranslators} from "lioness";
import GcpSelectInput from "../input_types/GcpSelectInputv2";
import ReactTable from "react-table";
import ReactModal from "react-modal";
import '../../styles/Modal.css'
import {makeApplicationItemTableData} from "../TableUtils";
import {commitmentLetterDownloadUrl} from "../../constants/GcpUrlConstants";
import swal from 'sweetalert'
import ContentModule from "../ContentModule";
import {faEdit, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {Redirect} from "react-router-dom";
import Iframe from "../Iframe";
import * as dataActions from "../../actions/dataActions";
import SSInput from "../input_types/SSInput";
import MultiSelectInput from "../input_types/MultiSelectInputv2";

import {isFileSizeExceeded, showFileError} from "../FileUtils";
import ApplicationInformationForm from "../input_types/ApplicationInformationForm";
import {brickUrl, classUrl, familyUrl, segmentsUrl} from "../../constants/CommonUrlConstants";
import axios from 'axios';
import {siteUrl} from '../../constants/UrlConstants';
import $ from 'jquery';
const DEFAULT_PAGE_SIZE = 5;

class NewApplication extends Component{

    constructor(props){
        super(props);

        this.state = {
            definitaion_:'',
            approveclass:'redclass',
            approvebtn:true,
            segment_id:'27',
            segments:[],
            family:[],
            class:[],
            brick:[],
            brand_value:[],
            hedef_value:[],
            segments_value:0,
            family_value:0,
            class_value:0,
            brick_value:0,
            descriptions_value:[],
            picture_value:[],
            image_url_value:[],
            netmiktar_value:[],
            hata:'',
            hatamessage:false,
            error_data:[{'marka':'border_color'}],
            table: {
                data: [],
                pages: null,
                loading: false,
                pageSize: DEFAULT_PAGE_SIZE,
                pageNumber: 0
            },

            showModal: false,
            showEditModal: false,
            showDeleteModal: false,

            isCommitmentLetterValid: this.props.isCommitmentLetterValid,
            commitmentLetterFile: null,

            isNumberSubmitting: false,
            isNumberEditSubmitting: false,
            isNumberDeletionSubmitting: false,

            extraFiles: null,
            applicationId: null,
            applicationItemToBeDeleted: null,
            applicationItemToBeEdited: null,

            currentApplicationItem: {
                targetMarket: null,
                brand: "",
                labelDescription: "",
                imageUrl: "",
                selectedBrick: null
            },
            editApplicationItem: {
                targetMarket: null,
                brand: "",
                labelDescription: "",
                imageUrl: "",
                selectedBrick: null
            },

            numberOfApplicationItems: 0,
            itemPrice: 0,

            applicationCompleted: false
        };



        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStateNameChange= this.handleStateNameChange.bind(this);

        this.handleGcpBrowserInputChange = this.handleGcpBrowserInputChange.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.submitRemoveItem = this.submitRemoveItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.handleSubmitApplication = this.handleSubmitApplication.bind(this);
        this.handleSubmitApplicationItem = this.handleSubmitApplicationItem.bind(this);
        this.handleSubmitEditApplicationItem = this.handleSubmitEditApplicationItem.bind(this);
        this.changeSegments = this.changeSegments.bind(this);
        this.changefamily = this.changefamily.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
        this.changeclass = this.changeclass.bind(this);
        this.changebrick = this.changebrick.bind(this);
        this.changeend = this.changeend.bind(this);
        this.marka = this.marka.bind(this);
        this.aciklama = this.aciklama.bind(this);
        this.hedef = this.hedef.bind(this);
        this.picture = this.picture.bind(this);
        this.netmiktar = this.netmiktar.bind(this);
        this.approve = this.approve.bind(this);
        axios.get(siteUrl+`/api/common/segments`)
          .then(res => {
            res.data.unshift({'pk':0,'label':'Seçiniz...','code':''})
            this.setState({
                segments: res.data
            })
        })
    }


    marka(items)
    {    
        console.log(items)
        if(items!=undefined)
        {
            this.setState({
                brand_value: items
            })
        }
    }

    aciklama(items)
    {    
        if(items!=undefined)
        {
            this.setState({descriptions_value: items})
        }
    }

    hedef(items)
    {    
        if(items!=undefined)
        {
            this.setState({hedef_value: items})
        }
    }

    errorHandler()
    {
        return this.state.error_data
    }

    picture(items)
    {    
        if(items!=undefined)
        {
            this.setState({picture_value: items})
        }
    }

    netmiktar(items)
    {  
        console.log(items)
        if(items!=undefined)
        {
            this.setState({netmiktar_value: items})
        }
    }

    changeSegments(id){
        this.setState({segment_id: id,segments_value:id})
        this.changefamily(id)
    }

    changefamily(id,value){
        
        var ths = this
        axios.get(siteUrl+`/api/common/family?segment_id=`+id)
        .then(res => {
            res.data.unshift({'pk':0,'label':'Seçiniz...','code':''})
            ths.setState({
                family: res.data
            })
             
            if(res.data[0]!=undefined)
            {
                if(value!=undefined)
                {
                    this.changeclass(value.family,value)
                    this.setState({family_value: value})
                }
                else
                {
                    this.changeclass(res.data[0].pk)
                    this.setState({family_value: res.data[0].pk})
                }
            }
        })
    }

    changeclass(id,value){
        this.setState({class_value: id})
        var ths = this
        axios.get(siteUrl+`/api/common/class?family_id=`+id)
        .then(res => {
            res.data.unshift({'pk':0,'label':'Seçiniz...','code':''})
            ths.setState({
                class: res.data
            })
            if(res.data[0]!=undefined)
            {
                if(value==undefined)
                {
                    this.changebrick(res.data[0].pk)
                }
                else
                {
                    this.changebrick(value.class,value)
                }
            }
        })
    }

    changebrick(id,value){
        var ths = this
        axios.get(siteUrl+`/api/common/brick?class_id=`+id)
        .then(res => {

            res.data.unshift({'pk':0,'label':'Seçiniz...','code':''})
            ths.setState({
                brick: res.data
            })
            if(value!=undefined)
            {
                this.setState({brick_value: value.pk})
            }
            else
            {
                this.setState({brick_value: res.data[0].pk})
            }
            
        })
    }

    changeend(id){
        this.setState({brick_value: id})
        for(var item in this.state.brick)
        {
            if(this.state.brick[item].pk==id)
            {
                console.log(this.state.brick[item])
                console.log(this.state.brick[item].definition)
                this.setState({definitaion_:this.state.brick[item].definition})
                break
            }
        }
        
    }

    componentWillMount(){
        let {applicationId, itemPrice} = this.props;
        if (!applicationId)
            applicationId = null;

        this.setState({
            applicationId: applicationId,
            itemPrice: itemPrice
        });

        if (!this.props.citiesLoaded)
            this.props.getCountryData();
    }

    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false, showDeleteModal: false, showEditModal: false });
    }

    handleInputChange(event, parentKey ) {
        if (parentKey === "edit")
            this.setState({
                editApplicationItem: {
                    ...this.state.editApplicationItem,
                    [event.target.id]: event.target.value
                }
            });
        else
            this.setState({
                currentApplicationItem: {
                    ...this.state.currentApplicationItem,
                    [event.target.id]: event.target.value
                }
            });
    }

    handleStateNameChange(event, parentKey) {
        if (parentKey === "edit")
            this.setState({
                editApplicationItem: {
                    ...this.state.editApplicationItem,
                    'targetMarket': event
                }
            });
        else
            this.setState({
                currentApplicationItem: {
                    ...this.state.currentApplicationItem,
                    'targetMarket': event
                }
            });
    }

    handleGcpBrowserInputChange(applicationKey, selectedBrick){
        if (applicationKey === "edit")
            this.setState({
                editApplicationItem: {
                    ...this.state.editApplicationItem,
                    selectedBrick: selectedBrick
                }
            });
        else
            this.setState({
                currentApplicationItem: {
                    ...this.state.currentApplicationItem,
                    selectedBrick: selectedBrick
                }
            });
    }

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            [event.target.id]: submitFile,
        });

    };

    submitApplicationItemFormCallback = (result) => {
        if (result.status === 200){
            this.setState({
                applicationId: result.data.application_id,
                isNumberSubmitting: false,
                showModal: false,

                currentApplicationItem: {
                    targetMarket: "",
                    brand: "",
                    labelDescription: "",
                    imageUrl: "",
                    selectedBrick: null
                },

                table: {
                    ...this.state.table,
                    loading: true
                }
            });

            this.props.requestDataForGtin8ApplicationItemList(result.data.application_id, this.state.table.pageSize, 0, this.loadDataCallback);
        }
        else {
            this.setState({
                isNumberSubmitting: false
            })
        }
    };

    submitApplicationFormCallback = (result) => {
        if (result.status === 200){
            this.setState({
                submitting: false,
                applicationCompleted: true
            })
        }
        else {
            this.setState({
                submitting: false
            })
        }
    };

    loadDataCallback = (result) => {
        const tableData = makeApplicationItemTableData(result.data.data);
        const totalTableSize = result.data.total_size;
        let pageSize = this.state.table.pageSize;

        if(result.status === 200){
            this.setState({
                table:{
                    ...this.state.table,
                    data: tableData,
                    pages: Math.ceil(totalTableSize / pageSize),
                    loading: false
                },
                numberOfApplicationItems: totalTableSize
            })
        }
    };

    deleteApplicationItemCallback = (result) => {
        if (result.status === 200){
            this.setState({
                isNumberDeletionSubmitting: false,
                showDeleteModal: false
            });

            this.props.requestDataForGtin8ApplicationItemList(this.props.applicationId, this.state.table.pageSize, 0, this.loadDataCallback);
        }
    };

    
    submitEditApplicationItemFormCallback = (result) => {
        if (result.status === 200){
            this.setState({
                isNumberEditSubmitting: false,
                showEditModal: false,
                table: {
                    ...this.state.table,
                    loading: true
                }
            });

            this.props.requestDataForGtin8ApplicationItemList(this.state.applicationId, this.state.table.pageSize, this.state.table.pageNumber, this.loadDataCallback);
        }
        else {
            this.setState({
                isNumberEditSubmitting: false
            })
        }
    };

    fetchData(state, instance) {
        this.setState({
            table: {
                ...this.state.table,
                pageNumber: state.page,
                pageSize: state.pageSize,
                loading: true
            }
        });

        this.props.requestDataForGtin8ApplicationItemList(this.state.applicationId, state.pageSize, state.page, this.loadDataCallback);
    }

    removeItem(value){
        this.setState({
            showDeleteModal: true,
            applicationItemToBeDeleted: value
        });
    }

    submitRemoveItem(){
        this.setState({
            isNumberDeletionSubmitting: true
        });

        this.props.submitDeleteApplicationItem(this.state.applicationItemToBeDeleted, this.deleteApplicationItemCallback)
    }

    editItem(value){
        const data = this.state.table.data;
        let indexItem = -1;

        data.map((item, index) => {
            if (item.id === value)
                indexItem = index;
            return item;
        });

        const item = this.state.table.data[indexItem];
        this.setState({
            showEditModal: true,
            applicationItemToBeEdited: value,
            brand_value: item.brand_array,
            descriptions_value: item.label_description_array,
            target_market: item.target_market_array,
            netmiktar_value: item.netmeasure_array,
            image_url: item.image_url_array,
            editApplicationItem: {
                targetMarket: item.target_market,
                targetMarketArray: item.target_market_array,
                brand: item.brand,
                brandArray: item.brand_array,
                labelDescription: item.label_description,
                labelDescriptionArray: item.label_description_array,
                netmeasureArray: item.netmeasure_array,
                imageUrl: item.image_url,
                imageUrlArray: item.image_url_array,
                selectedBrick: item.brick,

            }
        });
    }


     checkURL(url) {
            return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
        }

    handleSubmitApplicationItem(event) {
        
        event.preventDefault();
        var ths = this;
        const formData = new FormData();
            ths.setState({error_data:[]})

        var cnt = false;
        $('#marka').removeClass("border_color");
        $('#marka').next('select').removeClass('border_color')
        $('#descriptions').removeClass("border_color");
        $('#descriptions').next('select').removeClass('border_color')
        $('#hedef').removeClass("border_color");
        $('#hedef').next('select').removeClass('border_color')
        $('#netmiktar').removeClass("border_color");
        $('#netmiktar').next('select').removeClass('border_color')
        $('#segment').removeClass("border_color");
        $('#family').removeClass("border_color");
        $('#class').removeClass("border_color");
        $('#brick').removeClass("border_color");
        $('#picture').removeClass("border_color");
        $('#picture').next('select').removeClass('border_color')

        console.log(this.state.picture_value)
        if(this.state.picture_value.length>0)
        {
            for(var i in this.state.picture_value)
            {
                var chec = this.checkURL(this.state.picture_value[i].input)
                console.log(this.state.picture_value[i].input)
                if(!chec)
                {
                    cnt = true;
                    $('#picture').addClass("border_color");
                    $('#picture').next('select').addClass('border_color')
                    $('#hataerror').text('Lütfen ürünün resmi için geçerli bir URL giriniz.')
                }
            }
        }

        if(this.state.brand_value.length==0)
        {
            if($('#marka').val()=='')
            {
                cnt = true
                $('#marka').addClass("border_color");
                $('#marka').next('select').addClass('border_color')
            }
            else
            {
                this.setState({brand_value:[{'input':$('#marka').val(), 
                'label':$('#marka').next('select').find('option:selected').text().trim(),
                'lng':$('#marka').next('select').val()}]})
            }           
        }

        if(this.state.descriptions_value.length==0)
        {
            if($('#descriptions').val()=='')
            {
                cnt = true
                $('#descriptions').addClass("border_color");
                $('#descriptions').next('select').addClass('border_color')
            }
            else
            {
                this.setState({descriptions_value:[{'input':$('#descriptions').val(), 
                'label':$('#descriptions').next('select').find('option:selected').text().trim(),
                'lng':$('#descriptions').next('select').val()}]})
            }
            
        }

        if(this.state.hedef_value.length==0)
        {
            if($('#hedef').val()=='')
            {
                cnt = true
                $('#hedef').addClass("border_color");
                $('#hedef').next('select').addClass('border_color')
            }
            else
            {
                this.setState({hedef_value:[{'input':$('#hedef').val(), 
                'label':$('#hedef').find('option:selected').text().trim(),
                'lng':$('#hedef').val()}]})
            }
            
        }


            
    

        if(this.state.netmiktar_value.length==0 || Array.isArray(this.state.netmiktar_value)==false)
        {

            if($('#netmiktar').val()=='')
            {
                cnt = true
                $('#netmiktar').addClass("border_color");
                $('#netmiktar').next('select').addClass('border_color')
            }
            else
            {
                this.setState({netmiktar_value:[{'input':$('#netmiktar').val(), 
                'label':$('#netmiktar').next('select').find('option:selected').text().trim(),
                'lng':$('#netmiktar').next('select').val()}]})
            }
            
        }



        if(this.state.brick_value=='')
        {
            cnt = true
            $('#segment').addClass("border_color");
            $('#family').addClass("border_color");
            $('#class').addClass("border_color");
            $('#brick').addClass("border_color");
        }

       

        if(cnt)
            return false;
        
        console.log(this.state.picture_value)
        setTimeout(() => { 
            
            if(this.state.hedef_value.length==0)
                this.state.hedef_value = [1]

            var data = {
                'segment':this.state.segments_value,
                'family':this.state.family_value,
                'class':this.state.class_value,
                'brick':this.state.brick_value,
                'brands':this.state.brand_value,
                'description': this.state.descriptions_value,
                'hedef':this.state.hedef_value,
                'picture':this.state.picture_value,
                'netmiktar':this.state.netmiktar_value
            };


            var ths = this;
            if(this.state.showEditModal)
            {
                var token = localStorage.getItem("authToken");
                axios.post(siteUrl+`/api/gtin8/editapplicationitem?application_id=`+
                    ths.state.applicationItemToBeEdited,data,{'headers': {'Authorization':'Token '+token}})
                  .then(res => {
                    ths.setState({currentStep: 5})
                    ths.setState({hata: '', hatamessage: false})
                    ths.handleCloseModal()
                    ths.props.requestDataForGtin8ApplicationItemList(ths.state.applicationId,100, 0, ths.loadDataCallback);
                    ths.setState({segments_value: '',family_value:'',class_value:'',brick_value:'',brand_value:'', netmiktar_value:'',descriptions_value:''})
                }).catch(function (error) {
                    ths.setState({hata: error.response.data.message, hatamessage: true})
                });
            }
            else
            {
                var token = localStorage.getItem("authToken");
                axios.post(siteUrl+`/api/gtin8/newapplicationitem?application_id=`+
                    ths.state.applicationId,data,{'headers': {'Authorization':'Token '+token}})
                  .then(res => {
                    ths.setState({currentStep: 5})
                    ths.setState({hata: '', hatamessage: false})
                    ths.setState({applicationId:res.data.application_id})
                    ths.handleCloseModal()
                    ths.props.requestDataForGtin8ApplicationItemList(res.data.application_id,100, 0, ths.loadDataCallback);
                    ths.setState({segments_value: '',family_value:'',class_value:'',brick_value:'',brand_value:'', netmiktar_value:'',descriptions_value:''})
                    //window.location.reload();
                    //window.location.href = window.location.href+"?list=1";

                }).catch(function (error) {
                    ths.setState({hata: error.response.data.message, hatamessage: true})
                   
                });
            }
            ths.setState({approvebtn:true,approveclass: 'redclass'})
        }, 500);
    }

    handleSubmitEditApplicationItem(event) {
        event.preventDefault();

        if (!this.state.isNumberEditSubmitting)
        {
            this.setState({
                isNumberEditSubmitting: true
            })
        }
        // const formData = new FormData();
        // formData.append('target_market', this.state.editApplicationItem.targetMarket.pk);
        // formData.append('brand', this.state.editApplicationItem.brand);
        // formData.append('label_description', this.state.editApplicationItem.labelDescription);
        // formData.append('image_url', this.state.editApplicationItem.imageUrl);
        // formData.append('brick', this.state.editApplicationItem.selectedBrick.pk);
        // this.props.submitEditApplicationItem(formData, this.state.applicationItemToBeEdited, this.submitEditApplicationItemFormCallback)
    }

    approve(e){
       if(this.state.approvebtn)
       {
        this.setState({approvebtn:false})
        this.setState({approveclass: 'btn-modal-submit'})
       }
       else
       {
        this.setState({approvebtn:true,approveclass: 'redclass'})
       }
    }

    handleSubmitApplication(event) {
        event.preventDefault();

        if (!this.state.table.data.length) {
            const {t} = this.props;

            swal({
                title: t('Error'),
                text: t('You need to get at least one number!'),
                icon: 'error'
            });
            return;
        }

        const formData = new FormData();
        formData.append('application_id', this.state.applicationId);


        let extraFiles= this.state.extraFiles;
        if(extraFiles) {
            for (let i = 0; i < extraFiles.length; i++) {
                formData.append('extra_files', extraFiles[i]);
            }
        }

        if (this.state.commitmentLetterFile !== null)
            formData.append('commitment_letter', this.state.commitmentLetterFile);

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.isNumberSubmitting){
            this.setState({
                isNumberSubmitting: true
            })
        }

        this.props.submitApplicationForm(formData, this.submitApplicationFormCallback)
    }

    render(){
        const table = this.state.table;
        const {t} = this.props;
        const {currentApplicationItem, editApplicationItem} = this.state;

        const isNumberSubmitting = this.state.isNumberSubmitting ? "btn-disabled": "";
        const isNumberDeletionSubmitting = this.state.isNumberDeletionSubmitting ? "btn-disabled": "";
        const isNumberEditSubmitting = this.state.isNumberEditSubmitting ? "btn-disabled": "";

        return(
            <div className={"new-application"}>
                {
                    !this.state.applicationCompleted ?
                        <Fragment>
                            <Iframe
                                source={"GTIN_8/h4.html"}
                            />
                            <div className={"table-content mt-10"}>
                                <div className="mt-10 mb-20 gtn8-new-app t-center">
                                    <span className={"signup-button small mr-5 add-number"} onClick={this.handleOpenModal}>
                                        <FontAwesomeIcon icon={faPlus} className={"mr-5"}/>
                                        <T>Add number</T>
                                    </span>
                                </div>
                                <ReactTable
                                    columns={[
                                        {
                                            Header: <T>Target Market</T>,
                                            accessor: "target_market.label",
                                            sortable: false
                                        },
                                        {
                                            Header: <T>Brand</T>,
                                            accessor: "brand",
                                            sortable: false
                                        },
                                        {
                                            Header: <T>Label Description</T>,
                                            accessor: "label_description",
                                            sortable: false
                                        },
                                        {
                                            Header: <T>Actions</T>,
                                            accessor: "id",
                                            Cell: ({value}) => (
                                                <span>
                                                <span onClick={() => this.editItem(value)}><FontAwesomeIcon icon={faEdit} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/></span>
                                                <span onClick={() => this.removeItem(value)}><FontAwesomeIcon icon={faTrashAlt} size={"lg"} color={"red"} className={"curpnt ml-10"}/></span>
                                            </span>),
                                            sortable: false
                                        },
                                    ]}
                                    manual
                                    data={table.data}
                                    pages={table.pages}
                                    loading={table.loading}
                                    onFetchData={this.fetchData}
                                    defaultPageSize={DEFAULT_PAGE_SIZE}
                                    className="-striped -highlight"
                                    multiSort={false}

                                    previousText={<T>Previous</T>}
                                    nextText={<T>Next</T>}
                                    loadingText={<T>Loading...</T>}
                                    noDataText={<T>No rows found</T>}
                                    pageText={<T>Page</T>}
                                    ofText={<T>of</T>}
                                    rowsText={t('rows')}
                                />
                            </div>

                            <ReactModal
                                isOpen={this.state.showModal}
                                ariaHideApp={false}
                                className="Modal"
                                overlayClassName="Overlay"
                            >
                                <ContentModule moduleClass={"no-padding"}>
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="modal-title"><T>New GTIN-8</T></h2>
                                        </div>
                                        <div className="modal-body gtin8-scrool">
                                                <label className="formlabelismi"><T>Brand</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a1" id="marka" name="marka" placeholder={t('Brand')} handler={this.marka} error={this.state.error_data['marka']}/>
                                                <label className="formlabelismi"><T>Label Description</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a2" id="descriptions" name="etiket" placeholder={t('Label Description')} handler={this.aciklama} error={this.state.error_data['description']}/>
                                                <label className="formlabelismi"><T>Target Market</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a3" id="hedef" addbtn="true" type="hedefpazar" btn="true" name="hedefpazar" handler={this.hedef} placeholder="https://example.com/path/to/image" data={this.props.countriesData} error={this.state.error_data['target']}/>
                                                <label className="formlabelismi"><T>Product Image (URL)</T></label>
                                                <ApplicationInformationForm key="a4" id="picture" name="picture" handler={this.picture}  placeholder="https://example.com//path//to//image" error=""/>
                                                <label className="formlabelismi"><T>Net Quantity Information</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a5" id="netmiktar" type="birim" handler={this.netmiktar} name="netmiktar" placeholder="" error={this.state.error_data['netmiktar']}/>
                                                <hr/>
                                                <h6><T>GS1 Global Product Category Information</T></h6>
                                                <label className="formlabelismi"><T>Section</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a6" id="segment" type="segments" name="segments" handler={this.changeSegments} placeholder="" list={this.state.segments} error={this.state.error_data['segment']}/>
                                                <label className="formlabelismi"><T>Family</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a7" id="family" type="segments" name="family" placeholder="" handler={this.changeclass}  list={this.state.family} error={this.state.error_data['segment']}/>
                                                <label className="formlabelismi"><T>Class</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a8"  id="class" type="segments" name="class" placeholder="" handler={this.changebrick} list={this.state.class} error={this.state.error_data['segment']}/>
                                                <label className="formlabelismi"><T>Category</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a9" id="brick" type="segments" name="brick" placeholder="" handler={this.changeend} list={this.state.brick} error={this.state.error_data['segment']}/>
                                                <div id="aciklama">{this.state.definitaion_}</div>
                                                <div className="modal-footer">
                                                <div id="hataerror" className="hata"></div>
                                                     <div>
                                                        <T>Declared data is correct, valid, and we are responsible for data ownership and data quality; GS1 has the authority to take regulatory action, including temporarily stopping data visibility, within the scope of data quality; I agree that I am responsible for any damage and any dispute that may arise due to the declared data and that the final information entered will be shared with GS1 Head Office and that I will apply to GS1 Turkey in case of an update on the shared final product information.</T>
                                                        <br/><br/><b><input type="checkbox" onClick={this.approve}/> <T>I approve</T></b>
                                                    </div>

                                                    <button type="button" className={"btn-modal btn-modal-close mr-10 " + isNumberSubmitting} onClick={this.handleCloseModal}><T>Close</T></button>
                                                    <input disabled={this.state.approvebtn} className={"btn-modal "+this.state.approveclass+" "+ isNumberSubmitting} type={"submit"} onClick={this.handleSubmitApplicationItem} value={t("Submit")}/>
                                                </div>
                                        </div>
                                    </div>
                                </ContentModule>
                            </ReactModal>

                            <ReactModal
                                isOpen={this.state.showDeleteModal}
                                ariaHideApp={false}
                                className="Modal"
                                overlayClassName="Overlay"
                            >
                                <ContentModule moduleClass={"no-padding"}>
                                    <div className="modal-content pd-10">
                                        <div className="modal-header">
                                            <h2 className="modal-title"><T>Delete GTIN-8</T></h2>
                                        </div>
                                        <div className="modal-body">
                                            <T>Are you sure you want to delete this number?</T>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className={"btn-modal btn-modal-close mr-10 " + isNumberDeletionSubmitting} onClick={this.handleCloseModal}><T>No</T></button>
                                            <button className={"btn-modal btn-modal-submit " + isNumberDeletionSubmitting} onClick={this.submitRemoveItem}><T>Yes</T></button>
                                        </div>
                                    </div>
                                </ContentModule>
                            </ReactModal>

                            <ReactModal
                                isOpen={this.state.showEditModal}
                                ariaHideApp={false}
                                className="Modal"
                                overlayClassName="Overlay"
                            >
                                <ContentModule moduleClass={"no-padding"}>
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="modal-title"><T>Update GTIN-8 Data</T></h2>
                                        </div>
                                        <div className="modal-body gtin8-scrool">

                                                <label className="formlabelismi"><T>Brand</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a1" id="marka" name="marka" placeholder={t('Brand')} handler={this.marka} error={this.state.error_data['marka']} inputValue={editApplicationItem.brandArray}/>
                                                <label className="formlabelismi"><T>Label Description</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a2" id="descriptions" name="etiket" placeholder={t('Label Description')} handler={this.aciklama} inputValue={editApplicationItem.labelDescriptionArray} error={this.state.error_data['description']}/>
                                                <label className="formlabelismi"><T>Target Market</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a3" id="hedef" addbtn="true" type="hedefpazar" btn="true" name="hedefpazar" handler={this.hedef} placeholder="https://example.com/path/to/image" data={this.props.countriesData} inputValue={editApplicationItem.targetMarketArray} error={this.state.error_data['target']} />
                                                <label className="formlabelismi"><T>Product Image (URL)</T></label>
                                                <ApplicationInformationForm key="a4" name="picture" handler={this.picture}  placeholder="https://example.com//path//to//image" error="" inputValue={editApplicationItem.imageUrlArray}/>
                                                <label className="formlabelismi"><T>Net Quantity Information:</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a5" id="netmiktar"  type="birim" handler={this.netmiktar} name="netmiktar" placeholder="" inputValue={editApplicationItem.netmeasureArray} error={this.state.error_data['netmiktar']}/>
                                                <hr/>
                                                <h6><T>GS1 Global Product Category Information</T></h6>
                                                <label className="formlabelismi"><T>Section</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a6" fnks={this.changefamily} type="segments" id="segment" name="segments" handler={this.changeSegments} placeholder="" inputValue_={editApplicationItem.selectedBrick} list={this.state.segments} error={this.state.error_data['segment']}/>
                                                <label className="formlabelismi"><T>Family</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a7" fnks={this.changebrick} type="segments" id="family" name="family" placeholder="" handler={this.changeclass}  inputValue_={editApplicationItem.selectedBrick}  list={this.state.family} error={this.state.error_data['segment']}/>
                                                <label className="formlabelismi"><T>Class</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a8" fnks={this.changebrick} type="segments" id="class" name="class" placeholder="" handler={this.changebrick} inputValue_={editApplicationItem.selectedBrick} list={this.state.class} error={this.state.error_data['segment']}/>
                                                <label className="formlabelismi"><T>Category</T> <span>*</span></label>
                                                <ApplicationInformationForm key="a9" type="segments" id="brick"  name="brick" placeholder="" handler={this.changeend} inputValue_={editApplicationItem.selectedBrick} list={this.state.brick} error={this.state.error_data['segment']}/>

                                               <div className="modal-footer">
                                                    <div id="hataerror" className="hata"></div>
                                                    <button type="button" className={"btn-modal btn-modal-close mr-10 " + isNumberSubmitting} onClick={this.handleCloseModal}><T>Close</T></button>
                                                    <input className={"btn-modal btn-modal-submit " + isNumberSubmitting} type={"submit"} onClick={this.handleSubmitApplicationItem} value={t("Submit")}/>
                                                </div>
                                        </div>
                                    </div>
                                </ContentModule>
                            </ReactModal>

                            <ContentModule containerClass={"mt-20"}>
                                <form onSubmit={this.handleSubmitApplication} className="editor small signin">

                                    {
                                        this.state.isCommitmentLetterValid !== null && !this.state.isCommitmentLetterValid &&
                                            <div>
                                                <T
                                                    message={"Please first download the {{ link }}"}
                                                    link={<a href={commitmentLetterDownloadUrl} target={"_blank"}><T>Commitment Letter</T></a>}
                                                />
                                                <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile}/>
                                            </div>
                                    }
                                    <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false}  />

                                    <p><b><T>Money to be paid</T>: {this.state.itemPrice * this.state.numberOfApplicationItems} ₺</b></p>

                                    <div className="btnInline mt-10 gtn8-new-app t-center">
                                        <input className="signup-button small ml-5" type={"submit"} value={t("Apply")}/>
                                    </div>
                                </form>
                            </ContentModule>
                        </Fragment>:

                        <Redirect to={"/member/to/gtin8"}/>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        submitApplicationItemForm: (data, applicationId, callBack) => {
            dispatch(gtin8Actions.dbCreateGtin8ApplicationItem(data, applicationId, callBack))
        },
        submitDeleteApplicationItem: (applicationId, callBack) => {
            dispatch(gtin8Actions.dbDeleteGtin8ApplicationItem(applicationId, callBack))
        },
        submitEditApplicationItem: (data, applicationId, callBack) => {
            dispatch(gtin8Actions.dbEditApplicationItemFromNew(data, applicationId, callBack))
        },
        submitApplicationForm: (data, callBack) => {
            dispatch(gtin8Actions.dbCreateGtin8Application(data, callBack))
        },
        requestDataForGtin8ApplicationItemList: (applicationId, pagesize, page, callback) => {
            dispatch(gtin8Actions.dbRequestDataForGtin8ApplicationItemList(applicationId, pagesize, page, callback));
        },
        getCountryData: () => {
            dispatch(dataActions.dbGetCountryData());
        },
    }
};

const mapStateToProps = (state) => {
    const { data } = state;
    return {
        countriesLoaded: data.countriesLoaded,
        countriesData: data.countriesData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(NewApplication));