import React, {Component} from 'react'
import TextInput from "../input_types/TextInput";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as vwActions from "../../actions/vwActions";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import * as dataActions from "../../actions/dataActions";

class ApplicationItemEdit extends Component{

    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            applicationItem: {},
            submitting: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
        }

        this.props.loadApplicationItem(applicationId, this.applicationItemCallback);

        if (!this.props.citiesLoaded)
            this.props.getCountryData();
    }

    applicationItemCallback = (result) => {
        if (result.status === 200){
            this.setState({
                applicationItem: {
                    "vw": result.data.vw,
                    "brand": result.data.brand,
                    "labelDescription": result.data.labelDescription,
                },
                currentStep: 2
            })
        }
    };

    constructViewNumber(){
        const {t} = this.props;

        return <div>
                    <TextInput key={"vw"} inputId={"vw"} inputTitle={t("Varying Weight Number")} inputValue={this.state.applicationItem["vw"]} disabled/>
                    <TextInput key={"brand"} inputId={"brand"} inputTitle={t("Brand")} inputValue={this.state.applicationItem["brand"]}  maxLength={64} handleInputChange={this.handleInputChange}/>
                    <TextInput key={"labelDescription"} isTextArea inputId={"labelDescription"} inputTitle={t("Label Description")} inputValue={this.state.applicationItem["labelDescription"]} maxLength={500} handleInputChange={this.handleInputChange}/>
        </div>
    }

    handleInputChange(event) {
        this.setState({
            ...this.state,
            applicationItem: {
                ...this.state.applicationItem,
                [event.target.id]: event.target.value
            }
        });
    }

    submitFormCallback = (result) =>{
        if (result.status === 200){
            this.setState({
                currentStep: 3
            })
        }
        else {
            this.setState({
                submitting: false
            })
        }
    };

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        const formData = new FormData();
        let application = this.state.applicationItem;

        formData.append('application', JSON.stringify(application));
        const {applicationId} = this.props.match.params;

        this.props.submitForm(formData, applicationId, this.submitFormCallback)
    }

    render(){
        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>Update Varying Weight Number Data</T></h2>
                <ContentModule>
                    <T>You need to update following product data.</T><br/>
                    <T>In order to apply new identifiers you should correct all product information.</T>
                </ContentModule>
                {
                    this.state.currentStep === 1 ?
                        <div><T>Loading...</T></div> :

                        this.state.currentStep === 2 ?
                            <ContentModule>
                                <form onSubmit={this.handleSubmit} className="editor small signin">
                                    {
                                        this.constructViewNumber()
                                    }
                                    <div className="btnInline mt-10 t-center">
                                        <input className={"signup-button small t-center " + isSubmitting} type={"submit"} value={t("Submit")}/>
                                    </div>
                                </form>
                            </ContentModule> :
                            <ContentModule>
                                <h3><T>Varying Weight Number is successfully edited.</T></h3>
                            </ContentModule>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationItem: (applicationId, callback) => {
            dispatch(vwActions.dbLoadVwApplicationItem(applicationId, callback, 1))
        },
        submitForm: (data, applicationId, callBack) => {
            dispatch(vwActions.dbEditVwApplicationItem(data, applicationId, callBack))
        },
        getCountryData: () => {
            dispatch(dataActions.dbGetCountryData());
        },
    }
};

const mapStateToProps = (state) => {
    const { data } = state;
    return {
        countriesLoaded: data.countriesLoaded,
        countriesData: data.countriesData,
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ApplicationItemEdit)));
