import React, {Component} from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import MemberHeader from "./MemberHeader";
import {TOKEN_IN_VALIDATION, TOKEN_SUCCESSFULLY_VALIDATED} from "../constants/AuthedStatus";
import MemberHome from "./MemberHome";
import {T} from "lioness";
import CandidateRouter from "../routes/CandidateRouter";
import * as authorizeActions from "../actions/authorizeActions";
import {addNextUrl} from "../actions/authorizeActions";
import WithDebtRouter from "../routes/WithDebtRouter";
import GuestWrapper from "./GuestWrapper";
import HomeVideo from "./HomeVideo";
import WithEditProfileRouter from "../routes/WithEditProfileRouter";


class Home extends Component{

    componentWillMount(){
        if (this.props.authed === false && !localStorage.getItem("authToken"))
            this.props.addPath(this.props.location.pathname)
    }

    render(){
        return(
            <div>
            {
                (this.props.authed === false && !localStorage.getItem("authToken")) ?
                    <GuestWrapper component={<HomeVideo/>}/>:

                (this.props.tokenStatus === TOKEN_IN_VALIDATION || !this.props.memberStatusLoaded) ?

                    <div className={"content-wrapper"}>
                        <T>Loading...</T>
                    </div> :

                    (this.props.tokenStatus === TOKEN_SUCCESSFULLY_VALIDATED && this.props.memberStatusLoaded && (this.props.memberType === 0 || this.props.memberType === 1 || this.props.memberType === 3)) ?
                        (
                            <div>
                                <MemberHeader/>
                                {
                                    this.props.debt ?
                                        <WithDebtRouter/>:

                                        !this.props.isProfileComplete ?
                                            <WithEditProfileRouter/>:
                                            <CandidateRouter/>
                                }

                            </div>
                        ) :

                        (this.props.tokenStatus === TOKEN_SUCCESSFULLY_VALIDATED && this.props.memberStatusLoaded && this.props.memberType === 2) ?
                            <MemberHome debt={this.props.debt} isProfileComplete={this.props.isProfileComplete}/>:
                            <Redirect to={"/passiveuser"}/>
            }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        login: (email, password, callBack) => {
            dispatch(authorizeActions.dbLogin(email, password, callBack))
        },
        addPath: (path) => {
            dispatch(addNextUrl(path))
        }
    }
};


const mapStateToProps = (state, ownProps) => {
    const {authorize} = state;
    return {
        authed: authorize.authed,
        tokenStatus: authorize.tokenStatus,
        memberType: authorize.memberType,
        memberStatusLoaded: authorize.memberStatusLoaded,
        debt: authorize.debt,
        isProfileComplete: authorize.isProfileComplete
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));