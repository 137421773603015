import React, {Component} from 'react'
import {connect} from "react-redux";
import FileInput from "../input_types/FileInput";

import '../../styles/GcpCancel.css'
import * as gcpActions from "../../actions/gcpActions";
import {cancellingMembershipCommitmentLetterDownloadUrl} from "../../constants/GcpUrlConstants";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import {isFileSizeExceeded, showFileError} from "../FileUtils";

class CancelMembership extends Component{
    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            signedCommitmentLetterFile: null,
            signatoryCircularFile: null,
            hasDebt: false,
            submitting: false,
            extraFiles: null,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            [event.target.id]: submitFile,
        });

    };

    memberDebtCallback = (result) => {
        if (result.status === 200){
            this.setState({currentStep: 2, hasDebt: result.data.has_debt});

            if (!result.data.has_debt){
                this.props.checkMembership(this.membershipCheckCallback);
            }
        }
    };

    membershipCheckCallback = (result) => {
        if (result.status === 200){
            if (result.data.is_cancel_requested){
                this.setState({
                    currentStep: 3,
                })
            }
            else if (result.data.has_unapproved){
                this.setState({
                    currentStep: 4,
                })
            }
            else {
                this.setState({
                    currentStep: 5,
                })
            }
        }
    };

    membershipCancelCallback = (result) => {
        if (result.status === 200) {
            this.setState({
                currentStep: 6,
                submitting: false,
            })
        }
        else
            this.setState({
                submitting: false
            })
    };

    componentWillMount(){
        //load debt
        this.props.getMemberDebt(this.memberDebtCallback);
    }

    handleSubmit(event) {
        event.preventDefault();

        const formData = new FormData();
        formData.append('signed_commitment_letter', this.state.signedCommitmentLetterFile);
        formData.append('signatory_circular', this.state.signatoryCircularFile);

        let extraFiles= this.state.extraFiles;
        if(extraFiles) {
            for (let i = 0; i < extraFiles.length; i++) {
                formData.append('extra_files', extraFiles[i]);
            }
        }

        if(isFileSizeExceeded(formData))
            return showFileError();

        if (!this.state.submitting){
            this.setState({
                submitting: true
            })
        }

        this.props.membershipCancel(
            formData,
            this.membershipCancelCallback
        );
    }

    render(){
        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const {t} = this.props;

        return(
            <div className={"pos-abs"}>
                <h2 className={"t-center"}><T>Cancel Membership</T></h2>
                <ContentModule>
                    <T>You can cancel your system membership. However GTIN-13's which are generated from your GCP should not be used. Similarly any identification keys allocated to you (GTIN-8, Varying Weight Number, UPC, GTIN-12) should not be used. Otherwise legal processes regarding unlawful usage of GCP will be started.</T>
                </ContentModule>

                <div className={"mt-20"}>
                {
                    this.state.currentStep === 1 ?
                        <div className={"t-center"}><T>Loading...</T></div> :

                        this.state.currentStep === 2 && this.state.hasDebt ?
                            <div className={"debt-error"}>
                                <T>The deletion of membership can not be performed because of existing debts!</T>
                            </div> :

                            this.state.currentStep === 3 ?
                                <div className={"debt-error"}>
                                    <T>This operation is already requested before</T>
                                </div> :

                                this.state.currentStep === 4?
                                    <div className={"debt-error"}>
                                        <T>You have an existing unapproved gcp numbers/membership for cancellation.</T>
                                    </div> :

                                    this.state.currentStep === 5 ?
                                        <ContentModule>
                                            <form onSubmit={this.handleSubmit} className="editor small signin">
                                                {
                                                    <div>

                                                        <div className={"mt-10"}>
                                                            <T
                                                                message={"Please first download the {{ link }}"}
                                                                link={<a href={cancellingMembershipCommitmentLetterDownloadUrl} target={"_blank"}><T>Cancellation Commitment Letter</T></a>}
                                                            />
                                                        </div>
                                                        <FileInput inputId={"signedCommitmentLetterFile"} inputTitle={t("Signed Commitment Letter File")} handleUploadFile={this.handleUploadFile} required/>
                                                        <FileInput inputId={"signatoryCircularFile"} inputTitle={t("Signatory Circular File")} handleUploadFile={this.handleUploadFile} required/>
                                                        <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false} />

                                                    </div>
                                                }
                                                <div className="btnInline mt-10 t-center">
                                                    <input className={"signup-button " + isSubmitting} type="submit" value={t("Submit")}/>
                                                </div>
                                            </form>
                                        </ContentModule>:

                                        this.state.currentStep === 6 ?
                                            <div>
                                                <T>The deletion of the membership is successfully submitted</T>
                                            </div> : null
                }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMemberDebt: (callback) =>{
            dispatch(gcpActions.dbGetMemberDebt(callback))
        },
        checkMembership: (callback) =>{
            dispatch(gcpActions.dbCheckMembership(callback))
        },
        membershipCancel: (formData, callback) =>{
            dispatch(gcpActions.dbMembershipCancel(formData, callback))
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return{

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(CancelMembership));