import * as AuthorizeActionType from "../constants/AuthorizeActionType";
import {
    EMAIL_CHECK,
    MEMBER_DATA,
    MEMBER_STATUS,
    REQUEST_STATUS_CHECK,
    TOKEN_STATUS
} from "../constants/AuthorizeActionType";
import * as authorizeService from "../api/services/authorizeService";
import {push} from "react-router-redux";
import * as dataService from "../api/services/dataService";
import {TOKEN_FAILED_VALIDATION, TOKEN_IN_VALIDATION, TOKEN_SUCCESSFULLY_VALIDATED} from "../constants/AuthedStatus";
import Cookies from 'universal-cookie';
import {cookieDomain, cookiePath} from "../constants/CookieConstants";
import {ADD_NEXT_URL} from "../constants/AuthorizeActionType";
import {REMOVE_NEXT_URL} from "../constants/AuthorizeActionType";

export const dbValidateToken = (callback) => {
    return (dispatch, getState) => {
        dispatch(setTokenValidateStatus(TOKEN_IN_VALIDATION));

        return authorizeService.validateToken().then((result) => {
                dispatch(login(true));
                dispatch(setTokenValidateStatus(TOKEN_SUCCESSFULLY_VALIDATED));

                if (callback !== null)
                    callback();
            },
            (error) =>{
                localStorage.removeItem("authToken");
                dispatch(setTokenValidateStatus(TOKEN_FAILED_VALIDATION));
                dispatch(logout());
            });
    }
};

export const dbLogin = (username, password, callback, nextUrl) => {
    return (dispatch, getState) => {
        return authorizeService.loginUser(username, password).then((result) => {
                const token = result.data["token"];

                localStorage.setItem("authToken", token);

                const cookies = new Cookies();
                cookies.set('token', token, { path: cookiePath, domain: cookieDomain});

                dispatch(login(true));

                dispatch(dbValidateToken(null));
                dispatch(dbGetMemberStatus());
                if (nextUrl){
                    dispatch(push(nextUrl));
                    dispatch(removeNextUrl());
                }
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbImpersonateLogin = (data, callback) => {
    return (dispatch, getState) => {
        return authorizeService.impersonateLoginUser(data).then((result) => {
                const token = result.data["token"];

                localStorage.setItem("authToken", token);

                const cookies = new Cookies();
                cookies.set('token', token, { path: cookiePath, domain: cookieDomain});

                dispatch(login(true));

                dispatch(dbValidateToken(null));
                dispatch(dbGetMemberStatus());
            },
            (error) =>{
                callback(error);
            });
    }
};

export const dbGetMemberTitle = (memberId, callback) => {
    return (dispatch, getState) => {
        return authorizeService.getMemberTitle(memberId, callback).then((result) => {
                callback({
                    "status": result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
            });
    }
};

export const dbLogout = () => {
    return (dispatch, getState) => {
        //no need to wait for response as it creates sides effects in rendering
        localStorage.removeItem("authToken");
        const cookies = new Cookies();
        cookies.remove('token');

        dispatch(logout());
        return authorizeService.logoutUser().then((result) => {
            },
            (error) =>{
            });
    }
};

export const dbCheckEmail = (email, callback) => {
    return (dispatch, getState) => {
        return authorizeService.emailCheck(email, callback).then((result) => {
                dispatch(emailCheck(result.data));
                callback(result.data);
            },
            (error) =>{
                dispatch(emailCheck(-1));
            });
    }
};

export const dbCheckEmailTcknTaxNo = (data, callback) => {
    return (dispatch, getState) => {
        return authorizeService.checkEmailTcknTaxNo(data, callback).then((result) => {
                dispatch(emailCheck(result.data));
                // callback(result.data);
                callback({
                    "status": result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                dispatch(emailCheck(-1));
            });
    }
};

export const dbCheckRequestStatus= (callback) => {
    return (dispatch, getState) => {
        return authorizeService.checkRequestStatus(callback).then((result) => {
                dispatch(checkRequestStatus(result.data));
                callback({
                    "status": result.request.status,
                    "data": result.data
                });
            },
            (error) =>{
                dispatch(checkRequestStatus(-1));
            });
    }
};

export const dbSignUp = (data, callback) => {
    return (dispatch, getState) => {
        return authorizeService.signUp(data).then((result) => {
                callback(result.request.status);
            },
            (error) =>{
                callback(error);
            });
    }
};


export const dbUpdate = (data, callback, group) => {
    return (dispatch, getState) => {
        return authorizeService.update(data).then((result) => {
                if (result.data.status === "ok"){
                    callback('success', group)
                }
                else{
                    callback(result.data.message);
                }
            },
            (error) =>{
                callback(error, group);
            });
    }
};


export const dbUpdateProfileTitle = (data, callback) => {
    return (dispatch, getState) => {
        return authorizeService.dbUpdateProfileTitle(data).then((result) => {
                callback({
                    "status": result.request.status
                })
            },
            (error) =>{
                callback({
                    "status": error
                })
            });
    }
};


export const dbUpdateProfileCommitmentFile = (data, callback) => {
    return (dispatch, getState) => {
        return authorizeService.dbUpdateProfileCommitmentFile(data).then((result) => {
                callback({
                    "status": result.request.status
                })
            },
            (error) =>{
                callback({
                    "status": error
                })
            });
    }
};


export const dbUpdateProfileDetails = (data, callback) => {
    return (dispatch, getState) => {
        return authorizeService.dbUpdateProfileDetails(data).then((result) => {
                callback({
                    "status": result.request.status
                })
            },
            (error) =>{
                callback({
                    "status": error
                })
            });
    }
};


export const dbForgotPassword = (username, callback) => {
    return (dispatch, getState) => {
        return authorizeService.forgotPassword(username).then((result) => {
                if (result.data.status === "ok"){
                    dispatch(push("/to/forgot-password-completed"));
                }
                else{
                    callback(result.data.message);
                }
            },
            (error) =>{
                callback(error);
            });
    }
};


export const dbGetMemberStatus = () => {
    return (dispatch, getState) => {
        return dataService.dbMemberStatus().then((result) => {
                dispatch(initMemberStatus(result.data));
            },
            (error) =>{
                dispatch(initMemberStatus(-1));
            });
    }
};

export const dbGetMemberData = (callback) => {
    return (dispatch, getState) => {
        return dataService.dbMemberData().then((result) => {
                dispatch(initMemberData(result.data));
                callback(result.data);
            },
            (error) =>{
                dispatch(initMemberData(-1));
            });
    }
};

export const dbVerifyPostCode = (billingCityId, billingPostCode, cityId, cityPostCode, callback) => {
    return (dispatch, getState) => {
        return authorizeService.dbVerifyPostCode(billingCityId, billingPostCode, cityId, cityPostCode).then((result) => {
                callback({
                    "status": result.request.status,
                    "data": result.data.data
                });
            },
            (error) =>{
                callback(error);
            });
    }
};


/*==================================================== ACTION CONSTRUCTIONS ==========================================*/

export const login = (loginResult) => {
    return {
        type: AuthorizeActionType.LOGIN,
        payload: { authed: loginResult }
    }
};

export const logout = () => {
    return {
        type: AuthorizeActionType.LOGOUT,
        payload: { authed: false, guest: true }
    }
};

export const emailCheck = (result) => {
    return {
        type: EMAIL_CHECK,
        payload: {
            emailcheck: result,
        }
    }
};

export const checkRequestStatus = (result) => {
    return {
        type: REQUEST_STATUS_CHECK,
        payload: {
            requestStatus: result,
        }
    }
};

export const initMemberStatus = (result) => {
    return {
        type: MEMBER_STATUS,
        payload: {
            memberId: result.member_id,
            memberStatus: result.member_status,
            memberType: result.member_type,
            memberName: result.member_full_name,
            memberTitle: result.member_title,
            memberMasterGLN: result.member_master_gln,
            impersonatedMember: result.impersonated_member,
            debt: result.debt,
            expiredSubscriptionFeeList: result.expired_subscription_fee_list,
            isProfileComplete: result.is_profile_complete,
            udyToken: result.udy_token,
            udyBaseUrl: result.udy_base_url
        }
    }
};

export const initMemberData = (result) => {
    return {
        type: MEMBER_DATA,
        payload: {
            member: result,
            memberDataLoaded: true,
        }
    }
};

export const setTokenValidateStatus = (status) =>{
    return {
        type: TOKEN_STATUS,
        payload: {
            tokenStatus: status
        }
    }
};

export const addNextUrl = (nextUrl) => {
    return {
        type: ADD_NEXT_URL,
        payload: {
            nextUrl
        }
    }
};

export const removeNextUrl = () => {
    return {
        type: REMOVE_NEXT_URL,
        payload: {
        }
    }
};