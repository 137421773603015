import React, {Component} from 'react'
import '../../styles/Login.css'
import * as authorizeActions from "../../actions/authorizeActions";
import TextInput from "../input_types/TextInput";
import {connect} from "react-redux";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import {SITE_KEY} from "../../constants/Common";
import Reaptcha from "reaptcha";


class ForgotPassword extends Component{
    constructor(props){
        super(props);

        this.state = {
            emailInput: '',

            forgotPasswordError: false,
            forgotPasswordErrorMessage: '',

            isCaptchaLoaded: false,
            isCaptchaRendered: false,
            isCaptchaVerified: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    forgotPasswordCallBack = (messageError) => {
        const {t} = this.props;
        this.setState({
            forgotPasswordError: true,
            forgotPasswordErrorMessage: t(messageError)
        })
    };

    onLoad = () => {
        this.setState({
            isCaptchaLoaded: true
        })
    };

    onRender = () => {
        this.setState({
            isCaptchaRendered: true
        })
    };

    verifyCallback = (response) => {
        this.setState({
            isCaptchaVerified: true,
            loginError: false,
            loginErrorMessage: '',
        })
    };

    expiredCallback = () => {
        this.setState({
            isCaptchaVerified: false,
        })
    };

    handleSubmit(event) {
        event.preventDefault();
        const {t} = this.props;

        if (!this.state.isCaptchaVerified)
            return this.setState({
                forgotPasswordError: true,
                forgotPasswordErrorMessage: t('Please check the box'),
            });

        if(this.state.emailInput.trim()){
            this.props.forgotPassword(
                this.state.emailInput,
                this.forgotPasswordCallBack
            );
        }
    }

    render(){
        const {t} = this.props;
        return(
            <div className={"pos-abs"}>

                <h1 className={"t-center"}><T>Forgot Password</T></h1>

                <ContentModule>
                    <form onSubmit={this.handleSubmit} className={"editor small sigin"}>
                        <div className={"after-submit t-center"}>
                            {
                                this.state.forgotPasswordError &&
                                <div className="validation-summary-errors">
                                    <span dangerouslySetInnerHTML={{ __html: this.state.forgotPasswordErrorMessage }}></span>
                                </div>
                            }
                        </div>
                        <div>
                            <TextInput key={"emailInput"} inputType={"email"} inputId={"emailInput"} inputTitle={t("Email")} inputValue={this.state.emailInput} handleInputChange={this.handleInputChange}/>
                        </div>
                        <div className={"recaptcha-position"}>
                            <Reaptcha
                                sitekey={SITE_KEY}
                                onLoad={this.onLoad}
                                onRender={this.onRender}
                                onVerify={this.verifyCallback}
                                onExpire={this.expiredCallback}
                            />
                        </div>

                         <div className="btnInline d-flex-center mt-10">
                            <button className="btn" type="submit"><T>Reset Password</T></button>
                        </div>
                    </form>
                </ContentModule>

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        forgotPassword: (email, callBack) => {
            dispatch(authorizeActions.dbForgotPassword(email, callBack))
        },
    }
};

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslators(ForgotPassword));
