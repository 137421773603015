import * as activateService from "../api/services/activateService";
import {push} from "react-router-redux";


export const dbGetUser = (tokenId, callback) => {
    return (dispatch, getState) => {
        return activateService.dbGetUser(tokenId).then((result) => {
                callback({
                    status: "ok",
                    data: result.data
                })
            },
            (error) =>{
                // callback(messageError);
                let messageError = error.response && error.response.data;

                callback({
                    status: "error",
                    data: messageError
                })
            });
    }
};

export const dbRegisterOrResetUser = (memberId, password, isRegister, callback) => {
    return (dispatch, getState) => {
        return activateService.dbRegisterOrResetUser(memberId, password, isRegister).then((result) => {
                dispatch(push('/login'))
            },
            (error) =>{
                // callback(messageError);
                let messageError = error.response && error.response.data;

                callback({
                    status: "error",
                    data: messageError
                })
            });
    }
};


/*==================================================== ACTION CONSTRUCTIONS ==========================================*/
