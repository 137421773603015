import React, {Component} from 'react'
import ReactFlagsSelect from 'react-flags-select';

import {connect} from "react-redux";

import 'react-flags-select/css/react-flags-select.css';
import * as dataActions from "../actions/dataActions";

class LanguageSwitcher extends Component{
    componentWillMount() {
        this.setState({
            selectedLang: ((this.props.locale === "tr")? "TR": "GB")
        })

        this.onSelectFlag = this.onSelectFlag.bind(this);
    }

    onSelectFlag(countryCode) {
        if(countryCode === "TR"){
            this.props.setLocale("tr");
        }else {
            this.props.setLocale("en");
        }
    }

    render() {
        return(
            <div>
                <ReactFlagsSelect
                    defaultCountry={this.state.selectedLang}
                    countries={["TR", "GB"]}
                    customLabels={{"TR": "Türkçe","GB": "English"}}
                    placeholder="Select Language"
                    showSelectedLabel={false}
                    showOptionLabel={true}
                    selectedSize={18}
                    optionsSize={13}
                    alignOptions="right"
                    onSelect={this.onSelectFlag}
                    />
            </div>
        )
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setLocale: (locale) => {
            dispatch(dataActions.setLocale(locale));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    const {data} = state;
    return{
        locale: data.locale,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);