import React, {Component, Fragment} from 'react'
import {RadioButton, RadioGroup} from "react-radio-buttons";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import {connect} from "react-redux";
import * as auxActions from "../../actions/auxActions";
import {withRouter} from "react-router-dom";

class Pay extends Component{
    constructor(props){
        super(props);
        
        this.state = {
            currentStep: 1,
            paymentType: "card",
            instance_id: null
        };

        this.onChangePaymentType = this.onChangePaymentType.bind(this);
    }

    componentWillMount(){
        const {serviceType} = this.props;
        const {id} = this.props.match.params;

        const formData = new FormData();
        formData.append("service_type", serviceType);
        formData.append("service_id", id);

        this.setState({
            instance_id: id
        });

        this.props.getFeeDetail(formData, this.feeDetailCallback);
    }

    feeDetailCallback = (result) => {
        this.setState({
            currentStep: 2,

            invoice_number: result.data.invoice_number,
            invoice_date: result.data.invoice_date,
            price: result.data.price,
            paid_amount: result.data.paid_amount,
            exemption_amount: result.data.exemption_amount,
            remaining_amount: result.data.remaining_amount,
            bank_account: result.data.bank_account,
            is_payed: result.data.is_payed,
            oid: result.data.oid,
            okUrl: result.data.okUrl,
            failUrl: result.data.failUrl,
            rnd: result.data.rnd,
            hash: result.data.hash,
            clientid: result.data.clientid,
            service_type: result.data.service_type,
            user: result.data.user,
            title: result.data.title,
            fail_message: result.data.fail_message,
            return_code: result.data.return_code,
            fismi: result.data.Fismi
        })
    };

    onChangePaymentType(value) {
        this.setState({
            paymentType: value
        })
    }


    render(){
        const {t} = this.props;
        const {paymentType, currentStep} = this.state;

        return(
        <div>
            <div className={"content-module-sized"}>

                <h2 className={"t-center"}><T>Payment Page</T></h2>
                {
                    currentStep === 1?
                        <div>
                            <T>Loading...</T>
                        </div>:
                <Fragment>
                    <ContentModule>
                        <div className="editor small signin">
                            <div className={"d-flex item-priced"}>
                                <div className={"on-left"}>
                                    <T>Order Number</T>
                                </div>
                                <div className={"on-right"}>
                                    {this.state.order}
                                </div>
                            </div>
                            <div className={"d-flex item-priced"}>
                                <div className={"on-left"}>
                                    <T>Invoice Number</T>
                                </div>
                                <div className={"on-right"}>
                                    {this.state.invoice_number}
                                </div>
                            </div>
                            <div className={"d-flex item-priced"}>
                                <div className={"on-left"}>
                                    <T>Invoice Date</T>
                                </div>
                                <div className={"on-right"}>
                                    {this.state.invoice_date}
                                </div>
                            </div>
                            <div className={"d-flex item-priced"}>
                                <div className={"on-left"}>
                                    <T>Price</T>
                                </div>
                                <div className={"on-right"}>
                                    {(this.state.price * 100/120).toFixed(2)}₺
                                </div>
                            </div>
                            <div className={"d-flex item-priced"}>
                                <div className={"on-left"}>
                                    <T>KDV</T>
                                </div>
                                <div className={"on-right"}>
                                    {(this.state.price * 20/120).toFixed(2)}₺
                                </div>
                            </div>
                            <div className={""}>
                                <div className={"total-amount"}>
                                    <T>Total</T>: {this.state.price.toFixed(2)}₺
                                </div>
                            </div>
                        </div>

                        <div className="editor small signin mt-20">
                            <div className={"d-flex item-priced"}>
                                <div className={"on-left"}>
                                    <T>Total Price</T>
                                </div>
                                <div className={"on-right"}>
                                    {this.state.price.toFixed(2)}₺
                                </div>
                            </div>
                            <div className={"d-flex item-priced"}>
                                <div className={"on-left"}>
                                    <T>Paid Amount</T>
                                </div>
                                <div className={"on-right"}>
                                    {this.state.paid_amount.toFixed(2)}₺
                                </div>
                            </div>
                            {
                                this.state.exemption_amount > 0 &&
                                    <div className={"d-flex item-priced"}>
                                        <div className={"on-left"}>
                                            <T>Exemption Amount</T>
                                        </div>
                                        <div className={"on-right"}>
                                            {this.state.exemption_amount.toFixed(2)}₺
                                        </div>
                                    </div>
                            }
                            <div className={"d-flex item-priced"}>
                                <div className={"on-left"}>
                                    <T>Remaining Amount</T>
                                </div>
                                <div className={"on-right"}>
                                    {this.state.remaining_amount.toFixed(2)}₺
                                </div>
                            </div>
                        </div>


                    </ContentModule>

                    {
                        this.state.is_payed &&
                            <ContentModule colorClass={"bg-teal"}>
                                <div className={"bolded"}>
                                    <T>Payment was successfully received.</T>
                                </div>
                            </ContentModule>
                    }

                    {
                        !this.state.is_payed &&
                            <div>

                                {
                                    this.state.return_code !== '' && this.state.return_code !== '00' && this.state.fail_message &&
                                    <ContentModule colorClass={"bg-danger"}>
                                        <div className={"pay-error-message"}> <T>Last Error</T>: {this.state.fail_message} </div>
                                    </ContentModule>
                                }

                                {
                                    this.state.return_code === '00'  &&
                                    <ContentModule colorClass={"bg-teal"}>
                                        <div className={"bolded"}>
                                            <T>Payment was successfully received.</T>
                                        </div>
                                    </ContentModule>
                                }

                                <div>
                                    <h4><T>Choose a payment type</T></h4>
                                    <RadioGroup onChange={ this.onChangePaymentType } value={""} horizontal>
                                        <RadioButton value="card" rootColor="#525151" pointColor="#e15054">
                                            <T>Credit Card</T>
                                        </RadioButton>
                                        <RadioButton value="eft" rootColor="#525151" pointColor="#e15054">
                                            <T>EFT</T>
                                        </RadioButton>
                                    </RadioGroup>
                                </div>

                                <div>
                                    {
                                        paymentType === "card" ?
                                            <div>
                                                <ContentModule containerClass={"mt-20"}>
                                                    <p><T>To pay your fee please click Pay button. You will be redirected to secure payment page where you can enter your credit card information and pay your fee. As soon as the payment completed you will be redirected to this page.</T></p>
                                                    <form method="post"
                                                          action="https://sanalpos.isbank.com.tr/fim/est3Dgate">
                                                        <div className="btnInline mt-10 t-center">
                                                            <input className={"signup-button ml-5 "} type="submit"
                                                                   value={t("Proceed to Payment")}/>
                                                        </div>

                                                        <input type="hidden" name="oid" value={this.state.oid}/>
                                                        <input type="hidden" name="amount"
                                                               value={this.state.remaining_amount.toFixed(2)}/>

                                                        <input type="hidden" name="okUrl" value={this.state.okUrl}/>
                                                        <input type="hidden" name="failUrl"
                                                               value={this.state.failUrl}/>

                                                        <input type="hidden" name="rnd" value={this.state.rnd}/>
                                                        <input type="hidden" name="hash" value={this.state.hash}/>
                                                        <input type="hidden" name="clientid"
                                                               value={this.state.clientid}/>

                                                        <input type="hidden" name="service_type"
                                                               value={this.state.service_type}/>
                                                        <input type="hidden" name="user" value={this.state.user}/>
                                                        <input type="hidden" name="instance_id"
                                                               value={this.state.instance_id}/>

                                                        <input type="hidden" name="islemtipi" value="Auth"/>
                                                        <input type="hidden" name="storetype" value="3d_pay_hosting"/>
                                                        <input type="hidden" name="lang" value="tr"/>
                                                        <input type="hidden" name="currency" value="949"/>
                                                        <input type="hidden" name="refreshtime" value="0"/>
                                                        <input type="hidden" name="encoding" value="UTF-8"/>

                                                        <input type="hidden" name="firmaadi" value={this.state.title}/>
                                                        <input type="hidden" name="Fismi" value={this.state.fismi}/>

                                                        <input type="hidden" name="TranType" value="Auth"/>
                                                        <input type="hidden" name="Instalment" value=""/>
                                                        <input type="hidden" name="callbackUrl" value=""/>
                                                        <input type="hidden" name="hashAlgorithm" value="ver3"/>
                                                        <input type="hidden" name="BillToName" value={this.state.title}/>
                                                        <input type="hidden" name="BillToCompany" value={this.state.title}/>
                                                    </form>
                                                </ContentModule>
                                            </div> :
                                            <div>
                                                <ContentModule containerClass={"mt-20"}>
                                                    <p><T>In order to prevent delays to your application process please make sure you transfer money as described.</T></p>
                                                    <p>1. <T>Please provide the following account information.</T></p>
                                                    <p className={"ml-20 large"}>
                                                        <p><b>Tarafımızdan almış olduğunuz hizmete ilişkin fatura GS1 Sistemi'ne kayıtlı olan firmaya kesilecektir. EFT/Havale yoluyla yapılan ödemelerin GS1 Sistemi'ne kayıtlı olan üye banka hesabından yapılması yasal zorunluluktur.</b></p>
                                                        <T>Bank</T>: İş Bankası - Akay Şubesi<br/>
                                                        <T>Account Holder</T>: GS1 Türkiye Vakfı<br/>
                                                        <T>Account Number(IBAN)</T>: {this.state.bank_account}
                                                    </p>
                                                    <p>2. <T
                                                        message={"Please transfer exactly {{ price }}."}
                                                        price={<b>{this.state.remaining_amount.toFixed(2)}₺</b>}
                                                        />
                                                    </p>
                                                    <p>3. <T>Please enter only the following code to description.</T></p>
                                                    <p className={"ml-20 large"}><b>{this.state.oid}</b></p>
                                                    <p><T>If you follow these steps your payment will be matched with your application shortly. Otherwise this process may take long.</T></p>
                                                </ContentModule>
                                            </div>
                                    }
                                </div>
                                <div className="btnInline mt-10 t-center">
                                    <span className={"signup-button mr-5"} onClick={this.props.history.goBack}><T>Back</T></span>
                                </div>
                            </div>
                    }
                </Fragment>
                }
            </div>
        </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getFeeDetail: (data, callback) =>{
            dispatch(auxActions.dbGetFeeDetail(data, callback))
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    const { authorize } = state;

    return {
        memberType: authorize.memberType,
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(Pay)));