import React, {Component} from 'react'
import '../../styles/Dashboard.css'
import TextInput from "../input_types/TextInput";
import FileLink from "../input_types/FileLink";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import PayInfo from "../payment/PayInfo";

class ApplicationView extends Component{

    constructor(props){
        super(props);
        this.state = {
            application: null
        };

        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.handleOnWithdrawClick = this.handleOnWithdrawClick.bind(this);
    }

    componentWillMount(){
        let application = this.props.application;
        const {t} = this.props;

        if (application.latest_application_note === null){
            application['latest_application_note'] = t("No notes recorded!");
        }

        this.setState({
            application
        })
    }

    handleOnEdit(){
        this.props.handleEditAction();
    }

    handleOnWithdrawClick(){
        this.props.handleWithdrawApplication();
    }

    render(){
        const {t} = this.props;
        const digitsText = t("digits");
        let app = this.state.application;
        const status = app.application_status;
        const status_description = app.status_description;
        const requestedDigits = app.requested_digit + " " + digitsText;
        const annualTaxAssessment = app.annual_tax_assessment;
        const commercialCertificate = app.commercial_certificate;
        const commitmentLetter = app.commitment_letter;
        const incomeStatement = app.income_statement;
        const extraFiles = app.extra_files;
        const commercialCertificateReject = app.commercial_certificate_reject;
        const commitmentLetterReject = app.commitment_letter_reject;
        const incomeStatementReject = app.income_statement_reject;
        const latestApplicationNotes = app.latest_application_note;
        const requestedAmount= app.requested_amount;

        const isTransfer = app.is_transfer;
        const applicationType = app.application_type ? app.application_type : null;

        const registerDate = app.registry_gazette_date ? app.registry_gazette_date : null;
        const registerPage = app.registry_gazette_page ? app.registry_gazette_page : null;
        const registerNumber = app.registry_gazette_number ? app.registry_gazette_number : null;


        return(
            <div>
                <h2 className={"t-center"}><T>View Application</T></h2>
                <ContentModule containerClass={"content-module-sized"}>
                    <T>You can follow the status of your application from this page.</T>
                </ContentModule>

                <div className={"application-view"}>
                    <ContentModule containerClass={"content-module-sized"}>
                        <p><span className={"bolded"}><T>INFO</T>:</span> {status_description}</p>
                        <p><T>Information regarding your application has sent to your email address.</T></p>
                    </ContentModule>

                    {
                        applicationType !== 'merge' &&
                        <PayInfo service_fee={this.state.application["service_fee"]}
                                 is_cancelled={this.state.application["service_fee_cancelled"]}
                                 remaining_amount={this.state.application["remaining_amount"]}
                                 payment_status={this.state.application["payment_status"]}
                                 pre_sales_agreement={this.state.application["pre_sales_agreement"]}
                                 distant_sales_agreement={this.state.application["distant_sales_agreement"]}
                                 path={"/candidate/pay/servicefee/" + this.state.application["service_id"]}
                                 sized={true}
                        />
                    }

                    <ContentModule containerClass={"content-module-sized"}>
                        <form className="editor small signin">
                            <div>
                                {
                                    applicationType !== 'merge' && !isTransfer &&
                                    <div>
                                    <TextInput key={"requestedDigits"} inputId={"requestedDigits"} required={false} inputTitle={t("Requested Digits")} inputValue={requestedDigits} disabled/>
                                    <TextInput key={"requestedAmount"} inputId={"requestedAmount"} inputTitle={t("Requested Number Amount")} required={false} inputValue={requestedAmount} disabled/>
                                    </div>
                                }
                                {
                                    applicationType !== 'merge' &&
                                    <TextInput key={"annualTaxAssessment"} inputId={"annualTaxAssessment"} required={false} inputTitle={t("Annual Tax Assessment")} inputValue={annualTaxAssessment} disabled/>
                                }
                                {
                                    applicationType === 'merge' &&
                                        <div>
                                        <TextInput key={"registerDate"} inputId={"registerDate"}
                                                   inputTitle={t("Registry Gazette Date")}
                                                   inputValue={registerDate}
                                                   disabled />
                                        <TextInput key={"registerNumber"} inputId={"registerNumber"} inputTitle={t("Registry Gazette Number")} maxLength={20} inputValue={registerNumber} disabled />
                                        <TextInput key={"registerPage"} inputId={"registerPage"} inputTitle={t("Registry Gazette Page")} inputType={"number"} maxLength={20} inputValue={registerPage} disabled />
                                        </div>
                                }
                                <FileLink inputId={"commercialCertificateFile"} inputTitle={t("Commercial Certificate")} required={false} inputValue={commercialCertificate} errorText={commercialCertificateReject}/>
                                <FileLink inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} required={false} inputValue={commitmentLetter} errorText={commitmentLetterReject}/>
                                {
                                    incomeStatement &&
                                    <FileLink inputId={"incomeStatementFile"} inputTitle={t("Income Statement")} required={false} inputValue={incomeStatement} errorText={incomeStatementReject}/>
                                }
                                {
                                    isTransfer ?
                                        <div>
                                            <FileLink key={"petitionFile"} inputId={"petitionFile"} required={false} inputTitle={t("Petition Letter")} inputValue={app.petition} errorText={app.petition_reject}/>
                                            <FileLink key={"transferFile"} inputId={"transferFile"} required={false} inputTitle={t("Transfer Letter")} inputValue={app.transfer_paper} errorText={app.transfer_paper_reject}/>
                                            {
                                                app.transfer_type === 2 ?
                                                    <div>
                                                        <FileLink key={"inheritanceFile"} inputId={"inheritanceFile"} required={false} inputTitle={t("Inheritance Letter")} inputValue={app.inheritance_document} errorText={app.inheritance_document_reject}/>
                                                        {
                                                            app.consent_document &&
                                                            <FileLink key={"consentFile"} inputId={"consentFile"} required={false} inputTitle={t("Consent Letter")} inputValue={app.consent_document} errorText={app.consent_document_reject}/>
                                                        }
                                                    </div>: null
                                            }
                                        </div>: null
                                }
                                {
                                    extraFiles !== null && extraFiles.length ?
                                    <FileLink inputId={"extraFiles"} inputTitle={t("Extra Files")}  multiple={true} inputValue={extraFiles} required={false}/> :null
                                }
                                <TextInput key={"latestApplicationNote"} inputId={"latestApplicationNote"} required={false} inputTitle={t("Additional Notes")} inputValue={latestApplicationNotes} isTextArea disabled/>
                            </div>
                        </form>
                    </ContentModule>
                    <div className="application-action d-flex-center">
                        <button className="btn" onClick={this.handleOnWithdrawClick}><T>Withdraw Application</T></button>
                        {
                            status === 2 && <button onClick={this.handleOnEdit}><T>Edit</T></button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslators(ApplicationView);
