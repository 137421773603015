import React from 'react'
import headerLogo from '../images/header-logo.png'
import {Link} from "react-router-dom";

import {T} from 'lioness';
import LanguageSwitcher from './LanguageSwitcher';

import '../styles/Header.css';

export const Header = () =>{
    return(
        <header>
            <div className={"header-content"}>
                <div className="logo-container">
                    <Link className="logo" to={"/"}>
                        <img src={headerLogo} alt={"Logo"}/>
                    </Link>
                    <p className="title">
                        <strong><T>Welcome to GS1 Turkey Membership System</T></strong><br/>
                        <T>The Global Language of Business</T>
                    </p>
                </div>
                <div className={"menu-container"}>
                    <LanguageSwitcher />
                </div>
            </div>
        </header>
    )
};
