import swal from 'sweetalert'
import {getLocale} from "./authToken";

export const axiosError = (error, reject, popup = true) => {
    let response = "";
    if(error.response) {
        if (error.response.status === 500) {
            response = error.response.statusText;
        }
        else if (error.response.status === 401) {
            return window.location.href = '/logout'
        }
        else if (error.response.status === 403) {
            response = error.response.statusText;

            if (error.response.data && "detail" in error.response.data)
                response = error.response.data["detail"];
        }
        else if (error.response.status === 413) {
            response = error.response.statusText;
        }
        else if (error.response.status === 429) {
            response = error.response.statusText;
        }
        else if (error.response.data && error.response.data.status) {
            response = error.response.data.message;
        }
        else if (error.response.data) {
            let err = error.response.data;
            if (typeof err === 'object')
                for (let key in err) {
                    if (key !== "non_field_errors")
                        response += '\n' + key + '\n';
                    if (err[key].constructor === Array) {
                        response += err[key].join('\n');
                    }
                    response += '\n';
                }
            else
                response = error.response.statusText;
        }
    } else if(error.request) {
        response = error.message;
    }
    else {
        response = error.message;
    }

    if(popup) {
        const language = getLocale();
        let titleText = 'Error';

        if (language === 'tr')
            titleText = 'Hata';

        swal({
            title: titleText,
            text: response,
            icon: 'error'
        });
    }
    else
        reject(response);
};

