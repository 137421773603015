import React from 'react'
import {Link} from "react-router-dom";
import tick_ok from '../images/tick_ok.png'
import tick_error from '../images/tick_error.png'
import load_ok from '../images/load_ok.png'
import {faSearch, faTrashAlt, faEdit, faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";

import {T} from 'lioness';


const constructDataForGcpTable = (row) =>{
    return {
        id: row.id,
        gcp: row.gcp,
        digits: row.digits,
        status: <T>{row.status_name}</T>,
        registry_dt: row.registry_dt,
        actions:
            <div>
                <span>
                    <span>
                        <Link to={"/member/gcp/detail/" + row.id}>
                            <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                        </Link>
                    </span>
                    {
                        !row.is_membership_cancel_requested &&
                        !row.is_app_cancel_requested &&
                        <Link to={"/member/gcp/cancel/" + row.id}>
                            <FontAwesomeIcon icon={faTrashAlt} size={"lg"} color={"red"} className={"curpnt ml-10"}/>
                        </Link>
                    }
                </span>
            </div>
    };
};

export function makeGcpTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForGcpTable(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForApplicationGcpTable = (row) =>{
    return {
        id: row.id,
        gcp: row.gcp,
        digits: row.digits,
        status: <T>{row.status_name}</T>,
        created_at: row.created_at,
        actions:
            <div>
                <Link to={"/member/gcp/application/view/" + row.id}>
                    <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                </Link>
                {
                    row.status === 2 && <Link to={"/member/gcp/application/edit/" + row.id} className={"ml-5 mr-5"}>
                        <FontAwesomeIcon icon={faEdit} size={"lg"} color={"green"} className={"curpnt ml-10"}/>
                    </Link>
                }
                {
                    !(row.status === 7 || row.status === 8 || row.status === 9) &&
                    <Link to={"/member/gcp/withdraw/" + row.id}>
                        <FontAwesomeIcon icon={faTrashAlt} size={"lg"} color={"red"} className={"curpnt ml-10"}/>
                    </Link>
                }
            </div>
    };
};

export function makeGcpApplicationTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForApplicationGcpTable(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForTransferTable = (row) =>{
    return {
        id: row.id,
        status: row.status_name,
        transfer_type: row.transfer_type,
        from_member: row.from_member,
        created_at: row.created_at,
        // gcps: row.gcps,
    };
};

export function makeTransferListTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForTransferTable(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForGtin8 = (row) =>{
    return {
        id: row.id,
        status: <T>{row.status_name}</T>,
        created_at: row.created_at,
        actions:
            <div>
                <Link to={"/member/gtin8/application/" + row.id}>
                    <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                </Link>
                {
                    row.status === 2 &&
                    <Link to={"/member/gtin8/application/edit/" + row.id} className={"ml-5 mr-5"}>
                        <FontAwesomeIcon icon={faEdit} size={"lg"} color={"green"} className={"curpnt ml-10"}/>
                    </Link>
                }
            </div>
    };
};

export function makeGtin8TableData(data) {
    return data.map(row => {
        return {
            ...constructDataForGtin8(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForGtin8List = (row) =>{
    return {
        id: row.id,
        gtin8: row.gtin8,
        app_item_info_ok:
            <div>
                {
                    row.app_item_info_ok ?

                        <span className={"d-flex"}><img src={tick_ok} alt={""}/> <span className={"ml-5"}><T>Ok</T></span> </span> :

                        !row.app_item_info_ok && row.app_item_info_edited ?
                            <span className={"d-flex"}><img src={load_ok} alt={""}/> <span className={"ml-5"}><T>Waiting for revision</T></span> </span> :
                            <span className={"d-flex"}><img src={tick_error} alt={""}/><span className={"ml-5"}><T>Need edition</T></span></span>
                }
            </div>,
        registry_dt: row.registry_dt,
        actions:
            <div>
                <Link to={"/member/gtin8/applicationitem/" + row.id}>
                    <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                </Link>
                {
                    !row.app_item_info_ok && !row.app_item_info_edited &&
                    <Link to={"/member/gtin8/applicationitem/edit/" + row.id} className={"ml-5 mr-5"}>
                        <FontAwesomeIcon icon={faEdit} size={"lg"} color={"green"} className={"curpnt ml-10"}/>
                    </Link>
                }
            </div>
    };
};

export function makeGtin8ListTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForGtin8List(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForVw = (row) =>{
    return {
        id: row.id,
        status: <T>{row.status_name}</T>,
        created_at: row.created_at,
        actions:
            <div>
                <Link to={"/member/vw/application/" + row.id}>
                    <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                </Link>
                {
                    row.status === 2 &&
                    <Link to={"/member/vw/application/edit/" + row.id} className={"ml-5 mr-5"}>
                        <FontAwesomeIcon icon={faEdit} size={"lg"} color={"green"} className={"curpnt ml-10"}/>
                    </Link>
                }
            </div>
    };
};

export function makeVwTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForVw(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForVwList = (row) =>{
    return {
        id: row.id,
        vw: row.vw,
        app_item_info_ok:
            <div>
                {
                    row.app_item_info_ok ?

                        <span className={"d-flex"}><img src={tick_ok} alt={""}/> <span className={"ml-5"}><T>Ok</T></span> </span> :

                        !row.app_item_info_ok && row.app_item_info_edited ?
                            <span className={"d-flex"}><img src={load_ok} alt={""}/> <span className={"ml-5"}><T>Waiting for revision</T></span> </span> :
                            <span className={"d-flex"}><img src={tick_error} alt={""}/><span className={"ml-5"}><T>Need edition</T></span></span>
                }
            </div>,
        registry_dt: row.registry_dt,
        actions:
            <div>
                <Link to={"/member/vw/applicationitem/" + row.id}>
                    <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                </Link>
                {
                    !row.app_item_info_ok && !row.app_item_info_edited &&
                    <Link to={"/member/vw/applicationitem/edit/" + row.id} className={"ml-5 mr-5"}>
                        <FontAwesomeIcon icon={faEdit} size={"lg"} color={"green"} className={"curpnt ml-10"}/>
                    </Link>
                }
            </div>
    };
};

export function makeVwListTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForVwList(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForGtin13List = (row, params) =>{
    return {
        id: row.id,
        gtin13: row.gtin13,
        status: <T>{row.status}</T>,
        registry_dt: row.registry_dt,
        actions:
            <div>
                <Link to={"/member/gtin13/detail/" + row.id}>
                    <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                </Link>
                <Link to={"/member/gtin13/edit/" + row.id} className={"ml-5 mr-5"}>
                    <FontAwesomeIcon icon={faEdit} size={"lg"} color={"green"} className={"curpnt ml-10"}/>
                </Link>
                <a href={params.udyBaseUrl + "/#/login?token=" + params.udyToken + "&member_id=" + params.memberId + "&module=switch_to_udy&gtin=" + row.id + "&lang=" + params.locale} className={"ml-5 mr-5"} target={"_blank"}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} size={"lg"} color={"gray"} className={"curpnt ml-10"}/>
                </a>
            </div>
    };
};

export function makeGtin13ListTableData(data, params) {
    return data.map(row => {
        return {
            ...constructDataForGtin13List(row, params)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForGtin12List = (row, params) =>{
    return {
        id: row.id,
        gtin12: row.gtin12,
        status: <T>{row.status}</T>,
        registry_dt: row.registry_dt,
        actions:
            <div>
                <Link to={"/member/gtin12/detail/" + row.id}>
                    <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                </Link>
                <Link to={"/member/gtin12/edit/" + row.id} className={"ml-5 mr-5"}>
                    <FontAwesomeIcon icon={faEdit} size={"lg"} color={"green"} className={"curpnt ml-10"}/>
                </Link>
                {/*
                <a href={params.udyBaseUrl + "/#/login?token=" + params.udyToken + "&member_id=" + params.memberId + "&module=switch_to_udy&gtin=" + row.id + "&lang=" + params.locale} className={"ml-5 mr-5"} target={"_blank"}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} size={"lg"} color={"gray"} className={"curpnt ml-10"}/>
                </a>
                */}
            </div>
    };
};

export function makeGtin12ListTableData(data, params) {
    return data.map(row => {
        return {
            ...constructDataForGtin12List(row, params)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForUpc = (row) =>{
    return {
        id: row.id,
        requested_digit: row.requested_digit,
        status: <T>{row.status_name}</T>,
        created_at: row.created_at,
        sales_channel: row.sales_channel,
        actions:
            <div>
                <Link to={"/member/upc/application/" + row.id}>
                    <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                </Link>
            </div>
    };
};

export function makeUpcTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForUpc(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForUpcList = (row) =>{
    return {
        id: row.id,
        upc: row.upc,
        registry_dt: row.registry_dt,
        actions:
            <div>
                <Link to={"/member/upc/detail/" + row.id}>
                    <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                </Link>
            </div>
    };
};

export function makeUpcListTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForUpcList(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/

export function makeMemberStatsTableData(data) {
    let gcpStats = [], numberStats = [], applicationStats = [];
    let gcp_numbers = data.gcp_numbers;
    let number_stats = data.number_stats;
    let all_digits = data.all_digits;

    Object.keys(gcp_numbers).map(key => {
        return gcpStats.push({
            digit_count: <T>{gcp_numbers[key].name}</T>,
            total: gcp_numbers[key].total,
            active: gcp_numbers[key].active,
            cancelled: gcp_numbers[key].cancelled,
        });
    });

    gcpStats.push({
        digit_count: "",
        total: <b>{all_digits.total}</b>,
        active: <b>{all_digits.active}</b>,
        cancelled: <b>{all_digits.cancelled}</b>,
    });

    Object.keys(number_stats).map(key => {
        return numberStats.push({
            type: <T>{number_stats[key].name}</T>,
            total: number_stats[key].total,
            active: number_stats[key].active,
            cancelled: number_stats[key].cancelled,
        });
    });

    return {
        gcpStats: gcpStats,
        numberStats: numberStats,
        applicationStats: applicationStats,
    };
}

/*--------------------------------------------------------------------------------------------------------------------*/

const constructDataForApplicationItems = (row) =>{
    return {
        id: row.id,
        target_market: row.target_market,
        image_url: row.image_url,
        brand: row.brand,
        label_description: row.label_description,
        brick: row.brick,
    };
};

export function makeApplicationItemTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForApplicationItems(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/
const constructDataForServiceFee = (row) =>{
    return {
        product_type: row.product_type,
        price: row.price,
        invoice_number: row.invoice_number,
        payment_status: <T>{row.payment_status}</T>,
        actions:
            <div>
                <span>
                    <span>
                        <Link to={"/member/payment/servicefee/" + row.id}>
                            <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                        </Link>
                    </span>
                </span>
            </div>
    };
};

export function makeServiceFeeTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForServiceFee(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/
const constructDataForSubscriptionFee = (row) =>{
    return {
        year: row.year,
        invoice_number: row.invoice_number,
        invoice: row.invoice,
        invoice_date: row.invoice_date,
        subscription_fee: row.subscription_fee,
        payment_status: <T>{row.payment_status}</T>,
        actions:
            <div>
                <span>
                    <span>
                        <Link to={"/member/payment/subscription/" + row.id}>
                            <FontAwesomeIcon icon={faSearch} size={"lg"} color={"#4c6ef5"} className={"curpnt mr-5"}/>
                        </Link>
                    </span>
                </span>
            </div>
    };
};

export function makeSubscriptionFeeTableData(data) {
    return data.map(row => {
        return {
            ...constructDataForSubscriptionFee(row)
        };
    });
}

/*--------------------------------------------------------------------------------------------------------------------*/