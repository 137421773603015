import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import Iframe from 'react-iframe'

class ApplicationEdit extends Component{

    constructor(props){
        super(props);

        this.state = {
            gtin: ""
        };

    }

    componentWillMount(){
        let applicationId = 0;
        if (this.props && this.props.match && this.props.match.params && "applicationId" in this.props.match.params){
            applicationId = this.props.match.params["applicationId"]
            this.setState({"gtin": applicationId});
        }
    }

    render(){
        const {memberId, udyBaseUrl, udyToken, locale, t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>Update GTIN-13 Data</T></h2>
                <ContentModule>
                    <T>Within the scope of GS1 System Membership, you can enter the basic product attributes listed below with the barcode number (Global Trade Item Number-GTIN) that you have created and used with your Company Prefix Number (Global Trade Item Number-GTIN).</T><br/><br/>
                    <T>Entering your product barcode number and basic product attributes for the product carrying this number is recommended by both retailers and e-marketplaces, and also creates a marketing tool that you can use in 114 countries.</T><br/><br/>
                    {/*
                    <ul>
                        <li><strong><T>GTIN-13</T></strong>: <T>13-Digit Barcode Number</T> <b><T>(A GTIN-13 can only be entered once, the financial and legal responsibility of entering the old number in the new product connected to GTIN-13 or deliberately entering the new product belongs to the company)</T></b></li>
                        <li><strong><T>Company Name</T></strong>: <T>The company name registered to the system is automatically assigned</T></li>
                        <li><strong><T>Brand</T></strong>: <T>Brand name on the product (Not a company name)</T></li>
                        <li><strong><T>Label Description</T></strong>: <T>Description on the product label</T></li>
                        <li><strong><T>Target Market</T></strong>: <T>Country where the product is sold (All 7 qualification information must be entered in accordance with the language of this country)</T></li>
                        <li><strong><T>Product Image URL</T></strong>: <T>URL where the product photo is published</T></li>
                        <li><strong><T>Net Content</T></strong>: <T>Net Content of the product which is specified on the product</T></li>
                        <li><strong><T>Unit Of Measurements</T></strong>: <T>The unit of net content</T></li>
                        <li><strong><T>Category</T></strong>: <T>Product category according to GS1 Global Product Category</T></li>
                    </ul>
                    */}
                    <T>As of 08.06.2020, our automatic barcode number generation service has started on the system.</T><br/><br/>
                    <T>You can enter basic attributes of your product and you can have the system automatically generate the barcode number (GTIN-Global Trade Item Number) for you, product specific and singular.</T><br/><br/>
                    <T>However, since the generation of the product barcode number by the system is carried out with the numbers obtained from the pool of barcodes that have not been used yet; It is important that you do not generate a new number for a product that you have put on the market before and has a barcode number on it.</T><br/><br/>
                    <T
                        message="For detailed information about product registy {{ link }}."
                        link={<a href={"https://gs1tr.org/dosyalar/" + t("infoaccess") + ".pdf"} target={"_blank"}><T>click</T></a>}
                    />
                </ContentModule>

                <ContentModule padless={true}>
                    <Iframe
                        url={udyBaseUrl + "/#/login?token=" + udyToken + "&member_id=" + memberId + "&module=catalogue_edit&gtin=" + this.state.gtin +  "&lang=" + locale}
                        width="100%"
                        height="600px"
                        id="edit-gtin13-iframe"
                        frameBorder={0}
                    />
                </ContentModule>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const { data, authorize } = state;
    return {
        memberId: authorize.memberId,
        udyBaseUrl: authorize.udyBaseUrl,
        udyToken: authorize.udyToken,
        locale: data.locale,
    }
};

export default withRouter(connect(mapStateToProps)(withTranslators(ApplicationEdit)));
