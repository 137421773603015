import React, {Component} from 'react'
import {Header} from "./Header";
import {Link} from "react-router-dom";
import {T} from "lioness";
import ContentModule from "./ContentModule";

import "../styles/Drawer.css";

class GuestWrapper extends Component{

    render(){
        const {component, fullwidth} = this.props;
        const w95 = fullwidth ? "w95" : "";

        return(
        <div>
            <Header/>
            <div className={"content-wrapper"}>
                {
                    fullwidth ? null :
                    <div className={"drawer-main"}>
                        <ContentModule colorClass={"bg-sky-active"} containerClass={"drawer-wrapper"}>
                            <ul>
                                <li><Link to={"/login"}> <T>Login</T> </Link></li>
                                <li><Link to={"/signup"}> <T>Signup</T> </Link></li>
                            </ul>
                        </ContentModule>
                        <ContentModule colorClass={"bg-teal"} containerClass={"drawer-wrapper mt-20"}>
                            <h4><T>Login to Online System</T></h4>
                            <p>
                                <T>If you have become a member of our online system before, please click "Login" link.</T>
                            </p>
                            <p>
                                <T>If you are not a member, you can login after you signup. If you are already a member and want to use online system please contact with us.</T>
                            </p>
                        </ContentModule>
                    </div>
                }

                <div className={"drawer-content " + w95}>
                    {component}
                </div>
            </div>
        </div>

        )
    }
}

export default GuestWrapper;