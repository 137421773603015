import React, {Component} from 'react'
import FileInput from "../input_types/FileInput";
import TextInput from "../input_types/TextInput";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as vwActions from "../../actions/vwActions";
import {T, withTranslators} from "lioness";
import ContentModule from "../ContentModule";
import {isFileSizeExceeded, showFileError} from "../FileUtils";

class ApplicationEdit extends Component{

    constructor(props){
        super(props);

        this.state = {
            currentStep: 1,
            commitmentLetterFile: null,
            submitError: false,
            submitErrorMessage: "",
            extraFiles: null,
            latestApplicationNotes: "",
            submitting: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        const {applicationId} = this.props.match.params;

        this.props.loadApplicationEdit(applicationId, this.loadApplicationEdit)
    }

    loadApplicationEdit = (result) => {
        if (result.status === 200){
            if ("error" in result.data)
                this.setState({
                    currentStep: 4,
                });
            else
                this.setState({
                    currentStep: 2,
                    latestApplicationNotes: result.data.latest_application_note ?
                        result.data.latest_application_note : ""
                })
        }
    };

    submitFormCallback = (result) => {
        if (result.status === 200)
            this.setState({
                currentStep: 3,
                submitting: false
            });
        else
            this.setState({
                submitting: false
            })
    };

    handleUploadFile = (event) => {
        let fileList = event.target.files;
        let len = fileList.length;
        let submitFile = [];
        let i = 0;
        while(i < len) {
            submitFile.push(fileList[i]);
            i++;
        }
        if(event.target.id !== 'extraFiles'){
            submitFile = submitFile[0];
        }

        this.setState({
            [event.target.id]: submitFile,
        });

    };


    handleSubmit(event){
        event.preventDefault();

        const {applicationId} = this.props.match.params;

        const formData = new FormData();

        let extraFiles= this.state.extraFiles;
        if(extraFiles) {
            for (let i = 0; i < extraFiles.length; i++) {
                formData.append('extra_files', extraFiles[i]);
            }
        }

        if (this.state.commitmentLetterFile !== null)
            formData.append('commitment_letter', this.state.commitmentLetterFile);

        if (!extraFiles && !this.state.commitmentLetterFile){
            const {t} = this.props;
            this.setState({
                submitError: true,
                submitErrorMessage: t("You should upload one of the documents!")
            })
        }
        else{
            if(isFileSizeExceeded(formData))
                return showFileError();

            if (!this.state.submitting){
                this.setState({
                    submitting: true
                })
            }

            this.props.submitForm(formData, applicationId, this.submitFormCallback)
        }
    }

    render(){
        const isSubmitting = this.state.submitting ? "btn-disabled": "";
        const {t} = this.props;

        return(
            <div>
                <h2 className={"t-center"}><T>Update Varying Weight Number Application</T></h2>
                <ContentModule>
                    <T>Checks regarding your application has been done, to complete your application process please upload missing/wrong documents to the system.</T>
                </ContentModule>

                <div>
                    {
                        this.state.currentStep === 1 ?
                            <div><T>Loading...</T></div> :

                            this.state.currentStep === 2 ?
                                <ContentModule>
                                    <form onSubmit={this.handleSubmit} className="editor small signin">
                                        <FileInput inputId={"commitmentLetterFile"} inputTitle={t("Commitment Letter")} handleUploadFile={this.handleUploadFile} required={false}/>
                                        <FileInput inputId={"extraFiles"} name={"extraFiles[]"} inputTitle={t("Extra Files")} handleUploadFile={this.handleUploadFile} multiple={true} required={false} />
                                        <TextInput key={"latestApplicationNote"} inputId={"latestApplicationNote"} required={false} inputTitle={t("Additional Notes")} inputValue={this.state.latestApplicationNotes} isTextArea disabled/>
                                        <div className={"after-submit"}>
                                            {
                                                this.state.submitError &&
                                                <div className="validation-summary-errors">
                                                    <span>{this.state.submitErrorMessage}</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="btnInline mt-10 t-center">
                                            <input className={"signup-button " + isSubmitting} type="submit" value={t("Submit")}/>
                                        </div>
                                    </form>
                                </ContentModule> :

                                this.state.currentStep === 3 ?

                                    <div>
                                        <T>Application is successfully edited</T>
                                    </div> :

                                    this.state.currentStep === 4 ?
                                        <div>
                                            <T>Application cannot be edited</T>
                                        </div> : null
                    }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadApplicationEdit: (applicationId, callback) =>{
            dispatch(vwActions.dbLoadVwApplicationEdit(applicationId, callback))
        },

        submitForm: (data, applicationId, callBack) => {
            dispatch(vwActions.dbEditVwApplication(data, applicationId, callBack))
        },
    }
};

const mapStateToProps = (state) => {
    return {

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslators(ApplicationEdit)));