import axios from "axios/index";
import {getHeaderTokenWithMultipart, getHeaderWithToken} from "./authToken";
import {axiosError} from "./errorHandler";
import {
    requestDataForUpcApplications,
    requestDataForUpcList,
    upcApplicationDetail, upcDetail,
    upcNewApplication
} from "../../constants/UpcUrlConstants";

export const dbCheckCommitmentLetterValidity = () => {
    return new Promise(((resolve, reject) => {
        axios.get(upcNewApplication, getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbCreateUpcApplication = (data) => {
    return new Promise(((resolve, reject) => {
        axios.post(upcNewApplication, data, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbRequestDataForUpcApplications = (pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForUpcApplications +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbRequestDataForUpcList = (pagesize, page, sortedFieldName, sortedIsDesc) => {
    return new Promise(((resolve, reject) => {
        axios.get(requestDataForUpcList +
            "?pagesize=" + pagesize + "&page=" + page + "&sorted_field_name=" + sortedFieldName + "&sorted_is_desc=" + sortedIsDesc,
            getHeaderWithToken())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
            });
    }));
};

export const dbGetUpcApplication = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(upcApplicationDetail + "?application_id=" + applicationId, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};

export const dbGetUpcDetail = (applicationId) => {
    return new Promise(((resolve, reject) => {
        axios.get(upcDetail + "?application_id=" + applicationId, getHeaderTokenWithMultipart())
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                axiosError(error, reject);
                reject(error, reject);
            });
    }));
};
