import React, {Component} from 'react'
import bwipjs from 'bwip-js';
import { saveAs } from 'file-saver/FileSaver';

import {T} from 'lioness';

class BarcodeInput extends Component {

    constructor(props){
        super(props);

        this.state = {
            errorMessage: '',
        };
        this.saveCanvas = this.saveCanvas.bind(this);
        this.saveCanvas1 = this.saveCanvas1.bind(this);
    }

    componentDidMount() {
        const {number, type, id} = this.props;
        const _number = number ? number : '0123456789';
        const _type = type ? type : 'code128';
        let scale = 2;

        if(type === 'gs1datamatrix'){
            scale = 5;
        }

        bwipjs(id, {
                bcid:        _type,       // Barcode type
                text:        _number,    // Text to encode
                scale:       scale,               // 3x scaling factor
                //height:      10,              // Bar height, in millimeters
                includetext: true,            // Show human-readable text
                //textxalign:  'center',        // Always good to set this
            }, (err, cvs) => {
                if (err) {
                    this.setState({
                        errorMessage: err,
                    });
                } else {
                    // Nothing else to do in this example...
                }
            });

        setTimeout(function(){
            {
            bwipjs(id+'_1', {
            backgroundcolor:'FFFFFF',
                bcid:        _type,       // Barcode type
                text:        _number,    // Text to encode
                scale:       scale,               // 3x scaling factor
                //height:      10,              // Bar height, in millimeters
                includetext: true,            // Show human-readable text
                //textxalign:  'center',        // Always good to set this
            }, (err, cvs) => {
                if (err) {
                    this.setState({
                        errorMessage: err,
                    });
                } else {
                    // Nothing else to do in this example...
                }
            });
        }
    },1000)
    }

    saveCanvas() {
        let type = 'image/png';
        let ext = '.png';
        var canvas = document.getElementById(this.props.id);
        canvas.toBlob((blob) => {
                          saveAs(blob, this.props.number + ext);
                      }, type, 1);
    }


    saveCanvas1() {
        let type = 'image/png';
        let ext = '.png';
        var canvas = document.getElementById(this.props.id+'_1')
        canvas.toBlob((blob) => {
                          saveAs(blob, this.props.number + ext);
                      }, type, 1);
    }

    render() {
        const {style} = this.props;
        const _style = style ? style : {};

        return (
          <div className="child-wrapper" style={_style}>
                <div className={"t-center"} onClick={this.saveCanvas} >
                  <a href={"#"}><T>Save as PNG</T></a>
                </div>
                <div className={"t-center"} onClick={this.saveCanvas1} >
                  <a href={"#"}><T>Beyaz Zeminli İndir</T></a>
                </div>
                <canvas id={this.props.id} style={{'marginRight':'50px'}}></canvas>
                <canvas id={this.props.id+'_1'}></canvas>
              <p id={'error'}>{this.state.errorMessage}</p>
          </div>
        );
    }

}

export default BarcodeInput;